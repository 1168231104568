import AuthProtect from '../common/enums/AuthProtect';

export const getBillingInfo = (params) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/billing/get_billing_info',
    params,
  };
};

export const getBillingCharges = (params) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/billing/get_billing_charges',
    params,
  };
};

export const getMinAndMaxBillingId = () => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/billing/get_min_and_max_billing_id',
  };
};

export const createBilling = (data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: '/billing/create',
    data,
  };
};

export const changeBilling = (data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'PUT',
    url: '/billing/update',
    data,
  };
};

export const fetchNewChargesNonAffiliate = (params) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/billing/get_new_charges_non_affiliate',
    params,
  };
};

export const validateInvoiceNumber = (params) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/billing/validate_invoice_no',
    params,
  };
};

export const getMighalBillingDataList = (params) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/billing/get_mighal_billing_data_grid_list',
    params,
  };
};
