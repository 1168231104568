import AuthProtect from '../common/enums/AuthProtect';

export const getPOFileLinkS3 = (params) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/document/po/get-file-link',
    params,
  };
};

export const getMRTFileLinkS3 = (params) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/document/mrt/get-file-link',
    params,
  };
};

export const getDOCSFileLinkS3 = (params) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/document/docs/get-file-link',
    params,
  };
};

export const getDocumentStatus = (params) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/document/status',
    params,
  };
};
