import AuthProtect from '../common/enums/AuthProtect';

export const sendLoadPlan = (containerId, data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: '/email/send_load_plan/' + containerId,
    data,
  };
};

export const sendTodayMRTReceiving = (data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: '/email/send_email_today_mrt_receiving',
    data,
  };
};

export const sendEmailNonMighal = (id, data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: '/email/send_email_non_mighal/' + id,
    data,
  };
};
