/* eslint-disable no-undef */
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useStateValue } from '../../context/StateProvider';
import { Tab, Tabs } from 'react-bootstrap';
import SinglePageView from './SinglePageView';

const PdfTabContainer = () => {
  const [state] = useStateValue();
  const [key, setKey] = useState('matson');

  if (!state.isLogin) return <Redirect to='/login' />;

  if (state.isLogin && state.newUser) return <Redirect to='/change-password' />;

  return (
    <div className='wrapper'>
      <div className='container-fluid'>
        <h2>Shipping Schedules</h2>
      </div>
      <div className='container-fluid'>
        <Tabs id='controlled-tab-schedule' activeKey={key} onSelect={(k) => setKey(k)}>
          <Tab eventKey='matson' title='MATSON Schedule'>
            <SinglePageView
              PdfFile={process.env.REACT_APP_PUBLIC_URL + '/schedule/MATSON_Schedule.pdf'}
            />
          </Tab>
          <Tab eventKey='apl' title='APL Schedule'>
            <SinglePageView
              PdfFile={process.env.REACT_APP_PUBLIC_URL + '/schedule/APL_Schedule.pdf'}
            />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default PdfTabContainer;
