import React from 'react';

function ErrorPage() {
  const message = `Sorry, but this page doesn't exist`;
  return (
    <>
      <div className='errorpage'>
        <h1>{message}</h1>
        <p>
          It looks like the page your looking for is not available. Please return to the home page.
        </p>
      </div>
    </>
  );
}

export default ErrorPage;
