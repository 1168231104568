import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import * as ReactBootstrap from 'react-bootstrap';
import { useStateValue } from '../context/StateProvider';
// import { Chart } from 'react-google-charts';

import LoadingDotsIcon from './LoadingDotsIcon';
import { getMainDBData } from '../services/dashboardEndpoint';
import apiFetch from '../services/apiFetch';

// import SearchDashBoard from './SearchDashBoard';

const MainDashBoard = () => {
  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = useStateValue();
  const [isRender, setIsRender] = useState(true);
  const [dataInfoGrid, setDataInfoGrid] = useState([]);
  // const [pieData, setPieData] = useState([]);
  // const [barData, setBarData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const getDashBoardData = () => {
      return new Promise(async (resolve, reject) => {
        const endpoint = getMainDBData();
        const response = await apiFetch(endpoint);
        if (response && response.data) {
          let arrData = response.data;
          if (Array.isArray(arrData) && arrData.length > 0) {
            resolve(arrData);
          } else {
            reject([]);
          }
        }
      });
    };

    // const generatePieData = (mydata) => {
    //   let newArrData = [];
    //   let pieMyData = [];
    //   let headerData = ['Consignee', 'Total'];
    //   pieMyData.push(headerData);
    //   mydata.forEach((value) => {
    //     newArrData = [value.Destination, value.Total];
    //     pieMyData.push(newArrData);
    //   });
    //
    //   setPieData(pieMyData);
    // };

    // const generateBarData = (mydata) => {
    //   let newArrData = [];
    //   let barMyData = [];
    //   let headerData = ['Consignee', 'MTD', 'YTD'];
    //   barMyData.push(headerData);
    //   mydata.forEach((value) => {
    //     //console.log(value);
    //     newArrData = [value.Destination, value.MTD, value.YTD];
    //     barMyData.push(newArrData);
    //   });
    //
    //   setBarData(barMyData);
    // };

    if (isRender) {
      setDataInfoGrid([]);
      // setPieData([]);

      getDashBoardData()
        .then((result) => {
          //console.log(result);
          setDataInfoGrid(result);
          // generatePieData(result);
          // generateBarData(result);
          setIsFetching(false);
        })
        .catch(() => {
          setDataInfoGrid([]);
          setIsFetching(false);
        });
      setIsRender(false);
    }
    return () => {
      setIsRender(false);
      // dispatch({ type: 'SET_CONTAINER_FILTER', payload: '' });
    };
  }, [isRender]);

  /* if (!appState.isLogin) return <Redirect to='/login' />;

  if (appState.isLogin && appState.newUser)
    return <Redirect to='/change-password' />; */

  const columns = [
    {
      text: 'Consignee',
      dataField: 'Destination',
      sort: true,
      headerStyle: () => {
        return { width: '200px', textAlign: 'center' };
      },
      footer: 'All',
    },
    {
      text: 'Loading',
      dataField: 'InProcess',
      align: 'center',
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
      footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
    },
    {
      text: 'Shipped',
      dataField: 'InTransit',
      align: 'center',
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
      footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
    },
    {
      text: 'Total',
      dataField: 'Total',
      align: 'center',
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
      footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
    },
    {
      text: 'MTD Delivered',
      dataField: 'MTD',
      align: 'center',
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
      footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
    },
    {
      text: 'YTD Delivered',
      dataField: 'YTD',
      align: 'center',
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
      footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
    },
  ];

  const onRowSelect = (row) => {
    dispatch({ type: 'SET_CONTAINER_FILTER', payload: row.Destination });
    history.push('/container-view');
  };

  const selectRowProp = {
    mode: 'radio',
    clickToSelect: true,
    onSelect: onRowSelect,
  };

  // let today = new Date();
  if (isFetching) {
    return (
      <div className='wrapper'>
        <LoadingDotsIcon />
      </div>
    );
  }

  return (
    <div className=''>
      {/* <div className='generic-content-container container'>
        <h2>Main Dash Board</h2>
      </div> */}
      {/* <div className='container'> */}
      <BootstrapTable
        keyField='Destination'
        data={dataInfoGrid}
        columns={columns}
        selectRow={selectRowProp}
        footerClasses='dashboard-foot'
        striped
        hover
        condensed
      />
      <div className='generic-content-container container'>
        <div className='d-flex justify-content-center'>
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={6} className='mr-2'>
              {/*<Chart*/}
              {/*  width={'600px'}*/}
              {/*  height={'400px'}*/}
              {/*  chartType='PieChart'*/}
              {/*  loader={<LoadingDotsIcon />}*/}
              {/*  data={pieData}*/}
              {/*  options={{*/}
              {/*    title: 'Loading & Shipped',*/}
              {/*    // Just add this option*/}
              {/*    is3D: true,*/}
              {/*  }}*/}
              {/*  rootProps={{ 'data-testid': '2' }}*/}
              {/*/>*/}
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={5}>
              {/*<Chart*/}
              {/*  width={'500px'}*/}
              {/*  height={'300px'}*/}
              {/*  chartType='Bar'*/}
              {/*  loader={<LoadingDotsIcon />}*/}
              {/*  data={barData}*/}
              {/*  options={{*/}
              {/*    // Material design options*/}
              {/*    chart: {*/}
              {/*      title: 'Container Delivery Summary',*/}
              {/*      subtitle: `MTD and YTD for Year:${today.getFullYear()}`,*/}
              {/*    },*/}
              {/*  }}*/}
              {/*  rootProps={{ 'data-testid': '2' }}*/}
              {/*/>*/}
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </div>
      </div>
    </div>
  );
};

export default MainDashBoard;
