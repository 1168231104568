import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { SpinnerStyle } from '../styles/SpinnerStyle';

const LoadingSpinner = ({ loading }) => {
  return (
    <div className='content-container'>
      <div className='loading-spinner'>
        <ClipLoader size={100} color={'#0054a6'} loading={loading} css={SpinnerStyle} />
      </div>
    </div>
  );
};

export default LoadingSpinner;
