import AuthProtect from '../common/enums/AuthProtect';

export const getSettings = () => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/settings/get_settings_info',
  };
};

export const updateUserSettingsInfo = (data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'PUT',
    url: '/settings/update_settings_info',
    data,
  };
};
