import React, { useState } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { useStateValue } from '../context/StateProvider';
import ClipLoader from 'react-spinners/ClipLoader';
import { SpinnerStyle } from '../styles/SpinnerStyle';
import imgTJLogo from '../assets/images/tj-logo.jpg';
import { authLogin, forgotPassword } from '../services/userEndpoint';
import apiFetch from '../services/apiFetch';

const LoginPage = () => {
  const [state, dispatch] = useStateValue();
  const [userValue, setUserValue] = useState({
    email: '',
    password: '',
  });
  const [inputType, SetInputType] = useState('password');
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();

  if (state.isLogin) return <Redirect to='/' />;

  const handleChange = (prop) => (ev) => {
    setUserValue({ ...userValue, [prop]: ev.target.value });
  };

  const showHide = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    inputType === 'text' ? SetInputType('password') : SetInputType('text');
  };

  const handleLogin = async (ev) => {
    ev.preventDefault();
    setLoading(true);
    let loginData = {
      email: userValue.email,
      password: userValue.password,
    };

    try {
      const endpoint = authLogin(loginData);
      const response = await apiFetch(endpoint);
      //console.log(response);
      setLoading(false);
      if (response && response.data) {
        /* Save data to local storage */
        setLoading(false);
        localStorage.setItem('logisticsToken', response.data.token);
        localStorage.setItem('logisticsUseremail', response.data.useremail);
        localStorage.setItem('logisticsUserType', response.data.usertype);
        localStorage.setItem('logisticsFirstName', response.data.firstName);
        localStorage.setItem('logisticsLastName', response.data.lastName);
        localStorage.setItem('logisticsLocation', response.data.location);
        // noinspection JSCheckFunctionSignatures
        localStorage.setItem('logisticsFirstLogIn', Number(1));
        dispatch({ type: 'LOG_IN', payload: response.data.location });
        if (userValue.password.toLowerCase() === 'password') {
          dispatch({ type: 'NEW_USER' });
          alert('You are required to change the default password');
          history.push('/change-password');
        } else {
          dispatch({ type: 'PASS_CHANGE' });
          history.push('/');
        }
      } else {
        setLoading(false);
        alert('Incorrect username / password');
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      alert('We experience a technical problem. Please try again later.');
      dispatch({ type: 'LOG_OUT' });
    }
  };

  const handleResetBtn = async () => {
    if (userValue.email === '') {
      return alert('Please enter the User Email');
    }

    try {
      setLoading(true);
      let data = {
        email: userValue.email,
      };
      const response = await apiFetch(forgotPassword(data)).then((res) => res || null);

      if (response) {
        setLoading(false);
        alert(response.data);
      } else {
        setLoading(false);
        alert(response.data);
      }
    } catch (e) {
      setLoading(false);
      //console.log(e);
      alert('User Email is not valid');
    }
  };

  return (
    <div className='w-100'>
      <div className='loading-spinner'>
        <ClipLoader size={100} color={'#0054a6'} loading={isLoading} css={SpinnerStyle} />
      </div>
      <section className='container-fluid login-form login-form__bg-photo'>
        <section className='row justify-content-center'>
          <section className='login-form__container'>
            <div className='login-form__logo'>
              <img src={imgTJLogo} alt='TJ Logo' />
            </div>
            <Form className='login-form__form-container' onSubmit={handleLogin}>
              <label className='login-form__label'>Logistics</label>
              <Form.Group controlId='formBasicEmail'>
                <Form.Label>User Email</Form.Label>
                <Form.Control
                  type='email'
                  placeholder='Enter email'
                  required
                  onChange={handleChange('email')}
                />
              </Form.Group>

              <Form.Group controlId='formBasicPassword'>
                <Form.Label>Password</Form.Label>
                <div className='login-form__password'>
                  <Form.Control
                    className='d-block'
                    type={inputType}
                    placeholder='Password'
                    required
                    onChange={handleChange('password')}
                  />
                  <span onClick={showHide} className='login-form__password-show'>
                    <i className='fa fa-eye-slash pr-3'></i>
                  </span>
                </div>
              </Form.Group>
              <Button className='btn-block' variant='primary' type='submit'>
                Log In
              </Button>
              <div className='login-form__reset-div' onClick={handleResetBtn}>
                <a>Forgot Password</a>
              </div>
            </Form>
          </section>
        </section>
      </section>
    </div>
  );
};

export default LoginPage;
