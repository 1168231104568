import React, { useState, useEffect } from 'react';
import { NavLink, Link, useHistory, useLocation } from 'react-router-dom';
import { useStateValue } from '../context/StateProvider';
import Clock from './Clock';

import imgTJLogo from '../assets/images/tj-logo.jpg';

function SideBarV2() {
  const [state, dispatch] = useStateValue();
  const [isActiveC, setIsActiveC] = useState(false);
  const [isActiveR, setIsActiveR] = useState(false);
  const [isActiveT, setIsActiveT] = useState(false);
  const [isActiveS, setIsActiveS] = useState(false);
  const [navbar, setNavbar] = useState(true);
  const [isUserAuthorize, SetIsUserAuthorize] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const SetUserAccountInfo = async () => {
      let [UserAccount] = await Promise.all([localStorage.getItem('logisticsUserType')]);
      if (UserAccount === 'Admin' || UserAccount === 'Manager') {
        SetIsUserAuthorize(true);
        if (UserAccount === 'Admin') setIsAdmin(true);
        // console.log('SetIsUserAuthorize:', isUserAuthorize);
      } else {
        SetIsUserAuthorize(false);
      }
    };

    SetUserAccountInfo().then(() => null);
  }, [state.isLogin]);

  const toggleViewC = () => {
    setIsActiveC(!isActiveC);
    if (isActiveR) {
      setIsActiveR(false);
    }

    if (isActiveT) {
      setIsActiveT(false);
    }

    if (isActiveS) {
      setIsActiveS(false);
    }

    if (!state.isSidebarActive) {
      showNavbar();
    }
  };
  const toggleViewR = () => {
    setIsActiveR(!isActiveR);
    if (isActiveC) {
      setIsActiveC(false);
    }

    if (isActiveT) {
      setIsActiveT(false);
    }

    if (isActiveS) {
      setIsActiveS(false);
    }

    if (!state.isSidebarActive) {
      showNavbar();
    }
  };
  const toggleViewT = () => {
    setIsActiveT(!isActiveT);
    if (isActiveC) {
      setIsActiveC(false);
    }
    if (isActiveR) {
      setIsActiveR(false);
    }

    if (isActiveS) {
      setIsActiveS(false);
    }

    if (!state.isSidebarActive) {
      showNavbar();
    }
  };
  const toggleViewS = () => {
    if (!isUserAuthorize) {
      return alert('Your account is not allowed to view the system settings!');
    }

    setIsActiveS(!isActiveS);
    if (isActiveC) {
      setIsActiveC(false);
    }
    if (isActiveR) {
      setIsActiveR(false);
    }

    if (isActiveT) {
      setIsActiveT(false);
    }

    if (!state.isSidebarActive) {
      showNavbar();
    }
  };

  const toggleMenuBar = () => {
    toggleHideAllSubmenu();
    showNavbar();
  };

  const toggleHideAllSubmenu = () => {
    setIsActiveC(false);
    setIsActiveR(false);
    setIsActiveT(false);
    setIsActiveS(false);
  };

  const handleLogout = () => {
    dispatch({ type: 'LOG_OUT' });
    toggleHideAllSubmenu();
    localStorage.removeItem('logisticsToken');
    localStorage.removeItem('logisticsUseremail');
    localStorage.removeItem('logisticsUserType');
    localStorage.removeItem('logisticsLocation');
    localStorage.removeItem('logisticsConsignee');
    localStorage.removeItem('logisticsFirstName');
    localStorage.removeItem('logisticsLastName');
    localStorage.removeItem('logisticsFirstLogIn');
    sessionStorage.clear();
    history.push('/login');
  };

  const showNavbar = () => {
    setNavbar(!navbar);
    dispatch({ type: 'TOGGLE_SIDEBAR', payload: !navbar });
  };

  // const verifyUserRights = () => {
  //   if (isUserAuthorize) {
  //     return true;
  //   } else {
  //     alert('Your account is not allowed to view the Users!');
  //     return false;
  //   }
  // };

  // const toggleUserAccountsLink = () => {
  //   toggleHideAllSubmenu();
  //   verifyUserRights();
  // };
  return (
    <>
      <nav
        className={
          !state.isLogin
            ? 'sidebar__not-active'
            : `${state.isSidebarActive ? 'sidebar sidebar--active' : 'sidebar'} `
        }>
        <div className='sidebar--menubar'>
          <i
            className={
              state.isSidebarActive
                ? 'fa fa-bars sidebar--menubar-icon sidebar--menubar-active'
                : 'fa fa-bars sidebar--menubar-icon sidebar--menubar-in-active'
            }
            onClick={toggleMenuBar}
          />
          <p
            className={
              state.isSidebarActive ? 'sidebar--menubar-title' : 'sidebar--menubar-title--inactive'
            }>
            Logistics
          </p>
        </div>
        <div className='main-menu'>
          <ul>
            <li>
              <Link to='/' className='menu-item' onClick={() => toggleHideAllSubmenu()}>
                <div className={`menu-icon ${state.isSidebarActive ? '' : 'menu-icon--inactive'}`}>
                  <i className='fa fa-dashboard' />
                </div>
                <span
                  className={`menu-item--title ${
                    state.isSidebarActive
                      ? 'menu-item--title__active'
                      : 'menu-item--title__inactive'
                  }`}>
                  Dashboard
                </span>
              </Link>
            </li>
            <li>
              <Link
                to='/purchase-order'
                className='menu-item'
                onClick={() => toggleHideAllSubmenu()}>
                <div className={`menu-icon ${state.isSidebarActive ? '' : 'menu-icon--inactive'}`}>
                  <i className='fa fa-paper-plane' />
                </div>
                <span
                  className={`menu-item--title ${
                    state.isSidebarActive
                      ? 'menu-item--title__active'
                      : 'menu-item--title__inactive'
                  }`}>
                  Purchase Order
                </span>
              </Link>
            </li>
            <li>
              <Link to='#' className='menu-item' onClick={toggleViewC}>
                <div className={`menu-icon ${state.isSidebarActive ? '' : 'menu-icon--inactive'}`}>
                  <i className='fa fa-truck' />
                </div>
                <span
                  className={`menu-item--title ${
                    state.isSidebarActive
                      ? 'menu-item--title__active'
                      : 'menu-item--title__inactive'
                  }`}>
                  Container
                </span>
              </Link>
              <div className='sub-menu'>
                <ul className={`${isActiveC ? 'sub-menu--active' : 'sub-menu--inactive'}`}>
                  <li>
                    <NavLink to='/container-mighal' onClick={() => toggleHideAllSubmenu()}>
                      Mighal
                    </NavLink>
                    <NavLink to='/container-nonmighal' onClick={() => toggleHideAllSubmenu()}>
                      Non-Mighal
                    </NavLink>
                    <NavLink to='/container-view' onClick={() => toggleHideAllSubmenu()}>
                      View
                    </NavLink>
                    <NavLink to='/container-billing' onClick={() => toggleHideAllSubmenu()}>
                      Billing
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <Link to='#' className='menu-item' onClick={toggleViewR}>
                <i
                  className={`fa fa-print menu-icon ${
                    state.isSidebarActive ? '' : 'menu-icon--inactive'
                  }`}
                />
                <span
                  className={`menu-item--title ${
                    state.isSidebarActive
                      ? 'menu-item--title__active'
                      : 'menu-item--title__inactive'
                  }`}>
                  Reports
                </span>
              </Link>
              <div className='sub-menu'>
                <ul className={`${isActiveR ? 'sub-menu--active' : 'sub-menu--inactive'}`}>
                  <li>
                    <NavLink to='/containerorderreport' onClick={() => toggleHideAllSubmenu()}>
                      Container Order
                    </NavLink>
                    <NavLink to='/vessellistreport' onClick={() => toggleHideAllSubmenu()}>
                      Vessel List
                    </NavLink>
                    <NavLink to='/containerlistreport' onClick={() => toggleHideAllSubmenu()}>
                      Container List
                    </NavLink>
                    <NavLink to='/postatusreport' onClick={() => toggleHideAllSubmenu()}>
                      PO Status
                    </NavLink>
                    <NavLink to='/mighaltagsreport' onClick={() => toggleHideAllSubmenu()}>
                      Mighal Tags
                    </NavLink>
                    <NavLink to='/mighalbillingreport' onClick={() => toggleHideAllSubmenu()}>
                      Mighal Billing
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <Link to='#' className='menu-item' onClick={toggleViewT}>
                <i
                  className={`fa fa-link menu-icon ${
                    state.isSidebarActive ? '' : 'menu-icon--inactive'
                  }`}
                />
                <span
                  className={`menu-item--title ${
                    state.isSidebarActive
                      ? 'menu-item--title__active'
                      : 'menu-item--title__inactive'
                  }`}>
                  Tracking
                </span>
              </Link>
              <div className='sub-menu'>
                <ul className={`${isActiveT ? 'sub-menu--active' : 'sub-menu--inactive'}`}>
                  <li>
                    <a
                      href='https://www.matson.com/shipment-tracking.html'
                      target='_blank'
                      rel='noopener noreferrer'
                      onClick={() => toggleHideAllSubmenu()}>
                      Matson
                    </a>
                    <a
                      href='https://www.apl.com/ebusiness/tracking'
                      target='_blank'
                      rel='noopener noreferrer'
                      onClick={() => toggleHideAllSubmenu()}>
                      APL
                    </a>
                    <a
                      href='https://www.trackcontainer.net/mariana-express-lines-tracking'
                      target='_blank'
                      rel='noopener noreferrer'
                      onClick={() => toggleHideAllSubmenu()}>
                      Mariana
                    </a>
                    <NavLink to='/shipment-schedules'>Schedule</NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <Link to='#' className='menu-item' onClick={toggleViewS}>
                <i
                  className={`fa fa-cog menu-icon ${
                    state.isSidebarActive ? '' : 'menu-icon--inactive'
                  }`}
                />
                <span
                  className={`menu-item--title ${
                    state.isSidebarActive
                      ? 'menu-item--title__active'
                      : 'menu-item--title__inactive'
                  }`}>
                  Settings
                </span>
              </Link>
              <div className='sub-menu'>
                <ul className={`${isActiveS ? 'sub-menu--active' : 'sub-menu--inactive'}`}>
                  <li>
                    <NavLink to='/settings' onClick={() => toggleHideAllSubmenu()}>
                      Default Settings
                    </NavLink>
                    <NavLink to='/consignee' onClick={() => toggleHideAllSubmenu()}>
                      Consignee
                    </NavLink>
                    <NavLink
                      to={`${isAdmin ? '/user-accounts' : location.pathname}`}
                      onClick={() => toggleHideAllSubmenu()}>
                      Users
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <Link
                to='/change-password'
                className='menu-item'
                onClick={() => toggleHideAllSubmenu()}>
                <div className={`menu-icon ${state.isSidebarActive ? '' : 'menu-icon--inactive'}`}>
                  <i className='fa fa-key' />
                </div>
                <span
                  className={`menu-item--title ${
                    state.isSidebarActive
                      ? 'menu-item--title__active'
                      : 'menu-item--title__inactive'
                  }`}>
                  Password
                </span>
              </Link>
            </li>
            <li>
              <Link
                to='/frequentlyaskquestion'
                className='menu-item'
                onClick={() => toggleHideAllSubmenu()}>
                <div className={`menu-icon ${state.isSidebarActive ? '' : 'menu-icon--inactive'}`}>
                  <i className='fa fa-question-circle' />
                </div>
                <span
                  className={`menu-item--title ${
                    state.isSidebarActive
                      ? 'menu-item--title__active'
                      : 'menu-item--title__inactive'
                  }`}>
                  FAQs
                </span>
              </Link>
            </li>
            <li>
              <a className='menu-item' onClick={handleLogout}>
                <div className={`menu-icon ${state.isSidebarActive ? '' : 'menu-icon--inactive'}`}>
                  <i className='fa fa-sign-out' />
                </div>
                <span
                  className={`menu-item--title ${
                    state.isSidebarActive
                      ? 'menu-item--title__active'
                      : 'menu-item--title__inactive'
                  }`}>
                  Log-Out
                </span>
              </a>
            </li>
          </ul>
        </div>
        <div className='bottom-container'>
          <div
            className={`${
              state.isSidebarActive ? 'logo-container' : 'logo-container logo-container--inactive'
            }`}>
            <img src={imgTJLogo} className='' alt='Logo' />
          </div>
          <div
            className={`${
              state.isSidebarActive ? 'name-container' : 'name-container name-container--inactive'
            }`}>
            {`${state.isSidebarActive ? 'Hi' : ''} ${localStorage.getItem('logisticsFirstName')}`}
          </div>
          <div className='clock-container'>
            <Clock />
          </div>
        </div>
      </nav>
    </>
  );
}

export default SideBarV2;
