import React from 'react';

function FAQDetail({ question, answer }) {
  //console.log(question);
  //console.log(answer);
  return (
    <div className='mb-3'>
      <div className='flex row w-100'>
        <div className='ml-5 mr-2'>
          <h2 className='font-weight-bold faq__header-q'>Q.</h2>
        </div>
        <div className='flex-center text-center mt-auto mb-auto'>
          <p className='mt-auto mb-auto faq__header-question'>{question}</p>
        </div>
      </div>

      <div className='flex row w-100'>
        <div className='ml-5 mr-2'>
          <h2 className='font-weight-bold faq__header-a'>A.</h2>
        </div>
        <div className='flex-center text-center mt-auto mb-auto'>
          <p className='mt-auto mb-auto faq__header-answer'>{answer}</p>
        </div>
      </div>
    </div>
  );
}

export default FAQDetail;
