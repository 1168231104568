import React, { useEffect, useState } from 'react';
import momentTZ from 'moment-timezone';

let today = new Date();
let countryZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
let userToday = momentTZ.tz(today, countryZone).format('MM/DD/YY');
let userTime = momentTZ.tz(today, countryZone).format('hh:mm:ss A');

let initDate = {
  curDate: userToday,
  curTime: userTime,
};

function Clock() {
  const displayDate = initDate.curDate;
  const [displayTime, setDisplayTime] = useState(initDate.curTime);

  useEffect(() => {
    const tick = () => {
      let today = new Date();
      let countryZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let updTimer = momentTZ.tz(today, countryZone).format('hh:mm:ss A');
      setDisplayTime(updTimer);
    };

    const timerID = setInterval(() => tick(), 1000);

    return () => {
      clearInterval(timerID);
    };
  }, [displayTime]);

  return (
    <div className='clock'>
      {displayDate} {displayTime}
    </div>
  );
}

export default Clock;
