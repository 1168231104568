import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import * as ReactBootstrap from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ClipLoader from 'react-spinners/ClipLoader';
import { useStateValue } from '../../context/StateProvider';
import momentTZ from 'moment-timezone';
import { SpinnerStyle } from '../../styles/SpinnerStyle';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import FileSaver from 'file-saver';
import { fetchPOStatusGrid } from '../../services/poEndpoint';
import apiFetch from '../../services/apiFetch';
import {
  getPOStatusListPDF,
  getPOStatusListWithDeparturePDF,
  getRiderTempListPDF,
  getVendorMonitoringPDF,
} from '../../services/reportsEndpoint';
import { createPOStatusList } from '../../services/excelEndpoint';

function POStatus() {
  const [state] = useStateValue();
  const [isLoading, setIsLoading] = useState(false);
  const [getListRender, setGetListRender] = useState(true);
  const [dataInfoGrid, SetDataInfoGrid] = useState([]);
  const [consignee, SetConsignee] = useState('');
  const [sortBy, SetSortBy] = useState('po_status');
  const [includeDelivered, SetIncludeDelivered] = useState(false);
  const [filterBy, SetFilterBy] = useState('ALL');
  const [filterByTemp, setFilterByTemp] = useState('DRY');
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [deptDateSelect, setDeptDateSelect] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const endpoint = fetchPOStatusGrid();
        const response = await apiFetch(endpoint);

        if (response) {
          let arr = response.data;

          if (Array.isArray(arr) && arr.length > 0) {
            SetDataInfoGrid(arr);
            //console.log(arr);
          } else {
            SetDataInfoGrid([]);
          }
        } else {
          SetDataInfoGrid([]);
        }
        setGetListRender(false);
      } catch (error) {
        setGetListRender(false);
        console.log(error);
      }
    };

    if (getListRender) {
      getData().then(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }

    return () => setGetListRender(false);
  }, [getListRender, dataInfoGrid]);

  if (!state.isLogin) return <Redirect to='/login' />;
  if (state.isLogin && state.newUser) return <Redirect to='/change-password' />;

  //let postatusFilter = '';

  /* const clearFilter = () => {
    postatusFilter('');
  }; */

  const columns = [
    {
      text: 'Consignee',
      dataField: 'Consignee',
      sort: true,
      headerStyle: () => {
        return { width: '200px', textAlign: 'center' };
      },
      footer: 'All',
    },
    {
      text: 'Open',
      dataField: 'TotalOpen',
      align: 'center',
      headerStyle: () => {
        return { width: '150px', textAlign: 'center' };
      },
      footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
    },
    {
      text: 'Received',
      dataField: 'TotalReceived',
      align: 'center',
      headerStyle: () => {
        return { width: '150px', textAlign: 'center' };
      },
      footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
    },
    {
      text: 'Loading',
      dataField: 'TotalLoading',
      align: 'center',
      headerStyle: () => {
        return { width: '150px', textAlign: 'center' };
      },
      footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
    },
    {
      text: 'Shipped',
      dataField: 'TotalShipped',
      align: 'center',
      headerStyle: () => {
        return { width: '150px', textAlign: 'center' };
      },
      footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
    },
    {
      text: 'Total',
      dataField: 'Total',
      align: 'center',
      headerStyle: () => {
        return { width: '150px', textAlign: 'center' };
      },
      footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
    },
  ];

  const onRowSelect = (row) => {
    //console.log(row);
    try {
      SetConsignee(row.Consignee);
    } catch (error) {
      console.log(error);
    }
  };

  const selectRowProp = {
    mode: 'radio',
    clickToSelect: true,
    onSelect: onRowSelect,
  };

  const handlePOStatusPdfReport = (ev) => {
    ev.preventDefault();
    try {
      if (consignee !== '') {
        setIsLoading(true);
        if (deptDateSelect) {
          downloadDepartureReportPdf()
            .then((response) => {
              setIsLoading(false);
              const url = window.URL.createObjectURL(
                new Blob([response.data], { type: 'application/pdf' }),
              );
              window.open(url);
            })
            .catch((err) => {
              console.log(err);
              alert('Error Generating PDF Report');
              setIsLoading(false);
            });
        } else {
          downloadViewReportPdf()
            .then((response) => {
              setIsLoading(false);
              const url = window.URL.createObjectURL(
                new Blob([response.data], { type: 'application/pdf' }),
              );
              window.open(url);
            })
            .catch((err) => {
              console.log(err);
              alert('Error Generating PDF Report');
              setIsLoading(false);
            });
        }
      } else {
        alert('Please select a Consignee');
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const downloadViewReportPdf = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let today = new Date();
        let countryZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let userToday = momentTZ.tz(today, countryZone).format('MM/DD/YYYY');
        let userAsOf = momentTZ.tz(today, countryZone).format('MMMM DD, YYYY');
        let printTime = momentTZ.tz(today, countryZone).format('hh:mm A');

        const data = {
          UserEmail: localStorage.getItem('logisticsUseremail'),
          Consignee: consignee,
          SortBy: sortBy,
          FilterBy: filterBy,
          FilterTemp: filterByTemp,
          IncludeDelivered: includeDelivered,
          userToday,
          userAsOf,
          printTime,
        };
        const endpoint = getPOStatusListPDF(data);
        let response = await apiFetch(endpoint);
        if (response && response.data.type !== 'application/json') {
          resolve(response);
        } else {
          reject('Failed');
        }
      } catch (error) {
        console.log(error);
        reject('Failed');
      }
    });
  };

  const formatDateSQL = (valueDate) => {
    let d = new Date(valueDate),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('/');
  };

  const downloadDepartureReportPdf = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const today = new Date();
        const countryZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
        const userToday = momentTZ.tz(today, countryZone).format('MM/DD/YYYY');
        const userAsOf = momentTZ.tz(today, countryZone).format('MMMM DD, YYYY');
        const printTime = momentTZ.tz(today, countryZone).format('hh:mm A');

        const data = {
          UserEmail: localStorage.getItem('logisticsUseremail'),
          Consignee: consignee,
          FilterStatus: filterBy,
          FilterTemp: filterByTemp,
          BeginDate: formatDateSQL(fromDate),
          EndDate: formatDateSQL(toDate),
          IncludeDelivered: includeDelivered,
          userToday,
          userAsOf,
          printTime,
        };
        const endpoint = getPOStatusListWithDeparturePDF(data);
        const response = await apiFetch(endpoint);

        if (response && response.data.type !== 'application/json') {
          resolve(response);
        } else {
          reject('Failed');
        }
      } catch (error) {
        console.log(error);
        reject('Failed');
      }
    });
  };

  const handleGetRiderTempReport = (ev) => {
    ev.preventDefault();
    try {
      if (consignee !== '') {
        setIsLoading(true);
        downloadRiderTempReportPdf()
          .then((response) => {
            setIsLoading(false);
            const url = window.URL.createObjectURL(
              new Blob([response.data], { type: 'application/pdf' }),
            );
            window.open(url);
          })
          .catch((err) => {
            console.log(err);
            alert('Error Generating PDF Report');
            setIsLoading(false);
          });
      } else {
        alert('Please select a Consignee');
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const downloadRiderTempReportPdf = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const today = new Date();
        const countryZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
        const userToday = momentTZ.tz(today, countryZone).format('MM/DD/YYYY');
        const userAsOf = momentTZ.tz(today, countryZone).format('MMMM DD, YYYY');
        const printTime = momentTZ.tz(today, countryZone).format('hh:mm A');

        const data = {
          UserEmail: localStorage.getItem('logisticsUseremail'),
          Consignee: consignee,
          SortBy: sortBy,
          userToday,
          userAsOf,
          printTime,
        };
        const endpoint = getRiderTempListPDF(data);
        const response = await apiFetch(endpoint);

        if (response && response.data.type !== 'application/json') {
          resolve(response);
        } else {
          reject('Failed');
        }
      } catch (error) {
        console.log(error);
        reject('Failed');
      }
    });
  };

  const handleGetVendorMonitorReport = (ev) => {
    ev.preventDefault();
    try {
      if (consignee !== '') {
        setIsLoading(true);
        downloadMonitoringReportPdf()
          .then((response) => {
            setIsLoading(false);
            const url = window.URL.createObjectURL(
              new Blob([response.data], { type: 'application/pdf' }),
            );
            window.open(url);
          })
          .catch((err) => {
            console.log(err);
            alert('There was no OPEN PO with past due delivery!');
            setIsLoading(false);
          });
      } else {
        alert('Please select a Consignee');
      }
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const downloadMonitoringReportPdf = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const today = new Date();
        const countryZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
        const userToday = momentTZ.tz(today, countryZone).format('MM/DD/YYYY');
        const userAsOf = momentTZ.tz(today, countryZone).format('MMMM DD, YYYY');
        const printTime = momentTZ.tz(today, countryZone).format('hh:mm A');

        const data = {
          UserEmail: localStorage.getItem('logisticsUseremail'),
          Consignee_Name: consignee,
          userToday,
          userAsOf,
          printTime,
        };
        const endpoint = getVendorMonitoringPDF(data);
        const response = await apiFetch(endpoint);
        //console.log(response);
        if (response && response.data.type !== 'application/json') {
          resolve(response);
        } else {
          reject('Failed');
        }
      } catch (e) {
        console.log(e);
        reject('Failed');
        setIsLoading(false);
      }
    });
  };

  const handleSortChange = (ev) => {
    //console.log(ev.target.value);
    SetSortBy(ev.target.value);
  };

  const handleFilterPOChange = (ev) => {
    SetFilterBy(ev.target.value);
    if (ev.target.value === 'OPEN') {
      SetSortBy('MighalETD');
    }

    if (ev.target.value === 'RECEIVED') {
      SetSortBy('MRTdate');
    }

    if (ev.target.value === 'ALL') {
      SetSortBy('po_status');
    }
  };

  const handleFilterTempChange = (ev) => setFilterByTemp(ev.target.value);

  const handleCheckedIsDelivered = () => {
    SetIncludeDelivered(!includeDelivered);
  };

  const handleDeptDateSelect = () => {
    setDeptDateSelect(!deptDateSelect);
    setFilterByTemp('ALL');

    if (!deptDateSelect) {
      SetSortBy('Container');
    } else {
      SetSortBy('po_status');
    }
    if (filterBy !== 'LOADING' || filterBy !== 'SHIPPED') {
      SetFilterBy('SHIPPED');
    }
  };

  const modifiedDate = (dateValue) => {
    return new Date(momentTZ.tz(dateValue, 'Pacific/Guam'));
  };

  const onFromDateChange = (selected) => {
    if (selected > toDate) {
      return alert('From Date should not be higher than To Date');
    }

    let AdjusteddateValue = new Date(momentTZ.tz(selected, 'Pacific/Guam'));
    setFromDate(AdjusteddateValue);
  };

  const onToDateChange = (selected) => {
    if (selected < fromDate) {
      return alert('To Date should not be lower than From Date');
    }

    let AdjusteddateValue = new Date(momentTZ.tz(selected, 'Pacific/Guam'));
    setToDate(AdjusteddateValue);
  };

  const handleExportExcel = () => {
    try {
      if (consignee !== '') {
        if (!deptDateSelect) {
          setIsLoading(true);
          downloadExportExcel()
            .then((res) => {
              let byteCharacters = atob(res.data);
              let byteNumbers = new Array(byteCharacters.length);
              for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              let byteArray = new Uint8Array(byteNumbers);
              let blob = new Blob([byteArray], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              });

              /* let blob = new Blob([res.data], {
                type: 'contentType',
              }); */
              FileSaver.saveAs(blob, `POExcelSheet_${consignee}.xlsx`);
              setIsLoading(false);
            })
            .catch(() => {
              setIsLoading(false);
              alert('Unable to generate Excel');
            });
        } else {
          alert('Selection with Departure Date not available');
        }
      } else {
        alert('Please select a Consignee');
      }
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const downloadExportExcel = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const data = {
          UserEmail: localStorage.getItem('logisticsUseremail'),
          Consignee: consignee,
          SortBy: sortBy,
          FilterBy: filterBy,
          FilterTemp: filterByTemp,
          IncludeDelivered: includeDelivered,
        };
        const endpoint = createPOStatusList(data);
        const response = await apiFetch(endpoint);

        if (response) {
          resolve(response);
        } else {
          reject('Failed');
        }
      } catch (e) {
        console.log(e);
        reject('Failed');
      }
    });
  };

  return (
    <div className='wrapper'>
      <div className='loading-spinner'>
        <ClipLoader size={100} color={'#0054a6'} loading={isLoading} css={SpinnerStyle} />
      </div>
      <div className='help-button'>
        <a
          href='https://triplejlogistics.org/help/ReportsPOStatusMenu.html'
          target='_blank'
          rel='noopener noreferrer'
          className='btn btn-labeled btn-outline-dark btn-sm mr-1'>
          <span className='btn-label'>
            <i className='fa fa-question-circle pr-1'></i>
          </span>
          Help
        </a>
      </div>
      <div className='generic-content-container container-fluid'>
        <h2>P.O. Status</h2>
      </div>
      <div className='container-fluid'>
        <div className='container-fluid'>
          <ReactBootstrap.Row className='mb-2'>
            <button
              type='button'
              className='btn btn-labeled btn-primary btn-sm mr-1'
              onClick={handlePOStatusPdfReport}>
              <span className='btn-label'>
                <i className='fa fa-print pr-1'></i>
              </span>
              View PDF Report
            </button>
            <button
              type='button'
              className='btn btn-labeled btn-primary btn-sm mr-1'
              onClick={handleGetVendorMonitorReport}>
              <span className='btn-label'>
                <i className='fa fa-print pr-1'></i>
              </span>
              Vendor Delivery Monitor
            </button>
            <button
              type='button'
              className='btn btn-labeled btn-primary btn-sm mr-1'
              onClick={handleGetRiderTempReport}>
              <span className='btn-label'>
                <i className='fa fa-print pr-1'></i>
              </span>
              View Rider and Temp PO
            </button>
            <button
              type='button'
              className='btn btn-labeled btn-primary btn-sm mr-1'
              onClick={handleExportExcel}>
              <span className='btn-label'>
                <i className='fa fa-file-excel-o pr-1'></i>
              </span>
              Export to Excel Sheet
            </button>
            <ReactBootstrap.Form.Check
              type='checkbox'
              checked={includeDelivered}
              onChange={handleCheckedIsDelivered}
              id='select_isdelivered'
              className='ml-2 mt-auto mb-auto justify-content-center h-100 align-items-center'
              label='Delivered '
            />
          </ReactBootstrap.Row>
          <ReactBootstrap.Row className='mb-2'>
            <ReactBootstrap.Form.Check
              type='checkbox'
              checked={deptDateSelect}
              onChange={handleDeptDateSelect}
              id='select_deptDateSelect'
              className='ml-2 mt-auto mb-auto justify-content-center h-100 align-items-center'
              label='Find By Departure Date'
            />
            <label className='ml-3 mr-1 mt-auto mb-auto'>From: </label>
            <div className='ml-1 mt-auto mb-auto'>
              <DatePicker
                selected={modifiedDate(fromDate)}
                maxDate={toDate}
                locale='en'
                onChange={onFromDateChange}
                className=''
                disabled={!deptDateSelect}
              />
            </div>
            <label className='ml-3 mr-1 mt-auto mb-auto'>To: </label>
            <div className='ml-1 mt-auto mb-auto'>
              <DatePicker
                selected={modifiedDate(toDate)}
                minDate={fromDate}
                locale='en'
                onChange={onToDateChange}
                className=''
                disabled={!deptDateSelect}
              />
            </div>
          </ReactBootstrap.Row>
          <ReactBootstrap.Row className='mb-2'>
            <label className='ml-1 mr-1 mt-auto mb-auto'>Filter By Temperature: </label>
            <input
              className='radio-btn mt-auto mb-auto'
              type='radio'
              id='filter_temp'
              name='filter_temp'
              value='DRY'
              checked={filterByTemp === 'DRY'}
              onChange={handleFilterTempChange}
            />
            <label htmlFor='filter_temp_dry' className='mt-auto mb-auto'>
              DRY
            </label>
            <input
              className='radio-btn mt-auto mb-auto'
              type='radio'
              id='filter_temp'
              name='filter_temp'
              value='CHILL'
              checked={filterByTemp === 'CHILL'}
              onChange={handleFilterTempChange}
            />
            <label htmlFor='filter_temp_chill' className='mt-auto mb-auto'>
              CHILL
            </label>
            <input
              className='radio-btn mt-auto mb-auto'
              type='radio'
              id='filter_temp'
              name='filter_temp'
              value='FREEZE'
              checked={filterByTemp === 'FREEZE'}
              onChange={handleFilterTempChange}
            />
            <label htmlFor='filter_temp_freeze' className='mt-auto mb-auto'>
              FREEZE
            </label>
            <input
              className='radio-btn mt-auto mb-auto'
              type='radio'
              id='filter_temp'
              name='filter_temp'
              value='ALL'
              checked={filterByTemp === 'ALL'}
              onChange={handleFilterTempChange}
            />
            <label htmlFor='filter_temp_freeze' className='mt-auto mb-auto'>
              ALL
            </label>
          </ReactBootstrap.Row>
          <ReactBootstrap.Row className='mb-2'>
            <label className='ml-1 mr-1 mt-auto mb-auto'>Filter By Status: </label>
            <input
              className='radio-btn mt-auto mb-auto'
              type='radio'
              id='filter_po'
              name='filter_po'
              value='ALL'
              checked={filterBy === 'ALL'}
              onChange={handleFilterPOChange}
            />
            <label htmlFor='filter_po_all' className='mt-auto mb-auto'>
              ALL
            </label>
            <input
              className='radio-btn mt-auto mb-auto'
              type='radio'
              id='filter_po'
              name='filter_po'
              value='OPEN'
              checked={filterBy === 'OPEN'}
              onChange={handleFilterPOChange}
            />
            <label htmlFor='filter_po_open' className='mt-auto mb-auto'>
              OPEN
            </label>
            <input
              className='radio-btn mt-auto mb-auto'
              type='radio'
              id='filter_po'
              name='filter_po'
              value='RECEIVED'
              checked={filterBy === 'RECEIVED'}
              onChange={handleFilterPOChange}
            />
            <label htmlFor='filter_po_received' className='mt-auto mb-auto'>
              RECEIVED
            </label>
            <input
              className='radio-btn mt-auto mb-auto'
              type='radio'
              id='filter_po'
              name='filter_po'
              value='LOADING'
              checked={filterBy === 'LOADING'}
              onChange={handleFilterPOChange}
            />
            <label htmlFor='filter_po_loading' className='mt-auto mb-auto'>
              LOADING
            </label>
            <input
              className='radio-btn mt-auto mb-auto'
              type='radio'
              id='filter_po'
              name='filter_po'
              value='SHIPPED'
              checked={filterBy === 'SHIPPED'}
              onChange={handleFilterPOChange}
            />
            <label htmlFor='filter_po_shipped' className='mt-auto mb-auto'>
              SHIPPED
            </label>
          </ReactBootstrap.Row>
          <ReactBootstrap.Row className='mb-3'>
            <label className='ml-1 mr-1 mt-auto mb-auto'>Sort By: </label>
            <input
              className='radio-btn mt-auto mb-auto'
              type='radio'
              id='status'
              name='sort_by'
              value='po_status'
              checked={sortBy === 'po_status'}
              onChange={handleSortChange}
              hidden={deptDateSelect}
            />
            <label htmlFor='sort_by_po' className='mt-auto mb-auto' hidden={deptDateSelect}>
              Status
            </label>
            <input
              className='radio-btn mt-auto mb-auto'
              type='radio'
              id='vendor'
              name='sort_by'
              value='shipper'
              checked={sortBy === 'shipper'}
              onChange={handleSortChange}
            />
            <label htmlFor='sort_by_shipper' className='mt-auto mb-auto'>
              Vendor Name
            </label>
            <input
              className='radio-btn mt-auto mb-auto'
              type='radio'
              id='temperature'
              name='sort_by'
              value='Temperature'
              checked={sortBy === 'Temperature'}
              onChange={handleSortChange}
              hidden={deptDateSelect}
            />
            <label htmlFor='sort_by_temp' className='mt-auto mb-auto' hidden={deptDateSelect}>
              Temperature
            </label>
            <input
              className='radio-btn mt-auto mb-auto'
              type='radio'
              id='SortPO_Date'
              name='sort_by'
              value='MighalETD'
              checked={sortBy === 'MighalETD'}
              onChange={handleSortChange}
              hidden={deptDateSelect}
            />
            <label htmlFor='sort_by_date' className='mt-auto mb-auto' hidden={deptDateSelect}>
              Delivery Date
            </label>
            <input
              className='radio-btn mt-auto mb-auto'
              type='radio'
              id='SortPO_Date'
              name='sort_by'
              value='MRTdate'
              checked={sortBy === 'MRTdate'}
              onChange={handleSortChange}
            />
            <label htmlFor='sort_by_mrtdate' className='mt-auto mb-auto'>
              MRT Date
            </label>
            <input
              className='radio-btn mt-auto mb-auto'
              type='radio'
              id='temperature'
              name='sort_by'
              value='PO_No'
              checked={sortBy === 'PO_No'}
              onChange={handleSortChange}
            />
            <label htmlFor='sort_by_po' className='mt-auto mb-auto'>
              PO No.
            </label>
            <input
              className='radio-btn mt-auto mb-auto'
              type='radio'
              id='temperature'
              name='sort_by'
              value='Container'
              checked={sortBy === 'Container'}
              onChange={handleSortChange}
              hidden={!deptDateSelect}
            />
            <label htmlFor='sort_by_po' className='mt-auto mb-auto' hidden={!deptDateSelect}>
              Container No.
            </label>
            <input
              className='radio-btn mt-auto mb-auto'
              type='radio'
              id='temperature'
              name='sort_by'
              value='Departure'
              checked={sortBy === 'Departure'}
              onChange={handleSortChange}
              hidden={!deptDateSelect}
            />
            <label htmlFor='sort_by_po' className='mt-auto mb-auto' hidden={!deptDateSelect}>
              Departure Date
            </label>
          </ReactBootstrap.Row>
        </div>
      </div>
      <div className='container-fluid table--horiz-scroll'>
        <BootstrapTable
          keyField='Consignee'
          data={dataInfoGrid}
          columns={columns}
          selectRow={selectRowProp}
          footerClasses='dashboard-foot'
          striped
          hover
        />
      </div>
    </div>
  );
}

export default POStatus;
