import AuthProtect from '../common/enums/AuthProtect';

export const consigneeCreate = (data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: '/consignee/create',
    data,
  };
};

export const consigneeUpdate = (data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'PUT',
    url: '/consignee/update',
    data,
  };
};

export const consigneeGetAddress = (params) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/consignee/get_address',
    params,
  };
};

export const consigneeGetList = (params) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/consignee/get_list',
    params,
  };
};

export const getLocationCode = (params) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/consignee/get_location_code',
    params,
  };
};

export const getConsigneeAttention = (params) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/consignee/get_attention',
    params,
  };
};

export const getConsigneeList = (params) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/consignee/get_list',
    params,
  };
};
