// noinspection JSValidateTypes

import React, { useRef, useState } from 'react';
import { useStateValue } from '../context/StateProvider';
import IdleTimer from 'react-idle-timer';
import { useHistory } from 'react-router-dom';

function IdleTimerContainer() {
  const [state, dispatch] = useStateValue();
  const [isTimedOut, setIsTimedOut] = useState(false);
  const idleTimerRef = useRef(null);
  const sessionTimoutRef = useRef(null);
  const timeoutDuration = 1000 * 60 * 10; // 30 minutes
  const history = useHistory();

  const onIdle = () => {
    if (state.isLogin && isTimedOut) {
      console.log('User is idle');
      sessionTimoutRef.current = setTimeout(logOut, timeoutDuration);
    } else {
      idleTimerRef.current.reset();
      setIsTimedOut(true);
      // console.log(idleTimerRef);
    }
  };

  const logOut = () => {
    console.log('User is logged out');
    clearTimeout(sessionTimoutRef.current);
    dispatch({ type: 'LOG_OUT' });
    localStorage.removeItem('logisticsToken');
    localStorage.removeItem('logisticsUseremail');
    localStorage.removeItem('logisticsUserType');
    localStorage.removeItem('logisticsLocation');
    localStorage.removeItem('logisticsConsignee');
    localStorage.removeItem('logisticsFirstName');
    localStorage.removeItem('logisticsLastName');
    localStorage.removeItem('logisticsFirstLogIn');
    sessionStorage.clear();
    history.push('/login');
  };

  const onAction = () => {
    if (state.isLogin) {
      setIsTimedOut(false);
    }
  };

  const onActive = () => {
    if (state.isLogin) {
      console.log('User is active');
      setIsTimedOut(false);
      clearTimeout(sessionTimoutRef.current);
    }
  };

  return (
    <>
      <IdleTimer
        ref={idleTimerRef}
        element={document}
        timeout={timeoutDuration}
        onActive={onActive}
        onAction={onAction}
        onIdle={onIdle}
      />
    </>
  );
}

export default IdleTimerContainer;
