import React from 'react';

const GenericEmptyTable = () => {
  return (
    <div className='empty_table'>
      <div className='empty_table__wrapper'>
        <h4 className='empty_table__header'>There is no data available</h4>
        <h5>Please try again.</h5>
      </div>
    </div>
  );
};

export default GenericEmptyTable;
