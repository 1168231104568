import AuthProtect from '../common/enums/AuthProtect';

export const getMighalBillingPDF = (params) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    responseType: 'blob',
    url: '/reports/get_mighal_billing_pdf',
    params,
  };
};

export const getMighalBillingDetailBreakDown = (data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    responseType: 'blob',
    url: '/reports/pdf_maker/get_mighal_billing_detail_breakdown',
    data,
  };
};

export const getPOListByContNoPDF = (data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    responseType: 'blob',
    url: '/reports/get_po_list_by_container_no_pdf',
    data,
  };
};

export const getSummaryLoadingContainerPDF = (data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    responseType: 'blob',
    url: '/reports/pdf_maker/get_summary_loading_container_pdf',
    data,
  };
};

export const getVesselZeroPOAmountsPDF = (data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    responseType: 'blob',
    url: '/reports/pdf_maker/get_vessel_zero_po_amounts',
    data,
  };
};

export const getPOStatusListPDF = (data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    responseType: 'blob',
    url: '/reports/get_po_status_list_pdf',
    data,
  };
};

export const getPOStatusListWithDeparturePDF = (data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    responseType: 'blob',
    url: '/reports/get_po_status_list_with_departure_pdf',
    data,
  };
};

export const getRiderTempListPDF = (data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    responseType: 'blob',
    url: '/reports/pdf_maker/get_rider_temp_list_pdf',
    data,
  };
};

export const getVendorMonitoringPDF = (data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    responseType: 'blob',
    url: '/reports/pdf_maker/get_vendor_monitoring_pdf',
    data,
  };
};

export const getMighalTagsPDF = (data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    responseType: 'blob',
    url: '/reports/pdf_maker/get_mighal_tags_pdf',
    data,
  };
};
