import React from 'react';
import FAQDetail from '../components/FAQDetail';

function FAQ() {
  const FaqMsg = [
    {
      question: 'What is a Consignee ?',
      answer: 'This a company in a location who will receive the Container delivery.',
    },
    {
      question: 'What is Users ?',
      answer: 'These are the allowed users to use the www.triplejlogistics.org',
    },
    {
      question: 'What is  a Load Plan ?',
      answer: 'These are the items/po’s that will be loaded to a container.',
    },
    {
      question: 'What is a Shipped Out ?',
      answer: 'This a final list to be shipped to a consignee destination.',
    },
    {
      question: 'How to enter a Split Purchase Order ?',
      answer: 'Original Purchase Order Number plus suffix “X” (i.e GD-123456X).',
    },
    {
      question: 'How to enter a SIT container ?',
      answer: 'Original Container Number plus suffix “X” (i.e. MATU12345678X).',
    },
    {
      question: 'How to enter a RIDERS Purchase Order# ?',
      answer:
        'A sample for Riders PO# GD-RIDER1, G – location stands for Guam, D – Temperature for Dry, RIDER + sequence#.',
    },
    {
      question: 'What is a Container Unique Identifier# ?',
      answer:
        'It’s a temporary container number stands for Location + Temperature + Departure Date + Sequence Number.',
    },
  ];

  return (
    <div className='wrapper'>
      <div className='generic-content-container container text-center'>
        <h1 className='mt-3 faq__title'>{`Frequently Ask Questions (FAQ)`}</h1>
      </div>
      <div className='generic-content-container container'>
        {FaqMsg &&
          FaqMsg.map((msg, index) => {
            return <FAQDetail key={index} question={msg.question} answer={msg.answer} />;
          })}
      </div>
    </div>
  );
}

export default FAQ;
