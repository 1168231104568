import AuthProtect from '../common/enums/AuthProtect';

export const getMainDBData = () => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/dashboard/get_main_dashboard',
  };
};

export const getPODBData = () => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/dashboard/get_po_dashboard',
  };
};
