import React, { useState } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { useStateValue } from '../context/StateProvider';
import ClipLoader from 'react-spinners/ClipLoader';
import imgTJLogo from '../assets/images/tj-logo.jpg';
import { SpinnerStyle } from '../styles/SpinnerStyle';
import { resetUserPassword } from '../services/userEndpoint';
import apiFetch from '../services/apiFetch';

const ResetPassword = (props) => {
  // const state = useContext(StateContext);
  const [state] = useStateValue();
  const [inputType, SetInputType] = useState('password');
  const [isLoading, SetLoading] = useState(false);
  const [userValue, setUserValue] = useState({
    newpassword: '',
    confirmpassword: '',
  });
  const history = useHistory();

  if (state.isLogin) return <Redirect to='/' />;

  let errors = [];

  const handleChange = (prop) => (ev) => {
    setUserValue({ ...userValue, [prop]: ev.target.value });
  };

  const showHide = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    inputType === 'text' ? SetInputType('password') : SetInputType('text');
  };

  const validate = () => {
    return new Promise((resolve) => {
      if (userValue.newpassword === '') {
        errors.push('Please enter a New Password');
      }

      if (userValue.confirmpassword === '') {
        errors.push('Please enter a confirm your new password');
      }

      if (userValue.newpassword.trim() !== userValue.confirmpassword.trim()) {
        errors.push('Confirm password does not match with your new password');
      }

      if (userValue.newpassword.toLowerCase() === 'password') {
        errors.push('Cannot use default password');
      }

      if (userValue.newpassword.length > 0 && userValue.newpassword.length < 8) {
        errors.push('Password must be at least 8 characters');
      }

      if (userValue.newpassword.length > 20) {
        errors.push('Password cannot exceed 20 characters');
      }

      resolve('Done');
    });
  };

  const handleSubmitNewPass = async (ev) => {
    ev.preventDefault();
    SetLoading(true);
    try {
      await validate();
      if (!errors.length) {
        let body = {
          // eslint-disable-next-line react/prop-types
          token: props.match.params.id,
          NewPassword: userValue.newpassword,
        };

        //console.log(body);
        changeUserPassword(body)
          .then((result) => {
            //console.log(result);
            SetLoading(false);
            if (result === 'Success') {
              alert('Successfully reset the password. Please login');
              history.push('/login');
            } else {
              alert('Something went wrong');
            }
          })
          .catch(() => {
            SetLoading(false);
            alert('Unable to change password!');
          });
      } else {
        SetLoading(false);
        errors.forEach((msg) => {
          alert(msg);
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const changeUserPassword = (body) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoint = resetUserPassword(body.token, body);
        const response = await apiFetch(endpoint).catch((err) => {
          console.log(err);
          SetLoading(false);
          reject('Failed');
        });
        //console.log(response);
        if (response && response.data) {
          resolve('Success');
        } else {
          reject('Failed');
        }
      } catch (e) {
        console.log(e);
        reject('Failed');
      }
    });
  };

  return (
    <div className='w-100'>
      <div className='loading-spinner'>
        <ClipLoader size={100} color={'#0054a6'} loading={isLoading} css={SpinnerStyle} />
      </div>
      <section className='container-fluid login-form login-form__bg-photo'>
        <section className='row justify-content-center'>
          <section className='login-form__container'>
            <div className='login-form__logo'>
              <img src={imgTJLogo} alt='TJ Logo' />
            </div>
            <Form className='login-form__form-container' onSubmit={handleSubmitNewPass}>
              <label className='login-form__label'>Logistics</label>
              <Form.Group controlId='formBasicEmail'>
                <Form.Label>New Password</Form.Label>
                <div className='login-form__password'>
                  <Form.Control
                    className='d-block'
                    type={inputType}
                    placeholder='New Password'
                    required
                    onChange={handleChange('newpassword')}
                  />
                  <span onClick={showHide} className='login-form__password-show'>
                    <i className='fa fa-eye-slash pr-3'></i>
                  </span>
                </div>
              </Form.Group>
              <Form.Group controlId='formBasicPassword'>
                <Form.Label>Confirm Password</Form.Label>
                <div className='login-form__password'>
                  <Form.Control
                    className='d-block'
                    type={inputType}
                    placeholder='Confirm Password'
                    required
                    onChange={handleChange('confirmpassword')}
                  />
                  <span onClick={showHide} className='login-form__password-show'>
                    <i className='fa fa-eye-slash pr-3'></i>
                  </span>
                </div>
              </Form.Group>
              <Button className='btn-block' variant='primary' type='submit'>
                Submit
              </Button>
            </Form>
          </section>
        </section>
      </section>
    </div>
  );
};

export default ResetPassword;
