import React from 'react';

function LoadingDotsIcon() {
  return (
    <div className='dots-loading'>
      <div></div>
    </div>
  );
}

export default LoadingDotsIcon;
