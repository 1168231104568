import React, { useEffect, useState } from 'react';
import * as ReactBootstrap from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { useStateValue } from '../context/StateProvider';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ClipLoader from 'react-spinners/ClipLoader';
import '../tablefooter.css';
import momentTZ from 'moment-timezone';
import {
  consigneeGetAddress,
  consigneeGetList,
  getConsigneeList,
} from '../services/consigneeEndpoint';
import apiFetch from '../services/apiFetch';
import {
  changeBilling,
  createBilling,
  fetchNewChargesNonAffiliate,
  getBillingInfo,
  getMinAndMaxBillingId,
} from '../services/billingEndpoint';
import { getSettings } from '../services/settingsEndpoint';
import { containerCreate, containerUpdate } from '../services/containerEndpoint';
import { getMighalBillingPDF } from '../services/reportsEndpoint';

const initForm = {
  billing_ID: 0,
  cont_ID: 0,
  isAffiliate: 0,
  billTo: '',
  company: '',
  address: '',
  city: '',
  state_zip: '',
  vessel: '',
  voyage: '',
  from: '',
  to: '',
  inv_no: '',
  inv_date: new Date(),
  cont_no: '',
  file_ref: '',
  consignee: '',
  consigned_to: '',
  shipper: '',
  c_type: '1X40 REEFER',
  temperature: 'CHILL',
  booking: '',
  depart: new Date(),
  arrival: new Date(),
  inv_amt: 0,
  ofrt_amt: 0,
  ofrt_inv_amt: 0,
  contSize: '',
  ContainerStatus: '',
  DepartureDate: '',
  ArrivalDate: '',
  Desc1: 'Drayage',
  Desc1_Amt: 0,
  Desc2: 'Documentation',
  Desc2_Amt: 0,
  Desc3: 'Consolidation',
  Desc3_Amt: 0,
  Desc4: 'Insurance',
  Desc4_Amt: 0,
  Desc5: '',
  Desc5_Amt: 0,
  Desc6: '',
  Desc6_Amt: 0,
  Desc7: '',
  Desc7_Amt: 0,
  Desc8: '',
  Desc8_Amt: 0,
  Desc9: '',
  Desc9_Amt: 0,
  Desc10: '',
  Desc10_Amt: 0,
  ReturnTo: '',
  isSIT: 0,
  comment: '',
};

const MighalBillingEntryDetails = () => {
  const [state] = useStateValue();
  const [FormValue, SetFormValue] = useState(state.mighalbillingNoneAffiliates);
  const [billingID, setBillingID] = useState(state.mighalbillingNoneAffiliates.billing_ID);
  const [isFormDisable, SetisFormDisable] = useState(true);
  // const [getListRender, SetgetListRender] = useState(true);
  const [dataInfoGrid, SetDataInfoGrid] = useState([]);
  const [consigneeData, setConsigneeData] = useState([]);
  const [lastInvoice, setLastInvoice] = useState('');
  const [lastFileRefNo, setLastFileRefNo] = useState('');
  const [isNew, SetisNew] = useState(true);
  // const [isEdit, SetisEdit] = useState(false);
  const [isDisabledBtnNew, SetisDisabledBtnNew] = useState(false);
  const [isDisabledBtnEdit, SetisDisabledBtnEdit] = useState(false);
  const [isDisabledBtnSave, SetisDisabledBtnSave] = useState(true);
  const [isDisabledBtnCancel, SetisDisabledBtnCancel] = useState(true);
  const [isDisabledBtnPrev, setDisabledBtnPrev] = useState(true);
  const [isDisabledBtnNext, setDisabledBtnNext] = useState(true);
  const [totalValue, SetTotalValue] = useState(0);
  const [isLoading, SetIsLoading] = useState(false);
  const [renderData, setRenderData] = useState(true);
  const [minID, setMinID] = useState(0);
  const [maxID, setMaxID] = useState(0);
  //const history = useHistory();

  useEffect(() => {
    const getLookUpData = () => {
      return new Promise(async (resolve, reject) => {
        try {
          const params = {
            isAffiliate: 'all',
          };
          const endpoint = getConsigneeList(params);
          const response = await apiFetch(endpoint);

          if (response.data) {
            let data = response.data;
            if (data.length) {
              let filteredArray = [];
              filteredArray.push('SELECT CONSIGNEE');
              data.map((value) => {
                filteredArray.push(value.Consignee_Name);
              });
              setConsigneeData(filteredArray);

              const arrayData = [
                {
                  cont_ID: FormValue.cont_ID,
                  id: 1,
                  description: FormValue.Desc1,
                  amount: FormValue.Desc1_Amt,
                },
                {
                  cont_ID: FormValue.cont_ID,
                  id: 2,
                  description: FormValue.Desc2,
                  amount: FormValue.Desc2_Amt,
                },
                {
                  cont_ID: FormValue.cont_ID,
                  id: 3,
                  description: FormValue.Desc3,
                  amount: FormValue.Desc3_Amt,
                },
                {
                  cont_ID: FormValue.cont_ID,
                  id: 4,
                  description: FormValue.Desc4,
                  amount: FormValue.Desc4_Amt,
                },
                {
                  cont_ID: FormValue.cont_ID,
                  id: 5,
                  description: FormValue.Desc5,
                  amount: FormValue.Desc5_Amt,
                },
                {
                  cont_ID: FormValue.cont_ID,
                  id: 6,
                  description: FormValue.Desc6,
                  amount: FormValue.Desc6_Amt,
                },
                {
                  cont_ID: FormValue.cont_ID,
                  id: 7,
                  description: FormValue.Desc7,
                  amount: FormValue.Desc7_Amt,
                },
                {
                  cont_ID: FormValue.cont_ID,
                  id: 8,
                  description: FormValue.Desc8,
                  amount: FormValue.Desc8_Amt,
                },
                {
                  cont_ID: FormValue.cont_ID,
                  id: 9,
                  description: FormValue.Desc9,
                  amount: FormValue.Desc9_Amt,
                },
                {
                  cont_ID: FormValue.cont_ID,
                  id: 10,
                  description: FormValue.Desc10,
                  amount: FormValue.Desc10_Amt,
                },
              ];
              let sumValue = 0;
              for (const iterator of arrayData) {
                //console.log(parseFloat(iterator.amount));
                sumValue = sumValue + parseFloat(iterator.amount);
              }
              SetTotalValue(sumValue);
              SetDataInfoGrid(arrayData);
              resolve('Done');
            } else {
              setConsigneeData([]);
              reject('Empty');
            }
          }
        } catch (e) {
          console.log(e);
          reject('Failed');
        }
      });
    };

    if (renderData) {
      getLookUpData()
        .then(() => {
          getLastRecords()
            .then((result) => {
              setLastInvoice(result.LastInvoiceMighal);
              setLastFileRefNo(result.LastFileRefNo);
              getMinAndMaxID()
                .then((data) => {
                  setMinID(data[0].MINID);
                  setMaxID(data[0].MAXID);
                  // console.log('MIN', data[0].MINID);
                  // console.log('MAX', data[0].MAXID);
                  // console.log('Billing_ID', FormValue.billing_ID);
                  if (FormValue.billing_ID > data[0].MINID) {
                    setDisabledBtnPrev(false);
                  }

                  if (FormValue.billing_ID < data[0].MAXID) {
                    setDisabledBtnNext(false);
                  }

                  if (FormValue.billing_ID === data[0].MINID) {
                    setDisabledBtnPrev(true);
                  }

                  if (FormValue.billing_ID === data[0].MAXID) {
                    setDisabledBtnNext(true);
                  }
                  setRenderData(false);
                })
                .catch((e) => {
                  setRenderData(false);
                  console.log('getMinAndMaxID', e);
                });
            })
            .catch((e) => {
              setRenderData(false);
              console.log('getLastRecords', e);
            });
        })
        .catch((e) => {
          setRenderData(false);
          console.log('getLookUpData', e);
        });
    }
  }, []);

  if (!state.isLogin) return <Redirect to='/login' />;

  if (state.isLogin && state.newUser) return <Redirect to='/change-password' />;

  const columns = [
    {
      dataField: 'cont_ID',
      text: 'ContID',
      hidden: true,
    },
    {
      dataField: 'id',
      text: 'Line No.',
      hidden: true,
    },
    {
      dataField: 'description',
      text: 'Description',
      headerStyle: () => {
        return { width: '300px', textAlign: 'center' };
      },
    },
    {
      dataField: 'amount',
      text: 'Amount',
      align: 'right',
      type: 'number',
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
      formatter: (cell) => {
        //let valueObj = cell
        const fixedNumber = Number.parseFloat(cell).toFixed(2);
        return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      },
      validator: (newValue, row, column, done) => {
        setTimeout(() => {
          if (isNaN(newValue)) {
            return done({
              valid: false,
              message: 'Price should be numeric',
            });
          }
          return done();
        }, 200);
        return {
          async: true,
        };
      },
    },
  ];

  const getMinAndMaxID = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoint = getMinAndMaxBillingId();
        const response = await apiFetch(endpoint);
        if (response.data !== 'Failed') {
          resolve(response.data);
        } else {
          reject('Failed');
        }
      } catch (e) {
        console.log(e);
        reject('Failed');
      }
    });
  };

  const getLastRecords = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoint = getSettings();
        const responseSettings = await apiFetch(endpoint);
        if (responseSettings && responseSettings.data) {
          let resData = responseSettings.data[0];
          // console.log(resData);
          resolve(resData);
        } else {
          reject('Empty');
        }
      } catch (e) {
        console.log(e);
        reject('Empty');
      }
    });
  };

  const afterSaveCell = () => {
    /* let currentData = dataInfoGrid;
    SetDataInfoGrid(currentData); */
    //console.log('After Saving Cell!!');
    let sumValue = 0;
    for (const iterator of dataInfoGrid) {
      //console.log(iterator);
      sumValue = sumValue + parseFloat(iterator.amount);
    }
    SetTotalValue(sumValue);
  };

  const formatDate = (valueDate) => {
    let d = new Date(valueDate),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('/');
  };

  // const toCurrency = (number) => {
  //   const formatter = new Intl.NumberFormat('en-US', {
  //     style: 'currency',
  //     currency: 'USD',
  //   });
  //   return formatter.format(number);
  // };

  // const formatInvToNumber = (number) => {
  //   let convertoNum = number.replace(/\,/g, '');
  //   convertoNum = parseFloat(convertoNum, 10);
  //   return convertoNum;
  // };

  const getLookUpData = (isViewAll) => {
    return new Promise(async (resolve, reject) => {
      try {
        const params = {
          isAffiliate: isViewAll ? 'all' : 0,
        };
        const endpoint = consigneeGetList(params);
        const response = await apiFetch(endpoint);
        if (response.data) {
          let data = response.data;
          if (data.length) {
            let filteredArray = [];
            filteredArray.push('SELECT CONSIGNEE');
            data.map((value) => {
              filteredArray.push(value.Consignee_Name);
            });
            setConsigneeData(filteredArray);
            resolve('Done');
          }
        } else {
          reject('Failed');
        }
      } catch (e) {
        console.log('TryCatch getNonAffiliateOnlyLookup', e);
      }
    });
  };

  const applyDefaultBtnStatusState = () => {
    SetisFormDisable(true);
    SetisNew(true);
    // SetisEdit(false);
    SetisDisabledBtnNew(false);
    SetisDisabledBtnEdit(false);
    SetisDisabledBtnSave(true);
    SetisDisabledBtnCancel(true);
  };

  const handleCancelButton = () => {
    if (!isFormDisable) {
      getLookUpData(true)
        .then(() => {
          SetFormValue(initForm);
          SetisFormDisable(true);
          setRenderData(true);
          SetDataInfoGrid([]);
          applyDefaultBtnStatusState();
          reloadGridList(initForm);
        })
        .catch(() => {
          //
        });

      //dispatch({ type: 'SET_NON_AFFILIATE_BILLING', payload: initForm });
    }
  };

  const reloadGridList = (DataValues) => {
    let arrayData = [
      {
        cont_ID: DataValues.cont_ID,
        id: 1,
        description: DataValues.Desc1,
        amount: DataValues.Desc1_Amt,
      },
      {
        cont_ID: DataValues.cont_ID,
        id: 2,
        description: DataValues.Desc2,
        amount: DataValues.Desc2_Amt,
      },
      {
        cont_ID: DataValues.cont_ID,
        id: 3,
        description: DataValues.Desc3,
        amount: DataValues.Desc3_Amt,
      },
      {
        cont_ID: DataValues.cont_ID,
        id: 4,
        description: DataValues.Desc4,
        amount: DataValues.Desc4_Amt,
      },
      {
        cont_ID: DataValues.cont_ID,
        id: 5,
        description: DataValues.Desc5,
        amount: DataValues.Desc5_Amt,
      },
      {
        cont_ID: DataValues.cont_ID,
        id: 6,
        description: DataValues.Desc6,
        amount: DataValues.Desc6_Amt,
      },
      {
        cont_ID: DataValues.cont_ID,
        id: 7,
        description: DataValues.Desc7,
        amount: DataValues.Desc7_Amt,
      },
      {
        cont_ID: DataValues.cont_ID,
        id: 8,
        description: DataValues.Desc8,
        amount: DataValues.Desc8_Amt,
      },
      {
        cont_ID: DataValues.cont_ID,
        id: 9,
        description: DataValues.Desc9,
        amount: DataValues.Desc9_Amt,
      },
      {
        cont_ID: DataValues.cont_ID,
        id: 10,
        description: DataValues.Desc10,
        amount: DataValues.Desc10_Amt,
      },
    ];
    let sumValue = 0;
    for (const iterator of arrayData) {
      //console.log(parseFloat(iterator.amount));
      sumValue = sumValue + parseFloat(iterator.amount);
    }
    SetTotalValue(sumValue);
    SetDataInfoGrid(arrayData);
  };

  const handleNewButton = () => {
    //dispatch({ type: 'SET_NON_AFFILIATE_BILLING', payload: initForm });
    getLookUpData(false)
      .then(() => {
        SetFormValue(initForm);
        SetDataInfoGrid([]);
        setRenderData(true);
        SetisFormDisable(false);
        SetisNew(true);
        // SetisEdit(false);
        SetisDisabledBtnEdit(true);
        SetisDisabledBtnSave(false);
        SetisDisabledBtnCancel(false);
        reloadGridList(initForm);
      })
      .catch(() => {
        //
      });
  };

  const handleEditButton = () => {
    try {
      if (billingID === 0) {
        return alert('Cannot edit without current billing record');
      }
      if (FormValue.isAffiliate) {
        return alert(
          'Cannot edit for Affiliated Container. You may update the billing by going to the Mighal Container.',
        );
      }
      getLookUpData(true)
        .then(() => {
          SetisFormDisable(false);
          // SetisEdit(true);
          SetisNew(false);
          SetisDisabledBtnNew(true);
          SetisDisabledBtnSave(false);
          SetisDisabledBtnCancel(false);
        })
        .catch(() => {
          //
        });
    } catch (e) {
      console.log('tryCatch handleEditButton', e);
    }
  };

  const handleOnChange = (prop) => (ev) => {
    if (prop !== 'inv_date' && prop !== 'arrival' && prop !== 'depart') {
      SetFormValue({ ...FormValue, [prop]: ev.target.value });

      /* Get Company Information and Current Invoice No. */
      let updateForm = {};
      if (prop === 'billTo') {
        SetIsLoading(true);
        getConsigneeAddress(ev.target.value)
          .then((result) => {
            //console.log(result[0]);
            let addressInfo = result[0];
            let NewFileRefNo = parseInt(lastFileRefNo) + 1;
            let strDate = new Date();
            let shortYear = strDate.getFullYear();
            let twoDigitYear = shortYear.toString().substr(-2);

            updateForm = {
              ...FormValue,
              billTo: addressInfo.Consignee_Name,
              shipper: addressInfo.Consignee_Name,
              company: addressInfo.Company_Name,
              address: addressInfo.Address,
              city: addressInfo.City,
              state_zip: addressInfo.StateZip,
              to: addressInfo.LocationCode,
              from: 'TJ MIGHAL',
              inv_no: parseInt(lastInvoice) + 1,
              file_ref: `M${twoDigitYear}-${NewFileRefNo}`,
            };
            SetFormValue(updateForm);
            SetIsLoading(false);
          })
          .catch((err) => {
            SetIsLoading(false);
            console.log('handOnChange', err);
            return alert('Something went wrong');
          });
      }

      // if (prop === 'shipper') {
      //   switch (ev.target.value) {
      //     case 'DRY':
      //       updateForm = {
      //         ...FormValue,
      //         shipper: ev.target.value,
      //         c_type: '1X40 DRY',
      //       };
      //       SetFormValue(updateForm);
      //       break;
      //     default:
      //       updateForm = {
      //         ...FormValue,
      //         shipper: ev.target.value,
      //         c_type: '1X40 REEFER',
      //       };
      //       SetFormValue(updateForm);
      //       break;
      //   }
      // }
    } else {
      let AdjusteddateValue = new Date(momentTZ.tz(ev, 'Pacific/Guam'));
      SetFormValue({ ...FormValue, [prop]: AdjusteddateValue });
    }
  };

  const formatNumber = (number) => {
    const fixedNumber = Number.parseFloat(number).toFixed(2);
    return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const MakeConsigneeOptions = (data) => {
    return (
      data &&
      data.map((cons, index) => (
        <option key={index} value={cons}>
          {cons}
        </option>
      ))
    );
  };

  const getConsigneeAddress = (Destination) => {
    return new Promise(async (resolve, reject) => {
      try {
        const params = {
          consignee: Destination,
        };
        const endpoint = consigneeGetAddress(params);
        const response = await apiFetch(endpoint);
        if (response && response.data) {
          resolve(response.data);
        } else {
          reject('Failed');
        }
      } catch (e) {
        console.log('getConsigneeAddress', e);
        reject('Failed');
      }
    });
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    try {
      SetIsLoading(true);

      let ContainerRecord = {
        Container_ID: FormValue.cont_ID,
        Container_No: FormValue.cont_no.trim().toUpperCase(),
        Temperature_Type: FormValue.temperature,
        Attention: '',
        Email: '',
        Destination: FormValue.billTo,
        Departure_Date: formatDate(FormValue.inv_date),
        Arrival_Date: formatDate(FormValue.arrival),
        Container_Type: FormValue.c_type,
        Vessel: FormValue.vessel.trim().toUpperCase(),
        Status: 'Delivered',
        Voyage: FormValue.voyage,
        Booking: FormValue.booking.trim().toUpperCase(),
        Seal_No: '',
        Comment: '',
        File_Ref: FormValue.file_ref.trim().toUpperCase(),
        OceanFreight: FormValue.ofrt_inv_amt,
        UserEmail: localStorage.getItem('logisticsUseremail'),
        token: localStorage.getItem('logisticsToken'),
        isMighal: 0,
        isSIT: 0,
        isAffiliate: 0,
      };

      let newRecord = {
        billing_ID: FormValue.billing_ID,
        Container_ID: FormValue.cont_ID,
        Consignee_Name: FormValue.billTo,
        Consigned_To: FormValue.consignedTo,
        Shipper: FormValue.shipper,
        address: FormValue.address,
        city: FormValue.city,
        state_zip: FormValue.state_zip,
        from: FormValue.from,
        to: FormValue.to,
        inv_no: FormValue.inv_no,
        inv_date: formatDate(FormValue.inv_date),
        file_ref: FormValue.file_ref.toUpperCase(),
        oceanfreight: FormValue.ofrt_inv_amt,
        Desc1: '',
        Desc1_Amt: 0,
        Desc2: '',
        Desc2_Amt: 0,
        Desc3: '',
        Desc3_Amt: 0,
        Desc4: '',
        Desc4_Amt: 0,
        Desc5: '',
        Desc5_Amt: 0,
        Desc6: '',
        Desc6_Amt: 0,
        Desc7: '',
        Desc7_Amt: 0,
        Desc8: '',
        Desc8_Amt: 0,
        Desc9: '',
        Desc9_Amt: 0,
        Desc10: '',
        Desc10_Amt: 0,
        isOFRTChange: isNew ? 0 : 1,
        UserEmail: localStorage.getItem('logisticsUseremail'),
      };

      dataInfoGrid.forEach((value) => {
        switch (value.id) {
          case 1:
            newRecord = {
              ...newRecord,
              Desc1: value.description,
              Desc1_Amt: parseFloat(value.amount),
            };
            break;
          case 2:
            newRecord = {
              ...newRecord,
              Desc2: value.description,
              Desc2_Amt: parseFloat(value.amount),
            };
            break;
          case 3:
            newRecord = {
              ...newRecord,
              Desc3: value.description,
              Desc3_Amt: parseFloat(value.amount),
            };
            break;
          case 4:
            newRecord = {
              ...newRecord,
              Desc4: value.description,
              Desc4_Amt: parseFloat(value.amount),
            };
            break;
          case 5:
            newRecord = {
              ...newRecord,
              Desc5: value.description,
              Desc5_Amt: parseFloat(value.amount),
            };
            break;
          case 6:
            newRecord = {
              ...newRecord,
              Desc6: value.description,
              Desc6_Amt: parseFloat(value.amount),
            };
            break;
          case 7:
            newRecord = {
              ...newRecord,
              Desc7: value.description,
              Desc7_Amt: parseFloat(value.amount),
            };
            break;
          case 8:
            newRecord = {
              ...newRecord,
              Desc8: value.description,
              Desc8_Amt: parseFloat(value.amount),
            };
            break;
          case 9:
            newRecord = {
              ...newRecord,
              Desc9: value.description,
              Desc9_Amt: parseFloat(value.amount),
            };
            break;
          default:
            newRecord = {
              ...newRecord,
              Desc10: value.description,
              Desc10_Amt: parseFloat(value.amount),
            };
            break;
        }
      });

      if (isNew) {
        /* Handle Insert New Container Non-Affiliates */
        handleInsertNewRecord(ContainerRecord)
          .then((contID) => {
            if (contID > 0) {
              let holdObj = newRecord;
              newRecord = {
                ...holdObj,
                Container_ID: contID,
              };
              // console.log({ newRecord });
              /* Handle Insert New Mighal Billing */
              console.log('Handle Insert Billing');
              createNewBilling(newRecord)
                .then(() => {
                  //console.log(result);
                  getMinAndMaxID()
                    .then((data) => {
                      setMinID(data[0].MINID);
                      setMaxID(data[0].MAXID);
                    })
                    .catch((e) => {
                      console.log('Try Catch getMinAndMaxID', e);
                    });
                  SetIsLoading(false);
                  alert('Successfully Saved Billing');
                })
                .catch((err) => {
                  console.log('createNewBilling', err);
                  SetIsLoading(false);
                  alert('Unable to save billing');
                });
            }
            applyDefaultBtnStatusState();
          })
          .catch((err) => {
            console.log('handleInsertNewRecord', err);
            SetIsLoading(false);
            alert('Successfully Saved Billing');
            applyDefaultBtnStatusState();
          });
      } else {
        /* Update Container Info */
        handleUpdateContRecord(ContainerRecord)
          .then(() => {
            /* Handle Update Billing */
            if (newRecord.billing_ID > 0) {
              updateBilling(newRecord)
                .then(() => {
                  getMinAndMaxID()
                    .then((data) => {
                      setMinID(data[0].MINID);
                      setMaxID(data[0].MAXID);
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                  SetIsLoading(false);
                  alert('Successfully Update Billing');
                })
                .catch((err) => {
                  console.log('updateBilling', err);
                  SetIsLoading(false);
                  alert('Unable to update billing');
                });
              applyDefaultBtnStatusState();
            } else {
              applyDefaultBtnStatusState();
              SetIsLoading(false);
              alert('Cannot update unknown Billing ID');
            }
          })
          .catch((e) => {
            console.log('tryCatch Error', e);
          });
      }
    } catch (e) {
      console.log('handleSubmit', e);
      SetIsLoading(false);
    }
  };

  const createNewBilling = (bodyData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoint = createBilling(bodyData);
        const response = await apiFetch(endpoint);
        if (response.data === 'Success') {
          resolve(response.data);
        } else {
          reject('Failed');
        }
      } catch (e) {
        console.log('createNewBilling', e);
        reject('Failed');
      }
    });
  };

  const updateBilling = (bodyData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoint = changeBilling(bodyData);
        const response = await apiFetch(endpoint);
        if (response.data === 'Success') {
          resolve(response.data);
        } else {
          reject('Failed');
        }
      } catch (e) {
        console.log(e);
        reject('Failed');
      }
    });
  };

  const handleInsertNewRecord = (newContainerRecord) => {
    return new Promise(async (resolve, reject) => {
      const endpoint = containerCreate(newContainerRecord);
      const response = await apiFetch(endpoint);
      if (response && response.data) {
        resolve(response.data);
      } else {
        reject('Failed');
      }
    });
  };

  const handleUpdateContRecord = (updateContainerRecord) => {
    return new Promise(async (resolve, reject) => {
      const endpoint = containerUpdate(updateContainerRecord);
      const response = await apiFetch(endpoint);
      if (response && response.data) {
        resolve(response.data);
      } else {
        reject('Failed');
      }
    });
  };

  const handlePrintBilling = (ev) => {
    ev.preventDefault();
    try {
      if (FormValue.billing_ID > 0) {
        SetIsLoading(true);
        downloadBillingPDF()
          .then((response) => {
            SetIsLoading(false);
            const url = window.URL.createObjectURL(
              new Blob([response.data], { type: 'application/pdf' }),
            );
            window.open(url);
          })
          .catch((err) => {
            console.log(err);
            SetIsLoading(false);
            alert('No PDF Available');
          });
      } else {
        alert('Please select a Billing Invoice');
      }
    } catch (e) {
      console.log(e);
      SetIsLoading(false);
    }
  };

  const downloadBillingPDF = () => {
    return new Promise(async (resolve, reject) => {
      const params = {
        billingId: FormValue.billing_ID,
      };
      const endpoint = getMighalBillingPDF(params);
      const response = await apiFetch(endpoint);
      if (response.data.type !== 'application/json') {
        resolve(response);
      } else {
        reject('Failed');
      }
    });
  };

  const handlePrevRecord = () => {
    let findBilling_ID = FormValue.billing_ID - 1;

    try {
      // setRenderData(true);
      SetIsLoading(true);
      checkIfBillingExist(findBilling_ID)
        .then(async (data) => {
          // console.log({ PrevBillingInfo: data });
          let billingData = data[0];
          let contSize = billingData.Container_Type.substring(2, 4);
          let consigneeAddress = {};

          await getConsigneeAddress(billingData.Destination)
            .then((result) => {
              consigneeAddress = {
                billTo: result[0].Consignee_Name,
                addressTo: result[0].LocationCode,
                company: result[0].Company_Name,
                address: result[0].Address,
                city: result[0].City,
                state_zip: result[0].StateZip,
                from: 'TJ MIGHAL',
                to: result[0].Consignee_Name,
              };

              let BillingDetails = {
                billing_ID: billingData.Billing_ID,
                isAffiliate: billingData.isAffiliate,
                cont_ID: billingData.Container_ID,
                billTo: consigneeAddress.billTo,
                company: consigneeAddress.company,
                address: consigneeAddress.address,
                city: consigneeAddress.city,
                state_zip: consigneeAddress.state_zip,
                vessel: billingData.Vessel,
                voyage: billingData.Voyage,
                from: consigneeAddress.from,
                to: consigneeAddress.to,
                inv_no: billingData.Invoice_No,
                inv_date: modifiedDate(billingData.Departure_Date),
                cont_no: billingData.Container_No,
                file_ref: billingData.File_Ref,
                consignee: billingData.Consignee_Name,
                consigned_to: billingData.Consigned_To,
                shipper: billingData.Shipper,
                c_type: billingData.Container_Type,
                temperature: billingData.Temperature_Type,
                booking: billingData.Booking,
                depart: modifiedDate(billingData.Departure_Date),
                arrival: modifiedDate(billingData.Arrival_Date),
                inv_amt: formatNumber(billingData.TtlInvoiceAmt),
                ofrt_amt: formatNumber(billingData.OceanFreight),
                ofrt_inv_amt: 0,
                contSize,
                ContainerStatus: billingData.Status,
                DepartureDate: formatDate(billingData.Departure_Date),
                ArrivalDate: formatDate(billingData.Arrival_Date),
                Desc1: billingData.Desc1,
                Desc1_Amt: billingData.Desc1_Amt,
                Desc2: billingData.Desc2,
                Desc2_Amt: billingData.Desc2_Amt,
                Desc3: billingData.Desc3,
                Desc3_Amt: billingData.Desc3_Amt,
                Desc4: billingData.Desc4,
                Desc4_Amt: billingData.Desc4_Amt,
                Desc5: billingData.Desc5,
                Desc5_Amt: billingData.Desc5_Amt,
                Desc6: billingData.Desc6,
                Desc6_Amt: billingData.Desc6_Amt,
                Desc7: billingData.Desc7,
                Desc7_Amt: billingData.Desc7_Amt,
                Desc8: billingData.Desc8,
                Desc8_Amt: billingData.Desc8_Amt,
                Desc9: billingData.Desc9,
                Desc9_Amt: billingData.Desc9_Amt,
                Desc10: billingData.Desc10,
                Desc10_Amt: billingData.Desc10_Amt,
                ReturnTo: '',
                isSIT: billingData.isSIT,
                comment: billingData.Comment,
              };
              //console.log('Execute Dispatch');
              SetFormValue(BillingDetails);
              setBillingID(findBilling_ID);
              SetDataInfoGrid([]);
              reloadGridList(BillingDetails);
              if (findBilling_ID > minID) {
                setDisabledBtnPrev(false);
              }
              if (findBilling_ID === minID) {
                setDisabledBtnPrev(true);
              }
              if (findBilling_ID < maxID) {
                setDisabledBtnNext(false);
              } else {
                setDisabledBtnNext(true);
              }
              // setDisabledBtnPrev(billingID > minID ? true : false);
              // setDisabledBtnPrev(billingID === minID ? true : false);
              SetIsLoading(false);
              setRenderData(false);
            })
            .catch((e) => {
              setRenderData(false);
              SetIsLoading(false);
              console.log('getConsigneeAddress', e);
            });
        })
        .catch((e) => {
          setRenderData(false);
          console.log('checkBillingExist', e);
          SetIsLoading(false);
        });
    } catch (e) {
      setRenderData(false);
      console.log('TryCatch handlePrevRecord', e);
    }
  };

  const handleNextRecord = () => {
    let findBilling_ID = FormValue.billing_ID + 1;
    try {
      // setRenderData(true);
      SetIsLoading(true);
      checkIfBillingExist(findBilling_ID)
        .then(async (data) => {
          // console.log({ NextBillingInfo: data });
          let billingData = data[0];
          let contSize = billingData.Container_Type.substring(2, 4);
          let consigneeAddress = {};

          await getConsigneeAddress(billingData.Destination)
            .then((result) => {
              consigneeAddress = {
                billTo: result[0].Consignee_Name,
                addressTo: result[0].LocationCode,
                company: result[0].Company_Name,
                address: result[0].Address,
                city: result[0].City,
                state_zip: result[0].StateZip,
                from: 'TJ MIGHAL',
                to: result[0].Consignee_Name,
              };

              let BillingDetails = {
                billing_ID: billingData.Billing_ID,
                isAffiliate: billingData.isAffiliate,
                cont_ID: billingData.Container_ID,
                billTo: consigneeAddress.billTo,
                company: consigneeAddress.company,
                address: consigneeAddress.address,
                city: consigneeAddress.city,
                state_zip: consigneeAddress.state_zip,
                vessel: billingData.Vessel,
                voyage: billingData.Voyage,
                from: consigneeAddress.from,
                to: consigneeAddress.to,
                inv_no: billingData.Invoice_No,
                inv_date: modifiedDate(billingData.Departure_Date),
                cont_no: billingData.Container_No,
                file_ref: billingData.File_Ref,
                consignee: billingData.Consignee_Name,
                consigned_to: billingData.Consigned_To,
                shipper: billingData.Shipper,
                c_type: billingData.Container_Type,
                temperature: billingData.Temperature_Type,
                booking: billingData.Booking,
                depart: modifiedDate(billingData.Departure_Date),
                arrival: modifiedDate(billingData.Arrival_Date),
                inv_amt: formatNumber(billingData.TtlInvoiceAmt),
                ofrt_amt: formatNumber(billingData.OceanFreight),
                ofrt_inv_amt: billingData.TtlOfrtInv,
                contSize,
                ContainerStatus: billingData.Status,
                DepartureDate: formatDate(billingData.Departure_Date),
                ArrivalDate: formatDate(billingData.Arrival_Date),
                Desc1: billingData.Desc1,
                Desc1_Amt: billingData.Desc1_Amt,
                Desc2: billingData.Desc2,
                Desc2_Amt: billingData.Desc2_Amt,
                Desc3: billingData.Desc3,
                Desc3_Amt: billingData.Desc3_Amt,
                Desc4: billingData.Desc4,
                Desc4_Amt: billingData.Desc4_Amt,
                Desc5: billingData.Desc5,
                Desc5_Amt: billingData.Desc5_Amt,
                Desc6: billingData.Desc6,
                Desc6_Amt: billingData.Desc6_Amt,
                Desc7: billingData.Desc7,
                Desc7_Amt: billingData.Desc7_Amt,
                Desc8: billingData.Desc8,
                Desc8_Amt: billingData.Desc8_Amt,
                Desc9: billingData.Desc9,
                Desc9_Amt: billingData.Desc9_Amt,
                Desc10: billingData.Desc10,
                Desc10_Amt: billingData.Desc10_Amt,
                ReturnTo: '',
                isSIT: billingData.isSIT,
                comment: billingData.Comment,
              };
              //console.log('Execute Dispatch');
              SetFormValue(BillingDetails);
              setBillingID(findBilling_ID);
              SetDataInfoGrid([]);
              reloadGridList(BillingDetails);
              //updateStatusPrevNextButtons();
              if (billingID >= minID) {
                setDisabledBtnPrev(false);
              }
              if (findBilling_ID < maxID) {
                setDisabledBtnNext(false);
              } else {
                setDisabledBtnNext(true);
              }

              if (findBilling_ID === maxID) {
                setDisabledBtnNext(true);
              }
              /* setDisabledBtnNext(billingID < maxID ? true : false);    
              setDisabledBtnNext(billingID === maxID ? true : false); */
              SetIsLoading(false);
              setRenderData(false);
            })
            .catch((e) => {
              setRenderData(false);
              SetIsLoading(false);
              console.log('getConsigneeAddress', e);
            });
        })
        .catch((e) => {
          setRenderData(false);
          console.log('checkBillingExist', e);
          SetIsLoading(false);
        });
    } catch (e) {
      setRenderData(false);
      console.log('tryCatch handleNextRecord', e);
    }
  };

  const checkIfBillingExist = (findBilling_ID) => {
    return new Promise(async (resolve, reject) => {
      try {
        const params = {
          containerId: 0,
          billingId: findBilling_ID,
        };
        const endpoint = getBillingInfo(params);
        const response = await apiFetch(endpoint);
        if (response && response.data) {
          let arrData = response.data;
          if (Array.isArray(arrData) && arrData.length > 0) {
            resolve(arrData);
          } else {
            reject(false);
          }
        }
      } catch (e) {
        console.log('tryCatch checkIfBillingExist', e);
      }
    });
  };

  const handleComputeCharges = () => {
    try {
      SetIsLoading(true);
      getNewChargesNonAffiliate()
        .then((result) => {
          let Data = result[0];
          let DrayageCharge = Data.DrayageFee;
          let DocCharge = Data.DocumentationCharge;
          let contSize = FormValue.c_type.substring(2, 4);
          let InsValue = Data.InsurancePercent;
          let InsFixDecimalValue = InsValue * 100;
          let TtlAddOnCharge = formatNumber(Data.TtlPOinvOFrtAddOnCharge);

          let newDataGridInfo = {
            Desc1: 'Drayage BAY AREA PORT',
            Desc1_Amt: DrayageCharge,
            Desc2: 'Documentation',
            Desc2_Amt: DocCharge,
            Desc3: `Consolidation ${contSize}`,
            Desc3_Amt:
              contSize === '20' ? Data.ConsolidationCharge20ft : Data.ConsolidationCharge40ft,
            Desc4: `Insurance ${FormValue.temperature} ${InsFixDecimalValue.toFixed(
              2,
            )}% x $${TtlAddOnCharge}`,
            Desc4_Amt: Data.TtlInsuranceAmt,
            Desc5: '',
            Desc5_Amt: 0,
            Desc6: '',
            Desc6_Amt: 0,
            Desc7: '',
            Desc7_Amt: 0,
            Desc8: '',
            Desc8_Amt: 0,
            Desc9: '',
            Desc9_Amt: 0,
            Desc10: '',
            Desc10_Amt: 0,
          };

          let arrayData = [
            {
              cont_ID: FormValue.cont_ID,
              id: 1,
              description: newDataGridInfo.Desc1,
              amount: newDataGridInfo.Desc1_Amt,
            },
            {
              cont_ID: FormValue.cont_ID,
              id: 2,
              description: newDataGridInfo.Desc2,
              amount: newDataGridInfo.Desc2_Amt,
            },
            {
              cont_ID: FormValue.cont_ID,
              id: 3,
              description: newDataGridInfo.Desc3,
              amount: newDataGridInfo.Desc3_Amt,
            },
            {
              cont_ID: FormValue.cont_ID,
              id: 4,
              description: newDataGridInfo.Desc4,
              amount: newDataGridInfo.Desc4_Amt,
            },
            {
              cont_ID: FormValue.cont_ID,
              id: 5,
              description: newDataGridInfo.Desc5,
              amount: newDataGridInfo.Desc5_Amt,
            },
            {
              cont_ID: FormValue.cont_ID,
              id: 6,
              description: newDataGridInfo.Desc6,
              amount: newDataGridInfo.Desc6_Amt,
            },
            {
              cont_ID: FormValue.cont_ID,
              id: 7,
              description: newDataGridInfo.Desc7,
              amount: newDataGridInfo.Desc7_Amt,
            },
            {
              cont_ID: FormValue.cont_ID,
              id: 8,
              description: newDataGridInfo.Desc8,
              amount: newDataGridInfo.Desc8_Amt,
            },
            {
              cont_ID: FormValue.cont_ID,
              id: 9,
              description: newDataGridInfo.Desc9,
              amount: newDataGridInfo.Desc9_Amt,
            },
            {
              cont_ID: FormValue.cont_ID,
              id: 10,
              description: newDataGridInfo.Desc10,
              amount: newDataGridInfo.Desc10_Amt,
            },
          ];
          let sumValue = 0;
          for (const iterator of arrayData) {
            //console.log(parseFloat(iterator.amount));
            sumValue = sumValue + parseFloat(iterator.amount);
          }
          SetTotalValue(sumValue);
          SetDataInfoGrid(arrayData);
          SetIsLoading(false);
        })
        .catch((err) => {
          SetIsLoading(false);
          console.log(err);
        });
    } catch (e) {
      console.log('tryCatch handleComputeCharges', e);
    }
  };

  const getNewChargesNonAffiliate = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const params = {
          temperature: FormValue.temperature,
          oceanFreightInvAmount: parseFloat(FormValue.ofrt_inv_amt),
        };
        const endpoint = fetchNewChargesNonAffiliate(params);
        const response = await apiFetch(endpoint);
        if (response.data !== 'Failed') {
          resolve(response.data);
        } else {
          reject('Failed');
        }
      } catch (e) {
        console.log('tryCatch getNewChargesNonAffiliate', e);
      }
    });
  };

  const modifiedDate = (dateValue) => {
    return new Date(momentTZ.tz(dateValue, 'Pacific/Guam'));
  };

  return (
    <div className='wrapper'>
      <div className='loading-spinner'>
        <ClipLoader size={100} color={'#0054a6'} loading={isLoading} />
      </div>
      <div className='help-button'>
        <a
          href='https://triplejlogistics.org/help/MighalBillingMenu.html'
          target='_blank'
          rel='noopener noreferrer'
          className='btn btn-labeled btn-outline-dark btn-sm mr-1'>
          <span className='btn-label'>
            <i className='fa fa-question-circle pr-1'></i>
          </span>
          Help
        </a>
      </div>
      <div className='generic-content-container container-fluid'>
        <h2>Mighal Billing Details</h2>
      </div>
      <div className='container-fluid'>
        <div className='row mb-4'>
          <div className='col-md-12'>
            <button
              type='button'
              className='btn btn-labeled btn-primary btn-sm mr-1'
              disabled={isDisabledBtnNew}
              onClick={handleNewButton}
              hidden={true}>
              <span className='btn-label'>
                <i className='fa fa-plus pr-1'></i>
              </span>
              New
            </button>
            <button
              type='button'
              className='btn btn-labeled btn-primary btn-sm mr-1'
              disabled={isDisabledBtnEdit}
              onClick={handleEditButton}
              hidden={true}>
              <span className='btn-label'>
                <i className='fa fa-edit pr-1'></i>
              </span>
              Edit
            </button>
            <button
              type='submit'
              className='btn btn-labeled btn-primary btn-sm mr-1'
              disabled={isDisabledBtnSave}
              onClick={handleSubmit}
              hidden={true}>
              <span className='btn-label'>
                <i className='fa fa-floppy-o pr-1'></i>
              </span>
              Save
            </button>
            <button
              type='button'
              className='btn btn-labeled btn-primary btn-sm mr-1'
              onClick={handleCancelButton}
              disabled={isDisabledBtnCancel}
              hidden={true}>
              <span className='btn-label'>
                <i className='fa fa-ban pr-1'></i>
              </span>
              Cancel
            </button>
            <button
              type='button'
              className='btn btn-labeled btn-primary btn-sm mr-1'
              disabled={billingID <= 0}
              onClick={handlePrintBilling}>
              <span className='btn-label'>
                <i className='fa fa-print pr-1'></i>
              </span>
              Print Preview
            </button>
            <button
              type='button'
              className='btn btn-labeled btn-primary btn-sm mr-1'
              disabled={isFormDisable}
              onClick={handleComputeCharges}
              hidden={true}>
              <span className='btn-label'>
                <i className='fa fa-calculator pr-1'></i>
              </span>
              Compute Charges
            </button>
            <button
              type='button'
              className='btn btn-labeled btn-primary btn-sm ml-2 mr-1'
              disabled={isDisabledBtnPrev}
              onClick={handlePrevRecord}>
              <span className='btn-label'>
                <i className='fa fa-arrow-left pr-1'></i>
              </span>
              Previous Invoice
            </button>
            <button
              type='button'
              className='btn btn-labeled btn-primary btn-sm mr-1'
              disabled={isDisabledBtnNext}
              onClick={handleNextRecord}>
              Next Invoice
              <span className='btn-label'>
                <i className='fa fa-arrow-right pl-1'></i>
              </span>
            </button>
            <div className='d-flex float-right mt-auto mb-auto justify-content-center align-items-center h-100'>
              <span className='mt-auto mb-auto'>Seq No. </span>
              <span className='mt-auto mb-auto ml-1'>{billingID}</span>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid'>
        <ReactBootstrap.Form>
          <ReactBootstrap.Row className='mb-2'>
            <ReactBootstrap.Col xs={2}>
              <ReactBootstrap.Form.Label>Bill To</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Control
                as='select'
                disabled={isFormDisable}
                value={FormValue.billTo}
                onChange={handleOnChange('billTo')}
                tabIndex={1}>
                {MakeConsigneeOptions(consigneeData)}
              </ReactBootstrap.Form.Control>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={2}>
              <ReactBootstrap.Form.Label>Invoice No.</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Control
                placeholder='Invoice No...'
                value={FormValue.inv_no}
                onChange={handleOnChange('inv_no')}
                disabled={isFormDisable}
              />
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>

          <ReactBootstrap.Row className='mb-2'>
            <ReactBootstrap.Col xs={2}>
              <ReactBootstrap.Form.Label>Company Name</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Control
                placeholder='Company Name...'
                value={FormValue.company}
                onChange={handleOnChange('company')}
                disabled={isFormDisable}
              />
            </ReactBootstrap.Col>

            <ReactBootstrap.Col xs={2}>
              <ReactBootstrap.Form.Label>Invoice Date</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={4}>
              <div className=''>
                <DatePicker
                  selected={modifiedDate(FormValue.inv_date)}
                  onChange={handleOnChange('inv_date')}
                  className=''
                  disabled={isFormDisable}
                />
              </div>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>

          <ReactBootstrap.Row className='mb-2'>
            <ReactBootstrap.Col xs={2}>
              <ReactBootstrap.Form.Label>Address</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Control
                placeholder='Billing Address...'
                value={FormValue.address}
                onChange={handleOnChange('address')}
                disabled={isFormDisable}
              />
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={2}>
              <ReactBootstrap.Form.Label>File Reference No.</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Control
                placeholder='File Reference No...'
                value={FormValue.file_ref}
                onChange={handleOnChange('file_ref')}
                disabled={isFormDisable}
              />
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>

          <ReactBootstrap.Row className='mb-2'>
            <ReactBootstrap.Col xs={2}>
              <ReactBootstrap.Form.Label>City</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Control
                placeholder='City...'
                value={FormValue.city}
                onChange={handleOnChange('city')}
                disabled={isFormDisable}
              />
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={2}>
              <ReactBootstrap.Form.Label>Container No</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Control
                placeholder='Container No...'
                value={FormValue.cont_no}
                onChange={handleOnChange('cont_no')}
                disabled={isFormDisable}
              />
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>

          <ReactBootstrap.Row className='mb-2'>
            <ReactBootstrap.Col xs={2}>
              <ReactBootstrap.Form.Label>State & Zip Code</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Control
                placeholder='State & Zip Code...'
                value={FormValue.state_zip}
                onChange={handleOnChange('state_zip')}
                disabled={isFormDisable}
              />
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={2}>
              <ReactBootstrap.Form.Label>Booking</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Control
                placeholder='Booking...'
                value={FormValue.booking}
                onChange={handleOnChange('booking')}
                disabled={isFormDisable}
              />
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>

          <ReactBootstrap.Row className='mb-2'>
            <ReactBootstrap.Col xs={2}>
              <ReactBootstrap.Form.Label>From</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Control
                placeholder='Bill From...'
                value={FormValue.from}
                onChange={handleOnChange('from')}
                disabled={isFormDisable}
              />
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={2}>
              <ReactBootstrap.Form.Label>Vessel</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Control
                placeholder='Vessel...'
                value={FormValue.vessel}
                onChange={handleOnChange('vessel')}
                disabled={isFormDisable}
              />
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>

          <ReactBootstrap.Row className='mb-2'>
            <ReactBootstrap.Col xs={2}>
              <ReactBootstrap.Form.Label>To</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Control
                placeholder='Destination...'
                value={FormValue.consigned_to}
                onChange={handleOnChange('consigned_to')}
                disabled={isFormDisable}
              />
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={2}>
              <ReactBootstrap.Form.Label>Voyage</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Control
                placeholder='Voyage...'
                value={FormValue.voyage}
                onChange={handleOnChange('voyage')}
                disabled={isFormDisable}
              />
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>

          <ReactBootstrap.Row className='mb-2'>
            <ReactBootstrap.Col xs={2}>
              <ReactBootstrap.Form.Label>Shipper</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Control
                placeholder='Shipper ...'
                value={FormValue.shipper}
                onChange={handleOnChange('shipper')}
                disabled={isFormDisable}
              />
            </ReactBootstrap.Col>

            <ReactBootstrap.Col xs={2}>
              <ReactBootstrap.Form.Label>Container Type</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Control
                as='select'
                disabled={isFormDisable}
                value={FormValue.c_type}
                onChange={handleOnChange('c_type')}
                tabIndex={8}>
                <option>{`1X20 DRY`}</option>
                <option>{`1X40 DRY`}</option>
                <option>{`1X20 REEFER`}</option>
                <option>{`1X40 REEFER`}</option>
              </ReactBootstrap.Form.Control>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>

          <ReactBootstrap.Row className='mb-2'>
            <ReactBootstrap.Col xs={2}>
              <ReactBootstrap.Form.Label>Consignee</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Control
                placeholder='Consignee...'
                value={FormValue.consignee}
                onChange={handleOnChange('consignee')}
                disabled={isFormDisable}
              />
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={2}>
              <ReactBootstrap.Form.Label>Temperature</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Control
                as='select'
                disabled={isFormDisable}
                value={FormValue.temperature}
                onChange={handleOnChange('temperature')}
                tabIndex={9}>
                <option>{`CHILL`}</option>
                <option>{`DRY`}</option>
                <option>{`FREEZE`}</option>
              </ReactBootstrap.Form.Control>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
          <ReactBootstrap.Row className='mb-2'>
            <ReactBootstrap.Col xs={2}>
              <ReactBootstrap.Form.Label>Departure Date</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={4}>
              <div className=''>
                <DatePicker
                  selected={modifiedDate(FormValue.inv_date)}
                  className=''
                  onChange={handleOnChange('inv_date')}
                  disabled={isFormDisable}
                />
              </div>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={2}>
              <ReactBootstrap.Form.Label>Arrival Date</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={4}>
              <div className=''>
                <DatePicker
                  selected={modifiedDate(FormValue.arrival)}
                  className=''
                  onChange={handleOnChange('arrival')}
                  disabled={isFormDisable}
                />
              </div>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </ReactBootstrap.Form>
      </div>
      <div className='container-fluid table--horiz-scroll'>
        <BootstrapTable
          keyField='id'
          data={dataInfoGrid}
          columns={columns}
          cellEdit={cellEditFactory({
            mode: 'click',
            blurToSave: true,
            afterSaveCell,
          })}
          striped
          hover
        />
      </div>
      <div className='container-fluid'>
        <table className='tg tg--header'>
          <thead>
            <tr>
              <th className='tg-v4j2'>TOTAL</th>
              <th className='tg-v4j2'>{`$${formatNumber(totalValue)}`}</th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  );
};

export default MighalBillingEntryDetails;
