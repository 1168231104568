import AuthProtect from '../common/enums/AuthProtect';

function setPOParams(filters) {
  const params = {};
  if (filters.isNew) {
    params.isNew = filters.isNew;
  }

  if (filters.containerId) {
    params.containerId = filters.containerId;
  }

  if (filters.poNum) {
    params.poNum = filters.poNum;
  }

  if (filters.controlNo) {
    params.controlNo = filters.controlNo;
  }

  if (filters.consignee) {
    params.consignee = filters.consignee;
  }

  if (filters.userLocation) {
    params.userLocation = filters.userLocation;
  }

  if (filters.destination) {
    params.destination = filters.destination;
  }

  if (filters.containerId) {
    params.containerId = filters.containerId;
  }

  if (filters.isDelivered) {
    params.isDelivered = filters.isDelivered;
  }

  if (filters.temperature) {
    params.temperature = filters.temperature;
  }

  if (filters.vendor) {
    params.vendor = filters.vendor.trim().toUpperCase();
  }

  if (filters.description) {
    params.description = filters.description.trim().toUpperCase();
  }

  if (filters.comments) {
    params.comments = filters.comments.trim().toUpperCase();
  }

  if (filters.offset) {
    params.offset = filters.offset;
  } else {
    params.offset = 1;
  }

  if (filters.limit) {
    params.limit = filters.limit;
  } else {
    params.limit = 10;
  }

  return params;
}

export const getListByLocation = (destination, filters) => {
  const params = setPOParams(filters);
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/po/get_list_by_location/' + destination,
    params,
  };
};

export const getPOList = (filters) => {
  const params = setPOParams(filters);
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/po/get_list',
    params,
  };
};

export const getLastRiderNum = (params) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/po/get_last_rider',
    params,
  };
};

export const getTempLastPONum = (params) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/po/get_temp_last_po',
    params,
  };
};

export const updatePOWithContainerInfo = (data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'PUT',
    url: '/po/update_po_with_container_info',
    data,
  };
};

export const resetPOPrevContainer = (data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'PUT',
    url: '/po/reset_po_prev_container',
    data,
  };
};

export const poUpdateFromEditCommand = (data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'PUT',
    url: '/po/po_update_from_edit_command',
    data,
  };
};

export const getPOPDFFile = (PONo) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `/po/get_po_pdf_file/${PONo}`,
    responseType: 'blob',
  };
};

export const getPOInvPDFFile = (PONo) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `/po/get_inv_pdf_file/${PONo}`,
    responseType: 'blob',
  };
};

export const getPOMRTPDFFile = (POId) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `/po/get_mrt_pdf_file/${POId}`,
    responseType: 'blob',
  };
};

export const getDistTempPOByContainer = (params) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/po/get_distinct_temp_po_by_container',
    params,
  };
};

export const changePOEditGridView = (data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'PUT',
    url: '/po/update_po_edit_grid_view',
    data,
  };
};

export const changePOStatusGrid = (data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'PUT',
    url: '/po/update_po_status_grid',
    data,
  };
};

export const insertNewPORecord = (data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: '/po/insert_front',
    data,
  };
};

export const updatePORecord = (id, data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'PUT',
    url: `/po/${id}/update_po`,
    data,
  };
};

export const uploadPOFile = (id, data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: '/po/uploadPO/' + id,
    data,
  };
};

export const uploadInvoicePDF = (id, data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: '/po/upload_invoice_pdf/' + id,
    data,
  };
};

export const uploadMrtPDF = (data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: '/po/upload_mrt_pdf',
    data,
  };
};

export const addPOToContainerAfterReceived = (data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: '/po/add_po_to_container_after_received',
    data,
  };
};

export const fetchPOStatusGrid = () => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/po/get_po_status_grid',
  };
};

export const getVesselZeroPOAmounts = (filters) => {
  const params = setPOParams(filters);
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/po/get_vessel_zero_po_amounts_pop_up',
    params,
  };
};

export const getMaxMRT = () => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/po/get_max_mrt',
  };
};
