// noinspection JSValidateTypes

import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ClipLoader from 'react-spinners/ClipLoader';
import { SpinnerStyle } from '../../styles/SpinnerStyle';

function TableGrid({
  data,
  keyField,
  page,
  sizePerPage,
  totalSize,
  onTableChange,
  columns,
  isLoading,
  showSpinner,
  ...props
}) {
  if (isLoading && showSpinner) {
    return (
      <div className='wrapper'>
        <div className='loading-spinner'>
          <ClipLoader size={100} color={'#0054a6'} loading={isLoading} css={SpinnerStyle} />
        </div>
      </div>
    );
  }
  return (
    <>
      <BootstrapTable
        remote
        keyField={keyField}
        data={data}
        columns={columns}
        striped
        hover
        pagination={paginationFactory({ page, sizePerPage, totalSize, hideSizePerPage: true })}
        onTableChange={onTableChange}
        {...props}
      />
    </>
  );
}

export default TableGrid;
