import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import * as ReactBootstrap from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ClipLoader from 'react-spinners/ClipLoader';
import { useStateValue } from '../context/StateProvider';
import { consigneeCreate, consigneeUpdate, getConsigneeList } from '../services/consigneeEndpoint';
import apiFetch from '../services/apiFetch';

const initForm = {
  id: 0,
  consignee: '',
  company: '',
  location: 'GUAM',
  address: '',
  city: '',
  state: '',
  country: '',
  zipcode: '',
  isAffiliate: 'YES',
};

function Consignee() {
  const [state] = useStateValue();
  // const [getListRender, SetgetListRender] = useState(true);
  const [isFormDisable, SetisFormDisable] = useState(true);
  const [FormValue, SetFormValue] = useState(initForm);
  const [dataInfoGrid, SetDataInfoGrid] = useState([]);
  const [isNew, SetisNew] = useState(false);
  const [showForm, SetShowForm] = useState(false);
  const [isDisabledBtnNew, SetisDisabledBtnNew] = useState(false);
  const [isDisabledBtnEdit, SetisDisabledBtnEdit] = useState(false);
  const [isDisabledBtnSave, SetisDisabledBtnSave] = useState(true);
  const [isDisabledBtnCancel, SetisDisabledBtnCancel] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const params = {
        isAffiliate: 'all',
      };
      const endpoint = getConsigneeList(params);
      const response = await apiFetch(endpoint);
      //console.log(response.data);
      if (response && response.data) {
        let data = response.data;
        if (data.length) {
          let filteredResult = [];
          data.forEach((value) => {
            let newObject = {
              id: value.Consignee_ID,
              consignee: value.Consignee_Name,
              company: value.Company_Name,
              location: value.LocationCode,
              address: value.Address,
              city: value.City,
              state: value.State,
              country: value.Country,
              zipcode: value.Zipcode,
              isAffiliate: value.isAffiliate ? 'YES' : 'NO',
            };
            filteredResult.push(newObject);
          });
          SetDataInfoGrid(filteredResult);
        } else {
          SetDataInfoGrid([]);
        }
      } else {
        SetDataInfoGrid([]);
      }
      setIsLoading(false);
    };

    if (!isLoading) {
      getData().catch((e) => console.log(e));
    }
  }, []);

  if (!state.isLogin) return <Redirect to='/login' />;
  if (state.isLogin && state.newUser) return <Redirect to='/change-password' />;
  let errors = [];
  let locationFilter = '';
  let consigneeFilter = '';
  let affiliateFilter;

  const clearFilter = () => {
    locationFilter('');
    consigneeFilter('');
    affiliateFilter('');
  };

  const columns = [
    {
      dataField: 'id',
      text: '#',
      hidden: true,
      headerStyle: () => {
        return { width: '5px', textAlign: 'center' };
      },
    },
    {
      dataField: 'consignee',
      text: 'Consignee',
      sort: true,
      headerStyle: () => {
        return { width: '120px', textAlign: 'center' };
      },
      filter: textFilter({
        getFilter: (filter) => (consigneeFilter = filter),
      }),
    },
    {
      dataField: 'company',
      text: 'Company Name',
      headerStyle: () => {
        return { width: '200px', textAlign: 'center' };
      },
    },
    {
      dataField: 'location',
      text: 'Location',
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
      filter: textFilter({
        getFilter: (filter) => (locationFilter = filter),
      }),
    },
    {
      dataField: 'address',
      text: 'Address',
      headerStyle: () => {
        return { width: '170px', textAlign: 'center' };
      },
    },
    {
      dataField: 'city',
      text: 'City',
      headerStyle: () => {
        return { width: '150px', textAlign: 'center' };
      },
    },
    {
      dataField: 'state',
      text: 'State',
      headerStyle: () => {
        return { width: '70px', textAlign: 'center' };
      },
    },
    {
      dataField: 'country',
      text: 'Country',
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
    },
    {
      dataField: 'zipcode',
      text: 'Zipcode',
      headerStyle: () => {
        return { width: '70px', textAlign: 'center' };
      },
    },
    {
      dataField: 'isAffiliate',
      text: 'Affiliate',
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
      filter: textFilter({
        getFilter: (filter) => (affiliateFilter = filter),
      }),
    },
  ];

  const applyDefaultBtnStatusState = () => {
    SetFormValue(initForm);
    SetisFormDisable(true);
    SetisNew(false);
    SetShowForm(false);
    SetisDisabledBtnNew(false);
    SetisDisabledBtnEdit(false);
    SetisDisabledBtnSave(true);
    SetisDisabledBtnCancel(true);
  };

  const handleNewButton = () => {
    SetFormValue(initForm);
    SetisFormDisable(false);
    SetisNew(true);
    SetShowForm(true);
    SetisDisabledBtnEdit(true);
    SetisDisabledBtnSave(false);
    SetisDisabledBtnCancel(false);
  };

  const handleCancelButton = () => {
    if (!isFormDisable) {
      SetFormValue(initForm);
      SetisFormDisable(true);
      applyDefaultBtnStatusState();
    }
  };

  const handleEditButton = () => {
    if (FormValue.consignee !== '') {
      SetisFormDisable(false);
      SetShowForm(true);
      SetisNew(false);
      SetisDisabledBtnNew(true);
      SetisDisabledBtnSave(false);
      SetisDisabledBtnCancel(false);
    }
  };

  const onRowSelect = (row) => {
    //console.log(row);
    SetFormValue(row);
  };

  const selectRowProp = {
    mode: 'radio',
    clickToSelect: true,
    onSelect: onRowSelect,
  };

  const handleChange = (prop) => (ev) => {
    SetFormValue({ ...FormValue, [prop]: ev.target.value });
  };

  const validate = () => {
    return new Promise((resolve) => {
      if (FormValue.consignee === '') {
        errors.push('You must provide a Consignee Name');
      }
      resolve();
    });
  };

  const reloadGrid = async () => {
    const params = {
      isAffiliate: 'all',
    };
    const endpoint = getConsigneeList(params);
    const response = await apiFetch(endpoint);
    //console.log(response.data);
    if (response && response.data) {
      let data = response.data;
      if (data.length) {
        let filteredResult = [];
        data.forEach((value) => {
          let newObject = {
            id: value.Consignee_ID,
            consignee: value.Consignee_Name,
            company: value.Company_Name,
            location: value.LocationCode,
            address: value.Address,
            city: value.City,
            state: value.State,
            country: value.Country,
            zipcode: value.Zipcode,
            isAffiliate: value.isAffiliate ? 'YES' : 'NO',
          };
          filteredResult.push(newObject);
        });
        SetDataInfoGrid(filteredResult);
      } else {
        SetDataInfoGrid([]);
      }
    } else {
      SetDataInfoGrid([]);
    }
  };

  const insertNewRecord = (newRecord) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoint = consigneeCreate(newRecord);
        const response = await apiFetch(endpoint);
        if (response && response.data) {
          resolve('Success');
        } else {
          reject('Failed');
        }
      } catch (error) {
        console.log(error);
        reject();
      }
    });
  };

  const updateRecord = (Record) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoint = consigneeUpdate(Record);
        const response = await apiFetch(endpoint);

        if (response && response.data) {
          resolve('Success');
        } else {
          reject('Failed');
        }
      } catch (error) {
        console.log(error);
        reject();
      }
    });
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    try {
      setIsLoading(true);
      await validate();
      let Record = {
        ConsigneeID: FormValue.id,
        Consignee_Name: FormValue.consignee.toUpperCase(),
        Company_Name: FormValue.company.toUpperCase(),
        Location_Code: FormValue.location,
        Address: FormValue.address.toUpperCase(),
        City: FormValue.city.toUpperCase(),
        State: FormValue.state.toUpperCase(),
        Country: FormValue.country.toUpperCase(),
        Zipcode: FormValue.zipcode.toUpperCase(),
        isAffiliate: FormValue.isAffiliate === 'YES' ? 1 : 0,
        UserEmail: localStorage.getItem('logisticsUseremail'),
      };

      if (!errors.length) {
        if (isNew) {
          /* Save New Record */
          insertNewRecord(Record)
            .then(() => {
              setIsLoading(false);
              alert('Successfully created a new record!');
              reloadGrid();
              applyDefaultBtnStatusState();
            })
            .catch(() => {
              setIsLoading(false);
              alert('Error in creating a new record!');
            });
        } else {
          /* Update Selected Record */
          updateRecord(Record)
            .then(() => {
              setIsLoading(false);
              alert('Successfully updated the record!');
              reloadGrid();
              applyDefaultBtnStatusState();
            })
            .catch(() => {
              setIsLoading(false);
              alert('Error in updating the record!');
            });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className='btn-group' role='group'>
      {options.map((option) => {
        const isSelect = currSizePerPage === `${option.page}`;
        return (
          <button
            key={option.text}
            type='button'
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${isSelect ? 'btn-secondary' : 'btn-default'}`}>
            {option.text}
          </button>
        );
      })}
    </div>
  );

  const PaginationOptions = {
    hideSizePerPage: true,
    sizePerPageRenderer,
  };

  return (
    <div className='wrapper'>
      <div className='loading-spinner'>
        <ClipLoader size={100} color={'#0054a6'} loading={isLoading} />
      </div>
      <div className='help-button'>
        <a
          href='https://triplejlogistics.org/help/ConsigneeMenu.html'
          target='_blank'
          rel='noopener noreferrer'
          className='btn btn-labeled btn-outline-dark btn-sm mr-1'>
          <span className='btn-label'>
            <i className='fa fa-question-circle pr-1'></i>
          </span>
          Help
        </a>
      </div>
      <div className='generic-content-container container-fluid'>
        <h2>Consignee</h2>
      </div>
      <div className='container-fluid'>
        <div className='row mb-2'>
          <div className='col-md-12'>
            <button
              type='button'
              className='btn btn-sm btn-labeled btn-primary mr-1'
              onClick={handleNewButton}
              disabled={isDisabledBtnNew}>
              <span className='btn-label'>
                <i className='fa fa-plus pr-1'></i>
              </span>
              New
            </button>
            <button
              type='button'
              className='btn btn-sm btn-labeled btn-primary mr-1'
              disabled={isDisabledBtnEdit}
              onClick={handleEditButton}>
              <span className='btn-label'>
                <i className='fa fa-edit pr-1'></i>
              </span>
              Edit
            </button>
            <button
              type='submit'
              className='btn btn-sm btn-labeled btn-primary mr-1'
              disabled={isDisabledBtnSave}
              onClick={handleSubmit}>
              <span className='btn-label'>
                <i className='fa fa-check-square-o pr-1'></i>
              </span>
              Save
            </button>
            <button
              type='button'
              className='btn btn-sm btn-labeled btn-primary mr-1'
              onClick={handleCancelButton}
              disabled={isDisabledBtnCancel}>
              <span className='btn-label'>
                <i className='fa fa-ban pr-1'></i>
              </span>
              Cancel
            </button>
          </div>
        </div>
        <div
          className={`users-container ${
            showForm ? 'users-container--active' : 'users-container--inactive'
          }`}>
          <ReactBootstrap.Form>
            <ReactBootstrap.Form.Row>
              <ReactBootstrap.Form.Group controlId='formGridConsignee' className='col-sm'>
                <ReactBootstrap.Form.Label>Consignee</ReactBootstrap.Form.Label>
                <ReactBootstrap.Form.Control
                  placeholder='Enter Consignee'
                  disabled={isFormDisable}
                  value={FormValue.consignee}
                  onChange={handleChange('consignee')}
                />
              </ReactBootstrap.Form.Group>
              <ReactBootstrap.Form.Group controlId='formGridConsignee' className='col-sm'>
                <ReactBootstrap.Form.Label>Company Name</ReactBootstrap.Form.Label>
                <ReactBootstrap.Form.Control
                  placeholder='Enter Company Name'
                  disabled={isFormDisable}
                  value={FormValue.company}
                  onChange={handleChange('company')}
                />
              </ReactBootstrap.Form.Group>
            </ReactBootstrap.Form.Row>

            <ReactBootstrap.Form.Row>
              <ReactBootstrap.Form.Group controlId='formGridLocation' className='col-sm'>
                <label className='pr-2'>Location</label>
                <ReactBootstrap.Form.Control
                  as='select'
                  disabled={isFormDisable}
                  value={FormValue.location}
                  onChange={handleChange('location')}>
                  <option>{`GUAM`}</option>
                  <option>{`SAIPAN`}</option>
                  <option>{`PALAU`}</option>
                  <option>{`EBEYE`}</option>
                  <option>{`MAJURO`}</option>
                  <option>{`USA`}</option>
                </ReactBootstrap.Form.Control>
              </ReactBootstrap.Form.Group>
              <ReactBootstrap.Form.Group controlId='formGridLocation' className='col-sm'>
                <label className='pr-2'>Affiliate</label>
                <ReactBootstrap.Form.Control
                  as='select'
                  disabled={isFormDisable}
                  value={FormValue.isAffiliate}
                  onChange={handleChange('isAffiliate')}>
                  <option>{`YES`}</option>
                  <option>{`NO`}</option>
                </ReactBootstrap.Form.Control>
              </ReactBootstrap.Form.Group>
            </ReactBootstrap.Form.Row>

            <ReactBootstrap.Form.Row>
              <ReactBootstrap.Form.Group controlId='formGridConsignee' className='col-sm'>
                <ReactBootstrap.Form.Label>Address</ReactBootstrap.Form.Label>
                <ReactBootstrap.Form.Control
                  placeholder='Enter Address'
                  disabled={isFormDisable}
                  value={FormValue.address}
                  onChange={handleChange('address')}
                />
              </ReactBootstrap.Form.Group>

              <ReactBootstrap.Form.Group controlId='formGridConsignee' className='col-sm'>
                <ReactBootstrap.Form.Label>City</ReactBootstrap.Form.Label>
                <ReactBootstrap.Form.Control
                  placeholder='Enter City'
                  disabled={isFormDisable}
                  value={FormValue.city}
                  onChange={handleChange('city')}
                />
              </ReactBootstrap.Form.Group>
            </ReactBootstrap.Form.Row>

            <ReactBootstrap.Form.Row>
              <ReactBootstrap.Form.Group controlId='formGridConsignee' className='col-sm'>
                <ReactBootstrap.Form.Label>State</ReactBootstrap.Form.Label>
                <ReactBootstrap.Form.Control
                  placeholder='Enter State'
                  disabled={isFormDisable}
                  value={FormValue.state}
                  onChange={handleChange('state')}
                />
              </ReactBootstrap.Form.Group>
              <ReactBootstrap.Form.Group controlId='formGridConsignee' className='col-sm'>
                <ReactBootstrap.Form.Label>Country</ReactBootstrap.Form.Label>
                <ReactBootstrap.Form.Control
                  placeholder='Enter Country'
                  disabled={isFormDisable}
                  value={FormValue.country}
                  onChange={handleChange('country')}
                />
              </ReactBootstrap.Form.Group>
              <ReactBootstrap.Form.Group controlId='formGridConsignee' className='col-sm'>
                <ReactBootstrap.Form.Label>Zipcode</ReactBootstrap.Form.Label>
                <ReactBootstrap.Form.Control
                  placeholder='Enter Zipcode'
                  disabled={isFormDisable}
                  value={FormValue.zipcode}
                  onChange={handleChange('zipcode')}
                />
              </ReactBootstrap.Form.Group>
            </ReactBootstrap.Form.Row>
          </ReactBootstrap.Form>
        </div>
      </div>
      <div className='container-fluid'>
        <button
          className='btn btn-primary btn-sm mb-2'
          onClick={clearFilter}
          disabled={true}
          hidden={true}>
          Clear Filter
        </button>
      </div>
      <div
        className={`container-fluid scroll-container ${
          !showForm ? 'users-container--active' : 'users-container--inactive'
        }`}>
        <div className='scroll-container'>
          <BootstrapTable
            keyField='id'
            data={dataInfoGrid}
            columns={columns}
            selectRow={selectRowProp}
            pagination={paginationFactory(PaginationOptions)}
            filter={filterFactory()}
            filterPosition='top'
            striped
            hover
          />
        </div>
      </div>
      {/* <div className='container'>
        <img src={imgGrid} alt='View' />
      </div> */}
    </div>
  );
}

export default Consignee;
