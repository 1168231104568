import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import * as ReactBootstrap from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import validator from 'validator';
import { useStateValue } from '../context/StateProvider';
import ClipLoader from 'react-spinners/ClipLoader';
import LoadingDotsIcon from '../components/LoadingDotsIcon';
import { SpinnerStyle } from '../styles/SpinnerStyle';
import {
  addNewUser,
  changeUserRecord,
  fetchUserList,
  updateUserStatus,
  userEmailForm,
} from '../services/userEndpoint';
import apiFetch from '../services/apiFetch';

const initForm = {
  id: 0,
  location: 'GUAM - FIVE STAR',
  firstName: '',
  lastName: '',
  email: '',
  usertype: 'Manager',
  status: '',
};

function UserAccounts() {
  const [state] = useStateValue();
  const [getListRender, SetgetListRender] = useState(true);
  const [isFormDisable, SetisFormDisable] = useState(true);
  const [FormValue, SetFormValue] = useState(initForm);
  const [selectedEmail, SetSelectedEmail] = useState('');
  const [isNew, SetisNew] = useState(false);
  const [isEdit, SetisEdit] = useState(false);
  const [isDisabledBtnNew, SetisDisabledBtnNew] = useState(false);
  const [isDisabledBtnEdit, SetisDisabledBtnEdit] = useState(false);
  const [isDisabledBtnSave, SetisDisabledBtnSave] = useState(true);
  const [isDisabledBtnCancel, SetisDisabledBtnCancel] = useState(true);
  const [isDisabledBtnStatus, SetisDisabledBtnStatus] = useState(false);
  const [viewForm, SetViewForm] = useState(false);
  const [statusLabelBtn, SetStatusLabelBtn] = useState('Disable User');
  const [dataInfoGrid, SetDataInfoGrid] = useState([]);
  const [dataEmailGrid, setDataEmailGrid] = useState([]);
  const [isLoading, SetIsLoading] = useState(false);
  const [isFetching, SetIsFetching] = useState(true);
  const errors = [];
  let ConsLookUp = JSON.parse(localStorage.getItem('logisticsConsignee'));

  useEffect(() => {
    const getData = async () => {
      try {
        SetIsLoading(true);
        const endpoint = fetchUserList();
        const response = await apiFetch(endpoint);

        if (response && response.data) {
          let data = response.data;
          // console.log(data);
          //console.log(Array.isArray(data));
          if (Array.isArray(data)) {
            SetDataInfoGrid(data);
          } else {
            SetDataInfoGrid([]);
          }
          SetIsLoading(false);
        } else {
          SetDataInfoGrid([]);
          SetIsLoading(false);
        }
        SetIsFetching(false);
        SetgetListRender(false);
      } catch (curError) {
        console.log(curError);
      }
    };
    //console.log(dataInfoGrid.length);
    if (getListRender) {
      getData();
    }
  }, []);

  //console.log(state.user.UserType);
  if (!state.isLogin) return <Redirect to='/login' />;
  if (state.isLogin && state.newUser) return <Redirect to='/change-password' />;

  let locationFilter, fnameFilter, lnameFilter;

  const MakeConsigneeOptions = (data) => {
    return (
      data &&
      data.map((cons, index) => (
        <option key={index} value={cons}>
          {cons}
        </option>
      ))
    );
  };

  const clearFilter = () => {
    try {
      locationFilter('');
      fnameFilter('');
      lnameFilter('');
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      dataField: 'id',
      text: '#',
      hidden: true,
      headerStyle: () => {
        return { width: '40px', textAlign: 'left' };
      },
      sort: true,
    },
    {
      dataField: 'location',
      text: 'Consignee',
      sort: true,
      headerStyle: () => {
        return { width: '200px', textAlign: 'center' };
      },
      filter: textFilter({
        getFilter: (filter) => {
          locationFilter = filter;
        },
      }),
    },
    {
      dataField: 'firstName',
      text: 'First Name',
      headerStyle: () => {
        return { width: '140px', textAlign: 'center' };
      },
      filter: textFilter({
        getFilter: (filter) => {
          fnameFilter = filter;
        },
      }),
    },
    {
      dataField: 'lastName',
      text: 'Last Name',
      headerStyle: () => {
        return { width: '140px', textAlign: 'center' };
      },
      filter: textFilter({
        getFilter: (filter) => {
          lnameFilter = filter;
        },
      }),
    },
    {
      dataField: 'email',
      text: 'Email',
      headerStyle: () => {
        return { width: '250px', textAlign: 'center' };
      },
    },
    {
      dataField: 'usertype',
      text: 'User Type',
      sort: true,
      headerStyle: () => {
        return { width: '140px', textAlign: 'center' };
      },
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      headerStyle: () => {
        return { width: '140px', textAlign: 'center' };
      },
    },
  ];

  const getUserEmailNotificationForm = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const params = {
          userId: FormValue.id,
        };
        const endpoint = userEmailForm(params);
        SetIsLoading(true);
        const response = await apiFetch(endpoint);
        if (response && response.data) {
          let data = response.data;
          if (Array.isArray(data)) {
            SetIsLoading(false);
            setDataEmailGrid(data);
            return resolve(data);
          } else {
            SetIsLoading(false);
            return resolve([]);
          }
        } else {
          SetIsLoading(false);
          return resolve([]);
        }
      } catch (error) {
        console.error(error);
        SetIsLoading(false);
        return reject('System Error');
      }
    });
  };

  const handleCheckBoxEmail = (rowID, cell, name) => {
    let newEmailData;
    newEmailData = dataEmailGrid.map((obj) => {
      if (obj.id === rowID) {
        return { ...obj, [name]: cell === 1 ? 0 : 1 };
      } else {
        return obj;
      }
    });
    return setDataEmailGrid(newEmailData);
  };

  const validate = () => {
    return new Promise((resolve) => {
      if (FormValue.firstName === '') {
        errors.push('You must provide User First Name');
      }
      if (FormValue.lastName === '') {
        errors.push('You must provide User Last Name');
      }
      if (!validator.isEmail(FormValue.email)) {
        errors.push('You must provide a valid email address.');
      }
      resolve();
    });
  };

  const applyDefaultBtnStatusState = () => {
    SetFormValue(initForm);
    SetisFormDisable(true);
    SetisNew(false);
    SetisEdit(false);
    SetisDisabledBtnNew(false);
    SetisDisabledBtnEdit(false);
    SetisDisabledBtnSave(true);
    SetisDisabledBtnCancel(true);
    SetisDisabledBtnStatus(false);
    setDataEmailGrid([]);
    SetViewForm(false);
  };

  const reloadGrid = () => {
    return new Promise(async (resolve, reject) => {
      const endpoint = fetchUserList();
      const response = await apiFetch(endpoint);
      if (response && response.data.length) {
        resolve(response.data);
      } else {
        reject([]);
      }
    });
  };

  const handleEditUserRecord = (updatedUserRecord) => {
    return new Promise(async (resolve, reject) => {
      const endpoint = changeUserRecord(selectedEmail, updatedUserRecord);
      const response = await apiFetch(endpoint);
      // console.log(response.data);
      if (response && response.data) {
        resolve(response.data);
      } else {
        reject('Failed');
      }
    });
  };

  const handleInsertNewRecord = (newUserRecord) => {
    return new Promise(async (resolve, reject) => {
      const endpoint = addNewUser(newUserRecord);
      const response = await apiFetch(endpoint);
      //console.log(response.data);
      if (response && response.data) {
        resolve('Success');
      } else {
        reject('Failed');
      }
    });
  };

  const callUpdateStatus = (newStatusData) => {
    return new Promise(async (resolve, reject) => {
      const endpoint = updateUserStatus(selectedEmail, newStatusData);
      let response = await apiFetch(endpoint);
      //console.log(response.data);
      if (response && response.data) {
        resolve('Success');
      } else {
        reject('Failed');
      }
    });
  };

  const handleUpdateUserStatus = () => {
    try {
      let newStatus;
      if (selectedEmail !== '') {
        if (statusLabelBtn === 'Disable User') {
          newStatus = 'In-Active';
        } else {
          newStatus = 'Active';
        }

        let newStatusData = {
          token: localStorage.getItem('logisticsToken'),
          useremail: localStorage.getItem('logisticsUseremail'),
          status: newStatus,
        };

        callUpdateStatus(newStatusData)
          .then(() => {
            alert('User status is updated');
            reloadGrid()
              .then((result) => {
                SetDataInfoGrid(result);
              })
              .catch((err) => {
                console.log(err);
                alert('Record is empty');
              });
          })
          .catch(() => {
            alert('Error updating status');
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    try {
      await validate();

      if (!errors.length) {
        SetIsLoading(true);
        let newUserRecord = {
          email: FormValue.email,
          FirstName: FormValue.firstName,
          LastName: FormValue.lastName,
          UserType: FormValue.usertype,
          Location: FormValue.location,
          password: 'Password',
          token: localStorage.getItem('logisticsToken'),
          useremail: localStorage.getItem('logisticsUseremail'),
          dataEmail: dataEmailGrid,
        };

        if (isNew) {
          handleInsertNewRecord(newUserRecord)
            .then((result) => {
              console.log(result);
              if (result === 'Success') {
                alert('Successfully Registered');
                reloadGrid()
                  .then((result) => {
                    SetDataInfoGrid(result);
                  })
                  .catch((err) => {
                    console.log(err);
                    alert('Record is empty');
                  });
                applyDefaultBtnStatusState();
              }
            })
            .catch((err) => {
              console.log(err);
              applyDefaultBtnStatusState();
              alert(`Unable to register new user email: ${newUserRecord.email}`);
            });
        }

        let updatedUserRecord = {
          Newemail: FormValue.email,
          FirstName: FormValue.firstName,
          LastName: FormValue.lastName,
          UserType: FormValue.usertype,
          Location: FormValue.location,
          useremail: localStorage.getItem('logisticsUseremail'),
          dataEmail: dataEmailGrid,
        };

        if (isEdit) {
          handleEditUserRecord(updatedUserRecord)
            .then((result) => {
              console.log(result);
              if (result === 'Success') {
                alert('Successfully Updated Record');
                reloadGrid()
                  .then((result) => {
                    SetDataInfoGrid(result);
                  })
                  .catch((err) => {
                    console.log(err);
                    alert('Record is empty');
                  });
                applyDefaultBtnStatusState();
              }
            })
            .catch((err) => {
              console.log(err);
              applyDefaultBtnStatusState();
              alert(`Unable to update record useremail: ${selectedEmail}`);
            });
        }
        SetIsLoading(false);
      } else {
        alert(errors[0]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleNewButton = () => {
    SetFormValue(initForm);
    SetViewForm(true);
    SetisFormDisable(false);
    SetisNew(true);
    SetisEdit(false);
    SetisDisabledBtnEdit(true);
    SetisDisabledBtnStatus(true);
    SetisDisabledBtnSave(false);
    SetisDisabledBtnCancel(false);
    getUserEmailNotificationForm();
  };

  const handleCancelButton = () => {
    if (!isFormDisable) {
      SetFormValue(initForm);
      SetisFormDisable(true);
      //Initialize to default states
      applyDefaultBtnStatusState();
    }
  };

  const handleEditButton = () => {
    if (FormValue.email !== '') {
      SetisFormDisable(false);
      SetisEdit(true);
      SetisNew(false);
      SetisDisabledBtnNew(true);
      SetisDisabledBtnStatus(true);
      SetisDisabledBtnSave(false);
      SetisDisabledBtnCancel(false);
      SetViewForm(true);
      getUserEmailNotificationForm();
    }
  };

  const onRowSelect = (row) => {
    SetFormValue(row);
    SetSelectedEmail(row.email);
    // console.log(row);
    if (row.status === 'Active') {
      SetStatusLabelBtn('Disable User');
    } else {
      SetStatusLabelBtn('Activate User');
    }
  };

  const selectRowProp = {
    mode: 'radio',
    clickToSelect: true,
    onSelect: onRowSelect,
  };

  const handleChange = (prop) => (ev) => {
    SetFormValue({ ...FormValue, [prop]: ev.target.value });
    //console.log(FormValue);
  };

  const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className='btn-group' role='group'>
      {options.map((option) => {
        const isSelect = currSizePerPage === `${option.page}`;
        return (
          <button
            key={option.text}
            type='button'
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${isSelect ? 'btn-secondary' : 'btn-default'}`}>
            {option.text}
          </button>
        );
      })}
    </div>
  );

  const PaginationOptions = {
    hideSizePerPage: true,
    sizePerPageRenderer,
  };

  if (isFetching) {
    return (
      <div className='wrapper'>
        <LoadingDotsIcon />
      </div>
    );
  }

  return (
    <div className='wrapper'>
      <div className='loading-spinner'>
        <ClipLoader size={100} color={'#0054a6'} loading={isLoading} css={SpinnerStyle} />
      </div>
      <div className='help-button'>
        <a
          href='https://triplejlogistics.org/help/UsersMenu.html'
          target='_blank'
          rel='noopener noreferrer'
          className='btn btn-labeled btn-outline-dark btn-sm mr-1'>
          <span className='btn-label'>
            <i className='fa fa-question-circle pr-1'></i>
          </span>
          Help
        </a>
      </div>
      <div className='generic-content-container container-fluid'>
        <h2>User Accounts</h2>
      </div>
      <div className='container-fluid users-container'>
        <div className='row mb-2'>
          <div className='col-md-12'>
            <button
              type='button'
              className='btn btn-sm btn-labeled btn-primary mr-1'
              onClick={handleNewButton}
              disabled={isDisabledBtnNew}>
              <span className='btn-label'>
                <i className='fa fa-plus pr-1'></i>
              </span>
              New
            </button>
            <button
              type='button'
              className='btn btn-sm btn-labeled btn-primary mr-1'
              onClick={handleEditButton}
              disabled={isDisabledBtnEdit}>
              <span className='btn-label'>
                <i className='fa fa-edit pr-1'></i>
              </span>
              Edit
            </button>
            <button
              type='submit'
              className='btn btn-sm btn-labeled btn-primary mr-1'
              onClick={handleSubmit}
              disabled={isDisabledBtnSave}>
              <span className='btn-label'>
                <i className='fa fa-check-square-o pr-1'></i>
              </span>
              Save
            </button>
            <button
              type='button'
              className='btn btn-sm btn-labeled btn-primary mr-1'
              onClick={handleCancelButton}
              disabled={isDisabledBtnCancel}>
              <span className='btn-label'>
                <i className='fa fa-ban pr-1'></i>
              </span>
              Cancel
            </button>
            <button
              type='button'
              className='btn btn-sm btn-labeled btn-primary mr-1'
              disabled={isDisabledBtnStatus}
              onClick={handleUpdateUserStatus}>
              <span className='btn-label'>
                <i className='fa fa-trash-o pr-1'></i>
              </span>
              {statusLabelBtn}
            </button>
          </div>
        </div>
        <ReactBootstrap.Form onSubmit={handleSubmit} className=''>
          <div className={`${viewForm ? 'users-container--active' : 'users-container--inactive'}`}>
            <ReactBootstrap.Form.Row>
              <ReactBootstrap.Form.Group controlId='formGridLocation' className='d-inline-flex'>
                <label className='pr-2'>Consignee</label>
                <ReactBootstrap.Form.Control
                  as='select'
                  disabled={isFormDisable}
                  value={FormValue.location}
                  onChange={handleChange('location')}>
                  {MakeConsigneeOptions(ConsLookUp)}
                </ReactBootstrap.Form.Control>
              </ReactBootstrap.Form.Group>
            </ReactBootstrap.Form.Row>
            <ReactBootstrap.Form.Row>
              <ReactBootstrap.Form.Group
                controlId='formGridFName'
                className='pr-2 form-group--w-50'>
                <ReactBootstrap.Form.Label>First Name</ReactBootstrap.Form.Label>
                <ReactBootstrap.Form.Control
                  placeholder='Enter First Name'
                  disabled={isFormDisable}
                  value={FormValue.firstName}
                  onChange={handleChange('firstName')}
                  required
                />
              </ReactBootstrap.Form.Group>

              <ReactBootstrap.Form.Group controlId='formGridLName' className='form-group--w-50'>
                <ReactBootstrap.Form.Label>Last Name</ReactBootstrap.Form.Label>
                <ReactBootstrap.Form.Control
                  placeholder='Enter Last Name'
                  disabled={isFormDisable}
                  value={FormValue.lastName}
                  onChange={handleChange('lastName')}
                  required
                />
              </ReactBootstrap.Form.Group>
            </ReactBootstrap.Form.Row>
            <ReactBootstrap.Form.Row>
              <ReactBootstrap.Form.Group
                controlId='formGridEmail'
                className='pr-2 form-group--w-50'>
                <ReactBootstrap.Form.Label>Email address</ReactBootstrap.Form.Label>
                <ReactBootstrap.Form.Control
                  type='email'
                  placeholder='name@example.com'
                  disabled={isFormDisable}
                  value={FormValue.email}
                  onChange={handleChange('email')}
                  required
                />
              </ReactBootstrap.Form.Group>
              <ReactBootstrap.Form.Group controlId='formGridUType' className='form-group--w-50'>
                <ReactBootstrap.Form.Label>User Type</ReactBootstrap.Form.Label>
                <ReactBootstrap.Form.Control
                  as='select'
                  disabled={isFormDisable}
                  value={FormValue.usertype}
                  onChange={handleChange('usertype')}>
                  <option>{`Admin`}</option>
                  <option>{`Manager`}</option>
                  <option>{`User`}</option>
                  <option>{`Receiving`}</option>
                </ReactBootstrap.Form.Control>
              </ReactBootstrap.Form.Group>
            </ReactBootstrap.Form.Row>
            <div className='email-checkbox-container'>
              <div className='container-fluid border border-left-0 border-right-0 bg-secondary'>
                <div className='row'>
                  <div className='col-1 d-flex align-self-center font-weight-bold text-light'>
                    ID
                  </div>
                  <div className='col-5 d-flex align-self-center font-weight-bold text-light'>
                    Email Notification Program
                  </div>
                  <div className='col-2 d-flex align-self-center font-weight-bold text-light'>
                    To
                  </div>
                  <div className='col-2 d-flex align-self-center font-weight-bold text-light'>
                    Cc
                  </div>
                  <div className='col-2 d-flex align-self-center font-weight-bold text-light'>
                    BCc
                  </div>
                </div>
              </div>
              {dataEmailGrid &&
                dataEmailGrid.map((info, i) => {
                  return (
                    <div
                      key={i}
                      className='container-fluid border border-top-0 border-left-0 border-right-0'>
                      <div className='row'>
                        <div className='col-1 d-flex align-self-center'>{info.id}</div>
                        <div className='col-5 d-flex align-self-center'>{info.emailName}</div>
                        <div className='col-2 d-flex align-self-center'>
                          <input
                            type='checkbox'
                            checked={info.asTo}
                            id={`${info.id}_asTo`}
                            onChange={() => handleCheckBoxEmail(info.id, info.asTo, 'asTo')}
                          />
                        </div>
                        <div className='col-2 d-flex align-self-center'>
                          <input
                            type='checkbox'
                            checked={info.asCC}
                            id={`${info.id}_asCC`}
                            onChange={() => handleCheckBoxEmail(info.id, info.asCC, 'asCC')}
                          />
                        </div>
                        <div className='col-2 d-flex align-self-center'>
                          <input
                            type='checkbox'
                            checked={info.asBCC}
                            id={`${info.id}_asBCC`}
                            onChange={() => handleCheckBoxEmail(info.id, info.asBCC, 'asBCC')}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </ReactBootstrap.Form>
      </div>

      <div className='container-fluid'>
        <button
          className='btn btn-primary btn-sm mb-2'
          onClick={clearFilter}
          disabled={true}
          hidden={true}>
          Clear Filter
        </button>
      </div>
      <div
        className={`container-fluid table--horiz-scroll ${
          viewForm
            ? 'users-container users-container--inactive'
            : 'users-container users-container--active'
        }`}>
        <BootstrapTable
          keyField='id'
          data={dataInfoGrid}
          columns={columns}
          selectRow={selectRowProp}
          filter={filterFactory()}
          pagination={paginationFactory(PaginationOptions)}
          filterPosition='top'
          striped
          hover
        />
      </div>
    </div>
  );
}

export default UserAccounts;
