import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import { useStateValue } from '../context/StateProvider';
// import { Chart } from 'react-google-charts';
import * as ReactBootstrap from 'react-bootstrap';

import LoadingDotsIcon from './LoadingDotsIcon';
import { getPODBData } from '../services/dashboardEndpoint';
import apiFetch from '../services/apiFetch';

// import SearchDashBoard from './SearchDashBoard';

const PODashBoard = () => {
  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = useStateValue();
  const [isRender, setIsRender] = useState(true);
  const [dataInfoGrid, setDataInfoGrid] = useState([]);
  // const [pieData, SetPieData] = useState([]);
  // const [barData, SetBarData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const getDashBoardData = () => {
      return new Promise(async (resolve, reject) => {
        const endpoint = getPODBData();
        let response = await apiFetch(endpoint);
        if (response && response.data) {
          let arrData = response.data;
          if (Array.isArray(arrData) && arrData.length > 0) {
            resolve(arrData);
          } else {
            reject([]);
          }
        }
      });
    };

    // const generatePieData = (mydata) => {
    //   let newArrData = [];
    //   let pieMyData = [];
    //   let headerData = ['Consignee', 'Total'];
    //   pieMyData.push(headerData);
    //   mydata.forEach((value) => {
    //     newArrData = [value.Destination, value.Total];
    //     pieMyData.push(newArrData);
    //   });
    //   //console.log(pieMyData);
    //   // SetPieData(pieMyData);
    // };

    // const generateBarData = (mydata) => {
    //   let newArrData,
    //     barMyData = [];
    //   let headerData = ['Consignee', 'MTD', 'YTD'];
    //   barMyData.push(headerData);
    //   mydata.forEach((value) => {
    //     //console.log(value);
    //     newArrData = [value.Destination, value.MTD, value.YTD];
    //     barMyData.push(newArrData);
    //   });
    //   // SetBarData(barMyData);
    // };

    if (isRender) {
      setDataInfoGrid([]);
      // SetPieData([]);
      getDashBoardData()
        .then((result) => {
          //console.log(result);
          setDataInfoGrid(result);
          // generatePieData(result);
          // generateBarData(result);
        })
        .catch(() => {
          setDataInfoGrid([]);
          setIsFetching(false);
        });
      setIsRender(false);
      setIsFetching(false);
    }
    return () => {
      setIsRender(false);
      // dispatch({ type: 'SET_PO_FILTER', payload: '' });
    };
  }, [isRender]);

  const columns = [
    {
      text: 'Consignee',
      dataField: 'Destination',
      sort: true,
      headerStyle: () => {
        return { width: '200px', textAlign: 'center' };
      },
      footer: 'All',
    },
    {
      text: 'Open',
      dataField: 'OpenPO',
      align: 'center',
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
      footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
    },
    {
      text: 'Received',
      dataField: 'ReceivedPO',
      align: 'center',
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
      footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
    },
    {
      text: 'Loading',
      dataField: 'LoadingPO',
      align: 'center',
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
      footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
    },
    {
      text: 'Shipped',
      dataField: 'ShippedPO',
      align: 'center',
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
      footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
    },
    {
      text: 'Total',
      dataField: 'Total',
      align: 'center',
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
      footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
    },
    {
      text: 'MTD Delivered',
      dataField: 'MTD',
      align: 'center',
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
      footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
    },
    {
      text: 'YTD Delivered',
      dataField: 'YTD',
      align: 'center',
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
      footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
    },
  ];

  const onRowSelect = (row) => {
    //console.log(row);
    dispatch({ type: 'SET_PO_FILTER', payload: row.Destination });
    history.push('/purchase-order');
  };

  const selectRowProp = {
    mode: 'radio',
    clickToSelect: true,
    onSelect: onRowSelect,
  };

  // const monthNames = [
  //   'January',
  //   'February',
  //   'March',
  //   'April',
  //   'May',
  //   'June',
  //   'July',
  //   'August',
  //   'September',
  //   'October',
  //   'November',
  //   'December',
  // ];
  // let today = new Date();

  if (isFetching) {
    return (
      <div className='wrapper'>
        <LoadingDotsIcon />
      </div>
    );
  }

  return (
    <div className=''>
      {/* <div className='generic-content-container container'>
        <h2>Dash Board - Purchase Order Status</h2>
      </div> */}
      {/* <div className='container'> */}
      <BootstrapTable
        keyField='Destination'
        data={dataInfoGrid}
        columns={columns}
        selectRow={selectRowProp}
        footerClasses='dashboard-foot'
        striped
        hover
        condensed
      />
      <div className='generic-content-container container'>
        <div className='d-flex justify-content-center'>
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs={6} className='mr-2'>
              {/*<Chart*/}
              {/*  className='justify-content-center'*/}
              {/*  width={'600px'}*/}
              {/*  height={'400px'}*/}
              {/*  chartType='PieChart'*/}
              {/*  loader={<LoadingDotsIcon />}*/}
              {/*  data={pieData}*/}
              {/*  options={{*/}
              {/*    title: 'Loading & Shipped',*/}
              {/*    // Just add this option*/}
              {/*    is3D: true,*/}
              {/*  }}*/}
              {/*  rootProps={{ 'data-testid': '2' }}*/}
              {/*/>*/}
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={5}>
              {/*<Chart*/}
              {/*  width={'500px'}*/}
              {/*  height={'300px'}*/}
              {/*  chartType='Bar'*/}
              {/*  loader={<LoadingDotsIcon />}*/}
              {/*  data={barData}*/}
              {/*  options={{*/}
              {/*    // Material design options*/}
              {/*    chart: {*/}
              {/*      title: 'PO Delivered Summary',*/}
              {/*      subtitle: `MTD and YTD for Year:${today.getFullYear()}`,*/}
              {/*    },*/}
              {/*  }}*/}
              {/*  rootProps={{ 'data-testid': '2' }}*/}
              {/*/>*/}
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </div>
      </div>
    </div>
  );
};

export default PODashBoard;
