import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import * as ReactBootstrap from 'react-bootstrap';
import { useStateValue } from '../context/StateProvider';
import ClipLoader from 'react-spinners/ClipLoader';
import { SpinnerStyle } from '../styles/SpinnerStyle';
import { changePassword } from '../services/userEndpoint';
import apiFetch from '../services/apiFetch';

//import imgTJLogo from '../assets/images/tj-logo.jpg';

const ChangePassword = () => {
  const [state, dispatch] = useStateValue();
  const [userValue, setUserValue] = useState({
    newpassword: '',
    confirmpassword: '',
  });
  const [inputType, SetInputType] = useState('password');
  const [isLoading, SetLoading] = useState(false);
  const history = useHistory();

  if (!state.isLogin) return <Redirect to='/login' />;

  let errors = [];
  const defaultValue = {
    newpassword: '',
    confirmpassword: '',
  };

  const handleChange = (prop) => (ev) => {
    setUserValue({ ...userValue, [prop]: ev.target.value });
  };

  const showHide = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    inputType === 'text' ? SetInputType('password') : SetInputType('text');
  };

  const handleCancel = (ev) => {
    ev.preventDefault();
    setUserValue(defaultValue);
    if (state.newUser) {
      alert('You are required to change your default password');
    }
  };

  const validate = () => {
    return new Promise((resolve) => {
      if (userValue.newpassword === '') {
        errors.push('Please enter a New Password');
      }

      if (userValue.confirmpassword === '') {
        errors.push('Please enter a confirm your new password');
      }

      if (userValue.newpassword.trim() !== userValue.confirmpassword.trim()) {
        errors.push('Confirm password does not match with your new password');
      }

      if (userValue.newpassword.toLowerCase() === 'password') {
        errors.push('Cannot use default password');
      }

      if (userValue.newpassword.length > 0 && userValue.newpassword.length < 8) {
        errors.push('Password must be at least 8 characters');
      }

      if (userValue.newpassword.length > 20) {
        errors.push('Password cannot exceed 20 characters');
      }

      resolve('Done');
    });
  };

  const changeUserPassword = (updateRecord) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoint = changePassword(updateRecord);
        let response = await apiFetch(endpoint);
        //console.log(response.data);
        if (response && response.data) {
          resolve('Success');
        } else {
          reject('Failed');
        }
      } catch (error) {
        console.log(error);
        reject('Failed');
      }
    });
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    SetLoading(true);
    await validate();

    if (!errors.length) {
      let updatedUser = {
        password: userValue.newpassword,
        email: localStorage.getItem('logisticsUseremail'),
        UserEmail: localStorage.getItem('logisticsUseremail'),
      };

      changeUserPassword(updatedUser)
        .then(() => {
          SetLoading(false);
          setUserValue(defaultValue);
          dispatch({ type: 'PASS_CHANGE' });
          alert('Successfully change password');
          history.push('/');
        })
        .catch((err) => {
          console.log(err);
          SetLoading(false);
          alert('System unable to change password!');
        });
    } else {
      SetLoading(false);
      errors.forEach((msg) => {
        alert(msg);
      });
    }
  };

  return (
    <div className='wrapper'>
      <div className='loading-spinner'>
        <ClipLoader size={100} color={'#0054a6'} loading={isLoading} css={SpinnerStyle} />
      </div>
      <div className='help-button'>
        <a
          href='https://triplejlogistics.org/help/ChangePasswordMenu.html'
          target='_blank'
          rel='noopener noreferrer'
          className='btn btn-labeled btn-outline-dark btn-sm mr-1'>
          <span className='btn-label'>
            <i className='fa fa-question-circle pr-1'></i>
          </span>
          Help
        </a>
      </div>
      <div className='generic-content-container container-fluid'>
        <h2>Change Your Password</h2>
      </div>
      <div className='container-fluid'>
        <div className='container-fluid'>
          <ReactBootstrap.Row className='mb-2'>
            <button
              type='submit'
              className='btn btn-labeled btn-primary btn-sm mr-1'
              onClick={handleSubmit}>
              <span className='btn-label'>
                <i className='fa fa-floppy-o pr-1'></i>
              </span>
              Save
            </button>
            <button
              type='button'
              className='btn btn-labeled btn-primary btn-sm mr-1'
              onClick={handleCancel}>
              <span className='btn-label'>
                <i className='fa fa-ban pr-1'></i>
              </span>
              Cancel
            </button>
          </ReactBootstrap.Row>
        </div>
      </div>
      <div className='container-fluid'>
        <ReactBootstrap.Form>
          <ReactBootstrap.Form.Row className='mb-2'>
            <ReactBootstrap.Col xs={2}>
              <ReactBootstrap.Form.Label>New Password</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Control
                className='d-block'
                type={inputType}
                placeholder='New Password'
                required
                value={userValue.newpassword}
                onChange={handleChange('newpassword')}
              />
            </ReactBootstrap.Col>
          </ReactBootstrap.Form.Row>
          <ReactBootstrap.Form.Row className='mb-2'>
            <ReactBootstrap.Col xs={2}>
              <ReactBootstrap.Form.Label>Confirm Password</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Control
                className='d-block'
                type={inputType}
                placeholder='Confirm Password'
                required
                value={userValue.confirmpassword}
                onChange={handleChange('confirmpassword')}
              />
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={2} className='mt-auto mb-auto ml-0 mr-0'>
              <span onClick={showHide} className='justify-content-center'>
                <i className='fa fa-eye-slash pr-2'></i>
                Show Password
              </span>
            </ReactBootstrap.Col>
          </ReactBootstrap.Form.Row>
        </ReactBootstrap.Form>
      </div>
    </div>
  );
};

export default ChangePassword;
