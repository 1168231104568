import React, { useEffect } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import './App.scss';

/* Context */
import { useStateValue } from './context/StateProvider';

/* Components */
import SideBarV2 from './components/SideBarV2';
// import SideBar from './components/SideBar';
import MighalContainer from './pages/MighalContainer';
// import NonMighalContainer from './pages/NonMighalContainer';
import ViewContainer from './pages/ViewContainer';
import PurchaseOrder from './pages/PurchaseOrder';
import Merchants from './pages/Merchants';
import UserAccounts from './pages/UserAccounts';
import Settings from './pages/Settings';
import LoginPage from './pages/LoginPage';
import Consignee from './pages/Consignee';
import ChangePassword from './pages/ChangePassword';
import FAQ from './pages/FAQ';
import ContainerOrder from './components/ReportsView/ContainerOrder';
import VesselReport from './components/ReportsView/VesselList';
import ContainerReport from './components/ReportsView/ContainerList';
import POStatusReport from './components/ReportsView/POStatus';
import MighalTags from './components/ReportsView/MighalTags';
import MighalBillingAffiliates from './pages/MighalBillingAffiliates';
import DashBoardContainer from './pages/DashBoardContainer';
import MatsonTracking from './components/MatsonTracking';
import ErrorPage from './components/ErrorPage';
// import MotherTabContainer from './components/ContainerTabView/MotherTab';
import ResetPassword from './components/ResetPassword';
import MighalBillingReport from './components/ReportsView/MighalBillingReport';
// import apiURL from './apiUrl';
import MighalBillingNonAffiliates from './pages/MighalBillingNonAffiliates';
import PdfTabContainer from './components/PdfViewer/PdfTabContainer';
import IdleTimerContainer from './components/IdleTimerContainer';
// import IdleTimerHook from './components/IdleTimerHook';

function App() {
  const [state] = useStateValue();
  useEffect(() => {
    if (state.userLocation) {
      localStorage.setItem('logisticsLocation', state.userLocation);
      //console.log(state.userLocation);
    }
  }, []);

  return (
    <BrowserRouter>
      <SideBarV2 />
      <div className='row--f-col2'>
        <div
          className={
            state.isLogin
              ? `${state.isSidebarActive ? 'row__leftpanel--isActive' : 'row__leftpanel'}`
              : 'side-nav__not-active'
          }></div>
        <IdleTimerContainer />
        <Switch>
          <Route exact path='/'>
            <DashBoardContainer />
          </Route>
          <Route exact path='/login' component={LoginPage} />
          <Route exact path='/container-mighal' component={MighalContainer} />
          {/*<Route exact path='/container-nonmighal' component={NonMighalContainer} />*/}
          <Route exact path='/container-view' component={ViewContainer} />
          {/* <Route exact path="/container-tab" component={MotherTabContainer} /> */}
          <Route exact path='/container-billing' component={MighalBillingNonAffiliates} />
          <Route exact path='/mighalbilling/:id' component={MighalBillingAffiliates} />
          <Route exact path='/purchase-order' component={PurchaseOrder} />
          <Route exact path='/merchants' component={Merchants} />
          <Route exact path='/user-accounts' component={UserAccounts} />
          <Route exact path='/change-password'>
            <ChangePassword />
          </Route>
          <Route exact path='/settings'>
            <Settings />
          </Route>
          <Route exact path='/consignee'>
            <Consignee />
          </Route>
          <Route exact path='/frequentlyaskquestion'>
            <FAQ />
          </Route>
          <Route exact path='/containerorderreport'>
            <ContainerOrder />
          </Route>
          <Route exact path='/vessellistreport'>
            <VesselReport />
          </Route>
          <Route exact path='/containerlistreport'>
            <ContainerReport />
          </Route>
          <Route exact path='/postatusreport'>
            <POStatusReport />
          </Route>
          <Route exact path='/mighaltagsreport'>
            <MighalTags />
          </Route>
          <Route exact path='/mighalbillingreport'>
            <MighalBillingReport />
          </Route>
          <Route exact path='/matsontracking'>
            <MatsonTracking />
          </Route>
          <Route exact path='/shipment-schedules'>
            <PdfTabContainer />
          </Route>
          <Route path='/resetpassword/:id' component={ResetPassword} />
          <Route component={ErrorPage} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
