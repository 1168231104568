import AuthProtect from '../common/enums/AuthProtect';

export const containerUploadOcPDF = (containerId, data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: '/container/upload_doc_pdf/' + containerId,
    data,
  };
};

export const containerUpdate = (data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: '/container/update',
    data,
  };
};

export const containerCreate = (data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: '/container/create',
    data,
  };
};

export const loadPlanShippedOutPDF = (data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: '/loadplan/get_shipped_out_pdf',
    responseType: 'blob',
    data,
  };
};

export const getLoadPlanPDF = (data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: '/loadplan/get_loadplan_pdf',
    responseType: 'blob',
    data,
  };
};

export const getDocPDF = (params) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/container/get_doc_pdf',
    responseType: 'blob',
    params,
  };
};

export const getPendingOceanFreight = () => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/container/get_pending_ocean_freight',
  };
};

export const getContainerStatusPDFDocs = (params) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/container/get_status_pdf_docs',
    params,
  };
};

export const isContainerExist = (params) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/container/is_container_exist',
    params,
  };
};

export const getLoadPlanViewList = (params) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/container/get_loadplan_view_list',
    params,
  };
};

export const fetchCompleteContainerForEmailList = () => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/container/get_complete_container_for_email_list',
  };
};

export const getContainerInfoDetails = (containerId) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/container/get_container_info_details/' + containerId,
  };
};

export const changeContainerStatus = (data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: '/container/update_container_status',
    data,
  };
};

export const getContInfoWithPO = (params) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/container/get_cont_info_with_po',
    params,
  };
};

export const fetchModalContainerList = (params) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/container/get_modal_container_list_table',
    params,
  };
};

export const getContainerOrder = () => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/container/get_container_order',
  };
};
export const getVesselListGrid = (params) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/container/get_vessel_list_grid',
    params,
  };
};

export const getVesselListPDF = (data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: '/reports/get_vessel_list_pdf',
    responseType: 'blob',
    data,
  };
};

export const getContainerTabListName = (params) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/container/get_container_tab_list_name',
    params,
  };
};

export const getContainerAvailableCube = (params) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/container/get_container_available_cube',
    params,
  };
};
