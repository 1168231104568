import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useStateValue } from '../context/StateProvider';
import { Tab, Tabs } from 'react-bootstrap';
// import BootstrapTable from 'react-bootstrap-table-next';
import * as ReactBootstrap from 'react-bootstrap';
import MainDashBoard from '../components/MainDashBoard';
import PODashBoard from '../components/PODashBoard';
// import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { consigneeGetList } from '../services/consigneeEndpoint';
import apiFetch from '../services/apiFetch';
import { getPOInvPDFFile, getPOMRTPDFFile, getVesselZeroPOAmounts } from '../services/poEndpoint';
import TableGrid from '../components/TableGrid';
import DocType from '../common/enums/DocType';
import { getDocumentStatus, getMRTFileLinkS3, getPOFileLinkS3 } from '../services/documentEndpoint';

function DashBoardContainer() {
  const [state] = useStateValue();
  const [isRender, setIsRender] = useState(true);
  const [key, setKey] = useState('home');
  const [showModal, setShowModal] = useState(false);
  const [dataModalGrid, setDataModalGrid] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [offset, setOffset] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const limit = 10;
  //const history = useHistory();

  useEffect(() => {
    const getVesselZeroPoAmountsPopUp = async () => {
      try {
        setIsFetching(true);
        const params = {
          consignee: localStorage.getItem('logisticsLocation'),
          offset,
          limit,
        };
        const endpoint = getVesselZeroPOAmounts(params);
        const response = await apiFetch(endpoint);
        if (response && response.data) {
          const result = response.data;
          if (result.data.length) setDataModalGrid(result.data);
          setTotalPage(result.total);
          if (isRender) {
            setShowModal(true);
          }
        }
        setIsFetching(false);
      } catch (error) {
        console.log(error);
        setIsFetching(false);
      }
    };

    if (!isFetching && state.isLogin) {
      getVesselZeroPoAmountsPopUp().catch((e) => console.log(e));
    }

    return () => setIsRender(false);
  }, [offset, state.isLogin]);

  useEffect(() => {
    const getConsigneeLookup = () => {
      return new Promise(async (resolve, reject) => {
        const params = {
          isAffiliate: 1,
        };
        const endpoint = consigneeGetList(params);
        const response = await apiFetch(endpoint);

        if (response && response.data) {
          resolve(response);
        } else {
          reject('Failed');
        }
      });
    };

    if (isRender && state.isLogin) {
      getConsigneeLookup()
        .then(async (result) => {
          let consArray = [];
          consArray.push('SELECT CONSIGNEE');
          let tmpArray = result.data;
          for (const iterator of tmpArray) {
            consArray.push(iterator.Consignee_Name);
          }
          localStorage.setItem('logisticsConsignee', JSON.stringify(consArray));
        })
        .catch((err) => {
          console.log(err);
          localStorage.setItem('logisticsConsignee', '');
        });
      setIsRender(false);
    }

    return () => {
      // SetIsRender(false);
      // setShowModal(false);
    };
  }, []);

  if (!state.isLogin) return <Redirect to='/login' />;

  if (state.isLogin && state.newUser) return <Redirect to='/change-password' />;

  const handleCloseModal = () => {
    // localStorage.setItem('logisticsFirstLogIn', 0);
    setShowModal(false);
  };

  const mrtButtonPDF = (cell, row) => {
    let pdfStatus = row.MIGS_DOCSPdf;
    return (
      <div>
        <button
          className='btn btn-sm btn-secondary'
          onClick={viewMIGSDocsPDF(row.PO_ID)}
          disabled={!pdfStatus}>
          View
        </button>
      </div>
    );
  };

  const invButtonPDF = (cell, row) => {
    let pdfStatus = row.PO_InvoicePdf;
    return (
      <div>
        <button
          className='btn btn-sm btn-danger'
          onClick={viewTJDocsPDF(row.PO_ID, row.PO_No)}
          disabled={!pdfStatus}>
          View
        </button>
      </div>
    );
  };

  const handleTableChange = (type, { page }) => {
    setOffset(page);
  };
  // BS Table Filter
  // let consigneeFilter,
  //   shipperFilter,
  //   containerFilter,
  //   bookingFiler,
  //   deptFilter,
  //   arrivalFilter,
  //   controlFilter;

  const columnsModal = [
    {
      dataField: 'PO_ID',
      text: 'PO_ID',
      hidden: true,
    },
    {
      dataField: 'Pallets',
      text: 'Pallets',
      hidden: true,
    },
    {
      dataField: 'TotalPCS',
      text: 'Qty',
      hidden: true,
    },
    {
      dataField: 'Weight',
      text: 'LBS',
      hidden: true,
    },
    {
      dataField: 'CubeSize',
      text: 'CUFT',
      hidden: true,
    },
    {
      dataField: 'Invoice_Amount',
      text: 'Amount',
      hidden: true,
    },
    {
      dataField: 'PO_InvoicePdf',
      text: 'TJ INV',
      headerStyle: () => {
        return { width: '90px', textAlign: 'center' };
      },
      formatter: invButtonPDF,
      editable: false,
    },
    {
      dataField: 'MIGS_DOCSPdf',
      text: 'MIGS DOCS',
      headerStyle: () => {
        return { width: '90px', textAlign: 'center' };
      },
      formatter: mrtButtonPDF,
      editable: false,
    },
    {
      dataField: 'Status',
      text: 'Status',
      sort: true,
      headerStyle: () => {
        return { width: '95px', textAlign: 'center' };
      },
      // filter: textFilter({
      //   getFilter: (filter) => (consigneeFilter = filter),
      // }),
    },
    {
      dataField: 'Destination',
      text: 'Consignee',
      sort: true,
      headerStyle: () => {
        return { width: '120px', textAlign: 'center' };
      },
      // filter: textFilter({
      //   getFilter: (filter) => (consigneeFilter = filter),
      // }),
    },
    {
      dataField: 'PO_No',
      text: 'PO Num',
      sort: true,
      headerStyle: () => {
        return { width: '120px', textAlign: 'center' };
      },
    },
    {
      dataField: 'Shipper',
      text: 'Vendor',
      sort: true,
      headerStyle: () => {
        return { width: '200px', textAlign: 'center' };
      },
      // filter: textFilter({
      //   getFilter: (filter) => (shipperFilter = filter),
      // }),
    },
    {
      dataField: 'PO_Description',
      text: 'Description',
      sort: true,
      headerStyle: () => {
        return { width: '160px', textAlign: 'center' };
      },
    },
    {
      dataField: 'Container_No',
      text: 'Container No',
      sort: true,
      headerStyle: () => {
        return { width: '200px', textAlign: 'center' };
      },
      // filter: textFilter({
      //   getFilter: (filter) => (containerFilter = filter),
      // }),
    },
    {
      dataField: 'Booking',
      text: 'Booking',
      sort: true,
      headerStyle: () => {
        return { width: '120px', textAlign: 'center' };
      },
      // filter: textFilter({
      //   getFilter: (filter) => (bookingFiler = filter),
      // }),
    },
    {
      dataField: 'Departure_Date',
      text: 'Departure',
      sort: true,
      align: 'center',
      headerStyle: () => {
        return { width: '120px', textAlign: 'center' };
      },
      // filter: textFilter({
      //   getFilter: (filter) => (deptFilter = filter),
      // }),
    },
    {
      dataField: 'Arrival_Date',
      text: 'Arrival',
      sort: true,
      align: 'center',
      headerStyle: () => {
        return { width: '120px', textAlign: 'center' };
      },
      // filter: textFilter({
      //   getFilter: (filter) => (arrivalFilter = filter),
      // }),
    },
    {
      dataField: 'control',
      text: 'Control',
      sort: true,
      align: 'center',
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
      // filter: textFilter({
      //   getFilter: (filter) => (controlFilter = filter),
      // }),
    },
    {
      dataField: 'MRT_Date',
      text: 'MRT Date',
      sort: true,
      align: 'center',
      headerStyle: () => {
        return { width: '120px', textAlign: 'center' };
      },
    },
  ];

  const viewMIGSDocsPDF = (poID) => async (ev) => {
    ev.preventDefault();
    try {
      if (poID !== '' && poID > 0) {
        const status = await checkDocStatus(poID, DocType.MRT_FILE)
          .then((res) => res)
          .catch((e) => {
            throw e;
          });
        if (status && status.data) {
          downloadMIGSPdfFile(poID)
            .then((response) => {
              console.log('Finish Download MIGSPdf');
              const url = window.URL.createObjectURL(
                new Blob([response.data], { type: 'application/pdf' }),
              );
              window.open(url);
            })
            .catch(() => {
              alert('No PDF Available');
            });
        } else {
          downloadMRTS3Document(poID)
            .then((response) => {
              window.open(response);
            })
            .catch(() => {
              alert('No PDF Available');
            });
        }
      } else {
        alert('Please select a Purchase Order to view the PDF');
      }
    } catch (e) {
      console.log(e);
      alert('No PDF Available');
    }
  };

  const downloadMRTS3Document = async (POId) => {
    try {
      const endpoint = getMRTFileLinkS3({ POId });
      return await apiFetch(endpoint)
        .then((res) => (res && res.data) || null)
        .catch((e) => {
          throw e;
        });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const downloadMIGSPdfFile = (poNo) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoint = getPOMRTPDFFile(poNo);
        const response = await apiFetch(endpoint);
        //console.log(response.data.type);
        if (response && response.data.type !== 'application/json') {
          resolve(response);
        } else {
          reject('Failed');
        }
      } catch (error) {
        console.log(error);
        reject('Failed');
      }
    });
  };

  const downloadPOS3Document = async (POId, docType) => {
    try {
      const params = {
        POId,
        dataType: docType,
      };
      const endpoint = getPOFileLinkS3(params);
      return await apiFetch(endpoint)
        .then((res) => (res && res.data) || null)
        .catch((e) => {
          throw e;
        });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const checkDocStatus = async (POId, dataType) => {
    try {
      const params = {
        POId,
        dataType,
      };
      const endpoint = getDocumentStatus(params);
      return await apiFetch(endpoint)
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const viewTJDocsPDF = (poID, poNo) => async (ev) => {
    ev.preventDefault();
    try {
      if (poNo !== '' || poID > 0) {
        const status = await checkDocStatus(poID, DocType.INV_FILE)
          .then((res) => res)
          .catch((e) => {
            throw e;
          });
        if (status && status.data) {
          downloadTJInvPdfFile(poNo)
            .then((response) => {
              console.log('Finish Download TJINVPdf');
              const url = window.URL.createObjectURL(
                new Blob([response.data], { type: 'application/pdf' }),
              );
              window.open(url);
            })
            .catch(() => {
              alert('No PDF Available');
            });
        } else {
          downloadPOS3Document(poID, DocType.INV_FILE)
            .then((response) => {
              window.open(response);
            })
            .catch(() => {
              alert('No PDF Available');
            });
        }
      } else {
        alert('Please select a Purchase Order to view the PDF');
      }
    } catch (e) {
      console.log(e);
      alert('No PDF Available');
    }
  };

  const downloadTJInvPdfFile = (poNo) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoint = getPOInvPDFFile(poNo);
        const response = await apiFetch(endpoint);
        if (response && response.data.type !== 'application/json') {
          resolve(response);
        } else {
          reject('Failed');
        }
      } catch (error) {
        console.log(error);
        reject('Failed');
      }
    });
  };

  return (
    <div className='wrapper'>
      <div className='help-button'>
        <a
          href='https://triplejlogistics.org/help/DashboardMenu.html'
          target='_blank'
          rel='noopener noreferrer'
          className='btn btn-labeled btn-outline-dark btn-sm mr-1'>
          <span className='btn-label'>
            <i className='fa fa-question-circle pr-1'></i>
          </span>
          Help
        </a>
      </div>

      <ReactBootstrap.Modal
        dialogClassName='modal-size'
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={showModal}
        onHide={handleCloseModal}>
        <ReactBootstrap.Modal.Header closeButton>
          <ReactBootstrap.Modal.Title id='contained-modal-title-vcenter'>
            Important PO with No Invoice Copies Uploaded
          </ReactBootstrap.Modal.Title>
        </ReactBootstrap.Modal.Header>
        <ReactBootstrap.Modal.Body>
          <div className='container-fluid wrapper'>
            <ReactBootstrap.Row>
              <div className='container-fluid scroll-container'>
                <TableGrid
                  keyField='PO_ID'
                  data={dataModalGrid}
                  columns={columnsModal}
                  page={offset > 0 ? offset : 1}
                  sizePerPage={limit}
                  totalSize={totalPage}
                  onTableChange={handleTableChange}
                  isLoading={isFetching}
                  showSpinner={false}
                />
              </div>
            </ReactBootstrap.Row>
          </div>
        </ReactBootstrap.Modal.Body>
        <ReactBootstrap.Modal.Footer>
          <ReactBootstrap.Button variant='secondary' onClick={handleCloseModal}>
            Close
          </ReactBootstrap.Button>
        </ReactBootstrap.Modal.Footer>
      </ReactBootstrap.Modal>

      <div className='generic-content-container container'>
        <h2>Dashboard</h2>
      </div>
      <div className='container'>
        <Tabs id='controlled-tab-example' activeKey={key} onSelect={(k) => setKey(k)}>
          <Tab eventKey='home' title='Container Dashboard'>
            <MainDashBoard />
          </Tab>
          <Tab eventKey='po' title='PO Dashboard'>
            <PODashBoard />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default DashBoardContainer;
