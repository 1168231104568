import React, { useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import * as ReactBootstrap from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';
import { useStateValue } from '../../context/StateProvider';
import momentTZ from 'moment-timezone';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import FileSaver from 'file-saver';
import { getBillingInfo, getMighalBillingDataList } from '../../services/billingEndpoint';
import apiFetch from '../../services/apiFetch';
import { getMighalBillingPDF } from '../../services/reportsEndpoint';
import { consigneeGetAddress } from '../../services/consigneeEndpoint';
import { getMighalBillingExcel } from '../../services/excelEndpoint';

const MighalBillingReport = () => {
  const [state, dispatch] = useStateValue();
  const [isLoading, setIsLoading] = useState(false);
  //const [getListRender, SetgetListRender] = useState(true);
  const [dataInfoGrid, setDataInfoGrid] = useState([]);
  const [filterBy, setFilterBy] = useState('SHIPPED');
  const [billingID, setBillingID] = useState(0);
  // const [isAffiliate, setIsAffiliate] = useState('');
  const [consignee, setConsignee] = useState('');
  const [containerID, setContainerID] = useState(0);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const history = useHistory();

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const params = {
          filter: filterBy,
        };
        const endpoint = getMighalBillingDataList(params);
        const response = await apiFetch(endpoint);
        if (response && response.data) {
          let arr = response.data;
          if (Array.isArray(arr) && arr.length > 0) {
            setDataInfoGrid(arr);
            setIsLoading(false);
          } else {
            setDataInfoGrid([]);
            setIsLoading(false);
          }
        } else {
          setDataInfoGrid([]);
          setIsLoading(false);
        }
      } catch (e) {
        console.log(e);
        setIsLoading(false);
      }
    };
    if (!isLoading) {
      getData().then(() => null);
    }
  }, [filterBy]);

  if (!state.isLogin) return <Redirect to='/login' />;
  if (state.isLogin && state.newUser) return <Redirect to='/change-password' />;

  const formatNumber = (number) => {
    const fixedNumber = Number.parseFloat(number === null ? 0 : number).toFixed(2);
    return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const formatInvAmt = (cell, row) => {
    return formatNumber(row?.TotalInvoice);
  };

  const formatDate = (valueDate) => {
    let d = new Date(valueDate),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const formatDateSQL = (valueDate) => {
    let d = new Date(valueDate),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('/');
  };

  const columns = [
    {
      dataField: 'Billing_ID',
      text: 'Billing_ID',
      hidden: true,
    },
    {
      dataField: 'Container_ID',
      text: 'Container_ID',
      hidden: true,
    },
    {
      dataField: 'isAffiliate',
      text: 'isAffiliate',
      hidden: true,
    },
    {
      dataField: 'Status',
      text: 'Status',
      sort: true,
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
    },
    {
      dataField: 'Consignee',
      text: 'Consignee',
      sort: true,
      headerStyle: () => {
        return { width: '120px', textAlign: 'center' };
      },
      filter: textFilter(),
    },
    {
      dataField: 'Container_No',
      text: 'Container No.',
      sort: true,
      headerStyle: () => {
        return { width: '200px', textAlign: 'center' };
      },
      filter: textFilter(),
    },
    {
      dataField: 'Booking',
      text: 'Booking No.',
      headerStyle: () => {
        return { width: '150px', textAlign: 'center' };
      },
      filter: textFilter(),
    },
    {
      dataField: 'Departure_Date',
      text: 'Invoice Date',
      sort: true,
      headerStyle: () => {
        return { width: '120px', textAlign: 'center' };
      },
      filter: textFilter(),
    },
    {
      dataField: 'Arrival_Date',
      text: 'Arrival',
      sort: true,
      headerStyle: () => {
        return { width: '120px', textAlign: 'center' };
      },
      filter: textFilter(),
    },
    {
      dataField: 'Invoice_No',
      text: 'Invoice No.',
      sort: true,
      headerStyle: () => {
        return { width: '150px', textAlign: 'center' };
      },
      filter: textFilter(),
    },
    {
      dataField: 'TotalInvoice',
      text: 'Invoice Amount',
      align: 'right',
      headerStyle: () => {
        return { width: '150px', textAlign: 'left' };
      },
      formatter: formatInvAmt,
    },
  ];

  const handlePrintBilling = (ev) => {
    ev.preventDefault();
    try {
      if (billingID > 0) {
        setIsLoading(true);
        downloadBillingPDF()
          .then((response) => {
            setIsLoading(false);
            const url = window.URL.createObjectURL(
              new Blob([response.data], { type: 'application/pdf' }),
            );
            window.open(url);
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
            alert('No PDF Available');
          });
      } else {
        alert('Please select a Billing Invoice');
      }
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const downloadBillingPDF = () => {
    return new Promise(async (resolve, reject) => {
      const params = {
        billingId: billingID,
      };
      const endpoint = getMighalBillingPDF(params);
      const response = await apiFetch(endpoint);

      if (response && response.data.type !== 'application/json') {
        resolve(response);
      } else {
        reject('Failed');
      }
    });
  };

  const handeFilterChange = (ev) => {
    setFilterBy(ev.target.value);
  };

  const onRowSelect = (row) => {
    try {
      setBillingID(row.Billing_ID);
      // setIsAffiliate(row.isAffiliate);
      setConsignee(row.Consignee);
      setContainerID(row.Container_ID);
    } catch (error) {
      console.log('TryCatch onRowSelect', error);
    }
  };

  const selectRowProp = {
    mode: 'radio',
    clickToSelect: true,
    onSelect: onRowSelect,
  };

  const modifiedDate = (dateValue) => {
    return new Date(momentTZ.tz(dateValue, 'Pacific/Guam'));
  };

  const handleViewBillingDetails = async () => {
    try {
      if (billingID === 0) {
        return alert('Please select a billing');
      } else {
        /* Dispatch Non-Affiliate Billing */
        setIsLoading(true);
        let consigneeAddress = {};
        await getConsigneeAddress()
          .then((result) => {
            consigneeAddress = {
              billTo: result[0].Consignee_Name,
              company: result[0].Company_Name,
              address: result[0].Address,
              city: result[0].City,
              state_zip: result[0].StateZip,
              from: 'TJ MIGHAL',
              to: result[0].Consignee_Name,
            };

            checkIfBillingExist()
              .then((data) => {
                let billingData = data[0];
                let contSize = billingData.Container_Type.substring(2, 4);
                let BillingDetails = {
                  billing_ID: billingData.Billing_ID,
                  cont_ID: billingData.Container_ID,
                  billTo: consigneeAddress.billTo,
                  company: consigneeAddress.company,
                  address: consigneeAddress.address,
                  city: consigneeAddress.city,
                  state_zip: consigneeAddress.state_zip,
                  vessel: billingData.Vessel,
                  voyage: billingData.Voyage,
                  from: consigneeAddress.from,
                  to: consigneeAddress.to,
                  inv_no: billingData.Invoice_No,
                  inv_date: modifiedDate(billingData.Departure_Date),
                  cont_no: billingData.Container_No,
                  file_ref: billingData.File_Ref,
                  consignee: billingData.Consignee_Name,
                  consigned_to: billingData.Consigned_To,
                  shipper: billingData.Shipper,
                  c_type: billingData.Container_Type,
                  temperature: billingData.Temperature_Type,
                  booking: billingData.Booking,
                  depart: modifiedDate(billingData.Departure_Date),
                  arrival: modifiedDate(billingData.Arrival_Date),
                  inv_amt: formatNumber(billingData.TtlInvoiceAmt),
                  ofrt_amt: formatNumber(billingData.OceanFreight),
                  ofrt_inv_amt: billingData.TtlOfrtInv,
                  contSize,
                  ContainerStatus: billingData.Status,
                  DepartureDate: formatDate(billingData.Departure_Date),
                  ArrivalDate: formatDate(billingData.Arrival_Date),
                  Desc1: billingData.Desc1,
                  Desc1_Amt: billingData.Desc1_Amt,
                  Desc2: billingData.Desc2,
                  Desc2_Amt: billingData.Desc2_Amt,
                  Desc3: billingData.Desc3,
                  Desc3_Amt: billingData.Desc3_Amt,
                  Desc4: billingData.Desc4,
                  Desc4_Amt: billingData.Desc4_Amt,
                  Desc5: billingData.Desc5,
                  Desc5_Amt: billingData.Desc5_Amt,
                  Desc6: billingData.Desc6,
                  Desc6_Amt: billingData.Desc6_Amt,
                  Desc7: billingData.Desc7,
                  Desc7_Amt: billingData.Desc7_Amt,
                  Desc8: billingData.Desc8,
                  Desc8_Amt: billingData.Desc8_Amt,
                  Desc9: billingData.Desc9,
                  Desc9_Amt: billingData.Desc9_Amt,
                  Desc10: billingData.Desc10,
                  Desc10_Amt: billingData.Desc10_Amt,
                  ReturnTo: '',
                  isSIT: billingData.isSIT,
                  comment: billingData.Comment,
                };

                setIsLoading(false);
                dispatch({
                  type: 'SET_NON_AFFILIATE_BILLING',
                  payload: BillingDetails,
                });
                history.push('/container-billing');
              })
              .catch((err) => {
                setIsLoading(false);
                console.log('TryCatchErr checkIfBillingExist', err);
              });
          })
          .catch((err) => {
            setIsLoading(false);
            console.log(err);
            return alert('Something went wrong');
          });
      }
    } catch (e) {
      console.log('tryCatch handleViewBillingDetails', e);
    }
  };

  const getConsigneeAddress = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const params = {
          consignee,
        };
        const endpoint = consigneeGetAddress(params);
        const response = await apiFetch(endpoint);
        if (response && response.data) {
          resolve(response.data);
        } else {
          reject('Failed');
        }
      } catch (e) {
        console.log('TryCatch getConsigneeAddress', e);
        reject('Failed');
      }
    });
  };

  const checkIfBillingExist = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const params = {
          containerId: containerID,
          billingId: 0,
        };
        const endpoint = getBillingInfo(params);
        const response = await apiFetch(endpoint);

        if (response && response.data) {
          let arrData = response.data;

          if (Array.isArray(arrData) && arrData.length > 0) {
            //console.log({ arrdata });
            //SetMighalValue(arrdata[0]);
            resolve(arrData);
          } else {
            reject(false);
          }
        }
      } catch (e) {
        console.log('tryCatch checkIfBillingExist', e);
      }
    });
  };

  const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className='btn-group' role='group'>
      {options.map((option) => {
        const isSelect = currSizePerPage === `${option.page}`;
        return (
          <button
            key={option.text}
            type='button'
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${isSelect ? 'btn-secondary' : 'btn-default'}`}>
            {option.text}
          </button>
        );
      })}
    </div>
  );

  const PaginationOptions = {
    hideSizePerPage: true,
    sizePerPageRenderer,
  };

  const onFromDateChange = (selected) => {
    if (selected > toDate) {
      return alert('From Date should not be higher than To Date');
    }

    let AdjustedDateValue = new Date(momentTZ.tz(selected, 'Pacific/Guam'));
    setFromDate(AdjustedDateValue);
  };

  const onToDateChange = (selected) => {
    if (selected < fromDate) {
      return alert('To Date should not be lower than From Date');
    }

    let AdjusteddateValue = new Date(momentTZ.tz(selected, 'Pacific/Guam'));
    setToDate(AdjusteddateValue);
  };

  const handleExportExcel = () => {
    try {
      setIsLoading(true);
      downloadExportExcel()
        .then((res) => {
          let byteCharacters = atob(res.data);
          let byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          let byteArray = new Uint8Array(byteNumbers);
          let blob = new Blob([byteArray], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });

          /* let blob = new Blob([res.data], {
                type: 'contentType',
              }); */
          FileSaver.saveAs(blob, `Export_Mighal_Billing.xlsx`);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          alert('Unable to generate Excel');
        });
    } catch (e) {
      console.log(e);
    }
  };

  const downloadExportExcel = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const params = {
          userEmail: localStorage.getItem('logisticsUseremail'),
          beginDate: formatDateSQL(fromDate),
          endDate: formatDateSQL(toDate),
        };

        const endpoint = getMighalBillingExcel(params);
        const response = await apiFetch(endpoint).then((res) => res || null);

        if (response) {
          resolve(response);
        } else {
          reject('Failed');
        }
      } catch (e) {
        console.log(e);
        reject('Failed');
      }
    });
  };

  return (
    <div className='wrapper'>
      <div className='loading-spinner'>
        <ClipLoader size={100} color={'#0054a6'} loading={isLoading} />
      </div>
      <div className='help-button'>
        <a
          href='https://triplejlogistics.org/help/ReportsMighalTagsMenu.html'
          target='_blank'
          rel='noopener noreferrer'
          className='btn btn-labeled btn-outline-dark btn-sm mr-1'>
          <span className='btn-label'>
            <i className='fa fa-question-circle pr-1'></i>
          </span>
          Help
        </a>
      </div>
      <div className='generic-content-container container-fluid'>
        <h2>Mighal Billing List</h2>
      </div>
      <div className='container-fluid'>
        <div className='container-fluid'>
          <ReactBootstrap.Row className='mb-2'>
            <button
              type='button'
              className='btn btn-labeled btn-primary btn-sm mr-1'
              onClick={handlePrintBilling}>
              <span className='btn-label'>
                <i className='fa fa-print pr-1'></i>
              </span>
              Print Invoice
            </button>
            <button
              type='button'
              className='btn btn-labeled btn-primary btn-sm mr-1'
              onClick={handleViewBillingDetails}>
              <span className='btn-label'>
                <i className='fa fa-share pr-1'></i>
              </span>
              View Billing Details
            </button>
            <label className='ml-1 mr-1 mt-auto mb-auto'>Filter By: </label>
            <input
              className='radio-btn mt-auto mb-auto'
              type='radio'
              id='filter_by'
              name='filter'
              value='SHIPPED'
              checked={filterBy === 'SHIPPED'}
              onChange={handeFilterChange}
            />
            <label htmlFor='status' className='mt-auto mb-auto'>
              SHIPPED
            </label>
            <input
              className='radio-btn mt-auto mb-auto'
              type='radio'
              id='filter_by'
              name='filter'
              value='DELIVERED'
              checked={filterBy === 'DELIVERED'}
              onChange={handeFilterChange}
            />
            <label htmlFor='status' className='mt-auto mb-auto'>
              DELIVERED
            </label>
            <input
              className='radio-btn mt-auto mb-auto'
              type='radio'
              id='filter_by'
              name='filter'
              value='ALL'
              checked={filterBy === 'ALL'}
              onChange={handeFilterChange}
            />
            <label htmlFor='status' className='mt-auto mb-auto'>
              ALL
            </label>
          </ReactBootstrap.Row>
          <ReactBootstrap.Row className='mb-2'>
            <button
              type='button'
              className='btn btn-labeled btn-primary btn-sm mr-1'
              onClick={handleExportExcel}>
              <span className='btn-label'>
                <i className='fa fa-file-excel-o pr-1'></i>
              </span>
              Export to Excel Sheet
            </button>
            <label className='ml-3 mr-1 mt-auto mb-auto'>From: </label>
            <div className='ml-1 mt-auto mb-auto'>
              <DatePicker
                selected={modifiedDate(fromDate)}
                maxDate={toDate}
                locale='en'
                onChange={onFromDateChange}
                className=''
              />
            </div>
            <label className='ml-3 mr-1 mt-auto mb-auto'>To: </label>
            <div className='ml-1 mt-auto mb-auto'>
              <DatePicker
                selected={modifiedDate(toDate)}
                minDate={fromDate}
                locale='en'
                onChange={onToDateChange}
                className=''
              />
            </div>
          </ReactBootstrap.Row>
        </div>

        <div className='container-fluid scroll-container'>
          <BootstrapTable
            keyField='Billing_ID'
            data={dataInfoGrid}
            columns={columns}
            selectRow={selectRowProp}
            filter={filterFactory()}
            pagination={paginationFactory(PaginationOptions)}
            filterPosition='top'
            striped
            hover
          />
        </div>
      </div>
    </div>
  );
};

export default MighalBillingReport;
