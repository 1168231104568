// noinspection JSUnresolvedVariable,JSCheckFunctionSignatures

import React, { useEffect, useState, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ClipLoader from 'react-spinners/ClipLoader';
import DatePicker from 'react-datepicker';
import { useStateValue } from '../context/StateProvider';
import { SpinnerStyle } from '../styles/SpinnerStyle';
import TableGrid from '../components/TableGrid';
import Axios from 'axios';
import momentTZ from 'moment-timezone';
import {
  addPOToContainerAfterReceived,
  getLastRiderNum,
  getMaxMRT,
  getPOInvPDFFile,
  getPOMRTPDFFile,
  getPOPDFFile,
  getTempLastPONum,
  insertNewPORecord,
  updatePORecord,
} from '../services/poEndpoint';
import { getDocumentStatus, getPOFileLinkS3, getMRTFileLinkS3 } from '../services/documentEndpoint';
import apiFetch from '../services/apiFetch';
import { getSettings } from '../services/settingsEndpoint';
import {
  containerCreate,
  fetchModalContainerList,
  isContainerExist,
} from '../services/containerEndpoint';
import DocType from '../common/enums/DocType';
import { ValidateKeyPressNumber } from '../common/util/validateNumberKey';
import { getConsigneeAttention } from '../services/consigneeEndpoint';
import useFetchPoList from '../hooks/useFetchPOList';
import GenericEmptyTable from '../components/GenericEmptyTable';
import LoadingSpinner from '../components/LoadingSpinner';

const defaultForm = {
  status: 'OPEN',
  temp: 'CHILL',
  vendor: '',
  po: '',
  po_date: new Date(),
  mighaletd: new Date(),
  mrt_date: new Date(),
  desc: '',
  dest: '',
  weight: 0,
  cube: 0,
  pallet: 0,
  pcs: 0,
  control: '',
  comment: '',
  inv_amt: 0,
  withBuying: 0,
};

const initContainerValue = {
  containerId: 0,
  container: '',
  c_type: '1X20 REEFER',
  l_type: 'CHILL',
  vessel: 'OPEN',
  voyage: '',
  booking: '',
  attention: '',
  destination: '',
  location: '',
  depart: new Date(),
  arrival: new Date(),
  seal: '',
  status: 'Loading',
  available_cu: 0,
  available_lbs: 0,
  createDte: new Date(),
  emailTo: '',
  comment: '',
  oceanfreight: 0,
};

const defaultContPOValue = {
  contID: 0,
  contStatus: '',
  contNo: '',
};

const defaultFilterValue = {
  temperature: undefined,
  poNum: '',
  controlNo: '',
  vendor: '',
  description: '',
  comments: '',
};

function PurchaseOrder() {
  const [state, dispatch] = useStateValue();
  const PODataList = useFetchPoList();
  const [isFormDisable, setIsFormDisable] = useState(true);
  const [getListRender, setGetListRender] = useState(true);
  const [FormValue, setFormValue] = useState(defaultForm);
  const [POContValue, setPOContValue] = useState(defaultContPOValue);
  const [selectedPOId, setSelectedPOId] = useState('');
  const [isNew, setIsNew] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDisabledBtnNew, setIsDisabledBtnNew] = useState(false);
  const [isDisabledBtnEdit, setIsDisabledBtnEdit] = useState(false);
  const [isDisabledBtnSave, setIsDisabledBtnSave] = useState(true);
  const [isDisabledBtnCancel, setIsDisabledBtnCancel] = useState(true);
  const [disableUploadPoBtn, setDisableUploadPoBtn] = useState(true);
  const [disableUploadInvBtn, setDisableUploadInvBtn] = useState(true);
  const [filePOPdf, setFilePOPdf] = useState(null);
  const [fileMRTPdf, setFileMRTPdf] = useState(null);
  const [POCharKey, setPOCharKey] = useState('');
  const [PORider, SetPORider] = useState('');
  const [POTemp, SetPOTemp] = useState('');
  const [isRiderLabelVisible, setIsRiderLabelVisible] = useState(true);
  const [isTempLabelVisible, setIsTempLabelVisible] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [containerIDGrid, setContainerIDGrid] = useState(0);
  const [dataModalGrid, setDataModalGrid] = useState([]);
  const [inProcess, setInProcess] = useState(false);
  const [withBuyingFee, setWithBuyingFee] = useState(false);
  const [isPOPDFExists, setIsPOPDFExists] = useState(false);
  const [isINVPDFExists, setIsINVPDFExists] = useState(false);
  const [isMRTPDFExists, setIsMRTPDFExists] = useState(false);
  const [viewForm, SetViewForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [checkTemperature, setCheckTemperature] = useState(false);
  const [checkPONo, setCheckPONo] = useState(false);
  const [checkControl, setCheckControl] = useState(false);
  const [checkVendor, setCheckVendor] = useState(false);
  const [checkDescription, setCheckDescription] = useState(false);
  const [checkComment, setCheckComment] = useState(false);
  const [filterTemperature, setFilterTemperature] = useState(null);
  const [filterValues, setFilterValues] = useState(defaultFilterValue);

  const userLocation = useMemo(() => state.userLocation, [state.userLocation]);
  const offset = state.purchaseOrderState.page;
  const totalPage = state.purchaseOrderState.pageTotal;
  const isFetchPOList = state.purchaseOrderState.loading;
  const limit = state.purchaseOrderState.limit;
  const maxMRTChecker = state.purchaseOrderState.maxMRT;
  const dataInfoGrid = state.purchaseOrderState.poData;
  const showDeliver = state.purchaseOrderState.isDelivered;
  const fullPOState = state.purchaseOrderState;

  let errorsMsg = [];

  let ConsLookUp = JSON.parse(localStorage.getItem('logisticsConsignee'));
  const userAccess = localStorage.getItem('logisticsUserType');
  const UserEmail = localStorage.getItem('logisticsUseremail');
  const UserToken = localStorage.getItem('logisticsToken');
  const permission = useMemo(() => ({ edit: userAccess !== 'User' }), [userAccess]);
  const isUserMighalDEV = useMemo(
    () => userLocation === 'TJ MIGHAL' || userLocation === 'DEVELOPMENT TEAM',
    [userLocation],
  );
  useEffect(() => {
    const fetchMaxMRT = async () => {
      try {
        const endpoint = getMaxMRT();
        const response = await apiFetch(endpoint).then((res) => res.data || null);
        if (response) {
          return response;
        }
      } catch (error) {
        console.log('fetchMaxMRT failed', error);
      }
    };

    fetchMaxMRT().then((resp) => {
      const payload = {
        maxMRT: resp.maxMRT,
      };
      dispatch({ type: 'SET_PO_MRT_MAX', payload });
    });
  }, [maxMRTChecker, PODataList]);

  useEffect(() => {
    const getLastRiderPO = async () => {
      const params = {
        consignee: userLocation,
      };
      const endpoint = getLastRiderNum(params);
      const response = await apiFetch(endpoint);
      if (response && typeof response.data === 'object') {
        //console.log(response.data[0]);
        let DryRider = response.data[0].DRY;
        let ChillRider = response.data[0].CHILL;
        let FrzRider = response.data[0].FREEZE;
        let riderString = '';
        if (DryRider !== null && DryRider.length > 1) {
          riderString = `${DryRider}/`;
        }
        if (ChillRider !== null && ChillRider.length > 1) {
          riderString += `${ChillRider}/`;
        }
        if (FrzRider !== null && FrzRider.length > 1) {
          riderString += `${FrzRider}/`;
        }
        SetPORider(riderString);
        setIsRiderLabelVisible(false);
      }
    };

    const getLastTempPO = async () => {
      const params = {
        consignee: userLocation,
      };
      const endpoint = getTempLastPONum(params);
      const response = await apiFetch(endpoint);
      if (response && typeof response.data === 'object') {
        //console.log(response.data[0]);
        let DryTempPo = response.data[0].DRY;
        let ChillTempPo = response.data[0].CHILL;
        let FrzTempPo = response.data[0].FREEZE;
        let tempPoString = '';
        if (DryTempPo !== null && DryTempPo.length > 1) {
          tempPoString = `${DryTempPo}/`;
        }
        if (ChillTempPo !== null && ChillTempPo.length > 1) {
          tempPoString += `${ChillTempPo}/`;
        }
        if (FrzTempPo !== null && FrzTempPo.length > 1) {
          tempPoString += `${FrzTempPo}/`;
        }
        SetPOTemp(tempPoString);
        setIsTempLabelVisible(false);
      }
    };

    if (getListRender) {
      // destFilter(state.consigneePOFilterView);
      getLastRiderPO().then(() => null);
      getLastTempPO().then(() => null);
      // noinspection JSCheckFunctionSignatures
      setFormValue(initForm);
      setGetListRender(false);
    }

    if (userLocation !== '') {
      //console.log(userLoc);
      setFormValue({ ...FormValue, dest: userLocation });
    }

    return () => setGetListRender(false);
  }, [getListRender, userLocation]);

  /*   const formatNumber = (number) => {
    const fixedNumber = Number.parseFloat(number);
    return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }; */

  const initForm = {
    status: 'OPEN',
    temp: 'CHILL',
    vendor: '',
    po: '',
    po_date: new Date(),
    mighaletd: new Date(),
    mrt_date: new Date(),
    desc: '',
    dest: userLocation,
    weight: 0,
    cube: 0,
    pallet: 0,
    pcs: 0,
    control: '',
    comment: '',
    inv_amt: 0,
  };

  const MakeConsigneeOptions = (data) => {
    return (
      data &&
      data.map((cons, index) => (
        <option key={index} value={cons}>
          {cons}
        </option>
      ))
    );
  };

  if (!state.isLogin) return <Redirect to='/login' />;
  if (state.isLogin && state.newUser) return <Redirect to='/change-password' />;

  // const refreshDataGrid = () => {
  //
  //   const filters = {
  //     isDelivered: showDeliver,
  //     userLocation,
  //     offset: 0,
  //     limit,
  //     poNum: undefined,
  //   };
  //   const endpoint = getPOList(filters);
  // }

  const formatInvNumber = (number) => {
    const fixedNumber = Number.parseFloat(number === null ? 0 : number).toFixed(2);
    return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const formatINTNumber = (number) => {
    const fixedNumber = Number.parseInt(number === null ? 0 : number);
    return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const formatInvAmt = (cell) => {
    return formatInvNumber(cell);
  };

  const formatFixNumber = (cell) => {
    return formatINTNumber(cell);
  };

  const gridButtonPOPDF = (cell, row) => {
    //console.log(row);
    let pdfStatus = row.po_pdf;
    return (
      <div>
        <button
          className='btn btn-sm btn-primary'
          onClick={handleViewPOPdfFile(row.id, row.po)}
          disabled={!pdfStatus}>
          View
        </button>
      </div>
    );
  };

  const gridButtonInvPDF = (cell, row) => {
    let pdfStatus = row.inv_pdf;
    return (
      <div>
        <button
          className='btn btn-sm btn-danger'
          onClick={handleViewInvoicePdfFile(row.id, row.po)}
          disabled={!pdfStatus}>
          View
        </button>
      </div>
    );
  };

  const gridButtonMrtPDF = (cell, row) => {
    let pdfStatus = row.mrt_pdf;
    return (
      <div>
        <button
          className='btn btn-sm btn-secondary'
          onClick={handleViewMrtPdfFile(row.id)}
          disabled={!pdfStatus}>
          View
        </button>
      </div>
    );
  };

  const columns = [
    {
      dataField: 'id',
      text: 'PO ID',
      hidden: true,
      headerStyle: () => {
        return { width: '50px', textAlign: 'left' };
      },
    },
    {
      dataField: 'withBuying',
      text: 'withBuying',
      hidden: true,
    },
    {
      dataField: 'cont_ID',
      text: 'CONT ID',
      hidden: true,
      headerStyle: () => {
        return { width: '50px', textAlign: 'left' };
      },
    },
    {
      dataField: 'MRTdate',
      text: 'MRT Date',
      hidden: true,
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      headerStyle: () => {
        return { width: '95px', textAlign: 'center' };
      },
      // filter: textFilter({
      //   getFilter: (filter) => (poStatusFilter = filter),
      // }),
      editable: false,
    },
    {
      dataField: 'temp',
      text: 'Temp',
      sort: true,
      headerStyle: () => {
        return { width: '75px', textAlign: 'left' };
      },
      // filter: textFilter({
      //   getFilter: (filter) => (loadFilter = filter),
      // }),
      editable: false,
    },
    {
      dataField: 'vendor',
      text: 'Vendor',
      headerStyle: () => {
        return { width: '200px', textAlign: 'center' };
      },
      // filter: textFilter({
      //   getFilter: (filter) => (vendorFilter = filter),
      // }),
      editable: false,
    },
    {
      dataField: 'po',
      text: 'PO Num',
      headerStyle: () => {
        return { width: '120px', textAlign: 'center' };
      },
      // filter: textFilter({
      //   getFilter: (filter) => (poFilter = filter),
      // }),
      editable: false,
    },
    {
      dataField: 'desc',
      text: 'Description',
      headerStyle: () => {
        return { width: '150px', textAlign: 'center' };
      },
      // filter: textFilter({
      //   getFilter: (filter) => (descrFilter = filter),
      // }),
      // editable: true,
    },
    {
      dataField: 'dest',
      text: 'Consignee',
      sort: true,
      headerStyle: () => {
        return { width: '120px', textAlign: 'center' };
      },
      // filter: textFilter({
      //   getFilter: (filter) => (destFilter = filter),
      // }),
      editable: false,
    },
    {
      dataField: 'pallet',
      text: 'PLT',
      align: 'right',
      headerStyle: () => {
        return { width: '60px', textAlign: 'center' };
      },
      // editable: true,
    },
    {
      dataField: 'pcs',
      text: 'QTY',
      align: 'right',
      headerStyle: () => {
        return { width: '60px', textAlign: 'center' };
      },
      // editable: true,
    },
    {
      dataField: 'weight',
      text: 'LBS',
      align: 'right',
      style: (cell, row) => {
        if (row !== undefined) {
          if (
            (row?.status === 'OPEN' && row?.weight > 0) ||
            (row?.status === 'OPEN' && row?.cube > 0)
          ) {
            return {
              backgroundColor: '#ff9900',
            };
          }
        }
      },
      headerStyle: () => {
        return { width: '70px', textAlign: 'center' };
      },
      formatter: formatFixNumber,
      // editable: true,
    },
    {
      dataField: 'cube',
      text: 'CFT',
      align: 'right',
      style: (cell, row) => {
        if (
          (row?.status === 'OPEN' && row?.weight > 0) ||
          (row?.status === 'OPEN' && row?.cube > 0)
        ) {
          return {
            backgroundColor: '#ff9900',
          };
        }
      },
      headerStyle: () => {
        return { width: '70px', textAlign: 'center' };
      },
      formatter: formatFixNumber,
      // editable: true,
    },
    {
      dataField: 'control',
      text: 'Control',
      align: 'center',
      sort: true,
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
      // editable: true,
      // filter: textFilter({
      //   getFilter: (filter) => (controlFilter = filter),
      // }),
    },
    {
      dataField: 'inv_amt',
      text: 'Invoice Amount',
      align: 'right',
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
      formatter: formatInvAmt,
      // editable: true,
    },
    {
      dataField: 'comment',
      text: 'Comment',
      headerStyle: () => {
        return { width: '120px', textAlign: 'center' };
      },
      // filter: textFilter({
      //   getFilter: (filter) => (commentsFilter = filter),
      // }),
      // editable: true,
    },
    {
      dataField: 'po_date',
      text: 'PO Date',
      sort: true,
      edit: false,
      headerStyle: () => {
        return { width: '110px', textAlign: 'center' };
      },
      // filter: textFilter({
      //   getFilter: (filter) => (poFilter = filter),
      // }),
      editable: false,
    },
    {
      dataField: 'mighaletd',
      text: 'Del. Mighal',
      sort: true,
      edit: false,
      headerStyle: () => {
        return { width: '110px', textAlign: 'center' };
      },
      // filter: textFilter({
      //   getFilter: (filter) => (poFilter = filter),
      // }),
      editable: false,
    },
    {
      dataField: 'po_pdf',
      text: 'PO PDF',
      headerStyle: () => {
        return { width: '90px', textAlign: 'center' };
      },
      formatter: gridButtonPOPDF,
      editable: false,
    },
    {
      dataField: 'inv_pdf',
      text: 'TJ INV',
      headerStyle: () => {
        return { width: '90px', textAlign: 'center' };
      },
      formatter: gridButtonInvPDF,
      editable: false,
    },
    {
      dataField: 'mrt_pdf',
      text: 'MIG DOCS',
      headerStyle: () => {
        return { width: '90px', textAlign: 'center' };
      },
      formatter: gridButtonMrtPDF,
      editable: false,
    },
    {
      dataField: 'cont_no',
      text: 'CONTAINER',
      sort: true,
      headerStyle: () => {
        return { width: '150px', textAlign: 'center' };
      },
      editable: false,
    },
  ];

  const columnsModal = [
    {
      dataField: 'Container_ID',
      text: 'CONT ID',
      hidden: false,
      headerStyle: () => {
        return { width: '70px', textAlign: 'center' };
      },
    },
    {
      dataField: 'Status',
      text: 'Status',
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
    },
    {
      dataField: 'Temperature_Type',
      text: 'TEMP',
      headerStyle: () => {
        return { width: '80px', textAlign: 'left' };
      },
    },
    {
      dataField: 'Destination',
      text: 'Consignee',
      headerStyle: () => {
        return { width: '150px', textAlign: 'center' };
      },
    },
    {
      dataField: 'Container_No',
      text: 'Container No',
      headerStyle: () => {
        return { width: '200px', textAlign: 'center' };
      },
    },
    {
      dataField: 'Departure_Date',
      text: 'Departure',
      align: 'center',
      headerStyle: () => {
        return { width: '120px', textAlign: 'center' };
      },
    },
    {
      dataField: 'Arrival_Date',
      text: 'Arrival',
      align: 'center',
      headerStyle: () => {
        return { width: '120px', textAlign: 'center' };
      },
    },
    {
      dataField: 'TotalCube',
      text: 'TTL CF',
      align: 'right',
      headerStyle: () => {
        return { width: '90px', textAlign: 'center' };
      },
    },
    {
      dataField: 'TotalWeight',
      text: 'TTL LBS',
      align: 'right',
      headerStyle: () => {
        return { width: '90px', textAlign: 'center' };
      },
    },
  ];

  // const beforeSaveCell = (oldValue, newValue, row, column, done) => {
  //   setTimeout(() => {
  //     let Destination = row.dest;
  //     if (containerNoGrid !== '' && column.dataField === 'status') {
  //       alert(`Cannot change Status to assigned Container: ${containerNoGrid}`);
  //       done(false);
  //     } else {
  //       if (userLocation !== 'TJ MIGHAL' && userLocation !== 'DEVELOPMENT TEAM') {
  //         if (Destination !== userLocation) {
  //           alert('User Location does not match with PO Consignee');
  //           done(false);
  //         } else {
  //           if (newValue === 'CANCEL' && FormValue.control > 0) {
  //             alert('User is not allowed to CANCEL this PO with an existing Control Number');
  //             done(false);
  //           } else {
  //             done();
  //           }
  //         }
  //       } else {
  //         done();
  //       }
  //     }
  //   }, 0);
  //   return { async: true };
  // };

  // const afterSaveCell = (oldValue, newValue, column, row) => {
  //   try {
  //     let colField = row.dataField;
  //     let POID = column.id;
  //     let Destination = column.dest;
  //     let sqlColumn = '';
  //     let NewData;
  //
  //     switch (colField) {
  //       case 'status':
  //         sqlColumn = 'PO_Status';
  //         NewData = newValue;
  //         break;
  //       case 'pallet':
  //         sqlColumn = 'Pallets';
  //         NewData = parseInt(newValue);
  //         break;
  //       case 'pcs':
  //         sqlColumn = 'Total_Pieces';
  //         NewData = parseInt(newValue);
  //         break;
  //       case 'weight':
  //         sqlColumn = 'Weight';
  //         NewData = parseInt(newValue);
  //         break;
  //       case 'cube':
  //         sqlColumn = 'PO_Cube';
  //         NewData = parseInt(newValue);
  //         break;
  //       case 'control':
  //         sqlColumn = 'Control';
  //         NewData = parseInt(newValue);
  //         break;
  //       case 'inv_amt':
  //         sqlColumn = 'Invoice_Amount';
  //         NewData = parseFloat(newValue);
  //         break;
  //       case 'comment':
  //         sqlColumn = 'Comments';
  //         NewData = newValue.trim().toUpperCase();
  //         break;
  //       default:
  //         break;
  //     }
  //
  //     if (userLocation !== 'TJ MIGHAL' && userLocation !== 'DEVELOPMENT TEAM') {
  //       if (Destination !== userLocation) {
  //         return alert('User Location does not match with PO Consignee');
  //       } else {
  //         if (oldValue !== NewData && sqlColumn !== '') {
  //           setIsLoading(true);
  //           if (colField === 'status') {
  //             updatePOStatus(oldValue, NewData)
  //               .then(async () => {
  //                 alert('Successfully Updated PO Status');
  //                 // await reloadGrid()
  //                 //   .then((result) => {
  //                 //     setDataInfoGrid(result);
  //                 //   })
  //                 //   .catch(() => {
  //                 //     alert('Record is empty');
  //                 //   });
  //                 setIsLoading(false);
  //                 setOffset(1);
  //               })
  //               .catch((err) => {
  //                 console.log(err);
  //                 setIsLoading(false);
  //               });
  //           } else {
  //             updatePOGridInput(POID, sqlColumn, NewData)
  //               .then(async () => {
  //                 //console.log(result);
  //                 // await reloadGrid()
  //                 //   .then((result) => {
  //                 //     setDataInfoGrid(result);
  //                 //   })
  //                 //   .catch(() => {
  //                 //     alert('Record is empty');
  //                 //   });
  //                 setIsLoading(false);
  //                 setOffset(1);
  //               })
  //               .catch((err) => {
  //                 console.log(err);
  //                 setIsLoading(false);
  //               });
  //           }
  //         }
  //       }
  //     } else {
  //       if (oldValue !== NewData && sqlColumn !== '') {
  //         setIsLoading(true);
  //         if (colField === 'status') {
  //           updatePOStatus(oldValue, NewData)
  //             .then(async () => {
  //               alert('Successfully Updated PO Status');
  //               // await reloadGrid()
  //               //   .then((result) => {
  //               //     setDataInfoGrid(result);
  //               //   })
  //               //   .catch(() => {
  //               //     alert('Record is empty');
  //               //   });
  //               setIsLoading(false);
  //               setOffset(1);
  //             })
  //             .catch((err) => {
  //               console.log(err);
  //               setIsLoading(false);
  //             });
  //         } else {
  //           updatePOGridInput(POID, sqlColumn, NewData)
  //             .then(async () => {
  //               //console.log(result);
  //               // await reloadGrid()
  //               //   .then((result) => {
  //               //     setDataInfoGrid(result);
  //               //   })
  //               //   .catch(() => {
  //               //     alert('Record is empty');
  //               //   });
  //               setIsLoading(false);
  //               setOffset(1);
  //             })
  //             .catch((err) => {
  //               console.log(err);
  //               setIsLoading(false);
  //             });
  //         }
  //       }
  //     }
  //   } catch (e) {
  //     console.log(e);
  //     setIsLoading(false);
  //     alert('System Error');
  //   }
  // };

  // const updatePOGridInput = (PO_ID, sqlColumn, NewData) => {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const data = {
  //         UserEmail: localStorage.getItem('logisticsUseremail'),
  //         PO_ID,
  //         sqlColumn,
  //         NewData,
  //       };
  //       const endpoint = changePOEditGridView(data);
  //       const response = await apiFetch(endpoint);
  //       if (response && response.data === 'Success') {
  //         resolve(response.data);
  //       } else {
  //         reject('Failed');
  //       }
  //     } catch (e) {
  //       console.log(e);
  //       reject('Failed');
  //     }
  //   });
  // };

  // const updatePOStatus = (oldValue, newValue) => {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       if (oldValue !== newValue) {
  //         let data = {
  //           PO_ID: selectedPOId,
  //           NewStatus: newValue,
  //           UserEmail: localStorage.getItem('logisticsUseremail'),
  //         };
  //         const endpoint = changePOStatusGrid(data);
  //         const response = await apiFetch(endpoint);
  //         if (response && response.data === 'Success') {
  //           resolve(response.data);
  //         } else {
  //           reject('Failed');
  //         }
  //       }
  //     } catch (error) {
  //       console.log(error);
  //       reject('Failed');
  //     }
  //   });
  // };

  const applyDefaultBtnStatusState = () => {
    setFormValue(initForm);
    setIsFormDisable(true);
    setIsNew(false);
    setIsEdit(false);
    setIsDisabledBtnNew(false);
    setIsDisabledBtnEdit(false);
    setIsDisabledBtnSave(true);
    setIsDisabledBtnCancel(true);
    SetViewForm(false);
  };

  const handleNewButton = () => {
    setFormValue(initForm);
    setIsFormDisable(false);
    setIsNew(true);
    setIsEdit(false);
    setIsDisabledBtnEdit(true);
    setIsDisabledBtnSave(false);
    setIsDisabledBtnCancel(false);
    setWithBuyingFee(false);
    SetViewForm(true);
  };

  const handleCancelButton = () => {
    if (!isFormDisable) {
      setFormValue(initForm);
      setIsFormDisable(true);
      //Initialize to default states
      applyDefaultBtnStatusState();
    }
  };

  const handleEditButton = () => {
    if (FormValue.po !== '') {
      if (!isUserMighalDEV) {
        if (FormValue.dest !== userLocation) {
          alert('User Location does not match with PO Consignee');
        } else {
          dispatch({ type: 'SET_PO_PAGE_NUM', payload: 1 });
          setIsFormDisable(false);
          setIsEdit(true);
          setIsNew(false);
          setIsDisabledBtnNew(true);
          setIsDisabledBtnSave(false);
          setIsDisabledBtnCancel(false);
          SetViewForm(true);
        }
      } else {
        dispatch({ type: 'SET_PO_PAGE_NUM', payload: 1 });
        setIsFormDisable(false);
        setIsEdit(true);
        setIsNew(false);
        setIsDisabledBtnNew(true);
        setIsDisabledBtnSave(false);
        setIsDisabledBtnCancel(false);
        SetViewForm(true);
      }
    }
  };

  const onRowSelect = (row) => {
    try {
      let FormValues = {
        id: row.id,
        status: row.status,
        temp: row.temp,
        vendor: row.vendor,
        po: row.po,
        po_date: new Date(row.po_date),
        mighaletd: new Date(row.mighaletd),
        mrt_date: new Date(row.MRTdate),
        desc: row.desc,
        dest: row.dest,
        weight: parseFloat(row.weight),
        cube: parseFloat(row.cube),
        pallet: parseInt(row.pallet),
        pcs: parseInt(row.pcs),
        control: row.control,
        comment: row.comment,
        inv_amt: parseFloat(row.inv_amt),
        withBuying: row.withBuying,
      };

      setIsPOPDFExists(row.po_pdf === 1);
      setIsINVPDFExists(row.inv_pdf === 1);
      setIsMRTPDFExists(row.mrt_pdf === 1);
      setContainerIDGrid(row.cont_ID);
      setFormValue(FormValues);
      setSelectedPOId(row.id);
      //SetisDisabledBtnDelete(false);
      setDisableUploadPoBtn(false);
      setDisableUploadInvBtn(false);
      setWithBuyingFee(row.withBuying === 1);
    } catch (error) {
      console.log(error);
    }
  };

  const onRowSelectModal = (row) => {
    try {
      //console.log(row);
      let POContValue = {
        contID: row.Container_ID,
        contStatus: row.Status,
        contNo: row.Container_No,
      };
      setPOContValue(POContValue);
    } catch (e) {
      console.log(e);
    }
  };

  const selectRowProp = {
    mode: 'radio',
    clickToSelect: true,
    onSelect: onRowSelect,
    clickToEdit: true,
  };

  const selectRowPropModal = {
    mode: 'radio',
    clickToSelect: true,
    onSelect: onRowSelectModal,
  };

  const updateTempPOKey = () => {
    //console.log({ POCharKey });
    switch (POCharKey) {
      case 'C':
        setFormValue({ ...FormValue, temp: 'CHILL' });
        break;
      case 'D':
        setFormValue({ ...FormValue, temp: 'DRY' });
        break;
      case 'F':
        setFormValue({ ...FormValue, temp: 'FREEZE' });
        break;
      default:
        break;
    }
  };

  const handleChange = (prop) => async (ev) => {
    try {
      switch (prop) {
        case 'po_date':
          setFormValue({
            ...FormValue,
            [prop]: new Date(momentTZ.tz(ev, 'Pacific/Guam')),
          });
          break;
        case 'mighaletd':
          setFormValue({
            ...FormValue,
            [prop]: new Date(momentTZ.tz(ev, 'Pacific/Guam')),
          });
          break;
        case 'mrt_date':
          setFormValue({
            ...FormValue,
            [prop]: new Date(momentTZ.tz(ev, 'Pacific/Guam')),
          });
          break;
        case 'po':
          setFormValue({ ...FormValue, [prop]: ev.target.value });
          setPOCharKey(FormValue.po.charAt(1));
          break;
        case 'dest':
          setFormValue({ ...FormValue, [prop]: ev.target.value });
          setIsRiderLabelVisible(true);
          setIsTempLabelVisible(true);
          getLastRiderPOByConsignee(ev.target.value).then(() => null);
          getLastTempPOByConsignee(ev.target.value).then(() => null);
          break;
        // case 'status':
        //   if (containerNoGrid === '') {
        //     setFormValue({ ...FormValue, [prop]: ev.target.value });
        //   }
        //   break;
        default:
          setFormValue({ ...FormValue, [prop]: ev.target.value });
          break;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getLastTempPOByConsignee = async (Destination) => {
    const params = {
      consignee: Destination,
    };
    const endpoint = getTempLastPONum(params);
    const response = await apiFetch(endpoint);
    if (response && typeof response.data === 'object') {
      //console.log(response.data[0]);
      let DryTempPo = response.data[0].DRY;
      let ChillTempPo = response.data[0].CHILL;
      let FrzTempPo = response.data[0].FREEZE;
      let tempPoString = '';
      if (DryTempPo !== null && DryTempPo.length > 1) {
        tempPoString = `${DryTempPo}/`;
      }
      if (ChillTempPo !== null && ChillTempPo.length > 1) {
        tempPoString += `${ChillTempPo}/`;
      }
      if (FrzTempPo !== null && FrzTempPo.length > 1) {
        tempPoString += `${FrzTempPo}/`;
      }
      SetPOTemp(tempPoString);
      setIsTempLabelVisible(false);
    } else {
      SetPOTemp('');
      setIsTempLabelVisible(true);
    }
  };

  const getLastRiderPOByConsignee = async (Destination) => {
    try {
      const params = {
        consignee: Destination,
      };
      const endpoint = getLastRiderNum(params);
      const response = await apiFetch(endpoint);
      if (response && typeof response.data === 'object') {
        let DryRider = response.data[0].DRY;
        let ChillRider = response.data[0].CHILL;
        let FrzRider = response.data[0].FREEZE;
        let riderString = '';
        if (DryRider !== null && DryRider.length > 1) {
          riderString = `${DryRider}/`;
        }
        if (ChillRider !== null && ChillRider.length > 1) {
          riderString += `${ChillRider}/`;
        }
        if (FrzRider !== null && FrzRider.length > 1) {
          riderString += `${FrzRider}/`;
        }
        SetPORider(riderString);
        setIsRiderLabelVisible(false);
      } else {
        SetPORider('');
        setIsRiderLabelVisible(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const validate = () => {
    return new Promise(async (resolve) => {
      try {
        //console.log(FormValue);
        if (FormValue.po === '') {
          errorsMsg.push('You must provide a PO Number');
        }
        if (FormValue.desc === '') {
          errorsMsg.push('You must provide a Description');
        }
        if (FormValue.vendor === '') {
          errorsMsg.push('You must provide Vendor Name');
        }
        if (FormValue.weight !== '' && isNaN(FormValue.weight)) {
          errorsMsg.push('You must provide Numeric Value only for Weight');
        }

        if (FormValue.cube !== '' && isNaN(FormValue.cube)) {
          errorsMsg.push('You must provide Numeric Value only for Cubic Size');
        }
        if (FormValue.pcs !== '' && isNaN(FormValue.pcs)) {
          errorsMsg.push('You must provide Numeric Value only for Total Pieces');
        }
        let MRTLimitValue = maxMRTChecker + 1000;
        if (FormValue.control > MRTLimitValue) {
          errorsMsg.push(
            `Please review MRT Control Number entered is more than the expected sequence!`,
          );
        }

        resolve();
      } catch (error) {
        console.log(error);
      }
    });
  };

  /* const titleCase = (str) => {
    str = str.toLowerCase().split(' ');
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(' ');
  }; */

  const formatDate = (valueDate) => {
    let d = new Date(valueDate),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('/');
  };

  const formatDateContainer = (valueDate) => {
    let d = new Date(valueDate),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    try {
      //await convertBackToNumeric();
      await validate();
      //console.log(errorsMsg);
      setIsLoading(true);
      if (!errorsMsg.length) {
        //console.log(FormValue);
        let newPORecord = {
          Container_ID: '',
          PO_No: FormValue.po,
          PO_Date: formatDate(FormValue.po_date),
          MighalETD: formatDate(FormValue.mighaletd),
          MRTdate: formatDate(FormValue.mrt_date),
          Container_No: '',
          PO_Description: FormValue.desc.trim().toUpperCase(),
          Control: FormValue.control,
          Destination: FormValue.dest,
          Shipper: FormValue.vendor.trim().toUpperCase(),
          Comments: FormValue.comment.trim().toUpperCase(),
          Weight: parseInt(FormValue.weight),
          PO_Cube: parseInt(FormValue.cube),
          PO_Status: FormValue.status,
          Temperature: FormValue.temp,
          Pallets: parseInt(FormValue.pallet),
          Total_Pieces: parseInt(FormValue.pcs),
          Vendor: FormValue.vendor.trim().toUpperCase(),
          Invoice_Amount: parseFloat(FormValue.inv_amt),
          PO_InvoicePdf: '',
          PO_InvoicePdfname: '',
          PO_Pdf: '',
          PO_PdfFilename: '',
          withBuying: withBuyingFee === true ? 1 : 0,
          UserEmail: UserEmail,
        };

        if (isNew) {
          handleInsertNewRecord(newPORecord)
            .then((result) => {
              //console.log(result);
              if (result === 'Success') {
                alert(`Successfully Created Purchase Order ${FormValue.po}`);
              }
              applyDefaultBtnStatusState();
              setIsLoading(false);
              setTimeout(() => {
                dispatch({ type: 'SET_PO_PAGE_NUM', payload: 0 });
              }, 3000);
            })
            .catch((err) => {
              console.log(err);
              alert(err);
              //applyDefaultBtnStatusState();
              setIsLoading(false);
            });
        }

        let updateRecord = {
          PO_No: FormValue.po,
          PO_Description: FormValue.desc.trim().toUpperCase(),
          PO_Date: formatDate(FormValue.po_date),
          MighalETD: formatDate(FormValue.mighaletd),
          MRTdate: formatDate(FormValue.mrt_date),
          Control: parseInt(FormValue.control),
          Destination: FormValue.dest,
          Shipper: FormValue.vendor.trim().toUpperCase(),
          Comments: FormValue.comment.trim().toUpperCase(),
          Weight: parseInt(FormValue.weight),
          PO_Cube: parseInt(FormValue.cube),
          PO_Status: FormValue.status,
          Temperature: FormValue.temp,
          Pallets: parseInt(FormValue.pallet),
          Total_Pieces: parseInt(FormValue.pcs),
          Vendor: FormValue.vendor.trim().toUpperCase(),
          Invoice_Amount: parseFloat(FormValue.inv_amt),
          withBuying: withBuyingFee === true ? 1 : 0,
          UserEmail: UserEmail,
        };

        if (!isUserMighalDEV) {
          if (FormValue.status === 'CANCEL' && parseInt(FormValue.control) > 0) {
            setIsLoading(false);
            return alert('User is not allowed to CANCEL this PO with an existing Control Number');
          }
        }

        if (isEdit) {
          handleUpdateRecord(updateRecord)
            .then(async (result) => {
              setIsLoading(false);
              // console.log(result);
              if (result === 'Success') {
                alert(`Successfully Updated Purchase Order ${FormValue.po}`);
              }
              applyDefaultBtnStatusState();
              setIsLoading(false);
              setTimeout(() => {
                dispatch({ type: 'SET_PO_PAGE_NUM', payload: 0 });
              }, 3000);
            })
            .catch((err) => {
              console.log(err);
              alert(`Unable to Updated Purchase Order ${FormValue.po}`);
              //applyDefaultBtnStatusState();
              setIsLoading(false);
            });
        }
      } else {
        alert(errorsMsg[0]);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInsertNewRecord = (newPORecord) => {
    return new Promise(async (resolve, reject) => {
      const endpoint = insertNewPORecord(newPORecord);
      const response = await apiFetch(endpoint);
      //console.log(response.data);
      if (response && response.data === 'Success') {
        setTimeout(() => {
          resolve('Success');
        }, 3000);
      } else {
        reject(response.data);
      }
    });
  };

  const handleUpdateRecord = (updateRecord) => {
    return new Promise(async (resolve, reject) => {
      const endpoint = updatePORecord(selectedPOId, updateRecord);
      const response = await apiFetch(endpoint);
      //console.log(response.data);
      if (response && response.data === 'Success') {
        setTimeout(() => {
          resolve('Success');
        }, 3000);
      } else {
        reject(response.data);
      }
    });
  };

  const checkDocStatus = async (POId, dataType) => {
    try {
      const params = {
        POId,
        dataType,
      };
      const endpoint = getDocumentStatus(params);
      return await apiFetch(endpoint)
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const handleViewPOPdfFile = (poID, poNum) => async (ev) => {
    ev.preventDefault();
    try {
      if (poID !== '' || poNum !== '') {
        setIsLoading(true);
        const status = await checkDocStatus(poID, DocType.PO_FILE)
          .then((res) => res)
          .catch((e) => {
            throw e;
          });
        if (status && status.data) {
          downloadPOPdfFile(poNum)
            .then((response) => {
              setIsLoading(false);
              const url = window.URL.createObjectURL(
                new Blob([response.data], { type: 'application/pdf' }),
              );
              window.open(url);
            })
            .catch(() => {
              alert('No PDF Available');
              setIsLoading(false);
            });
        } else {
          downloadPOS3Document(poID, DocType.PO_FILE)
            .then((response) => {
              setIsLoading(false);
              window.open(response);
            })
            .catch(() => {
              alert('No PDF Available');
              setIsLoading(false);
            });
        }
      } else {
        alert('Please select a Purchase Order to view the PDF');
      }
    } catch (error) {
      console.log(error);
      alert('No PDF Available');
    }
  };

  const downloadPOS3Document = async (POId, docType) => {
    try {
      const params = {
        POId,
        dataType: docType,
      };
      const endpoint = getPOFileLinkS3(params);
      return await apiFetch(endpoint)
        .then((res) => (res && res.data) || null)
        .catch((e) => {
          throw e;
        });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const downloadPOPdfFile = (poNum) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoint = getPOPDFFile(poNum);
        const response = await apiFetch(endpoint);
        if (response.data.type !== 'application/json') {
          resolve(response);
        } else {
          reject('Failed');
        }
      } catch (error) {
        console.log(error);
        reject('Failed');
      }
    });
  };

  const handleViewInvoicePdfFile = (poID, poNum) => async (ev) => {
    ev.preventDefault();
    try {
      if (poNum !== '' || poID !== '') {
        setIsLoading(true);
        const status = await checkDocStatus(poID, DocType.INV_FILE)
          .then((res) => res)
          .catch((e) => {
            throw e;
          });
        if (status && status.data) {
          downloadInvoicePdfFile(poNum)
            .then((response) => {
              setIsLoading(false);
              console.log('Finish Download POInvPdf');
              const url = window.URL.createObjectURL(
                new Blob([response.data], { type: 'application/pdf' }),
              );
              window.open(url);
            })
            .catch(() => {
              alert('No PDF Available');
              setIsLoading(false);
            });
        } else {
          downloadPOS3Document(poID, DocType.INV_FILE)
            .then((response) => {
              setIsLoading(false);
              window.open(response);
            })
            .catch(() => {
              alert('No PDF Available');
              setIsLoading(false);
            });
        }
      } else {
        alert('Please select a Purchase Order to view the PDF');
      }
    } catch (error) {
      console.log(error);
      alert('No PDF Available');
      setIsLoading(false);
    }
  };

  const downloadInvoicePdfFile = (poNum) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoint = getPOInvPDFFile(poNum);
        const response = await apiFetch(endpoint);

        if (response.data.type !== 'application/json') {
          resolve(response);
        } else {
          reject('Failed');
        }
      } catch (error) {
        console.log(error);
        reject('Failed');
      }
    });
  };

  const handleViewMrtPdfFile = (POId) => async (ev) => {
    ev.preventDefault();
    try {
      if (POId !== '') {
        setIsLoading(true);
        const status = await checkDocStatus(POId, DocType.MRT_FILE)
          .then((res) => res)
          .catch((e) => {
            throw e;
          });
        if (status && status.data) {
          downloadMrtPdfFile(POId)
            .then((response) => {
              setIsLoading(false);
              const url = window.URL.createObjectURL(
                new Blob([response.data], { type: 'application/pdf' }),
              );
              window.open(url);
            })
            .catch(() => {
              alert('No PDF Available');
              setIsLoading(false);
            });
        } else {
          downloadMRTS3Document(POId)
            .then((response) => {
              setIsLoading(false);
              window.open(response);
            })
            .catch(() => {
              alert('No PDF Available');
              setIsLoading(false);
            });
        }
      } else {
        alert('Please select a Purchase Order to view the PDF');
      }
    } catch (error) {
      console.log(error);
      alert('No PDF Available');
      setIsLoading(false);
    }
  };

  const downloadMRTS3Document = async (POId) => {
    try {
      const endpoint = getMRTFileLinkS3({ POId });
      return await apiFetch(endpoint)
        .then((res) => (res && res.data) || null)
        .catch((e) => {
          throw e;
        });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const downloadMrtPdfFile = (POId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoint = getPOMRTPDFFile(POId);
        const response = await apiFetch(endpoint);
        if (response.data.type !== 'application/json') {
          resolve(response);
        } else {
          reject('Failed');
        }
      } catch (error) {
        console.log(error);
        reject('Failed');
      }
    });
  };

  const handleChangePOPDFFileInput = (ev) => {
    ev.preventDefault();

    try {
      let confirmSelectOverWrite = null;
      if (isPOPDFExists) {
        confirmSelectOverWrite = window.confirm(
          `PO Pdf file already exist, Do you wish to overwrite?\r\nIf Yes, click OK. If No, click CANCEL`,
        );

        if (!confirmSelectOverWrite) {
          return false;
        } else {
          let fileSize = ev.target.files[0].size;
          setFilePOPdf(ev.target.files[0]);
          if (fileSize / 1024 > 500) {
            let confirmSelection = window.confirm(
              `File Size is greater than 500KB, Do you wish to continue uploading?\r\nIf Yes, click OK. If No, click CANCEL`,
            );
            // alert(`Filesize is greater than 600KB`);
            if (!confirmSelection) {
              return false;
            } else {
              if (!isUserMighalDEV) {
                if (FormValue.dest !== userLocation) {
                  alert('User Location does not match with PO Consignee');
                } else {
                  if (ev.target.files[0] !== null && ev.target.files[0] !== '') {
                    setIsLoading(true);
                    postPOPDFFile(ev.target.files[0])
                      .then(async (response) => {
                        alert(response);
                        setFilePOPdf(null);
                        setDisableUploadPoBtn(true);
                        setIsFormDisable(true);
                        setIsLoading(false);
                        if (offset > 0) {
                          dispatch({ type: 'SET_PO_PAGE_NUM', payload: 0 });
                        } else {
                          dispatch({ type: 'SET_PO_PAGE_NUM', payload: 1 });
                        }
                      })
                      .catch((err) => {
                        console.log(err);
                        setFilePOPdf(null);
                        setDisableUploadPoBtn(true);
                        setIsFormDisable(true);
                        setIsLoading(false);
                      });
                  } else {
                    alert('Please select a PO File');
                  }
                }
              } else {
                if (ev.target.files[0] !== null && ev.target.files[0] !== '') {
                  setIsLoading(true);
                  postPOPDFFile(ev.target.files[0])
                    .then(async (response) => {
                      alert(response);
                      setFilePOPdf(null);
                      setDisableUploadPoBtn(true);
                      setIsFormDisable(true);
                      setIsLoading(false);
                      await dispatch({ type: 'SET_PO_PAGE_NUM', payload: 0 });
                    })
                    .catch((err) => {
                      console.log(err);
                      setFilePOPdf(null);
                      setDisableUploadPoBtn(true);
                      setIsFormDisable(true);
                      setIsLoading(false);
                    });
                } else {
                  alert('Please select a PO File');
                }
              }
            }
          } else {
            if (!isUserMighalDEV) {
              if (FormValue.dest !== userLocation) {
                alert('User Location does not match with PO Consignee');
              } else {
                if (ev.target.files[0] !== null && ev.target.files[0] !== '') {
                  setIsLoading(true);
                  postPOPDFFile(ev.target.files[0])
                    .then(async (response) => {
                      alert(response);
                      setFilePOPdf(null);
                      setDisableUploadPoBtn(true);
                      setIsFormDisable(true);
                      setIsLoading(false);
                      await dispatch({ type: 'SET_PO_PAGE_NUM', payload: 0 });
                    })
                    .catch((err) => {
                      console.log(err);
                      setFilePOPdf(null);
                      setDisableUploadPoBtn(true);
                      setIsFormDisable(true);
                      setIsLoading(false);
                    });
                } else {
                  alert('Please select a PO File');
                }
              }
            } else {
              if (ev.target.files[0] !== null && ev.target.files[0] !== '') {
                setIsLoading(true);
                postPOPDFFile(ev.target.files[0])
                  .then(async (response) => {
                    alert(response);
                    setFilePOPdf(null);
                    setDisableUploadPoBtn(true);
                    setIsFormDisable(true);
                    setIsLoading(false);
                    await dispatch({ type: 'SET_PO_PAGE_NUM', payload: 0 });
                  })
                  .catch((err) => {
                    console.log(err);
                    setFilePOPdf(null);
                    setDisableUploadPoBtn(true);
                    setIsFormDisable(true);
                    setIsLoading(false);
                  });
              } else {
                alert('Please select a PO File');
              }
            }
          }
        }
      } else {
        let fileSize = ev.target.files[0].size;
        setFilePOPdf(ev.target.files[0]);
        if (fileSize / 1024 > 500) {
          let confirmSelection = window.confirm(
            `File Size is greater than 500KB, Do you wish to continue uploading?\r\nIf Yes, click OK. If No, click CANCEL`,
          );
          // alert(`Filesize is greater than 600KB`);
          if (!confirmSelection) {
            return false;
          } else {
            if (!isUserMighalDEV) {
              if (FormValue.dest !== userLocation) {
                alert('User Location does not match with PO Consignee');
              } else {
                if (ev.target.files[0] !== null && ev.target.files[0] !== '') {
                  setIsLoading(true);
                  postPOPDFFile(ev.target.files[0])
                    .then(async (response) => {
                      alert(response);
                      setFilePOPdf(null);
                      setDisableUploadPoBtn(true);
                      setIsFormDisable(true);
                      setIsLoading(false);
                      if (offset > 0) {
                        dispatch({ type: 'SET_PO_PAGE_NUM', payload: 0 });
                      } else {
                        dispatch({ type: 'SET_PO_PAGE_NUM', payload: 1 });
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                      setFilePOPdf(null);
                      setDisableUploadPoBtn(true);
                      setIsFormDisable(true);
                      setIsLoading(false);
                    });
                } else {
                  alert('Please select a PO File');
                }
              }
            } else {
              if (ev.target.files[0] !== null && ev.target.files[0] !== '') {
                setIsLoading(true);
                postPOPDFFile(ev.target.files[0])
                  .then(async (response) => {
                    alert(response);
                    setFilePOPdf(null);
                    setDisableUploadPoBtn(true);
                    setIsFormDisable(true);
                    setIsLoading(false);
                    if (offset > 0) {
                      dispatch({ type: 'SET_PO_PAGE_NUM', payload: 0 });
                    } else {
                      dispatch({ type: 'SET_PO_PAGE_NUM', payload: 1 });
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    setFilePOPdf(null);
                    setDisableUploadPoBtn(true);
                    setIsFormDisable(true);
                    setIsLoading(false);
                  });
              } else {
                alert('Please select a PO File');
              }
            }
          }
        } else {
          if (!isUserMighalDEV) {
            if (FormValue.dest !== userLocation) {
              alert('User Location does not match with PO Consignee');
            } else {
              if (ev.target.files[0] !== null && ev.target.files[0] !== '') {
                setIsLoading(true);
                postPOPDFFile(ev.target.files[0])
                  .then(async (response) => {
                    alert(response);
                    setFilePOPdf(null);
                    setDisableUploadPoBtn(true);
                    setIsFormDisable(true);
                    setIsLoading(false);
                    if (offset > 0) {
                      dispatch({ type: 'SET_PO_PAGE_NUM', payload: 0 });
                    } else {
                      dispatch({ type: 'SET_PO_PAGE_NUM', payload: 1 });
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    setFilePOPdf(null);
                    setDisableUploadPoBtn(true);
                    setIsFormDisable(true);
                    setIsLoading(false);
                  });
              } else {
                alert('Please select a PO File');
              }
            }
          } else {
            if (ev.target.files[0] !== null && ev.target.files[0] !== '') {
              setIsLoading(true);
              postPOPDFFile(ev.target.files[0])
                .then(async (response) => {
                  alert(response);
                  setFilePOPdf(null);
                  setDisableUploadPoBtn(true);
                  setIsFormDisable(true);
                  setIsLoading(false);
                  if (offset > 0) {
                    dispatch({ type: 'SET_PO_PAGE_NUM', payload: 0 });
                  } else {
                    dispatch({ type: 'SET_PO_PAGE_NUM', payload: 1 });
                  }
                })
                .catch((err) => {
                  console.log(err);
                  setFilePOPdf(null);
                  setDisableUploadPoBtn(true);
                  setIsFormDisable(true);
                  setIsLoading(false);
                });
            } else {
              alert('Please select a PO File');
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const processUploadInvPdf = (PDFData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const data = new FormData();
        data.append('pdfFile', PDFData);
        data.append('token', UserToken);
        postInvPDFFile(data)
          .then(async (response) => {
            alert(response);
            setIsLoading(false);
            setDisableUploadInvBtn(true);
            setIsFormDisable(true);
            resolve('Finished');
          })
          .catch((err) => {
            console.log(err);
            // SetFileInvPdf(null);
            setDisableUploadInvBtn(true);
            setIsFormDisable(true);
            reject(err);
          });
      } catch (e) {
        reject(e);
      }
    });
  };

  const handleChangeInvoicePdfFileInput = (ev) => {
    ev.preventDefault();
    try {
      let fileSize = ev.target.files[0].size;
      // SetFileInvPdf(ev.target.files[0]);
      if (isINVPDFExists) {
        let confirmSelectOverWrite = window.confirm(
          `Invoice Pdf file aready exist, Do you wish to overwrite?\r\nIf Yes, click OK. If No, click CANCEL`,
        );
        if (!confirmSelectOverWrite) {
          return false;
        } else {
          if (fileSize / 1024 > 500) {
            let confirmSelection = window.confirm(
              `File Size is greater than 500KB, Do you wish to continue uploading?\r\nIf Yes, click OK. If No, click CANCEL`,
            );
            // alert(`Filesize is greater than 600KB`);
            if (!confirmSelection) {
              return false;
            } else {
              if (!isUserMighalDEV) {
                if (FormValue.dest !== userLocation) {
                  alert('User Location does not match with PO Consignee');
                } else {
                  if (ev.target.files[0] !== null && ev.target.files[0] !== '') {
                    setIsLoading(true);
                    processUploadInvPdf(ev.target.files[0])
                      .then((response) => {
                        console.log(response);
                        setIsLoading(false);
                        if (offset > 0) {
                          dispatch({ type: 'SET_PO_PAGE_NUM', payload: 0 });
                        } else {
                          dispatch({ type: 'SET_PO_PAGE_NUM', payload: 1 });
                        }
                      })
                      .catch((err) => {
                        console.log(err);
                        alert(err);
                        setIsLoading(false);
                      });
                  } else {
                    alert('Please select a PDF File');
                  }
                }
              } else {
                if (ev.target.files[0] !== null && ev.target.files[0] !== '') {
                  setIsLoading(true);
                  processUploadInvPdf(ev.target.files[0])
                    .then((response) => {
                      console.log(response);
                      setIsLoading(false);
                      if (offset > 0) {
                        dispatch({ type: 'SET_PO_PAGE_NUM', payload: 0 });
                      } else {
                        dispatch({ type: 'SET_PO_PAGE_NUM', payload: 1 });
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                      alert(err);
                      setIsLoading(false);
                    });
                } else {
                  alert('Please select a PDF File');
                }
              }
            }
          } else {
            if (!isUserMighalDEV) {
              if (FormValue.dest !== userLocation) {
                alert('User Location does not match with PO Consignee');
              } else {
                if (ev.target.files[0] !== null && ev.target.files[0] !== '') {
                  setIsLoading(true);
                  processUploadInvPdf(ev.target.files[0])
                    .then(() => {
                      setIsLoading(false);
                      if (offset > 0) {
                        dispatch({ type: 'SET_PO_PAGE_NUM', payload: 0 });
                      } else {
                        dispatch({ type: 'SET_PO_PAGE_NUM', payload: 1 });
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                      setIsLoading(false);
                    });
                } else {
                  alert('Please select a PO File');
                }
              }
            } else {
              if (ev.target.files[0] !== null && ev.target.files[0] !== '') {
                setIsLoading(true);
                processUploadInvPdf(ev.target.files[0])
                  .then(() => {
                    setIsLoading(false);
                    if (offset > 0) {
                      dispatch({ type: 'SET_PO_PAGE_NUM', payload: 0 });
                    } else {
                      dispatch({ type: 'SET_PO_PAGE_NUM', payload: 1 });
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    alert(err);
                    setIsLoading(false);
                  });
              } else {
                alert('Please select a PO Invoice File');
              }
            }
          }
        }
      } else {
        if (fileSize / 1024 > 500) {
          let confirmSelection = window.confirm(
            `File Size is greater than 500KB, Do you wish to continue uploading?\r\nIf Yes, click OK. If No, click CANCEL`,
          );
          // alert(`Filesize is greater than 600KB`);
          if (!confirmSelection) {
            return false;
          } else {
            if (!isUserMighalDEV) {
              if (FormValue.dest !== userLocation) {
                alert('User Location does not match with PO Consignee');
              } else {
                if (ev.target.files[0] !== null && ev.target.files[0] !== '') {
                  setIsLoading(true);
                  processUploadInvPdf(ev.target.files[0])
                    .then(() => {
                      setIsLoading(false);
                      if (offset > 0) {
                        dispatch({ type: 'SET_PO_PAGE_NUM', payload: 0 });
                      } else {
                        dispatch({ type: 'SET_PO_PAGE_NUM', payload: 1 });
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                      alert(err);
                      setIsLoading(false);
                    });
                } else {
                  alert('Please select a PO File');
                }
              }
            } else {
              if (ev.target.files[0] !== null && ev.target.files[0] !== '') {
                setIsLoading(true);
                processUploadInvPdf(ev.target.files[0])
                  .then(() => {
                    setIsLoading(false);
                    if (offset > 0) {
                      dispatch({ type: 'SET_PO_PAGE_NUM', payload: 0 });
                    } else {
                      dispatch({ type: 'SET_PO_PAGE_NUM', payload: 1 });
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    alert(err);
                    setIsLoading(false);
                  });
              } else {
                alert('Please select a PO Invoice File');
              }
            }
          }
        } else {
          if (!isUserMighalDEV) {
            if (FormValue.dest !== userLocation) {
              alert('User Location does not match with PO Consignee');
            } else {
              if (ev.target.files[0] !== null && ev.target.files[0] !== '') {
                setIsLoading(true);
                processUploadInvPdf(ev.target.files[0])
                  .then(() => {
                    setIsLoading(false);
                    if (offset > 0) {
                      dispatch({ type: 'SET_PO_PAGE_NUM', payload: 0 });
                    } else {
                      dispatch({ type: 'SET_PO_PAGE_NUM', payload: 1 });
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    alert(err);
                    setIsLoading(false);
                  });
              } else {
                alert('Please select a PO File');
              }
            }
          } else {
            if (ev.target.files[0] !== null && ev.target.files[0] !== '') {
              setIsLoading(true);
              processUploadInvPdf(ev.target.files[0])
                .then(() => {
                  setIsLoading(false);
                  if (offset > 0) {
                    dispatch({ type: 'SET_PO_PAGE_NUM', payload: 0 });
                  } else {
                    dispatch({ type: 'SET_PO_PAGE_NUM', payload: 1 });
                  }
                })
                .catch((err) => {
                  console.log(err);
                  alert(err);
                  setIsLoading(false);
                });
            } else {
              alert('Please select a PDF File');
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeMRTPDFFileInput = (ev) => {
    ev.preventDefault();
    try {
      if (!isUserMighalDEV) {
        return alert('User is not authorize to upload MRT Docs');
      }

      let fileSize = ev.target.files[0].size;
      setFileMRTPdf(ev.target.files[0]);

      if (isMRTPDFExists) {
        let confirmSelectOverWrite = window.confirm(
          `Invoice Pdf file aready exist, Do you wish to overwrite?\r\nIf Yes, click OK. If No, click CANCEL`,
        );
        if (!confirmSelectOverWrite) {
          return false;
        } else {
          if (fileSize / 1024 > 500) {
            let confirmSelection = window.confirm(
              `File Size is greater than 500KB, Do you wish to continue uploading?\r\nIf Yes, click OK. If No, click CANCEL`,
            );
            // alert(`Filesize is greater than 600KB`);

            if (!confirmSelection) {
              return false;
            } else {
              if (ev.target.files[0] !== null && ev.target.files[0] !== '') {
                setIsLoading(true);
                processUploadMRTPDF(ev.target.files[0])
                  .then(() => {
                    setIsLoading(false);
                    if (offset > 0) {
                      dispatch({ type: 'SET_PO_PAGE_NUM', payload: 0 });
                    } else {
                      dispatch({ type: 'SET_PO_PAGE_NUM', payload: 1 });
                    }
                  })
                  .catch((err) => {
                    alert(err);
                    setIsLoading(false);
                  });
              } else {
                alert('Please select a DOCS File');
              }
            }
          } else {
            if (ev.target.files[0] !== null && ev.target.files[0] !== '') {
              setIsLoading(true);
              processUploadMRTPDF(ev.target.files[0])
                .then(() => {
                  setIsLoading(false);
                  if (offset > 0) {
                    dispatch({ type: 'SET_PO_PAGE_NUM', payload: 0 });
                  } else {
                    dispatch({ type: 'SET_PO_PAGE_NUM', payload: 1 });
                  }
                })
                .catch((err) => {
                  console.log(err);
                  alert(err);
                  setIsLoading(false);
                });
            } else {
              alert('Please select a DOCS File');
            }
          }
        }
      } else {
        if (fileSize / 1024 > 500) {
          let confirmSelection = window.confirm(
            `File Size is greater than 500KB, Do you wish to continue uploading?\r\nIf Yes, click OK. If No, click CANCEL`,
          );
          // alert(`Filesize is greater than 600KB`);
          if (!confirmSelection) {
            return false;
          } else {
            if (ev.target.files[0] !== null && ev.target.files[0] !== '') {
              setIsLoading(true);
              processUploadMRTPDF(ev.target.files[0])
                .then(() => {
                  setIsLoading(false);
                  if (offset > 0) {
                    dispatch({ type: 'SET_PO_PAGE_NUM', payload: 0 });
                  } else {
                    dispatch({ type: 'SET_PO_PAGE_NUM', payload: 1 });
                  }
                })
                .catch((err) => {
                  console.log(err);
                  setIsLoading(false);
                });
            } else {
              alert('Please select a Docs File');
            }
          }
        } else {
          if (ev.target.files[0] !== null && ev.target.files[0] !== '') {
            setIsLoading(true);
            setIsLoading(true);
            processUploadMRTPDF(ev.target.files[0])
              .then(() => {
                setIsLoading(false);
                if (offset > 0) {
                  dispatch({ type: 'SET_PO_PAGE_NUM', payload: 0 });
                } else {
                  dispatch({ type: 'SET_PO_PAGE_NUM', payload: 1 });
                }
              })
              .catch((err) => {
                console.log(err);
                setIsLoading(false);
              });
          } else {
            alert('Please select a Docs File');
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const processUploadMRTPDF = (PDFData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const data = new FormData();
        data.append('pdfFile', PDFData);
        data.append('token', UserToken);
        data.append('POID', selectedPOId);
        data.append('UserEmail', UserEmail);

        await postMRTPDFFile(data)
          .then(async (response) => {
            alert(response); //'Success'
            setFileMRTPdf(null);
            setDisableUploadInvBtn(true);
            setDisableUploadPoBtn(true);
            setIsFormDisable(true);
            setIsLoading(false);
            resolve('Finished');
          })
          .catch((err) => {
            console.error(err);
            setFileMRTPdf(null);
            setDisableUploadInvBtn(true);
            setDisableUploadPoBtn(true);
            setIsFormDisable(true);
            reject(err);
          });
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  };

  const postPOPDFFile = (file) => {
    return new Promise(async (resolve, reject) => {
      try {
        const data = new FormData();
        data.append('pdfFile', file);
        const headers = {
          'Content-Type': 'multipart/form-data',
        };

        const params = {
          POId: FormValue.id,
          docType: DocType.PO_FILE,
          access_token: UserToken,
        };

        Axios({
          method: 'POST',
          url: `${process.env.REACT_APP_APIBASEURL}/document/po/upload`,
          data,
          headers,
          params,
        })
          .then(async (resp) => {
            if (resp.status === 200) {
              const buffer = await downloadPOS3Document(FormValue.id, DocType.PO_FILE);
              if (buffer) window.open(buffer);
              resolve('Success');
            }
          })
          .catch((resp) => {
            console.log('failed upload MRT >>>', resp);
            reject('Failed');
          });
      } catch (error) {
        console.log(error);
        reject('Failed');
      }
    });
  };

  const postInvPDFFile = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const headers = {
          'Content-Type': 'multipart/form-data',
        };
        Axios({
          method: 'POST',
          url: `${process.env.REACT_APP_APIBASEURL}/document/po/upload`,
          data,
          headers,
          params: {
            POId: FormValue.id,
            docType: DocType.INV_FILE,
            access_token: UserToken,
          },
        })
          .then(async (resp) => {
            if (resp.status === 200) {
              const buffer = await downloadPOS3Document(FormValue.id, DocType.INV_FILE);
              if (buffer) window.open(buffer);
              resolve('Success');
            }
          })
          .catch((resp) => {
            console.log('failed upload MRT >>>', resp);
            reject('Failed');
          });
      } catch (error) {
        console.log(error);
        reject('Failed');
      }
    });
  };

  const postMRTPDFFile = (data) => {
    return new Promise((resolve, reject) => {
      try {
        const headers = {
          'Content-Type': 'multipart/form-data',
        };
        Axios({
          method: 'POST',
          url: `${process.env.REACT_APP_APIBASEURL}/document/mrt/upload`,
          data,
          headers,
          params: {
            POId: FormValue.id,
            userEmail: UserEmail,
            access_token: UserToken,
          },
        })
          .then(async (resp) => {
            if (resp.status === 200) {
              const buffer = await downloadMRTS3Document(FormValue.id);
              if (buffer) window.open(buffer);
              resolve('Success');
            }
          })
          .catch((resp) => {
            console.log('failed upload MRT >>>', resp);
            reject('Failed');
          });
      } catch (error) {
        console.error(error);
        reject('Failed');
      }
    });
  };

  const handleCheckedDeliver = () => {
    dispatch({ type: 'SET_PO_IS_DELIVERED', payload: !showDeliver });
  };

  const onChangeInvAmt = (ev) => {
    setFormValue({ ...FormValue, inv_amt: ev.target.value });
  };

  const toCurrency = (number) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatter.format(number);
  };

  const handleAddToContainer = () => {
    try {
      if (POContValue.contID === 0) {
        return alert('Please select a container!');
      }

      setInProcess(true);
      savePOtoContainer()
        .then(async () => {
          setInProcess(false);
          setShowModal(false);
          setIsLoading(false);
          await dispatch({
            type: 'SET_PO_PAGE_NUM',
            payload: 0,
          });
        })
        .catch(() => {
          alert('Unable to add PO into the Container!');
        });
    } catch (e) {
      console.log(e);
    }
  };

  const getLastRecords = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoint = getSettings();
        const responseSettings = await apiFetch(endpoint);
        if (responseSettings && responseSettings.data) {
          let resData = responseSettings.data[0];
          // console.log(resData);
          resolve(resData);
        } else {
          reject('Empty');
        }
      } catch (e) {
        console.log(e);
        reject('Empty');
      }
    });
  };

  const savePOtoContainer = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const data = {
          UserEmail: UserEmail,
          POID: selectedPOId,
          Container_ID: POContValue.contID,
          Container_No: POContValue.contNo,
          Status: POContValue.contStatus.toUpperCase(),
        };
        const endpoint = addPOToContainerAfterReceived(data);
        const response = await apiFetch(endpoint);
        if (response && response.data === 'Success') {
          resolve('Success');
        } else {
          reject('Failed');
        }
      } catch (e) {
        console.log(e);
        reject('Failed');
      }
    });
  };

  const handleCloseModal = () => setShowModal(false);

  const handleOpenModal = () => {
    //console.log({ FormValue });
    if (!isUserMighalDEV) {
      return alert('User Account not allowed add to container');
    }

    if (FormValue.dest === 'MBI GUAM') {
      return alert('Cannot add to container for Non-affiliate PO.');
    }

    setInProcess(false);
    switch (FormValue.status) {
      case 'RECEIVED':
        if (containerIDGrid === 0) {
          setIsLoading(true);
          getModalContainerList()
            .then((result) => {
              //console.log(result);
              setDataModalGrid(result);
              setIsLoading(false);
              setShowModal(true);
            })
            .catch(async () => {
              //console.log(err);
              setIsLoading(false);
              let confirmSelection = window.confirm(
                'No existing container. Do you want to create a temporary container?',
              );
              if (!confirmSelection) {
                return false;
              }
              setIsLoading(true);
              /* Create A New Temporary Container*/
              let NewFileRefNo = '';
              await getLastRecords()
                .then((result) => {
                  let lastRecordFileRef = parseInt(result.LastFileRefNo) + 1;
                  let strDate = new Date();
                  let shortYear = strDate.getFullYear();
                  // noinspection JSDeprecatedSymbols
                  let twoDigitYear = shortYear.toString().substr(-2);
                  NewFileRefNo = `M${twoDigitYear}-${lastRecordFileRef}`;
                })
                .catch((e) => {
                  console.log('TryCatch getLastRecords', e);
                });

              getAttention(FormValue.dest)
                .then(async (result) => {
                  let deptDte = formatDateContainer(new Date());
                  let tempType = FormValue.temp;
                  let newTempContName = `${result?.LocationCode}-${tempType}-${deptDte}`;
                  const params = {
                    containerNo: newTempContName,
                  };
                  const endpoint = isContainerExist(params);
                  const response = await apiFetch(endpoint).then((res) => res?.data || null);
                  if (response && response?.count !== null) {
                    newTempContName = `${newTempContName}-${Number(response?.count) + 1}`;
                  }
                  let newObjectData;
                  let ContType;
                  switch (FormValue.temp) {
                    case 'DRY':
                      ContType = '1X40 DRY';
                      break;
                    default:
                      ContType = '1X40 REEFER';
                      break;
                  }
                  newObjectData = {
                    ...initContainerValue,
                    l_type: FormValue.temp,
                    c_type: ContType,
                    destination: FormValue.dest,
                    attention: result.Attention,
                    location: result.LocationCode,
                    emailTo: result.User_email,
                    container: newTempContName,
                  };

                  /* Insert New Container Record */
                  let newContainerRecord = {
                    Container_No: newObjectData.container.trim().toUpperCase(),
                    Temperature_Type: newObjectData.l_type,
                    Attention: newObjectData.attention,
                    Email: newObjectData.emailTo,
                    Destination: newObjectData.destination,
                    Departure_Date: formatDate(newObjectData.depart),
                    Arrival_Date: formatDate(newObjectData.arrival),
                    Container_Type: newObjectData.c_type,
                    Vessel: newObjectData.vessel,
                    Status: newObjectData.status,
                    Voyage: newObjectData.voyage,
                    Booking: newObjectData.booking,
                    Seal_No: newObjectData.seal,
                    Comment: newObjectData.comment,
                    OceanFreight: parseFloat(newObjectData.oceanfreight),
                    UserEmail: UserEmail,
                    isMighal: 1,
                    isSIT: 0,
                    isAffiliate: 1,
                    File_Ref: NewFileRefNo,
                  };

                  handleInsertNewContainerRecord(newContainerRecord)
                    .then(() => {
                      getModalContainerList()
                        .then((result) => {
                          //console.log(result);
                          setDataModalGrid(result);
                          setIsLoading(false);
                          setShowModal(true);
                        })
                        .catch(() => {
                          //console.log(err);
                          setIsLoading(false);
                          alert('Something went wrong getting the temporary container');
                        });
                    })
                    .catch(() => {
                      //console.log(err);
                      setIsLoading(false);
                      alert('Unable to create a temporary container');
                    });

                  setIsLoading(false);
                })
                .catch((err) => {
                  console.log(err);
                  setIsLoading(false);
                  return alert('Something Went Wrong');
                });
            });
        } else {
          return alert(`PO is already allocated to a container`);
        }
        break;
      case 'SHIPPED':
        return alert(`PO is already allocated to ${containerIDGrid} that is already ship`);
      case 'OPEN':
        return alert(`PO needs to be received to add into a container`);
      case 'LOADING':
        {
          const confirmSelection = window.confirm(
            `PO is already allocated to ${containerIDGrid}, Do you wish to transfer into a different container?`,
          );
          if (!confirmSelection) {
            return false;
          } else {
            setIsLoading(true);
            getModalContainerList()
              .then((result) => {
                //console.log(result);
                setDataModalGrid(result);
                setIsLoading(false);
                setShowModal(true);
              })
              .catch(() => {
                //console.log(err);
                setIsLoading(false);
                alert('Please create a temporary loading container');
              });
          }
        }
        break;
      default:
        break;
    }
  };

  const getModalContainerList = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const params = {
          consignee: FormValue.dest,
          tempType: FormValue.temp,
        };
        const endpoint = fetchModalContainerList(params);
        const response = await apiFetch(endpoint).then((res) => res?.data || []);
        if (response && response.length) {
          resolve(response);
        } else {
          reject('Empty');
        }
      } catch (e) {
        //console.log(e);
        reject(e);
      }
    });
  };

  const getAttention = (consDestination) => {
    return new Promise(async (resolve, reject) => {
      try {
        let params = {
          location: consDestination,
        };
        const endpoint = getConsigneeAttention(params);
        const response = await apiFetch(endpoint).then((res) => res?.data || null);
        if (response) {
          if (Array.isArray(response) && response.length) {
            resolve(response[0]);
          } else {
            reject('Empty');
          }
        } else {
          reject('Failed');
        }
      } catch (error) {
        reject('Failed');
      }
    });
  };

  const handleInsertNewContainerRecord = (newContainerRecord) => {
    return new Promise(async (resolve, reject) => {
      const endpoint = containerCreate(newContainerRecord);
      const response = await apiFetch(endpoint).then((res) => res?.data || null);
      if (response) {
        resolve(response);
      } else {
        reject('Failed');
      }
    });
  };

  const modifiedDate = (dateValue) => {
    return new Date(momentTZ.tz(dateValue, 'Pacific/Guam'));
  };

  const handleTableChange = (type, { page }) => {
    dispatch({ type: 'SET_PO_PAGE_NUM', payload: page });
  };

  const handleSearchButton = () => {
    const queryPO = {
      ...filterValues,
      control: Number(filterValues.control),
    };

    const payload = {
      ...fullPOState,
      searchPO: queryPO,
      triggerRefresh: true,
    };
    dispatch({ type: 'SET_PO_HANDLE_SEARCH', payload });
  };

  const handleClearSearch = () => {
    setCheckPONo(false);
    setCheckTemperature(false);
    setCheckControl(false);
    setCheckDescription(false);
    setCheckVendor(false);
    setCheckComment(false);
    setFilterValues(defaultFilterValue);

    const queryPO = {
      ...defaultFilterValue,
      control: Number(filterValues.control),
    };

    const payload = {
      ...fullPOState,
      searchPO: queryPO,
      triggerRefresh: true,
    };
    dispatch({ type: 'SET_PO_HANDLE_SEARCH', payload });
  };

  const handleFilterSearch = (prop) => (ev) => {
    try {
      if (prop === 'temperature') setFilterTemperature(ev.target.value);
      setFilterValues({ ...filterValues, [prop]: ev.target.value });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className='wrapper'>
      <div className='loading-spinner'>
        <ClipLoader size={100} color={'#0054a6'} loading={isLoading} css={SpinnerStyle} />
      </div>
      <div className='help-button'>
        <a
          href='https://triplejlogistics.org/help/POMenu.html'
          target='_blank'
          rel='noopener noreferrer'
          className='btn btn-labeled btn-outline-dark btn-sm mr-1'>
          <span className='btn-label'>
            <i className='fa fa-question-circle pr-1'></i>
          </span>
          Help
        </a>
      </div>
      <Modal
        dialogClassName='modal-size'
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={showModal}
        onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>Choose Container</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='container-fluid wrapper'>
            <Row>
              <div className='container-fluid scroll-container'>
                <BootstrapTable
                  keyField='Container_ID'
                  data={dataModalGrid}
                  columns={columnsModal}
                  selectRow={selectRowPropModal}
                  striped
                  hover
                />
              </div>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant='primary' disabled={inProcess} onClick={handleAddToContainer}>
            {inProcess ? 'Processing...' : 'Save Changes'}
          </Button>
        </Modal.Footer>
      </Modal>
      <div className='generic-content-container container-fluid'>
        <h2>Purchase Order</h2>
      </div>
      <div className='container-fluid'>
        <div className='container-fluid'>
          <Row className='mb-2'>
            {permission.edit ? (
              <button
                type='button'
                className='btn btn-labeled btn-primary mr-1'
                onClick={handleNewButton}
                disabled={isDisabledBtnNew}>
                <span className='btn-label'>
                  <i className='fa fa-plus pr-1'></i>
                </span>
                New
              </button>
            ) : null}
            <button
              type='button'
              className='btn btn-labeled btn-primary mr-1'
              onClick={handleEditButton}
              disabled={isDisabledBtnEdit}>
              <span className='btn-label'>
                <i className='fa fa-edit pr-1'></i>
              </span>
              {permission.edit ? 'Edit' : 'View'}
            </button>
            {permission.edit ? (
              <button
                type='button'
                className='btn btn-labeled btn-primary mr-1'
                disabled={isDisabledBtnSave}
                onClick={handleSubmit}>
                <span className='btn-label'>
                  <i className='fa fa-check-square-o pr-1'></i>
                </span>
                Save
              </button>
            ) : null}
            <button
              type='button'
              className='btn btn-labeled btn-primary mr-1'
              onClick={handleCancelButton}
              disabled={isDisabledBtnCancel}>
              <span className='btn-label'>
                <i className='fa fa-ban pr-1'></i>
              </span>
              Cancel
            </button>
            {permission.edit ? (
              <button
                className='btn btn-primary btn-small mr-1'
                disabled={disableUploadPoBtn}
                onClick={handleOpenModal}>
                <span className='btn-label'>
                  <i className='fa fa-hand-o-right pr-1'></i>
                </span>
                Add To Container
              </button>
            ) : null}
            <Form.Check
              type='checkbox'
              checked={showDeliver}
              onChange={handleCheckedDeliver}
              id='select_poisdelivered'
              className='ml-2 mt-auto mb-auto justify-content-center h-100 align-items-center'
              label='Delivered'
            />
            <div className='d-flex flex-fill justify-content-end h6 small'>
              <label hidden={isRiderLabelVisible} className='float-right'>
                {' '}
                {PORider}{' '}
              </label>
              <label hidden={isTempLabelVisible} className='float-right'>
                {' '}
                {POTemp}{' '}
              </label>
            </div>
          </Row>
        </div>
      </div>
      <hr />
      <div
        className={`container-fluid po-form-container ${
          viewForm ? 'po-form-container--active' : 'po-form-container--inactive'
        }`}>
        <Form>
          <Row className='mb-2'>
            <Col xs={2}>
              <Form.Label>Consignee</Form.Label>
            </Col>
            <Col xs={4}>
              <Form.Control
                as='select'
                disabled={isFormDisable}
                value={FormValue.dest}
                onChange={handleChange('dest')}
                tabIndex={1}>
                {MakeConsigneeOptions(ConsLookUp)}
              </Form.Control>
            </Col>

            <Col xs={2}>
              <Form.Label>Weight</Form.Label>
            </Col>
            <Col xs={4}>
              <Form.Control
                placeholder='Weight...'
                tabIndex={9}
                disabled={isFormDisable}
                value={FormValue.weight}
                onChange={handleChange('weight')}
              />
            </Col>
          </Row>

          <Row className='mb-2'>
            <Col xs={2}>
              <Form.Label>P.O. Number</Form.Label>
            </Col>
            <Col xs={4}>
              <Form.Control
                tabIndex={2}
                autoFocus={true}
                disabled={isFormDisable}
                placeholder='P.O. Number...'
                value={FormValue.po}
                onChange={handleChange('po')}
                onBlur={updateTempPOKey}
                required
              />
            </Col>

            <Col xs={2}>
              <Form.Label>Cubic Size</Form.Label>
            </Col>
            <Col xs={4}>
              <Form.Control
                placeholder='Cu.Ft...'
                tabIndex={9}
                disabled={isFormDisable}
                value={FormValue.cube}
                onChange={handleChange('cube')}
              />
            </Col>
          </Row>

          <Row className='mb-2'>
            <Col xs={2}>
              <Form.Label>P.O. Date</Form.Label>
            </Col>
            <Col xs={4}>
              <div className=''>
                <DatePicker
                  tabIndex={3}
                  selected={modifiedDate(FormValue.po_date)}
                  onChange={handleChange('po_date')}
                  locale='en'
                  className=''
                  disabled={isFormDisable}
                />
              </div>
            </Col>

            <Col xs={2}>
              <Form.Label>Pallets</Form.Label>
            </Col>
            <Col xs={4}>
              <Form.Control
                placeholder='Pallets...'
                tabIndex={11}
                disabled={isFormDisable}
                value={FormValue.pallet}
                onChange={handleChange('pallet')}
              />
            </Col>
          </Row>

          <Row className='mb-2'>
            <Col xs={2}>
              <Form.Label>Del. Mighal</Form.Label>
            </Col>
            <Col xs={4}>
              <div className=''>
                <DatePicker
                  tabIndex={4}
                  selected={modifiedDate(FormValue.mighaletd)}
                  onChange={handleChange('mighaletd')}
                  locale='en'
                  className=''
                  disabled={isFormDisable}
                />
              </div>
            </Col>
            <Col xs={2}>
              <Form.Label>Total Pieces</Form.Label>
            </Col>
            <Col xs={4}>
              <Form.Control
                placeholder='Total Pieces...'
                tabIndex={12}
                disabled={isFormDisable}
                value={FormValue.pcs}
                onChange={handleChange('pcs')}
              />
            </Col>
          </Row>

          <Row className='mb-2'>
            <Col xs={2}>
              <Form.Label>Vendor</Form.Label>
            </Col>
            <Col xs={4}>
              <Form.Control
                tabIndex={5}
                placeholder='Vendor...'
                disabled={isFormDisable}
                value={FormValue.vendor}
                onChange={handleChange('vendor')}
                required
              />
            </Col>
            <Col xs={2}>
              <Form.Label>MRT Date</Form.Label>
            </Col>
            <Col xs={4}>
              <div className=''>
                <DatePicker
                  tabIndex={13}
                  selected={modifiedDate(FormValue.mrt_date)}
                  onChange={handleChange('mrt_date')}
                  className=''
                  disabled={isFormDisable}
                />
              </div>
            </Col>
          </Row>

          <Row className='mb-2'>
            <Col xs={2}>
              <Form.Label>Description</Form.Label>
            </Col>
            <Col xs={4}>
              <Form.Control
                tabIndex={6}
                placeholder='Description...'
                disabled={isFormDisable}
                value={FormValue.desc}
                onChange={handleChange('desc')}
                required
              />
            </Col>
            <Col xs={2}>
              <Form.Label>Control</Form.Label>
            </Col>
            <Col xs={4}>
              <Form.Control
                placeholder='Control...'
                tabIndex={14}
                disabled={isFormDisable}
                value={FormValue.control}
                onChange={handleChange('control')}
              />
            </Col>
          </Row>

          <Row className='mb-2'>
            <Col xs={2}>
              <Form.Label>Temp Type</Form.Label>
            </Col>
            <Col xs={4}>
              <Form.Control
                tabIndex={7}
                as='select'
                disabled={isFormDisable}
                value={FormValue.temp}
                onChange={handleChange('temp')}>
                <option>{`CHILL`}</option>
                <option>{`DRY`}</option>
                <option>{`FREEZE`}</option>
              </Form.Control>
            </Col>
            <Col xs={2}>
              <Form.Label>Comment</Form.Label>
            </Col>
            <Col xs={4}>
              <Form.Control
                placeholder='Comment...'
                tabIndex={15}
                disabled={isFormDisable}
                value={FormValue.comment}
                onChange={handleChange('comment')}
              />
            </Col>
          </Row>

          <Row className='mb-2'>
            <Col xs={2}>
              <Form.Label>Invoice Amount</Form.Label>
            </Col>
            <Col xs={4}>
              {!isFormDisable ? (
                <Form.Control
                  type='number'
                  placeholder='Amount...'
                  tabIndex={8}
                  disabled={isFormDisable}
                  value={FormValue.inv_amt}
                  onChange={onChangeInvAmt}
                />
              ) : (
                <Form.Control
                  type='text'
                  tabIndex={8}
                  disabled={isFormDisable}
                  value={toCurrency(FormValue.inv_amt)}
                  readOnly={true}
                />
              )}
            </Col>
            <Col xs={2}>
              <Form.Label>Status</Form.Label>
            </Col>
            <Col xs={4}>
              <Form.Control
                as='select'
                tabIndex={16}
                disabled={isFormDisable}
                value={FormValue.status}
                onChange={handleChange('status')}>
                <option>{`OPEN`}</option>
                <option>{`RECEIVED`}</option>
                <option>{`LOADING`}</option>
                <option>{`SHIPPED`}</option>
                <option>{`DELIVERED`}</option>
                <option>{`CANCEL`}</option>
              </Form.Control>
            </Col>
          </Row>

          <Row className='mb-2'>
            <Col xs={2}>
              <Form.Label></Form.Label>
            </Col>
            <Col xs={4}>
              <Form.Check
                type='checkbox'
                checked={withBuyingFee}
                onChange={() => setWithBuyingFee(!withBuyingFee)}
                disabled={isFormDisable}
                id='select_buyingfee'
                className='ml-2 mt-auto mb-auto justify-content-center h-100 align-items-center'
                label='Add Buying Charge 5%'
              />
            </Col>
          </Row>
        </Form>
      </div>

      <div className='container-fluid align-items-center'>
        <div className='font-weight-bold mb-2 text-uppercase'>Upload Your Documents:</div>
        <Row className='container-fluid align-items-center'>
          <div className='d-flex mt-auto mb-auto align-items-center pb-2'>
            <p className='mt-auto mb-auto pr-1 text-uppercase'>P.O. File</p>
            <Form.File
              id='upload_po'
              name='upload_po'
              key={Date.now()}
              filename={filePOPdf}
              disabled={disableUploadPoBtn}
              onChange={handleChangePOPDFFileInput}
            />
          </div>

          <div className='d-flex mt-auto mb-auto align-items-center pb-2'>
            <p className='mt-auto mb-auto pr-1 text-uppercase'>Island Inv.</p>
            <Form.File
              id='upload_inv'
              name='upload_inv'
              key={Date.now()}
              disabled={disableUploadInvBtn}
              onChange={handleChangeInvoicePdfFileInput}
            />
          </div>

          <div className='d-flex mt-auto mb-auto align-items-center pb-2'>
            <p className='mt-auto mb-auto pr-1 text-uppercase'>Mighal Docs</p>
            <Form.File
              id='upload_mrt'
              name='upload_mrt'
              key={Date.now()}
              filename={fileMRTPdf}
              disabled={disableUploadPoBtn}
              onChange={handleChangeMRTPDFFileInput}
            />
          </div>
        </Row>
      </div>
      <div className={!viewForm ? 'po-form-container--active' : 'po-form-container--inactive'}>
        <div className='container-fluid'>
          <Form>
            <Form.Label className='font-weight-bold mb-2 text-uppercase'>
              Filter Options:
            </Form.Label>
            <Form.Group className='mb-3 ml-3' controlId='formSearchPO'>
              <Row>
                <Col sm={1}>
                  <Form.Check
                    type='checkbox'
                    id='select_temp'
                    className='mt-auto mb-auto justify-content-center h-100 align-items-center'
                    label='Temperature'
                    checked={checkTemperature}
                    onChange={() => setCheckTemperature(!checkTemperature)}
                  />
                </Col>
                <input
                  className='radio-btn mt-auto mb-auto'
                  type='radio'
                  id='filter_temp'
                  name='filter_temp'
                  value='DRY'
                  disabled={!checkTemperature}
                  checked={filterTemperature === 'DRY'}
                  onChange={handleFilterSearch('temperature')}
                />
                <label htmlFor='filter_temp_dry' className='mt-auto mb-auto'>
                  DRY
                </label>
                <input
                  className='radio-btn mt-auto mb-auto'
                  type='radio'
                  id='filter_temp'
                  name='filter_temp'
                  value='CHILL'
                  disabled={!checkTemperature}
                  checked={filterTemperature === 'CHILL'}
                  onChange={handleFilterSearch('temperature')}
                />
                <label htmlFor='filter_temp_chill' className='mt-auto mb-auto'>
                  CHILL
                </label>
                <input
                  className='radio-btn mt-auto mb-auto'
                  type='radio'
                  id='filter_temp'
                  name='filter_temp'
                  value='FREEZE'
                  disabled={!checkTemperature}
                  checked={filterTemperature === 'FREEZE'}
                  onChange={handleFilterSearch('temperature')}
                />
                <label htmlFor='filter_temp_freeze' className='mt-auto mb-auto'>
                  FREEZE
                </label>
              </Row>
              <Row className='my-1'>
                <Col sm={1}>
                  <Form.Check
                    type='checkbox'
                    id='select_po'
                    className='mt-auto mb-auto justify-content-center h-100 align-items-center'
                    label='PO Number'
                    checked={checkPONo}
                    onChange={() => setCheckPONo(!checkPONo)}
                  />
                </Col>
                <Col sm={2}>
                  <input
                    type='string'
                    placeholder='Search PO Number'
                    disabled={!checkPONo}
                    value={filterValues.poNum}
                    onChange={handleFilterSearch('poNum')}
                  />
                </Col>
              </Row>
              <Row className='my-1'>
                <Col sm={1}>
                  <Form.Check
                    type='checkbox'
                    id='select_control'
                    className='mt-auto mb-auto justify-content-center h-100 align-items-center'
                    label='Control'
                    checked={checkControl}
                    onChange={() => setCheckControl(!checkControl)}
                  />
                </Col>
                <Col sm={2}>
                  <input
                    type='string'
                    placeholder='Search Control Number'
                    disabled={!checkControl}
                    onKeyDown={ValidateKeyPressNumber}
                    value={filterValues.controlNo}
                    onChange={handleFilterSearch('controlNo')}
                  />
                </Col>
              </Row>
              <Row className='my-1'>
                <Col sm={1}>
                  <Form.Check
                    type='checkbox'
                    id='select_vendor'
                    className='mt-auto mb-auto justify-content-center h-100 align-items-center'
                    label='Vendor'
                    checked={checkVendor}
                    onChange={() => setCheckVendor(!checkVendor)}
                  />
                </Col>
                <Col sm={2}>
                  <input
                    type='string'
                    placeholder='Search Vendor'
                    disabled={!checkVendor}
                    value={filterValues.vendor}
                    onChange={handleFilterSearch('vendor')}
                  />
                </Col>
              </Row>
              <Row className='my-1'>
                <Col sm={1}>
                  <Form.Check
                    type='checkbox'
                    id='select_description'
                    className='mt-auto mb-auto justify-content-center h-100 align-items-center'
                    label='Description'
                    checked={checkDescription}
                    onChange={() => setCheckDescription(!checkDescription)}
                  />
                </Col>
                <Col sm={2}>
                  <input
                    type='string'
                    placeholder='Search Description'
                    disabled={!checkDescription}
                    value={filterValues.description}
                    onChange={handleFilterSearch('description')}
                  />
                </Col>
              </Row>
              <Row className='my-1'>
                <Col sm={1}>
                  <Form.Check
                    type='checkbox'
                    id='select_comment'
                    className='mt-auto mb-auto justify-content-center h-100 align-items-center'
                    label='Comments'
                    checked={checkComment}
                    onChange={() => setCheckComment(!checkComment)}
                  />
                </Col>
                <Col sm={2}>
                  <input
                    type={'string'}
                    placeholder='Search Comments'
                    disabled={!checkComment}
                    value={filterValues.comments}
                    onChange={handleFilterSearch('comments')}
                  />
                </Col>
              </Row>
              <Row className='my-2'>
                <button
                  type='button'
                  className='ml-3 btn btn-labeled btn-primary btn-sm mr-1'
                  disabled={isFetchPOList}
                  onClick={handleSearchButton}>
                  <span className='btn-label'>
                    <i className={'fa fa-check pr-1'} />
                  </span>
                  Search
                </button>
                <button
                  type='button'
                  className='ml-3 btn btn-labeled btn-primary btn-sm mr-1'
                  disabled={isFetchPOList}
                  onClick={handleClearSearch}>
                  <span className='btn-label'>
                    <i className={'fa fa-undo pr-1'} />
                  </span>
                  Clear
                </button>
              </Row>
            </Form.Group>
          </Form>
        </div>
      </div>

      <hr />
      <div className='container-fluid mb-2'></div>
      <div className={!viewForm ? 'po-form-container--active' : 'po-form-container--inactive'}>
        <div className='container-fluid scroll-container'>
          {isFetchPOList ? (
            <LoadingSpinner />
          ) : PODataList && PODataList.length ? (
            <TableGrid
              keyField='id'
              data={dataInfoGrid}
              columns={columns}
              selectRow={selectRowProp}
              page={offset > 0 ? offset : 1}
              sizePerPage={limit}
              totalSize={totalPage}
              onTableChange={handleTableChange}
              showSpinner={false}
            />
          ) : (
            <div className='container-fluid wrapper'>
              <GenericEmptyTable />
            </div>
          )}
          {/*<DoubleScrollbar>*/}
          {/*<BootstrapTable*/}
          {/*  keyField='id'*/}
          {/*  data={dataInfoGrid}*/}
          {/*  columns={columns}*/}
          {/*  selectRow={selectRowProp}*/}
          {/*  filter={filterFactory()}*/}
          {/*  pagination={paginationFactory(PaginationOptions)}*/}
          {/*  cellEdit={cellEditFactory({*/}
          {/*    mode: 'dbclick',*/}
          {/*    blurToSave: true,*/}
          {/*    beforeSaveCell,*/}
          {/*    afterSaveCell,*/}
          {/*  })}*/}
          {/*  filterPosition='top'*/}
          {/*  striped*/}
          {/*  hover*/}
          {/*/>*/}
          {/*</DoubleScrollbar>*/}
        </div>
      </div>
    </div>
  );
}

export default PurchaseOrder;
