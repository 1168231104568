// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import { useStateValue } from '../context/StateProvider';
import { getPOList } from '../services/poEndpoint';
import apiFetch from '../services/apiFetch';

const genericErrorMessage =
  'We just experienced a technical issue, please wait a few seconds and try again.';

function useFetchPoList() {
  const [state, dispatch] = useStateValue();
  const [data, setData] = useState([]);
  const page = state.purchaseOrderState.page;
  const limit = state.purchaseOrderState.limit;
  const userLocation = state.userLocation;
  const searchPO = state.purchaseOrderState.searchPO;
  const isDelivered = state.purchaseOrderState.isDelivered;
  const POState = state.purchaseOrderState;
  const triggerRefresh = state.purchaseOrderState.triggerRefresh;

  useEffect(() => {
    const getData = async () => {
      dispatch({
        type: 'SET_PO_FETCHING',
        payload: {
          ...POState,
          poData: [],
          loading: true,
        },
      });
      let sendPage = 0;
      if (page !== 1) {
        sendPage = page;
      }

      const filters = {
        ...searchPO,
        isDelivered,
        userLocation,
        offset: sendPage,
        limit,
      };

      const endpoint = getPOList(filters);
      setData([]);
      apiFetch(endpoint)
        .then((res) => {
          if (res && res.status === 200) {
            const dataTable = res.data.data;
            const dataTotal = res.data.total;

            const payload = {
              ...POState,
              loading: false,
              poData: dataTable,
              pageTotal: dataTotal,
              triggerRefresh: false,
            };
            dispatch({ type: 'SET_PO_FULL_FILLED', payload });
            setData(dataTable);
          } else {
            const payload = {
              ...POState,
              loading: false,
              error: genericErrorMessage,
              poData: [],
              pageTotal: 0,
              triggerRefresh: false,
            };
            dispatch({ type: 'SET_PO_REJECT', payload });
            setData([]);
          }
        })
        .catch((err) => {
          console.log('apiFetch catch err >>>', err);
          const payload = {
            ...POState,
            loading: false,
            error: genericErrorMessage,
            poData: [],
            pageTotal: 0,
          };
          dispatch({ type: 'SET_PO_REJECT', payload });
          setData([]);
        });
    };

    getData().then(() => null);
  }, [page, isDelivered, triggerRefresh, dispatch]);

  return data;
}

export default useFetchPoList;
