/* eslint-disable no-unused-vars */
// noinspection JSUnresolvedVariable,DuplicatedCode,JSDeprecatedSymbols,JSCheckFunctionSignatures,JSValidateTypes

import React, { useEffect, useMemo, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import * as ReactBootstrap from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import _ from 'lodash';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import DatePicker from 'react-datepicker';
import ClipLoader from 'react-spinners/ClipLoader';
import apiFetch from '../services/apiFetch';
import {
  changePOEditGridView,
  getDistTempPOByContainer,
  getListByLocation,
  getPOInvPDFFile,
  getPOMRTPDFFile,
  getPOPDFFile,
  poUpdateFromEditCommand,
  resetPOPrevContainer,
  updatePOWithContainerInfo,
} from '../services/poEndpoint';
import { useStateValue } from '../context/StateProvider';
import momentTZ from 'moment-timezone';
import 'react-datepicker/dist/react-datepicker.css';
import { SpinnerStyle } from '../styles/SpinnerStyle';
import DoubleScrollbar from 'react-double-scrollbar';
import {
  containerCreate,
  containerUpdate,
  // containerUploadOcPDF,
  getContainerStatusPDFDocs,
  getDocPDF,
  getLoadPlanPDF,
  getPendingOceanFreight,
  isContainerExist,
  loadPlanShippedOutPDF,
} from '../services/containerEndpoint';
import { sendLoadPlan } from '../services/emailEndpoint';
import { getBillingCharges, getBillingInfo } from '../services/billingEndpoint';
import {
  consigneeGetAddress,
  consigneeGetList,
  getConsigneeAttention,
  getConsigneeList,
  getLocationCode,
} from '../services/consigneeEndpoint';
import { getSettings } from '../services/settingsEndpoint';
import Axios from 'axios';
import {
  getDOCSFileLinkS3,
  getDocumentStatus,
  getMRTFileLinkS3,
  getPOFileLinkS3,
} from '../services/documentEndpoint';
import DocType from '../common/enums/DocType';
import docType from '../common/enums/DocType';

function MighalContainer() {
  const [state, dispatch] = useStateValue();
  const [isRender, SetIsRender] = useState(true);
  const [ChillStatus, SetChillStatus] = useState(true);
  const [DryStatus, SetDryStatus] = useState(true);
  const [FrzStatus, SetFrzStatus] = useState(true);
  const [isAffiliate, setIsAffiliate] = useState(state.containerLoadPlanForm.isAffiliate);
  const [isFormDisable, setIsFormDisable] = useState(true);
  const [FormValue, SetFormValue] = useState(state.containerLoadPlanForm);
  const [isNew, setIsNew] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isFilterAffiliateDisabled, setIsFilterAffiliateDisabled] = useState(false);
  const [isDisabledBtnGetPO, setIsDisabledBtnGetPO] = useState(true);
  const [isDisabledBtnNew, setIsDisabledBtnNew] = useState(false);
  const [isDisabledBtnEdit, setIsDisabledBtnEdit] = useState(false);
  const [isDisabledBtnSave, setIsDisabledBtnSave] = useState(true);
  const [isDisabledBtnCancel, setIsDisabledBtnCancel] = useState(true);
  const [isDisabledBtnShippedOut, setIsDisabledBtnShippedOut] = useState(true);
  const [isDisabledBtnLoadPlan, setIsDisabledBtnLoadPlan] = useState(false);
  const [isDisabledViewDoc1, setIsDisabledViewDoc1] = useState(true);
  const [isDisabledViewDoc2, setIsDisabledViewDoc2] = useState(true);
  const [isDisabledViewDoc3, setIsDisabledViewDoc3] = useState(true);
  const [isDisabledBtnEmail, setIsDisabledBtnEmail] = useState(true);
  const [deptDate, setDeptDate] = useState(FormValue.depart);
  const [arrivalDate, setArrivalDate] = useState(FormValue.arrival);
  const [dataInfoGrid, SetDataInfoGrid] = useState([]);
  const [consigneeData, setConsigneeData] = useState([]);
  const [Pdf1, SetPdf1File] = useState(null);
  const [Pdf2, SetPdf2File] = useState(null);
  const [Pdf3, SetPdf3File] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPO, setSelectedPO] = useState([]);
  const [PoListQuery, setPoListQuery] = useState([]);
  const [totalPOWeight, setTotalPOWeight] = useState(0);
  const [totalPOCube, setTotalPOCube] = useState(0);
  const [totalInvAmt, setTotalInvAmt] = useState(0);
  const [isSIT, setIsSIT] = useState(state.containerLoadPlanForm.isSIT);
  const [showModal, setShowModal] = useState(false);
  const [dataModalGrid, setDataModalGrid] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  // const [offset, setOffset] = useState(0);
  // const [totalPage, setTotalPage] = useState(0);
  const [queryPOFilter, setQueryPOFilter] = useState(null);
  const limit = 10;
  const history = useHistory();

  const userLoc = state.userLocation;
  const userAccess = localStorage.getItem('logisticsUserType');
  const permission = useMemo(() => ({ edit: userAccess !== 'User' }), [userAccess]);
  const isUserMighalDEV = useMemo(
    () => userLoc === 'TJ MIGHAL' || userLoc === 'DEVELOPMENT TEAM',
    [userLoc],
  );

  useEffect(() => {
    const getDistinctTempPoByContainer = () => {
      return new Promise(async (resolve, reject) => {
        try {
          const params = {
            containerId: FormValue.containerId,
          };
          const endpoint = getDistTempPOByContainer(params);
          const response = await apiFetch(endpoint);
          if (response.data !== 'Failed') {
            resolve(response.data);
          } else {
            reject('Failed');
          }
        } catch (e) {
          console.log(e);
          reject('Failed');
        }
      });
    };

    const getLookUpData = () => {
      return new Promise(async (resolve, reject) => {
        try {
          let params = {
            isAffiliate: isAffiliate ? 1 : 0,
          };
          const endpoint = consigneeGetList(params);
          const response = await apiFetch(endpoint);

          if (response.data) {
            let data = response.data;
            if (data && data.length) {
              let filteredArray = [];
              filteredArray.push('SELECT CONSIGNEE');
              data.map((value) => {
                filteredArray.push(value.Consignee_Name);
              });

              setConsigneeData(filteredArray);
              resolve('Done');
            }
          } else {
            reject('Failed');
          }
        } catch (e) {
          console.log('TryCatch getNonAffiliateOnlyLookup', e);
        }
      });
    };

    const Get_PendingOceanFreight = () => {
      return new Promise(async (resolve, reject) => {
        try {
          const endpoint = getPendingOceanFreight();
          const response = await apiFetch(endpoint);

          if (response && response.data) {
            let arr = response.data;
            if (Array.isArray(arr) && arr.length > 0) {
              resolve(arr);
            } else {
              reject('Empty');
            }
          } else {
            reject('Empty');
          }
        } catch (e) {
          console.log(e);
          reject('Failed');
        }
      });
    };

    if (isRender) {
      getLookUpData().catch((err) => {
        console.log(err);
      });

      if (FormValue.containerId !== 0) {
        //Check if ShippedOut Report is valid
        let IsShippedOutReady = FormValue.status !== 'Shipped';
        setIsDisabledBtnShippedOut(IsShippedOutReady);
        setIsDisabledBtnLoadPlan(!IsShippedOutReady);
        setIsFilterAffiliateDisabled(true);
        getDistinctTempPoByContainer()
          .then((result) => {
            let tempQuery, poQuery;
            let ChillInitValue = false;
            let DryInitValue = false;
            let FrzInitValue = false;

            if (result && result.length > 0) {
              tempQuery = '(';
              for (const d of result) {
                //console.log(d.Temperature.trim());
                switch (d.Temperature) {
                  case 'CHILL':
                    ChillInitValue = true;
                    break;
                  case 'DRY':
                    DryInitValue = true;
                    break;
                  case 'FREEZE':
                    FrzInitValue = true;
                    break;
                  default:
                    break;
                }
                tempQuery += `'${d.Temperature}',`;
              }
              SetChillStatus(ChillInitValue);
              SetDryStatus(DryInitValue);
              SetFrzStatus(FrzInitValue);
              tempQuery += ')';
              poQuery = tempQuery.slice(0, -2) + ')';
              setQueryPOFilter(poQuery);

              // Get Status PDF Docs
              getStatusPDFDocs(FormValue.containerId)
                .then((result) => {
                  setIsDisabledViewDoc1(!result.DocFile1);
                  setIsDisabledViewDoc2(!result.DocFile2);
                  setIsDisabledViewDoc3(!result.DocFile3);

                  // Check if Container is Ready for Email
                  let HasOFRT = FormValue.oceanfreight > 0 ? 1 : 0;
                  let HasZeroInvAmt = FormValue.HasZeroInvAmt;
                  let HasMighalInvoice = FormValue.InvoiceNo.length > 0 ? 1 : 0;
                  let HasBooking = FormValue.booking.length > 0 ? 1 : 0;
                  let HasSealNo = FormValue.seal.length > 0 ? 1 : 0;
                  let HasDocFile1 = result.DocFile2;
                  let isLogistics = state.userLocation === 'TJ MIGHAL' ? 1 : 0;

                  if (
                    HasOFRT &&
                    !HasZeroInvAmt &&
                    HasMighalInvoice &&
                    HasBooking &&
                    HasSealNo &&
                    !HasDocFile1
                  ) {
                    setIsDisabledBtnEmail(false);
                  } else if (isLogistics) {
                    setIsDisabledBtnEmail(false);
                  } else {
                    setIsDisabledBtnEmail(true);
                  }
                })
                .catch((err) => {
                  console.log('getStatusPDFDocs', err);
                });
              // Get pending Ocean Freight Data
              Get_PendingOceanFreight()
                .then((result) => {
                  setDataModalGrid(result);
                  setShowModal(true);
                })
                .catch((err) => {
                  console.log(err);
                  setDataModalGrid([]);
                  setShowModal(false);
                });
            }
          })
          .catch((err) => {
            console.log('getDistinctTempPoByContainer', err);
          });
      }
    }
    return () => {
      SetIsRender(false);
    };
  }, [isRender]); //[isRender, FormValue, PoListQuery]);

  useEffect(() => {
    if (!isFetching && !_.isEmpty(queryPOFilter)) {
      loadPOGridByLocation(queryPOFilter)
        .then((result) => {
          let arrTemp = [];
          let arrQryTemp = [];
          let sumPOWeight = 0;
          let sumPOCube = 0;
          let sumInvAmt = 0;
          for (const iterator of result) {
            //console.log(iterator.contId);
            if (iterator.contId === FormValue.containerId && FormValue.containerId > 0) {
              arrTemp.push(iterator.id);
              arrQryTemp.push(iterator.po);
              sumPOWeight += Number(iterator.weight);
              sumPOCube += Number(iterator.cube);
              sumInvAmt += Number(iterator.inv_amt);
            }
            //console.log(iterator.id);
          }
          setTotalPOWeight(sumPOWeight);
          setTotalPOCube(sumPOCube);
          setTotalInvAmt(sumInvAmt);
          setSelectedPO(arrTemp);
          setPoListQuery(arrQryTemp);
          let filteredData = result.filter((data) => {
            return data.contId === FormValue.containerId;
          });

          if (FormValue.status === 'Shipped') {
            SetDataInfoGrid(filteredData);
          } else if (FormValue.status === 'Delivered') {
            SetDataInfoGrid(filteredData);
          } else {
            SetDataInfoGrid(result);
          }
          SetIsRender(false);
        })
        .catch(() => {
          setSelectedPO([]);
          SetDataInfoGrid([]);
        });
    }
  }, [queryPOFilter]);

  let errors = [];
  // let ConsLookUp = JSON.parse(localStorage.getItem('logisticsConsignee'));

  if (!state.isLogin) return <Redirect to='/login' />;
  if (state.isLogin && state.newUser) return <Redirect to='/change-password' />;

  const initForm = {
    containerId: 0,
    container: '',
    c_type: '1X20 REEFER',
    l_type: 'CHILL',
    vessel: '',
    voyage: '',
    booking: '',
    attention: '',
    emailTo: '',
    destination: 'SELECT CONSIGNEE',
    location: '',
    depart: new Date(),
    arrival: new Date(),
    seal: '',
    status: 'Loading',
    available_cu: 0,
    available_lbs: 0,
    createDte: new Date(),
    comment: '',
    oceanfreight: 0,
    isSIT: false,
    file_ref: '',
    isAffiliate: true,
    HasZeroInvAmt: false,
    InvoiceNo: '',
  };

  const getStatusPDFDocs = (ContID) => {
    return new Promise(async (resolve, reject) => {
      try {
        const params = { containerId: ContID };
        const endpoint = getContainerStatusPDFDocs(params);
        const response = await apiFetch(endpoint);

        if (response.data) {
          resolve(response.data);
        } else {
          reject('Failed');
        }
      } catch (e) {
        console.log(e);
        reject('Failed');
      }
    });
  };

  // const clearFilter = () => {
  //   poFilter('');
  //   poStatusFilter('');
  //   vendorFilter('');
  // };

  const MakeConsigneeOptions = (data) => {
    return (
      data &&
      data.map((cons, index) => (
        <option key={index} value={cons}>
          {cons}
        </option>
      ))
    );
  };

  const gridButtonPOPDF = (cell, row) => {
    let pdfStatus = row.po_pdf;
    return (
      <div>
        <button
          className='btn btn-sm btn-primary'
          onClick={viewPOPdfFile(row.id, row.po)}
          disabled={!pdfStatus}>
          View
        </button>
      </div>
    );
  };

  const gridButtonInvPDF = (cell, row) => {
    let pdfStatus = row.inv_pdf;
    return (
      <div>
        <button
          className='btn btn-sm btn-danger'
          onClick={viewPOInvPdfFile(row.id, row.po)}
          disabled={!pdfStatus}>
          View
        </button>
      </div>
    );
  };

  const gridButtonMrtPDF = (cell, row) => {
    let pdfStatus = row.mrt_pdf;
    return (
      <div>
        <button
          className='btn btn-sm btn-secondary'
          onClick={handleViewMrtPdfFile(row.id)}
          disabled={!pdfStatus}>
          View
        </button>
      </div>
    );
  };

  const formatNumber = (number) => {
    const fixedNumber = Number.parseFloat(number === null ? 0 : number).toFixed(2);
    return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const formatInvAmt = (cell) => {
    return formatNumber(cell);
  };

  const formatINTNumber = (number) => {
    const fixedNumber = Number.parseInt(number === null ? 0 : number);
    return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const formatFixNumber = (cell) => {
    return formatINTNumber(cell);
  };

  const toCurrency = (number) => {
    try {
      const formatter = Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(number);
    } catch (error) {
      console.log(error);
    }
  };

  const formatDate = (valueDate) => {
    let d = new Date(valueDate),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const formatDateSQL = (valueDate) => {
    let d = new Date(valueDate),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('/');
  };

  const columns = [
    {
      dataField: 'id',
      text: 'PO ID',
      hidden: true,
    },
    {
      dataField: 'po_status',
      text: 'Status',
      headerStyle: () => {
        return { width: '95px', textAlign: 'center' };
      },
      filter: textFilter(),
      editable: false,
    },
    {
      dataField: 'shipper',
      text: 'Vendor',
      headerStyle: () => {
        return { width: '150px', textAlign: 'center' };
      },
      filter: textFilter(),
      editable: false,
    },
    {
      dataField: 'po',
      text: 'Purchase Order',
      headerStyle: () => {
        return { width: '150px', textAlign: 'center' };
      },
      filter: textFilter(),
      editable: false,
    },
    {
      dataField: 'desc',
      text: 'Description',
      headerStyle: () => {
        return { width: '150px', textAlign: 'center' };
      },
      editable: false,
    },
    {
      dataField: 'pallet',
      text: 'PLTS',
      align: 'right',
      headerStyle: () => {
        return { width: '70px', textAlign: 'center' };
      },
      editable: true,
    },
    {
      dataField: 'pcs',
      text: 'QTY',
      align: 'right',
      headerStyle: () => {
        return { width: '70px', textAlign: 'center' };
      },
      editable: true,
    },
    {
      dataField: 'weight',
      text: 'LBS',
      align: 'right',
      headerStyle: () => {
        return { width: '70px', textAlign: 'center' };
      },
      editable: true,
      formatter: formatFixNumber,
    },
    {
      dataField: 'cube',
      text: 'CFT',
      align: 'right',
      headerStyle: () => {
        return { width: '70px', textAlign: 'center' };
      },
      editable: true,
      formatter: formatFixNumber,
    },
    {
      dataField: 'control',
      text: 'Control',
      align: 'center',
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
      editable: true,
      filter: textFilter(),
    },
    {
      dataField: 'comment',
      text: 'Comments',
      headerStyle: () => {
        return { width: '120px', textAlign: 'center' };
      },
      editable: true,
    },
    {
      dataField: 'inv_amt',
      text: 'Invoice Amount',
      align: 'right',
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
      formatter: formatInvAmt,
      editable: true,
    },
    {
      dataField: 'po_pdf',
      text: 'PO PDF',
      headerStyle: () => {
        return { width: '90px', textAlign: 'center' };
      },
      formatter: gridButtonPOPDF,
      editable: false,
    },
    {
      dataField: 'inv_pdf',
      text: 'TJ INV',
      headerStyle: () => {
        return { width: '90px', textAlign: 'center' };
      },
      formatter: gridButtonInvPDF,
      editable: false,
    },
    {
      dataField: 'mrt_pdf',
      text: 'MIG DOCS',
      headerStyle: () => {
        return { width: '90px', textAlign: 'center' };
      },
      formatter: gridButtonMrtPDF,
      editable: false,
    },
  ];

  const columnsModal = [
    {
      dataField: 'Container_ID',
      text: 'CONT ID',
      hidden: true,
    },
    {
      dataField: 'Attention',
      text: 'Attention',
      hidden: true,
    },
    {
      dataField: 'Email',
      text: 'Email',
      hidden: true,
    },
    {
      dataField: 'Container_Type',
      text: 'Container Type',
      hidden: true,
    },
    {
      dataField: 'Seal_No',
      text: 'Seal',
      hidden: true,
    },
    {
      dataField: 'Comment',
      text: 'Comment',
      hidden: true,
    },
    {
      dataField: 'Temperature_Type',
      text: 'Temperature_Type',
      hidden: true,
    },
    {
      dataField: 'Billing_ID',
      text: 'Print PDF',
      hidden: true,
    },
    {
      dataField: 'Consignee',
      text: 'Consignee',
      headerStyle: () => {
        return { width: '150px', textAlign: 'center' };
      },
    },
    {
      dataField: 'File_Ref',
      text: 'File Ref',
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
    },
    {
      dataField: 'Invoice_No',
      text: 'Invoice No.',
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
    },
    {
      dataField: 'Container_No',
      text: 'Container No',
      headerStyle: () => {
        return { width: '200px', textAlign: 'center' };
      },
    },
    {
      dataField: 'Booking',
      text: 'Booking',
      headerStyle: () => {
        return { width: '120px', textAlign: 'center' };
      },
    },
    {
      dataField: 'Vessel',
      text: 'Vessel',
      headerStyle: () => {
        return { width: '120px', textAlign: 'center' };
      },
    },
    {
      dataField: 'Voyage',
      text: 'Voyage',
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
    },
    {
      dataField: 'Departure_Date',
      text: 'Departure',
      align: 'center',
      headerStyle: () => {
        return { width: '120px', textAlign: 'center' };
      },
    },
    {
      dataField: 'Arrival_Date',
      text: 'Arrival',
      align: 'center',
      headerStyle: () => {
        return { width: '120px', textAlign: 'center' };
      },
    },
  ];

  const beforeSaveCell = (oldValue, newValue, row, column, done) => {
    setTimeout(() => {
      if (state.userLocation !== 'TJ MIGHAL' && state.userLocation !== 'DEVELOPMENT TEAM') {
        alert('User Account not allowed to edit');
        done(false);
      } else {
        done();
      }
    }, 0);
    return { async: true };
  };

  const afterSaveCell = (oldValue, newValue, column, row) => {
    //console.log({ oldValue });

    try {
      let colField = row.dataField;
      let POID = column.id;
      let sqlColumn = '';
      let NewData;
      switch (colField) {
        case 'pallet':
          sqlColumn = 'Pallets';
          NewData = parseInt(newValue);
          break;
        case 'pcs':
          sqlColumn = 'Total_Pieces';
          NewData = parseInt(newValue);
          break;
        case 'weight':
          sqlColumn = 'Weight';
          NewData = parseInt(newValue);
          break;
        case 'cube':
          sqlColumn = 'PO_Cube';
          NewData = parseInt(newValue);
          break;
        case 'control':
          sqlColumn = 'Control';
          NewData = parseInt(newValue);
          break;
        case 'inv_amt':
          sqlColumn = 'Invoice_Amount';
          NewData = parseFloat(newValue);
          break;
        case 'comment':
          sqlColumn = 'Comments';
          NewData = newValue.trim().toUpperCase();
          break;
        default:
          break;
      }

      if (oldValue !== NewData && sqlColumn !== '') {
        setIsLoading(true);
        /* console.log({ NewData });
        console.log({ sqlColumn }); */
        updatePOGridInput(POID, sqlColumn, NewData)
          .then(() => {
            setIsLoading(false);

            let sumPOWeight = 0;
            let sumPOCube = 0;
            let sumPOInvAmt = 0;
            for (const iterator of dataInfoGrid) {
              if (iterator.contId === FormValue.containerId) {
                sumPOWeight = sumPOWeight + parseInt(iterator.weight);
                sumPOCube = sumPOCube + parseInt(iterator.cube);
                sumPOInvAmt = sumPOInvAmt + parseFloat(iterator.inv_amt);
              }
            }
            setTotalPOWeight(sumPOWeight);
            setTotalPOCube(sumPOCube);
            setTotalInvAmt(sumPOInvAmt);
          })
          .catch(() => {
            setIsLoading(false);
            alert('Unable to update server record');
          });
      }
    } catch (e) {
      console.log(e);
      setIsLoading(false);
      alert('System Error');
    }
  };

  const updatePOGridInput = (PO_ID, sqlColumn, NewData) => {
    return new Promise(async (resolve, reject) => {
      try {
        let bodyData = {
          UserEmail: localStorage.getItem('logisticsUseremail'),
          PO_ID,
          sqlColumn,
          NewData,
        };
        const endpoint = changePOEditGridView(bodyData);
        const response = await apiFetch(endpoint);
        if (response.data === 'Success') {
          resolve(response.data);
        } else {
          reject('Failed');
        }
      } catch (e) {
        console.log(e);
        reject('Failed');
      }
    });
  };

  const applyDefaultBtnStatusState = () => {
    SetFormValue(initForm);
    setDeptDate(new Date());
    setArrivalDate(new Date());
    setIsFormDisable(true);
    setIsNew(false);
    setIsEdit(false);
    setIsDisabledBtnNew(false);
    setIsDisabledBtnEdit(false);
    setIsDisabledBtnSave(true);
    setIsDisabledBtnCancel(true);
    setIsDisabledBtnGetPO(true);
    setIsDisabledViewDoc1(true);
    setIsDisabledViewDoc2(true);
    setIsDisabledViewDoc3(true);
    setTotalPOWeight(0);
    setTotalPOCube(0);
    setTotalInvAmt(0);
  };

  const getLastRecords = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoint = getSettings();
        const responseSettings = await apiFetch(endpoint);
        if (responseSettings.data) {
          let resData = responseSettings.data[0];
          // console.log(resData);
          resolve(resData);
        } else {
          reject('Empty');
        }
      } catch (e) {
        console.log(e);
        reject('Empty');
      }
    });
  };

  const handleNewButton = () => {
    if (state.userLocation !== 'TJ MIGHAL' && state.userLocation !== 'DEVELOPMENT TEAM') {
      return alert('User Account not allowed to edit');
    }

    getLastRecords()
      .then((result) => {
        let lastRecordFileRef = parseInt(result.LastFileRefNo) + 1;
        let strDate = new Date();
        let shortYear = strDate.getFullYear();
        let twoDigitYear = shortYear.toString().substr(-2);
        let NewFileRefNo = `M${twoDigitYear}-${lastRecordFileRef}`;

        let initializeRecord = {
          ...initForm,
          file_ref: NewFileRefNo,
          isAffiliate,
        };

        SetFormValue(initializeRecord);
        setIsFilterAffiliateDisabled(false);
        setDeptDate(new Date());
        setArrivalDate(new Date());
        setIsFormDisable(false);
        setIsNew(true);
        setIsEdit(false);
        setIsDisabledBtnEdit(true);
        setIsDisabledBtnSave(false);
        setIsDisabledBtnCancel(false);
        setIsDisabledBtnGetPO(false);
        setIsDisabledViewDoc1(true);
        setIsDisabledViewDoc2(true);
        setIsDisabledViewDoc3(true);
        setTotalPOWeight(0);
        setTotalPOCube(0);
        setTotalInvAmt(0);
        SetDataInfoGrid([]);
        setSelectedPO([]);
        setPoListQuery([]);
        dispatch({ type: 'POPULATE_LOAD_PLAN', payload: initializeRecord });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleCancelButton = () => {
    if (!isFormDisable) {
      SetFormValue(initForm);
      //setIsFilterAffiliateDisabled(false);
      setIsFormDisable(true);
      SetDataInfoGrid([]);
      applyDefaultBtnStatusState();
      dispatch({ type: 'POPULATE_LOAD_PLAN', payload: initForm });
    }
  };

  const handleEditButton = () => {
    if (state.userLocation !== 'TJ MIGHAL' && state.userLocation !== 'DEVELOPMENT TEAM') {
      return alert('User Account not allowed to edit');
    }

    if (FormValue.container !== '') {
      setIsFormDisable(false);
      setIsEdit(true);
      setIsNew(false);
      setIsDisabledBtnNew(true);
      setIsDisabledBtnSave(false);
      setIsDisabledBtnCancel(false);
      setIsDisabledBtnGetPO(false);
    }
  };

  const handleChange = (prop) => (ev) => {
    try {
      SetFormValue({ ...FormValue, [prop]: ev.target.value });
      if (prop === 'destination') {
        let inputValue = ev.target.value;
        let consDestination = ev.target.value;
        //console.log(consDestination);
        getAttention(consDestination)
          .then(async (result) => {
            let deptDte = formatDate(deptDate);
            let tempType = FormValue.l_type;
            let newTempContName = `${result.LocationCode}-${tempType}-${deptDte}`;

            // Check if Container No Exist
            let params = {
              containerNo: newTempContName,
            };
            const endpoint = isContainerExist(params);
            const response = await apiFetch(endpoint);

            if (typeof response.data === 'number') {
              let currentCount = response.data;
              newTempContName = `${newTempContName}-${currentCount + 1}`;
            }
            let newObjectAttention;

            //SetTempContName(newTempContName);
            newObjectAttention = {
              ...FormValue,
              attention: result.Attention,
              destination: inputValue,
              location: result.LocationCode,
              emailTo: result.User_email,
              container: newTempContName,
            };
            SetFormValue(newObjectAttention);
          })
          .catch(async (err) => {
            console.log(err);
            //console.log(consDestination);
            // Get Location Code
            let params = {
              consignee: consDestination,
            };
            const endpoint = getLocationCode(params);
            const resLocCode = await apiFetch(endpoint);

            if (resLocCode.data) {
              let LocCode = resLocCode.data;
              let deptDte = formatDate(deptDate);
              let tempType = FormValue.l_type;
              let newTempContName = `${LocCode}-${tempType}-${deptDte}`;

              // If ContNo Exist, Get current count
              const params = {
                containerNo: newTempContName,
              };
              const endpoint = isContainerExist(params);
              const contCount = await apiFetch(endpoint);

              if (typeof contCount.data === 'number') {
                let currentCount = contCount.data;
                newTempContName = `${newTempContName}-${currentCount + 1}`;
                let objectData = {
                  ...FormValue,
                  attention: '',
                  destination: inputValue,
                  container: newTempContName,
                  location: LocCode,
                  emailTo: '',
                };
                SetFormValue(objectData);
              }
            }
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAttention = (consDestination) => {
    return new Promise(async (resolve, reject) => {
      try {
        const params = {
          location: consDestination,
        };
        const endpoint = getConsigneeAttention(params);
        const response = await apiFetch(endpoint);

        if (response) {
          let data = response.data;
          if (Array.isArray(data) && data.length) {
            const obj = data[0];
            resolve(obj);
          } else {
            reject('Empty Attention');
          }
        } else {
          reject('Empty Attention');
        }
      } catch (error) {
        reject('Failed');
      }
    });
  };

  const handleChecked = (prop) => () => {
    if (prop === 'chill') {
      SetChillStatus(!ChillStatus);
    }
    if (prop === 'dry') {
      SetDryStatus(!DryStatus);
    }
    if (prop === 'frz') {
      SetFrzStatus(!FrzStatus);
    }
  };

  const handleCheckedSIT = () => setIsSIT(!isSIT);

  const removePOSelected = (arr, value) => {
    let index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  };

  const handleOnSelect = (row, isSelect) => {
    if (state.userLocation !== 'TJ MIGHAL' && state.userLocation !== 'DEVELOPMENT TEAM') {
      alert('User Account not allowed to edit');
      return false;
    }

    let POTempType = row.po.substring(1, 2);
    let ContainerTempType = FormValue.l_type.substring(0, 1);
    if (POTempType !== ContainerTempType) {
      let confirmSelection = window.confirm(
        `Selected PO ${row.po} is not a ${FormValue.l_type} item for this container. Do you wish to proceed?`,
      );
      if (!confirmSelection) {
        return false;
      }
    }

    if (!isFormDisable) {
      let currentPOWeight = totalPOWeight;
      let currentPOCube = totalPOCube;
      let currentTtlInvAmt = totalInvAmt;
      if (isSelect) {
        // console.log(row);
        let currentSelect = selectedPO;
        let prevPOListQuery = PoListQuery;
        setSelectedPO([...currentSelect, row.id]);
        setPoListQuery([...prevPOListQuery, row.po]);
        setTotalPOWeight(currentPOWeight + parseInt(row.weight));
        setTotalPOCube(currentPOCube + parseInt(row.cube));
        setTotalInvAmt(currentTtlInvAmt + parseFloat(row.inv_amt));
      } else {
        let newArray = removePOSelected(selectedPO, row.id);
        let newPOListArray = removePOSelected(PoListQuery, row.po);
        //console.log({ newArray });
        setSelectedPO(newArray);
        setPoListQuery(newPOListArray);
        setTotalPOWeight(currentPOWeight - parseInt(row.weight));
        setTotalPOCube(currentPOCube - parseInt(row.cube));
        setTotalInvAmt(currentTtlInvAmt - parseFloat(row.inv_amt));
      }
    }
  };

  const handleOnSelectAll = (isSelect, rows) => {
    //console.log(rows);
    if (state.userLocation !== 'TJ MIGHAL' && state.userLocation !== 'DEVELOPMENT TEAM') {
      return alert('User Account not allowed to edit');
    }

    if (!isFormDisable) {
      if (isSelect) {
        const SumPoWeight = rows.reduce((acc, row) => acc + Number(row.weight), 0);
        const SumPoCube = rows.reduce((acc, row) => acc + Number(row.cube), 0);
        const SumInvAmt = rows.reduce((acc, row) => acc + Number(row.inv_amt), 0);
        const ids = rows.map((r) => r.id);
        const POs = rows.map((r) => r.po);
        setTotalPOWeight(SumPoWeight);
        setTotalPOCube(SumPoCube);
        setTotalInvAmt(SumInvAmt);
        setSelectedPO(ids);
        setPoListQuery(POs);
      } else {
        setTotalPOWeight(0);
        setTotalPOCube(0);
        setTotalInvAmt(0);
        setSelectedPO([]);
        setPoListQuery([]);
      }
    }
  };

  const selectRowProp = {
    mode: 'checkbox',
    clickToSelect: false,
    selected: selectedPO,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
  };

  const handleGetPoList = (ev) => {
    ev.preventDefault();
    let tempArray = [];
    setSelectedPO([]);
    if (ChillStatus) {
      tempArray.push('CHILL');
    }
    if (DryStatus) {
      tempArray.push('DRY');
    }
    if (FrzStatus) {
      tempArray.push('FREEZE');
    }

    //console.log(tempArray);
    let tempQuery, finalQuery;

    if (tempArray.length > 0) {
      tempQuery = '(';
      for (let tmp in tempArray) {
        tempQuery += `'${tempArray[tmp]}',`;
      }
      tempQuery += ')';
      finalQuery = tempQuery.slice(0, -2) + ')';
    } else {
      finalQuery = `('CHILL','DRY','FREEZE')`;
    }

    loadPOGridByLocation(finalQuery)
      .then((result) => {
        //console.log(result);
        if (result.length) {
          let arrTemp = [];
          let arrQryTemp = [];
          let sumPOWeight = 0;
          let sumPOCube = 0;
          let sumInvAmt = 0;
          for (const iterator of result) {
            if (iterator.contId === FormValue.containerId && FormValue.containerId > 0) {
              arrTemp.push(iterator.id);
              arrQryTemp.push(iterator.po);
              sumPOWeight += Number(iterator.weight);
              sumPOCube += Number(iterator.cube);
              sumInvAmt += Number(iterator.inv_amt);
            }
          }
          setTotalPOWeight(sumPOWeight);
          setTotalPOCube(sumPOCube);
          setTotalInvAmt(sumInvAmt);
          setSelectedPO(arrTemp);
          setPoListQuery(arrQryTemp);
          SetDataInfoGrid(result);
        } else {
          alert(`There is NO available PO for loading from the Consignee ${FormValue.destination}`);
        }
      })
      .catch((err) => {
        console.log(err);
        SetDataInfoGrid([]);
      });
  };

  const loadPOGridByLocation = (getPoQuery) => {
    return new Promise(async (resolve, reject) => {
      //console.log(getPoQuery);
      try {
        if ((FormValue.destination !== '' || undefined) && getPoQuery !== '') {
          setIsFetching(true);
          const filters = {
            temperature: getPoQuery,
            containerId: FormValue.containerId,
            isDelivered: FormValue.status === 'Delivered',
            isNew,
            offset: 0,
            limit,
          };
          const endpoint = getListByLocation(FormValue.destination, filters);
          const response = await apiFetch(endpoint).then((res) => res || null);

          if (response.data) {
            setIsFetching(false);
            // let { data } = response.data;
            if (response.data && response.data.length) {
              // setTotalPage(response.data.total);
              resolve(response.data);
            } else {
              // setTotalPage(0);
              reject([]);
            }
          } else {
            setIsFetching(false);
            reject([]);
          }
        }
      } catch (error) {
        setIsFetching(false);
        console.log(error);
      }
    });
  };

  const validate = () => {
    return new Promise((resolve) => {
      if (FormValue.container === '') {
        errors.push('You must provide a Container No.');
      }

      if (FormValue.emailTo === '' && isAffiliate) {
        errors.push('There is no valid GM Email address');
      }

      if (FormValue.status === 'Shipped') {
        if (FormValue.vessel === '') {
          errors.push('You must provide Vessel Name for In-Transit to Destination');
        }
        if (FormValue.voyage === '') {
          errors.push('You must provide Voyage No. for In-Transit to Destination');
        }
        if (FormValue.booking === '') {
          errors.push('You must provide Booking No. for In-Transit to Destination');
        }
        if (FormValue.attention === '' && isAffiliate) {
          errors.push(
            'You must provide attention to the receiving personel for In-Transt to Destination',
          );
        }
        if (FormValue.seal === '') {
          errors.push('You must provide Seal No. for In-Transt to Destination');
        }
      }

      resolve();
    });
  };

  const updateSelectedPOWithContainerInfo = (ContID, ContStatus) => {
    return new Promise(async (resolve, reject) => {
      try {
        //console.log({ selectedPO });
        let inString = '(';
        for (const iterator of selectedPO) {
          //console.log(iterator);
          inString += `${iterator},`;
        }
        inString += ')';

        let queryStr = inString.substring(0, inString.length - 2) + ')';

        //console.log(selectedPO);
        let dataBody = {
          Container_ID: ContID,
          Container_No: FormValue.container,
          Container_Status: ContStatus,
          PO_Id: queryStr,
          SelectedPO: selectedPO,
          UserEmail: localStorage.getItem('logisticsUseremail'),
        };
        const endpoint = updatePOWithContainerInfo(dataBody);
        const response = await apiFetch(endpoint).then((res) => res || null);
        if (response) {
          resolve('Success');
        } else {
          reject('Failed');
        }
      } catch (error) {
        console.log(error);
        reject('Error');
      }
    });
  };

  const UpdatePOListRemoveAll = (ContID) => {
    return new Promise(async (resolve, reject) => {
      try {
        let dataBody = {
          Container_ID: ContID,
          PO_Id: `('')`,
          UserEmail: localStorage.getItem('logisticsUseremail'),
        };
        const endpoint = resetPOPrevContainer(dataBody);
        const response = await apiFetch(endpoint).then((res) => res || null);

        if (response.data === 'Done') {
          resolve('Success');
        } else {
          reject('Failed');
        }
      } catch (e) {
        console.log(e);
        reject('Failed Update');
      }
    });
  };

  const UpdateEditPOList = (ContID) => {
    return new Promise(async (resolve, reject) => {
      try {
        let inString = '(';
        for (const iterator of selectedPO) {
          //console.log(iterator);
          inString += `${iterator},`;
        }
        inString += ')';

        let queryStr = inString.substring(0, inString.length - 2) + ')';
        //console.log(selectedPO);
        let dataBody = {
          Container_ID: ContID,
          Container_No: FormValue.container,
          PO_Id: queryStr,
          SelectedPO: selectedPO,
          Cont_Status: FormValue.status,
          UserEmail: localStorage.getItem('logisticsUseremail'),
        };
        const endpoint = poUpdateFromEditCommand(dataBody);
        const response = await apiFetch(endpoint).then((res) => res || null);
        if (response) {
          resolve('Success');
        } else {
          reject('Failed');
        }
      } catch (error) {
        console.log(error);
        reject('Error');
      }
    });
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    if (state.userLocation !== 'TJ MIGHAL' && state.userLocation !== 'DEVELOPMENT TEAM') {
      return alert('User Account not allowed to edit');
    }
    setIsLoading(true);
    try {
      await validate();

      if (!errors.length) {
        if (isNew) {
          let newContainerRecord = {
            Container_No: FormValue.container.trim().toUpperCase(),
            Temperature_Type: FormValue.l_type,
            Attention: FormValue.attention,
            Email: FormValue.emailTo,
            Destination: FormValue.destination,
            Departure_Date: formatDateSQL(deptDate),
            Arrival_Date: formatDateSQL(arrivalDate),
            Container_Type: FormValue.c_type,
            Vessel: FormValue.vessel.trim().toUpperCase(),
            Status: FormValue.status,
            Voyage: FormValue.voyage.trim().toUpperCase(),
            Booking: FormValue.booking.trim().toUpperCase(),
            Seal_No: FormValue.seal,
            Comment: FormValue.comment.trim().toUpperCase(),
            File_Ref: FormValue.file_ref.trim().toUpperCase(),
            OceanFreight: parseFloat(FormValue.oceanfreight),
            UserEmail: localStorage.getItem('logisticsUseremail'),
            isMighal: 1,
            isSIT,
            isAffiliate: isAffiliate ? 1 : 0,
          };
          // console.log({ newContainerRecord });
          handleInsertNewRecord(newContainerRecord)
            .then(async (result) => {
              //console.log(result);
              let sendData = {};
              if (result > 0) {
                //Update Doc1 PDF
                let Cont_Id = result;
                if (Pdf1 !== null) {
                  sendData = {
                    ID: Cont_Id,
                    PdfFile: Pdf1,
                    Doc_No: 1,
                  };
                  await UploadDocPDF(sendData).catch((err) => {
                    console.log(err);
                  });
                }
                //Update Doc2 PDF
                if (Pdf2 !== null) {
                  sendData = {
                    ID: Cont_Id,
                    PdfFile: Pdf2,
                    Doc_No: 2,
                  };
                  await UploadDocPDF(sendData).catch((err) => {
                    console.log(err);
                    errors.push(err);
                  });
                }
                //Update Doc3 PDF
                if (Pdf3 !== null) {
                  sendData = {
                    ID: Cont_Id,
                    PdfFile: Pdf3,
                    Doc_No: 3,
                  };
                  await UploadDocPDF(sendData).catch((err) => {
                    console.log(err);
                    errors.push(err);
                  });
                }

                //console.log(selectedPO);
                if (selectedPO.length > 0) {
                  await updateSelectedPOWithContainerInfo(Cont_Id, newContainerRecord.Status).catch(
                    (err) => {
                      console.log(err);
                      errors.push('Error Updating PO Container Info');
                    },
                  );
                }

                applyDefaultBtnStatusState();

                if (errors.length > 0) {
                  setIsLoading(false);
                  alert('Error Uploadling PDF Document');
                } else {
                  setIsLoading(false);
                  alert('Successfully Created Container');
                  applyDefaultBtnStatusState();
                  SetFormValue(initForm);
                  SetIsRender(false);
                  SetDataInfoGrid([]);
                  history.push('/container-view');
                }
              }
            })
            .catch((err) => {
              console.log(err);
              setIsLoading(false);
              alert('Unable to save record');
              applyDefaultBtnStatusState();
            });
        }

        if (isEdit) {
          let updateContainerRecord = {
            Container_ID: FormValue.containerId,
            Container_No: FormValue.container.trim().toUpperCase(),
            Temperature_Type: FormValue.l_type,
            Attention: FormValue.attention,
            Email: FormValue.emailTo,
            Destination: FormValue.destination,
            Departure_Date: formatDateSQL(deptDate),
            Arrival_Date: formatDateSQL(arrivalDate),
            Container_Type: FormValue.c_type,
            Vessel: FormValue.vessel.trim().toUpperCase(),
            Status: FormValue.status,
            Voyage: FormValue.voyage,
            Booking: FormValue.booking.trim().toUpperCase(),
            Seal_No: FormValue.seal,
            Comment: FormValue.comment.trim().toUpperCase(),
            File_Ref: FormValue.file_ref.trim().toUpperCase(),
            OceanFreight: parseFloat(FormValue.oceanfreight),
            UserEmail: localStorage.getItem('logisticsUseremail'),
            isSIT,
          };

          handleUpdateContRecord(updateContainerRecord)
            .then(async () => {
              //console.log(result);
              let sendData = {};
              if (Pdf1 !== null) {
                sendData = {
                  ID: FormValue.containerId,
                  PdfFile: Pdf1,
                  Doc_No: 1,
                };
                await UploadDocPDF(sendData).catch((err) => {
                  console.log(err);
                });
              }
              //Update Doc2 PDF
              if (Pdf2 !== null) {
                sendData = {
                  ID: FormValue.containerId,
                  PdfFile: Pdf2,
                  Doc_No: 2,
                };
                await UploadDocPDF(sendData).catch((err) => {
                  console.log(err);
                  errors.push(err);
                });
              }
              //Update Doc3 PDF
              if (Pdf3 !== null) {
                sendData = {
                  ID: FormValue.containerId,
                  PdfFile: Pdf3,
                  Doc_No: 3,
                };
                await UploadDocPDF(sendData).catch((err) => {
                  console.log(err);
                  errors.push(err);
                });
              }

              //console.log({ selectedPO });
              if (selectedPO.length > 0) {
                await UpdateEditPOList(FormValue.containerId).catch((err) => {
                  console.log(err);
                  errors.push('Error Updating PO Container Info');
                });
              } else {
                /* Remove all PO in this container */
                await UpdatePOListRemoveAll(FormValue.containerId)
                  .then((result) => console.log(result))
                  .catch((err) => {
                    console.log(err);
                    errors.push('Error Updating PO Container Info');
                  });
              }

              applyDefaultBtnStatusState();

              if (errors.length > 0) {
                setIsLoading(false);
                alert('Error Uploadling PDF Document');
              } else {
                setIsLoading(false);
                applyDefaultBtnStatusState();
                SetFormValue(initForm);
                SetIsRender(false);
                SetDataInfoGrid([]);
                alert('Successfully Updated Container Information Details');
                history.push('/container-view');
              }
            })
            .catch(() => {
              setIsLoading(false);
              alert('Unable to update record');
              applyDefaultBtnStatusState();
            });
        }
      } else {
        alert(errors[0]);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const UploadDocPDF = (ContData) => {
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();
      if (ContData.ID > 0) {
        formData.append('pdfFile', ContData.PdfFile);
        try {
          const headers = {
            'Content-Type': 'multipart/form-data',
          };
          Axios({
            method: 'POST',
            url: `${process.env.REACT_APP_APIBASEURL}/document/docs/upload`,
            data: formData,
            headers,
            params: {
              access_token: localStorage.getItem('logisticsToken'),
              contID: ContData.ID,
              docNo: ContData.Doc_No,
              userEmail: localStorage.getItem('logisticsUseremail'),
            },
          })
            .then((resp) => {
              if (resp.status === 200) {
                resolve('Success');
              }
            })
            .catch((resp) => {
              console.log('failed upload DOCS >>>', resp);
              reject('Failed');
            });
        } catch (error) {
          console.log(error);
          reject('Failed');
        }
      }
    });
  };

  const handleUpdateContRecord = (updateContainerRecord) => {
    return new Promise(async (resolve, reject) => {
      const endpoint = containerUpdate(updateContainerRecord);
      const response = await apiFetch(endpoint).then((res) => res || null);
      if (response) {
        resolve(response.data);
      } else {
        reject('Failed');
      }
    });
  };

  const handleInsertNewRecord = (newContainerRecord) => {
    return new Promise(async (resolve, reject) => {
      const endpoint = containerCreate(newContainerRecord);
      const response = await apiFetch(endpoint).then((res) => res || null);
      if (response && response.data) {
        resolve(response.data);
      } else {
        reject('Failed');
      }
    });
  };

  const handleChangeDoc1FileInput = (ev) => {
    ev.preventDefault();
    SetPdf1File(ev.target.files[0]);
  };

  const handleChangeDoc2FileInput = (ev) => {
    ev.preventDefault();
    SetPdf2File(ev.target.files[0]);
  };

  const handleChangeDoc3FileInput = (ev) => {
    ev.preventDefault();
    SetPdf3File(ev.target.files[0]);
  };

  const handleViewShippedOutPdf = (ev) => {
    ev.preventDefault();
    try {
      if (FormValue.container !== '') {
        setIsLoading(true);
        downloadShippedOutPdf()
          .then((response) => {
            //console.log(result);
            setIsLoading(false);
            const url = window.URL.createObjectURL(
              new Blob([response.data], { type: 'application/pdf' }),
            );
            window.open(url);
          })
          .catch((err) => {
            console.log(err);
            alert('No PDF Available');
            setIsLoading(false);
          });
      }
    } catch (error) {
      console.log(error);
      alert('No PDF Available');
    }
  };

  const handleViewLoadPlanPdf = (ev) => {
    ev.preventDefault();
    try {
      if (FormValue.container !== '') {
        setIsLoading(true);
        downloadLoadPlanPdf()
          .then((response) => {
            setIsLoading(false);
            const url = window.URL.createObjectURL(
              new Blob([response.data], { type: 'application/pdf' }),
            );
            window.open(url);
          })
          .catch((err) => {
            console.log(err);
            alert('No PDF Available');
            setIsLoading(false);
          });
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const downloadShippedOutPdf = () => {
    return new Promise(async (resolve, reject) => {
      let today = new Date();
      let countryZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let userToday = momentTZ.tz(today, countryZone).format('YYYY-MM-DD');
      let bodyData = {
        PrintDate: userToday,
        ContId: FormValue.containerId,
        Departure: formatDate(FormValue.depart),
        Attention: FormValue.attention,
        Arrival: formatDate(FormValue.arrival),
        Consignee: FormValue.destination,
        ContType: FormValue.c_type,
        Temperature: FormValue.l_type,
        Destination: FormValue.destination,
        Booking: FormValue.booking,
        Vessel: FormValue.vessel,
        Seal: FormValue.seal,
        Voyage: FormValue.voyage,
        FileRef: FormValue.file_ref,
        InvoiceNo: FormValue.InvoiceNo,
        Container_No: FormValue.container,
        PO_List: PoListQuery,
        Comments: FormValue.comment,
      };

      const endpoint = loadPlanShippedOutPDF(bodyData);
      const response = await apiFetch(endpoint);

      if (response.data.type !== 'application/json') {
        resolve(response);
      } else {
        reject('Failed');
      }
    });
  };

  const downloadLoadPlanPdf = () => {
    return new Promise(async (resolve, reject) => {
      let today = new Date();
      let countryZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let userToday = momentTZ.tz(today, countryZone).format('YYYY-MM-DD');
      let bodyData = {
        PrintDate: userToday,
        ContId: FormValue.containerId,
        Departure: formatDate(FormValue.depart),
        Attention: FormValue.attention,
        Arrival: formatDate(FormValue.arrival),
        Consignee: FormValue.destination,
        ContType: FormValue.c_type,
        Temperature: FormValue.l_type,
        Destination: FormValue.destination,
        Booking: FormValue.booking,
        Vessel: FormValue.vessel,
        Seal: FormValue.seal,
        Voyage: FormValue.voyage,
        Container_No: FormValue.container,
        PO_List: PoListQuery,
        Comments: FormValue.comment,
      };
      const endpoint = getLoadPlanPDF(bodyData);
      const response = await apiFetch(endpoint);

      if (response.data.type !== 'application/json') {
        resolve(response);
      } else {
        reject('Failed');
      }
    });
  };

  const sendEmailNotification = async () => {
    try {
      setIsLoading(true);
      let today = new Date();
      let countryZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let userToday = momentTZ.tz(today, countryZone).format('YYYY-MM-DD');
      const bodyData = {
        PrintDate: userToday,
        UserEmail: localStorage.getItem('logisticsUseremail'),
        ContId: FormValue.containerId,
        Departure: formatDate(FormValue.depart),
        Attention: FormValue.attention,
        Arrival: formatDate(FormValue.arrival),
        Consignee: FormValue.destination,
        ContType: FormValue.c_type,
        Destination: FormValue.destination,
        Booking: FormValue.booking,
        Vessel: FormValue.vessel,
        Seal: FormValue.seal,
        Voyage: FormValue.voyage,
        Container_No: FormValue.container,
        To: FormValue.emailTo,
        PO_List: PoListQuery,
        Comments: FormValue.comment,
        Temperature: FormValue.l_type,
        FileRef: FormValue.file_ref,
        InvoiceNo: FormValue.InvoiceNo,
      };
      //console.log(bodyData);
      const endpoint = sendLoadPlan(bodyData.ContId, bodyData);
      const response = await apiFetch(endpoint);

      if (response.data === 'Success') {
        setIsLoading(false);
        alert('Successfully sent Email Notifications');
      } else {
        setIsLoading(false);
        alert('Failed to send Email Notifications');
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const checkDocStatus = async (ContId, POId, docNo, docType) => {
    try {
      const params = {
        POId,
        ContId,
        docNo,
        dataType: docType,
      };

      const endpoint = getDocumentStatus(params);
      return await apiFetch(endpoint)
        .then((res) => res.data)
        .catch((e) => {
          throw e;
        });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const handleViewDocPDF = (DocNo) => async (ev) => {
    ev.preventDefault();
    try {
      if (FormValue.containerId > 0) {
        setIsLoading(true);
        const status = await checkDocStatus(FormValue.containerId, null, DocNo, docType.DOCS_FILE)
          .then((res) => res)
          .catch((e) => {
            throw e;
          });
        if (status && status.data) {
          downloadDocPDF(DocNo)
            .then((response) => {
              setIsLoading(false);
              const url = window.URL.createObjectURL(
                new Blob([response.data], { type: 'application/pdf' }),
              );
              window.open(url);
            })
            .catch((err) => {
              console.log(err);
              setIsLoading(false);
              alert('No PDF Available');
            });
        } else {
          downloadS3Document(FormValue.containerId, DocNo)
            .then((response) => {
              setIsLoading(false);
              window.open(response);
            })
            .catch(() => {
              alert('No PDF Available');
              setIsLoading(false);
            });
        }
      } else {
        alert('There is No Container selected');
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const downloadDocPDF = (DocNo) => {
    return new Promise(async (resolve, reject) => {
      try {
        const params = {
          docNo: DocNo,
          containerId: FormValue.containerId,
        };
        const endpoint = getDocPDF(params);
        const response = await apiFetch(endpoint);

        if (response.data.type !== 'application/json') {
          resolve(response);
        } else {
          reject('Failed');
        }
      } catch (error) {
        console.log(error);
      }
    });
  };

  const downloadS3Document = async (contID, docNo) => {
    try {
      const params = {
        contID,
        docNo,
      };
      const endpoint = getDOCSFileLinkS3(params);
      return await apiFetch(endpoint)
        .then((res) => (res && res.data) || null)
        .catch((e) => {
          throw e;
        });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const viewPOPdfFile = (poID, poNum) => async (ev) => {
    //console.log(prop);
    ev.preventDefault();
    try {
      if (poID !== '' || poNum !== '') {
        setIsLoading(true);
        const status = await checkDocStatus(null, poID, null, DocType.PO_FILE)
          .then((res) => res)
          .catch((e) => {
            throw e;
          });
        if (status && status.data) {
          downloadPOPdfFile(poNum)
            .then((response) => {
              setIsLoading(false);
              console.log('Finish Download PO Pdf');
              const url = window.URL.createObjectURL(
                new Blob([response.data], { type: 'application/pdf' }),
              );
              window.open(url);
            })
            .catch(() => {
              alert('No PDF Available');
              setIsLoading(false);
            });
        } else {
          downloadPOS3Document(poID, DocType.PO_FILE)
            .then((response) => {
              setIsLoading(false);
              window.open(response);
            })
            .catch(() => {
              alert('No PDF Available');
              setIsLoading(false);
            });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const downloadPOPdfFile = (PONo) => {
    return new Promise(async (resolve, reject) => {
      const endpoint = getPOPDFFile(PONo);
      const response = await apiFetch(endpoint);

      if (response.data.type !== 'application/json') {
        resolve(response);
      } else {
        reject('Failed');
      }
    });
  };

  const downloadPOS3Document = async (POId, docType) => {
    try {
      const params = {
        POId,
        dataType: docType,
      };
      const endpoint = getPOFileLinkS3(params);
      return await apiFetch(endpoint)
        .then((res) => (res && res.data) || null)
        .catch((e) => {
          throw e;
        });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const viewPOInvPdfFile = (poID, poNum) => async (ev) => {
    //console.log(prop);
    ev.preventDefault();
    try {
      if (poID !== '' || poNum !== '') {
        setIsLoading(true);
        const status = await checkDocStatus(null, poID, null, DocType.INV_FILE)
          .then((res) => res)
          .catch((e) => {
            throw e;
          });
        if (status && status.data) {
          downloadPOInvPdfFile(poNum)
            .then((response) => {
              setIsLoading(false);
              console.log('Finish Download PO Pdf');
              const url = window.URL.createObjectURL(
                new Blob([response.data], { type: 'application/pdf' }),
              );
              window.open(url);
            })
            .catch(() => {
              alert('No PDF Available');
              setIsLoading(false);
            });
        } else {
          downloadPOS3Document(poID, DocType.INV_FILE)
            .then((response) => {
              setIsLoading(false);
              window.open(response);
            })
            .catch(() => {
              alert('No PDF Available');
              setIsLoading(false);
            });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const downloadPOInvPdfFile = (PONo) => {
    return new Promise(async (resolve, reject) => {
      const endpoint = getPOInvPDFFile(PONo);
      const response = await apiFetch(endpoint);

      if (response.data.type !== 'application/json') {
        resolve(response);
      } else {
        reject('Failed');
      }
    });
  };

  const handleViewMrtPdfFile = (POId) => async (ev) => {
    ev.preventDefault();
    try {
      if (POId !== '') {
        setIsLoading(true);
        const status = await checkDocStatus(null, POId, null, DocType.MRT_FILE)
          .then((res) => res)
          .catch((e) => {
            throw e;
          });

        if (status && status.data) {
          downloadMrtPdfFile(POId)
            .then((response) => {
              setIsLoading(false);
              console.log('Finish Download MrtPdf');
              const url = window.URL.createObjectURL(
                new Blob([response.data], { type: 'application/pdf' }),
              );
              window.open(url);
            })
            .catch(() => {
              alert('No PDF Available');
              setIsLoading(false);
            });
        } else {
          downloadMRTS3Document(POId)
            .then((response) => {
              setIsLoading(false);
              window.open(response);
            })
            .catch(() => {
              alert('No PDF Available');
              setIsLoading(false);
            });
        }
      } else {
        alert('Please select a Purchase Order to view the PDF');
      }
    } catch (error) {
      console.log(error);
      alert('No PDF Available');
      setIsLoading(false);
    }
  };

  const downloadMrtPdfFile = (POId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoint = getPOMRTPDFFile(POId);
        const response = await apiFetch(endpoint);
        //console.log(response.data.type);
        if (response.data.type !== 'application/json') {
          resolve(response);
        } else {
          reject('Failed');
        }
      } catch (error) {
        console.log(error);
        reject('Failed');
      }
    });
  };

  const downloadMRTS3Document = async (POId) => {
    try {
      const endpoint = getMRTFileLinkS3({ POId });
      return await apiFetch(endpoint)
        .then((res) => (res && res.data) || null)
        .catch((e) => {
          throw e;
        });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const checkIfBillingExist = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const params = {
          containerId: FormValue.containerId,
          billingId: 0,
        };
        const endpoint = getBillingInfo(params);
        const response = await apiFetch(endpoint);

        if (response && response.data) {
          let arrData = response.data;

          if (Array.isArray(arrData) && arrData.length > 0) {
            resolve(arrData);
          } else {
            reject(false);
          }
        }
      } catch (e) {
        console.log(e);
      }
    });
  };

  const populateMighalBilling = async (ev) => {
    ev.preventDefault();

    if (state.userLocation !== 'TJ MIGHAL' && state.userLocation !== 'DEVELOPMENT TEAM') {
      alert('Please contact MIGHAL to ask for Final Billing Documents');
      return false;
    }

    try {
      if (FormValue.status === 'Loading') {
        return alert('Cannot create a Mighal Billing if container is not ready for Ship Out.');
      }

      if (FormValue.destination !== 'SELECT CONSIGNEE') {
        setIsLoading(true);
        let consigneeAddress = {};
        await getConsigneeAddress()
          .then((result) => {
            //console.log(result[0]);
            consigneeAddress = {
              billTo: result[0].Consignee_Name,
              addressTo: result[0].LocationCode,
              company: result[0].Company_Name,
              address: result[0].Address,
              city: result[0].City,
              state_zip: result[0].StateZip,
              from: 'TJ MIGHAL',
              to: result[0].Consignee_Name,
            };
          })
          .catch((err) => {
            setIsLoading(false);
            console.log(err);
            return alert('Something went wrong');
          });

        await checkIfBillingExist()
          .then((data) => {
            let billingData = data[0];
            let contSize = FormValue.c_type.substring(2, 4);
            let currentMighalBilling = {
              billing_ID: billingData.Billing_ID,
              isAffiliate: isAffiliate ? 1 : 0,
              cont_ID: billingData.Container_ID,
              billTo: consigneeAddress.billTo,
              addressTo: billingData.Consigned_To,
              company: consigneeAddress.company,
              address: consigneeAddress.address,
              city: consigneeAddress.city,
              state_zip: consigneeAddress.state_zip,
              vessel: `${FormValue.vessel} ${FormValue.voyage}`,
              from: consigneeAddress.from,
              to: consigneeAddress.to,
              inv_no: billingData.Invoice_No,
              inv_date: modifiedDate(FormValue.depart),
              cont_no: FormValue.container,
              file_ref: FormValue.file_ref,
              consignee: billingData.Consignee_Name,
              shipper: billingData.Shipper,
              booking: FormValue.booking,
              cont_temp: FormValue.l_type,
              arrival: modifiedDate(FormValue.arrival),
              inv_amt: formatNumber(billingData.TtlInvoiceAmt),
              ofrt_amt: formatInvAmt(billingData.OceanFreight),
              contSize,
              ContainerStatus: FormValue.status,
              DepartureDate: formatDate(FormValue.depart),
              ArrivalDate: formatDate(FormValue.arrival),
              Desc1: billingData.Desc1,
              Desc1_Amt: billingData.Desc1_Amt,
              Desc2: billingData.Desc2,
              Desc2_Amt: billingData.Desc2_Amt,
              Desc3: billingData.Desc3,
              Desc3_Amt: billingData.Desc3_Amt,
              Desc4: billingData.Desc4,
              Desc4_Amt: billingData.Desc4_Amt,
              Desc5: billingData.Desc5,
              Desc5_Amt: billingData.Desc5_Amt,
              Desc6: billingData.Desc6,
              Desc6_Amt: billingData.Desc6_Amt,
              Desc7: billingData.Desc7,
              Desc7_Amt: billingData.Desc7_Amt,
              Desc8: billingData.Desc8,
              Desc8_Amt: billingData.Desc8_Amt,
              Desc9: billingData.Desc9,
              Desc9_Amt: billingData.Desc9_Amt,
              Desc10: billingData.Desc10,
              Desc10_Amt: billingData.Desc10_Amt,
              ReturnTo: 'container-mighal',
              isSIT,
              comment: FormValue.comment,
            };
            //console.log(currentMighalBilling);
            dispatch({
              type: 'UPDATE_MIGHAL_BILLING_DATA',
              payload: currentMighalBilling,
            });
            setIsLoading(false);
            if (FormValue.container.length > 0) {
              history.push(`/mighalbilling/${FormValue.container}`);
            } else {
              alert('Cannot proceed with no Container No.');
            }
          })
          .catch(() => {
            geTotalPOInvoiceAmt()
              .then((result) => {
                let contSize = FormValue.c_type.substring(2, 4);
                let InsValue = result.InsurancePercent;
                let InsFixDecimalValue = InsValue * 100;
                let POTtlInvAmount = formatNumber(result.TtlInvoiceAmt);
                let RecorderFee = FormValue.l_type === 'DRY' ? 0 : formatNumber(result.RecorderFee);
                let TtlAddOnCharge = formatNumber(result.TtlPOinvOFrtAddOnCharge);
                let DrayageCharge = isSIT ? result.DrayageFee * 0.5 : result.DrayageFee;
                let DocCharge = isSIT
                  ? result.DocumentationCharge * 0.5
                  : result.DocumentationCharge;

                let newMighalBilling = {
                  billing_ID: 0,
                  isAffiliate: isAffiliate ? 1 : 0,
                  cont_ID: FormValue.containerId,
                  billTo: consigneeAddress.billTo,
                  addressTo: consigneeAddress.addressTo,
                  company: consigneeAddress.company,
                  address: consigneeAddress.address,
                  city: consigneeAddress.city,
                  state_zip: consigneeAddress.state_zip,
                  vessel: `${FormValue.vessel} ${FormValue.voyage}`,
                  from: 'TJ MIGHAL',
                  to: consigneeAddress.to,
                  inv_no: parseInt(result.LastInvoiceMighal) + 1,
                  inv_date: modifiedDate(FormValue.depart),
                  cont_no: FormValue.container,
                  file_ref: FormValue.file_ref,
                  consignee: consigneeAddress.billTo,
                  shipper: FormValue.l_type,
                  booking: FormValue.booking,
                  cont_temp: FormValue.l_type,
                  arrival: modifiedDate(FormValue.arrival),
                  inv_amt: POTtlInvAmount,
                  ofrt_amt: formatInvAmt(FormValue.oceanfreight),
                  contSize,
                  ContainerStatus: FormValue.status,
                  DepartureDate: formatDate(FormValue.depart),
                  ArrivalDate: formatDate(FormValue.arrival),
                  Desc1: 'Drayage BAY AREA PORT',
                  Desc1_Amt: DrayageCharge,
                  Desc2: 'Documentation',
                  Desc2_Amt: DocCharge,
                  Desc3: `Consolidation ${contSize}`,
                  Desc3_Amt:
                    contSize === '20'
                      ? result.ConsolidationCharge20ft
                      : result.ConsolidationCharge40ft,
                  Desc4: `Insurance ${FormValue.l_type} ${InsFixDecimalValue.toFixed(
                    2,
                  )}% x $${TtlAddOnCharge}`,
                  Desc4_Amt: result.TtlInsuranceAmt,
                  Desc5: FormValue.l_type === 'DRY' ? '' : `Recorder Charge ${FormValue.comment}`,
                  Desc5_Amt: FormValue.l_type === 'DRY' ? 0 : RecorderFee,
                  Desc6:
                    result.BuyingTtlAmt > 0
                      ? `Buying Charge $${formatNumber(result.BuyingTtlAmt)} x 5%`
                      : '',
                  Desc6_Amt: result.BuyingTtlAmt > 0 ? result.BuyingCharge : 0,
                  Desc7: '',
                  Desc7_Amt: 0,
                  Desc8: '',
                  Desc8_Amt: 0,
                  Desc9: '',
                  Desc9_Amt: 0,
                  Desc10: '',
                  Desc10_Amt: 0,
                  ReturnTo: 'container-mighal',
                  isSIT,
                  comment: FormValue.comment,
                };
                //console.log(newMighalBilling);
                dispatch({
                  type: 'UPDATE_MIGHAL_BILLING_DATA',
                  payload: newMighalBilling,
                });
                setIsLoading(false);
                history.push(`/mighalbilling/${FormValue.container}`);
              })
              .catch((err) => {
                setIsLoading(false);
                console.log(err);
                alert('Something went wrong');
              });
          });
      } else {
        alert('Please select a Consignee');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getConsigneeAddress = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let params = {
          consignee: FormValue.destination,
        };
        const endpoint = consigneeGetAddress(params);
        const response = await apiFetch(endpoint);
        if (response) {
          resolve(response.data);
        } else {
          reject('Failed');
        }
      } catch (e) {
        console.log(e);
        reject('Failed');
      }
    });
  };

  const geTotalPOInvoiceAmt = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let params = {
          containerId: FormValue.containerId,
          tempType: FormValue.l_type,
        };
        const endpoint = getBillingCharges(params);
        const response = await apiFetch(endpoint);
        if (response.data) {
          resolve(response.data[0]);
        } else {
          reject('Failed');
        }
      } catch (e) {
        console.log(e);
        reject('Failed');
      }
    });
  };

  const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className='btn-group' role='group'>
      {options.map((option) => {
        const isSelect = currSizePerPage === `${option.page}`;
        return (
          <button
            key={option.text}
            type='button'
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${isSelect ? 'btn-secondary' : 'btn-default'}`}>
            {option.text}
          </button>
        );
      })}
    </div>
  );

  const PaginationOptions = {
    hideSizePerPage: true,
    sizePerPageRenderer,
  };

  const handleChangeAffiliate = () => {
    setIsAffiliate(!isAffiliate);
    getLookUpData(!isAffiliate)
      .then((result) => {
        console.log('getLookUpData', result);
      })
      .catch((err) => {
        console.log('Error getLookUpData', err);
      });
  };

  const getLookUpData = (filterAffiliate) => {
    return new Promise(async (resolve, reject) => {
      try {
        const params = {
          isAffiliate: filterAffiliate ? 1 : 0,
        };
        const endpoint = getConsigneeList(params);
        const response = await apiFetch(endpoint);
        if (response.data) {
          let data = response.data;
          if (data.length) {
            let filteredArray = [];
            filteredArray.push('SELECT CONSIGNEE');
            data.map((value) => {
              filteredArray.push(value.Consignee_Name);
            });
            //console.log(filteredArray);
            setConsigneeData(filteredArray);
            resolve('Done');
          }
        } else {
          reject('Failed');
        }
      } catch (e) {
        console.log('TryCatch getNonAffiliateOnlyLookup', e);
      }
    });
  };

  const modifiedDate = (dateValue) => {
    return new Date(momentTZ.tz(dateValue, 'Pacific/Guam'));
  };

  const onDepartureValueChange = (selected) => {
    let AdjusteddateValue = new Date(momentTZ.tz(selected, 'Pacific/Guam'));
    setDeptDate(AdjusteddateValue);
  };

  const onArrivalValueChange = (selected) => {
    let AdjusteddateValue = new Date(momentTZ.tz(selected, 'Pacific/Guam'));
    setArrivalDate(AdjusteddateValue);
  };

  const handleCloseModal = () => setShowModal(false);

  // const handleTableChange = (type, { page }) => {
  //   setOffset(page);
  // };

  return (
    <div className='wrapper'>
      <div className='loading-spinner'>
        <ClipLoader size={100} color={'#0054a6'} loading={isLoading} css={SpinnerStyle} />
      </div>
      <div className='help-button'>
        <a
          href='https://triplejlogistics.org/help/MighalContainerMenu.html'
          target='_blank'
          rel='noopener noreferrer'
          className='btn btn-labeled btn-outline-dark btn-sm mr-1'>
          <span className='btn-label'>
            <i className='fa fa-question-circle pr-1'></i>
          </span>
          Help
        </a>
      </div>
      <ReactBootstrap.Modal
        dialogClassName='modal-size'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={showModal}
        onHide={handleCloseModal}>
        <ReactBootstrap.Modal.Header closeButton>
          <ReactBootstrap.Modal.Title id='contained-modal-title-vcenter'>
            Container With Pending Ocean Freight
          </ReactBootstrap.Modal.Title>
        </ReactBootstrap.Modal.Header>
        <ReactBootstrap.Modal.Body>
          <div className='container-fluid wrapper'>
            <ReactBootstrap.Row>
              <div className='container-fluid scroll-container'>
                <BootstrapTable
                  keyField='Container_ID'
                  data={dataModalGrid}
                  columns={columnsModal}
                  striped
                  hover
                />
              </div>
            </ReactBootstrap.Row>
          </div>
        </ReactBootstrap.Modal.Body>
        <ReactBootstrap.Modal.Footer>
          <ReactBootstrap.Button variant='secondary' onClick={handleCloseModal}>
            Close
          </ReactBootstrap.Button>
        </ReactBootstrap.Modal.Footer>
      </ReactBootstrap.Modal>
      <div className='generic-content-container container-fluid'>
        <h2>Mighal Container</h2>
      </div>
      <div className='container-fluid'>
        <div className='row mb-2'>
          <div className='col-md-12'>
            {isUserMighalDEV && permission.edit ? (
              <>
                <button
                  type='button'
                  className='btn btn-labeled btn-primary mr-1'
                  onClick={handleNewButton}
                  disabled={isDisabledBtnNew}>
                  <span className='btn-label'>
                    <i className='fa fa-plus pr-1'></i>
                  </span>
                  New
                </button>
                <button
                  type='button'
                  className='btn btn-labeled btn-primary mr-1'
                  onClick={handleEditButton}
                  disabled={isDisabledBtnEdit}>
                  <span className='btn-label'>
                    <i className='fa fa-edit pr-1'></i>
                  </span>
                  Edit
                </button>
                <button
                  type='submit'
                  className='btn btn-labeled btn-primary mr-1'
                  disabled={isDisabledBtnSave}
                  onClick={handleSubmit}>
                  <span className='btn-label'>
                    <i className='fa fa-check-square-o pr-1'></i>
                  </span>
                  Save
                </button>
                <button
                  type='button'
                  className='btn btn-labeled btn-primary mr-1'
                  onClick={handleCancelButton}
                  disabled={isDisabledBtnCancel}>
                  <span className='btn-label'>
                    <i className='fa fa-ban pr-1'></i>
                  </span>
                  Cancel
                </button>
              </>
            ) : null}

            <input
              className='radio-btn'
              type='radio'
              id='select_affiliate'
              name='affiliate'
              checked={isAffiliate}
              onChange={handleChangeAffiliate}
              disabled={isFilterAffiliateDisabled}
            />
            <label htmlFor='container' className='mt-auto mb-auto'>
              Affiliate
            </label>
            <input
              className='radio-btn'
              type='radio'
              id='select_affiliate'
              name='nonaffiliate'
              checked={!isAffiliate}
              onChange={handleChangeAffiliate}
              disabled={isFilterAffiliateDisabled}
            />
            <label htmlFor='container' className='mt-auto mb-auto'>
              Non-Affiliate
            </label>

            <div className='d-flex float-right'>
              <ReactBootstrap.Form.Check
                type='checkbox'
                id='select_SIT'
                className='mr-3 ml-2 mt-auto mb-auto'
                label='SIT'
                disabled={isFormDisable}
                checked={isSIT}
                onChange={handleCheckedSIT}
              />
              <div className='update-loadplan'>
                <label className='update-loadplan__label mt-auto mb-auto'>Available Lbs.</label>
                <label className='update-loadplan__label mr-3 pl-1 mt-auto mb-auto'>
                  {formatINTNumber(FormValue.available_lbs)}
                </label>
                <label className='update-loadplan__label mt-auto mb-auto'>Available Cu.Ft.</label>
                <label className='update-loadplan__label mr-3 pl-1 mt-auto mb-auto'>
                  {formatINTNumber(FormValue.available_cu)}
                </label>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className=''>
          <ReactBootstrap.Form>
            <ReactBootstrap.Row className='mb-2'>
              <ReactBootstrap.Col xs={2}>
                <ReactBootstrap.Form.Label>Consignee</ReactBootstrap.Form.Label>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={4}>
                <ReactBootstrap.Form.Control
                  as='select'
                  disabled={isFormDisable}
                  value={FormValue.destination}
                  onChange={handleChange('destination')}
                  tabIndex={1}>
                  {MakeConsigneeOptions(consigneeData)}
                </ReactBootstrap.Form.Control>
              </ReactBootstrap.Col>

              <ReactBootstrap.Col xs={2}>
                <ReactBootstrap.Form.Label>Container</ReactBootstrap.Form.Label>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={4}>
                <ReactBootstrap.Form.Control
                  placeholder='Container'
                  disabled={isFormDisable}
                  value={FormValue.container}
                  onChange={handleChange('container')}
                  tabIndex={7}
                />
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>

            <ReactBootstrap.Row className='mb-2'>
              <ReactBootstrap.Col xs={2}>
                <ReactBootstrap.Form.Label>Attention</ReactBootstrap.Form.Label>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={4}>
                <ReactBootstrap.Form.Control
                  placeholder='Receiving Dept...'
                  disabled={isFormDisable}
                  value={FormValue.attention}
                  onChange={handleChange('attention')}
                  tabIndex={2}
                />
              </ReactBootstrap.Col>

              <ReactBootstrap.Col xs={2}>
                <ReactBootstrap.Form.Label>Container Type</ReactBootstrap.Form.Label>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={4}>
                <ReactBootstrap.Form.Control
                  as='select'
                  disabled={isFormDisable}
                  value={FormValue.c_type}
                  onChange={handleChange('c_type')}
                  tabIndex={8}>
                  <option>{`1X20 DRY`}</option>
                  <option>{`1X40 DRY`}</option>
                  <option>{`1X20 REEFER`}</option>
                  <option>{`1X40 REEFER`}</option>
                </ReactBootstrap.Form.Control>
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>

            <ReactBootstrap.Row className='mb-2'>
              <ReactBootstrap.Col xs={2}>
                <ReactBootstrap.Form.Label>Vessel</ReactBootstrap.Form.Label>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={4}>
                <ReactBootstrap.Form.Control
                  placeholder='Vessel Name...'
                  disabled={isFormDisable}
                  value={FormValue.vessel}
                  onChange={handleChange('vessel')}
                  tabIndex={3}
                />
              </ReactBootstrap.Col>

              <ReactBootstrap.Col xs={2}>
                <ReactBootstrap.Form.Label>Temp Type</ReactBootstrap.Form.Label>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={4}>
                <ReactBootstrap.Form.Control
                  as='select'
                  disabled={isFormDisable}
                  value={FormValue.l_type}
                  onChange={handleChange('l_type')}
                  tabIndex={9}>
                  <option>{`CHILL`}</option>
                  <option>{`DRY`}</option>
                  <option>{`FREEZE`}</option>
                </ReactBootstrap.Form.Control>
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>

            <ReactBootstrap.Row className='mb-2'>
              <ReactBootstrap.Col xs={2}>
                <ReactBootstrap.Form.Label>Voyage Number</ReactBootstrap.Form.Label>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={4}>
                <ReactBootstrap.Form.Control
                  placeholder='Voyage Number...'
                  disabled={isFormDisable}
                  value={FormValue.voyage}
                  onChange={handleChange('voyage')}
                  tabIndex={4}
                />
              </ReactBootstrap.Col>

              <ReactBootstrap.Col xs={2}>
                <ReactBootstrap.Form.Label>Booking Number</ReactBootstrap.Form.Label>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={4}>
                <ReactBootstrap.Form.Control
                  placeholder='Booking Number...'
                  disabled={isFormDisable}
                  value={FormValue.booking}
                  onChange={handleChange('booking')}
                  tabIndex={10}
                />
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>

            <ReactBootstrap.Row className='mb-2'>
              <ReactBootstrap.Col xs={2}>
                <ReactBootstrap.Form.Label>Departure Date</ReactBootstrap.Form.Label>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={4}>
                <div className=''>
                  <DatePicker
                    selected={modifiedDate(deptDate)}
                    onChange={onDepartureValueChange}
                    locale='en'
                    className=''
                    disabled={isFormDisable}
                    tabIndex={5}
                  />
                </div>
              </ReactBootstrap.Col>

              <ReactBootstrap.Col xs={2}>
                <ReactBootstrap.Form.Label>Seal No</ReactBootstrap.Form.Label>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={4}>
                <ReactBootstrap.Form.Control
                  placeholder='Seal No...'
                  disabled={isFormDisable}
                  value={FormValue.seal}
                  onChange={handleChange('seal')}
                  tabIndex={11}
                />
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>

            <ReactBootstrap.Row className='mb-2'>
              <ReactBootstrap.Col xs={2}>
                <ReactBootstrap.Form.Label>Arrival Date</ReactBootstrap.Form.Label>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={4}>
                <div className=''>
                  <DatePicker
                    selected={modifiedDate(arrivalDate)}
                    locale='en'
                    onChange={onArrivalValueChange}
                    disabled={isFormDisable}
                    className=''
                    tabIndex={6}
                  />
                </div>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={2}>
                <ReactBootstrap.Form.Label>Comments</ReactBootstrap.Form.Label>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={4}>
                <ReactBootstrap.Form.Control
                  placeholder='Comments...'
                  disabled={isFormDisable}
                  value={FormValue.comment}
                  onChange={handleChange('comment')}
                  tabIndex={12}
                />
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>

            <ReactBootstrap.Row className='mb-2'>
              <ReactBootstrap.Col xs={2}>
                <ReactBootstrap.Form.Label>Ocean Freight Amount</ReactBootstrap.Form.Label>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={4}>
                {!isFormDisable ? (
                  <ReactBootstrap.Form.Control
                    type='number'
                    placeholder='Ocean Freight Amount'
                    disabled={isFormDisable}
                    value={FormValue.oceanfreight}
                    onChange={handleChange('oceanfreight')}
                    tabIndex={13}
                  />
                ) : (
                  <ReactBootstrap.Form.Control
                    type='text'
                    disabled={isFormDisable}
                    value={toCurrency(FormValue.oceanfreight)}
                    readOnly={true}
                  />
                )}
              </ReactBootstrap.Col>

              <ReactBootstrap.Col xs={2}>
                <ReactBootstrap.Form.Label>Status</ReactBootstrap.Form.Label>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={4}>
                <ReactBootstrap.Form.Control
                  as='select'
                  disabled={isFormDisable}
                  value={FormValue.status}
                  onChange={handleChange('status')}
                  tabIndex={14}>
                  <option>{`Loading`}</option>
                  <option>{`Shipped`}</option>
                  <option>{`On-Hold`}</option>
                  <option>{`Delivered`}</option>
                  <option>{`Cancel`}</option>
                </ReactBootstrap.Form.Control>
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
            <ReactBootstrap.Row className='mb-2'>
              <ReactBootstrap.Col xs={2}>
                <ReactBootstrap.Form.Label></ReactBootstrap.Form.Label>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={4}></ReactBootstrap.Col>
              <ReactBootstrap.Col xs={2}>
                <ReactBootstrap.Form.Label>File Reference</ReactBootstrap.Form.Label>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={4}>
                <ReactBootstrap.Form.Control
                  placeholder='File Reference...'
                  disabled={isFormDisable}
                  value={FormValue.file_ref}
                  onChange={handleChange('file_ref')}
                  tabIndex={12}
                />
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>

            <ReactBootstrap.Row className='container-fluid col-sm w-100 align-content-sm-between'>
              <div className='d-flex mt-auto mb-auto align-items-center pb-2'>
                <button
                  className='btn btn-sm btn-primary mr-1'
                  disabled={isDisabledViewDoc1}
                  onClick={handleViewDocPDF(1)}>
                  View
                </button>
                <ReactBootstrap.Form.Label className='mt-auto mb-auto mr-1'>
                  Docs 1
                </ReactBootstrap.Form.Label>
                <ReactBootstrap.Form.File
                  id='upload1'
                  disabled={isFormDisable}
                  onChange={handleChangeDoc1FileInput}
                />
              </div>

              <div className='d-flex mt-auto mb-auto align-items-center pb-2'>
                <button
                  className='btn btn-sm btn-primary mr-1'
                  disabled={isDisabledViewDoc2}
                  onClick={handleViewDocPDF(2)}>
                  View
                </button>
                <ReactBootstrap.Form.Label className='mt-auto mb-auto mr-1'>
                  Docs 2
                </ReactBootstrap.Form.Label>
                <ReactBootstrap.Form.File
                  id='upload2'
                  disabled={isFormDisable}
                  onChange={handleChangeDoc2FileInput}
                />
              </div>

              <div className='d-flex mt-auto mb-auto align-items-center pb-2'>
                <button
                  className='btn btn-sm btn-primary mr-1'
                  disabled={isDisabledViewDoc3}
                  onClick={handleViewDocPDF(3)}>
                  View
                </button>
                <ReactBootstrap.Form.Label className='mt-auto mb-auto mr-1'>
                  MRT Docs
                </ReactBootstrap.Form.Label>
                <ReactBootstrap.Form.File
                  id='upload3'
                  disabled={isFormDisable}
                  onChange={handleChangeDoc3FileInput}
                />
              </div>
            </ReactBootstrap.Row>
          </ReactBootstrap.Form>
        </div>
      </div>
      <hr />
      <div className='container-fluid'>
        <ReactBootstrap.Form>
          <ReactBootstrap.Row>
            <ReactBootstrap.Col xs='auto'>
              <ReactBootstrap.Form.Check
                type='checkbox'
                checked={ChillStatus}
                onChange={handleChecked('chill')}
                id='select_chl'
                className='mb-2'
                label='CHILL'
                disabled={isFormDisable}
              />
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs='auto'>
              <ReactBootstrap.Form.Check
                type='checkbox'
                checked={DryStatus}
                onChange={handleChecked('dry')}
                id='select_dry'
                className='mb-2'
                label='DRY'
                disabled={isFormDisable}
              />
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs='auto'>
              <ReactBootstrap.Form.Check
                type='checkbox'
                checked={FrzStatus}
                onChange={handleChecked('frz')}
                id='select_frz'
                className='mb-2'
                label='FREEZE'
                disabled={isFormDisable}
              />
            </ReactBootstrap.Col>
            <ReactBootstrap.Col>
              <button
                className='btn btn-primary btn-sm mb-2'
                disabled={isDisabledBtnGetPO}
                onClick={handleGetPoList}>
                Get PO List
              </button>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col>
              <ReactBootstrap.Form.Label>Total Weight:</ReactBootstrap.Form.Label>
              <ReactBootstrap.Form.Label className='ml-2 font-weight-bold'>
                {formatINTNumber(totalPOWeight)}
              </ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col>
              <ReactBootstrap.Form.Label>Total Cube:</ReactBootstrap.Form.Label>
              <ReactBootstrap.Form.Label className='ml-2 font-weight-bold'>
                {formatINTNumber(totalPOCube)}
              </ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col>
              <ReactBootstrap.Form.Label>Total Invoice:</ReactBootstrap.Form.Label>
              <ReactBootstrap.Form.Label className='ml-2 font-weight-bold'>
                {formatInvAmt(totalInvAmt)}
              </ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </ReactBootstrap.Form>
      </div>
      {/*<div className='container-fluid'>*/}
      {/*  <button*/}
      {/*    className='btn btn-primary btn-sm mb-2'*/}
      {/*    onClick={clearFilter}*/}
      {/*    disabled={true}*/}
      {/*    hidden={true}>*/}
      {/*    Clear Filter*/}
      {/*  </button>*/}
      {/*</div>*/}
      <div className='container-fluid scroll-container'>
        <DoubleScrollbar>
          <BootstrapTable
            keyField='id'
            data={dataInfoGrid}
            columns={columns}
            selectRow={selectRowProp}
            filter={filterFactory()}
            pagination={paginationFactory(PaginationOptions)}
            cellEdit={cellEditFactory({
              mode: 'dbclick',
              blurToSave: true,
              afterSaveCell,
              beforeSaveCell,
            })}
            filterPosition='top'
            striped
            hover
          />
        </DoubleScrollbar>
      </div>
      <hr />
      <div className='container-fluid'>
        <div className=''>
          <div className='float-right'>
            <label className='font-weight-bold'>Seq. No</label>
            <label className='mr-3 pl-1'>
              {!isNew ? FormValue.containerId : state.maxContID + 1}
            </label>
            <label className='font-weight-bold'>Create Date:</label>
            <label className='mr-3 pl-1'>{formatDate(FormValue.createDte)}</label>
          </div>
        </div>
      </div>
      <div className='container-fluid'>
        <div className='mb-2'>
          <div className='col-md-12'>
            <button
              type='button'
              className='btn btn-labeled btn-secondary btn-sm mr-1'
              onClick={sendEmailNotification}
              disabled={isDisabledBtnEmail}>
              <span className='btn-label'>
                <i className='fa fa-envelope-o pr-1'></i>
              </span>
              Email Ship Out Documents
            </button>
            <button
              type='button'
              className='btn btn-labeled btn-secondary btn-sm mr-1'
              onClick={handleViewLoadPlanPdf}
              disabled={isDisabledBtnLoadPlan}>
              <span className='btn-label'>
                <i className='fa fa-print pr-1'></i>
              </span>
              Load Plan
            </button>
            <button
              type='button'
              className='btn btn-labeled btn-secondary btn-sm mr-1'
              onClick={handleViewShippedOutPdf}
              disabled={isDisabledBtnShippedOut}>
              <span className='btn-label'>
                <i className='fa fa-print pr-1'></i>
              </span>
              Shipped Out
            </button>
            <button
              className='btn btn-labeled btn-secondary btn-sm mr-1'
              onClick={populateMighalBilling}>
              <span className='btn-label'>
                <i className='fa fa-print pr-1'></i>
              </span>
              Mighal Billing
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MighalContainer;
