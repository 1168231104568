import AuthProtect from '../common/enums/AuthProtect';

export const authLogin = (data) => {
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    method: 'POST',
    url: '/login',
    data,
  };
};

export const forgotPassword = (data) => {
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    method: 'PUT',
    url: '/user/forgot_password',
    data,
  };
};

export const resetUserPassword = (token, data) => {
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    method: 'POST',
    url: '/user/reset_password/' + token,
    data,
  };
};

export const fetchUserList = () => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/user/user_list',
  };
};

export const userEmailForm = (params) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/user/user_email_form',
    params,
  };
};

export const changeUserRecord = (id, data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'PUT',
    url: `/user/${id}/edit`,
    data,
  };
};

export const addNewUser = (data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: `/user/register`,
    data,
  };
};

export const updateUserStatus = (id, data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'PUT',
    url: `/user/${id}/update_status`,
    data,
  };
};

export const changePassword = (data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'PUT',
    url: `/user/change_password`,
    data,
  };
};
