import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import * as ReactBootstrap from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';
import { useStateValue } from '../../context/StateProvider';
import momentTZ from 'moment-timezone';
import { SpinnerStyle } from '../../styles/SpinnerStyle';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { reject } from 'lodash';
import { fetchPOStatusGrid } from '../../services/poEndpoint';
import apiFetch from '../../services/apiFetch';
import { getMighalTagsPDF } from '../../services/reportsEndpoint';
import { sendTodayMRTReceiving } from '../../services/emailEndpoint';

function MighalTags() {
  const [state] = useStateValue();
  const [isLoading, setIsLoading] = useState(false);
  const [getListRender, setGetListRender] = useState(true);
  const [dataInfoGrid, setDataInfoGrid] = useState([]);
  const [consigneeName, setConsignee] = useState('');
  const [isAll, setIsAll] = useState(false);
  const [includeDelivered, setIncludeDelivered] = useState(false);
  const [minRangeValue, setMinRangeValue] = useState(1);
  const [maxRangeValue, setMaxRangeValue] = useState(999999);
  const [mrtDate, setMrtDate] = useState(new Date());

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const endpoint = fetchPOStatusGrid();
        const response = await apiFetch(endpoint);

        if (response && response.data) {
          let arr = response.data;
          if (Array.isArray(arr) && arr.length > 0) {
            setDataInfoGrid(arr);
            //console.log(arr);
          } else {
            setDataInfoGrid([]);
          }
        } else {
          setDataInfoGrid([]);
        }
        setGetListRender(false);
      } catch (error) {
        setGetListRender(false);
        console.log(error);
      }
    };

    if (getListRender) {
      getData().then(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }

    return () => setGetListRender(false);
  }, [getListRender, dataInfoGrid]);

  if (!state.isLogin) return <Redirect to='/login' />;
  if (state.isLogin && state.newUser) return <Redirect to='/change-password' />;

  const columns = [
    {
      text: 'Consignee',
      dataField: 'Consignee',
      sort: true,
      headerStyle: () => {
        return { width: '200px', textAlign: 'center' };
      },
      footer: '',
    },
    {
      text: 'Open',
      dataField: 'TotalOpen',
      align: 'center',
      headerStyle: () => {
        return { width: '150px', textAlign: 'center' };
      },
      footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
    },
    {
      text: 'Received',
      dataField: 'TotalReceived',
      align: 'center',
      headerStyle: () => {
        return { width: '150px', textAlign: 'center' };
      },
      footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
    },
    {
      text: 'Loading',
      dataField: 'TotalLoading',
      align: 'center',
      headerStyle: () => {
        return { width: '150px', textAlign: 'center' };
      },
      footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
    },
    {
      text: 'Shipped',
      dataField: 'TotalShipped',
      align: 'center',
      headerStyle: () => {
        return { width: '150px', textAlign: 'center' };
      },
      footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
    },
    {
      text: 'Total',
      dataField: 'Total',
      align: 'center',
      headerStyle: () => {
        return { width: '150px', textAlign: 'center' };
      },
      footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
    },
  ];
  const handleGeneratePDFReport = (ev) => {
    ev.preventDefault();
    try {
      setIsLoading(true);
      if (isAll) {
        downloadViewReportPdf()
          .then((response) => {
            setIsLoading(false);
            const url = window.URL.createObjectURL(
              new Blob([response.data], { type: 'application/pdf' }),
            );
            window.open(url);
          })
          .catch((err) => {
            console.log(err);
            alert('Error Generating PDF Report');
            setIsLoading(false);
          });
      } else {
        if (consigneeName !== '') {
          downloadViewReportPdf()
            .then((response) => {
              setIsLoading(false);
              const url = window.URL.createObjectURL(
                new Blob([response.data], { type: 'application/pdf' }),
              );
              window.open(url);
            })
            .catch((err) => {
              console.log(err);
              alert('Error Generating PDF Report');
              setIsLoading(false);
            });
        } else {
          alert('Please select a Consignee');
          setIsLoading(false);
        }
      }
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const downloadViewReportPdf = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let today = new Date();
        let countryZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
        let userToday = momentTZ.tz(today, countryZone).format('MM/DD/YYYY');
        let userAsOf = momentTZ.tz(today, countryZone).format('MMMM DD, YYYY');
        let printTime = momentTZ.tz(today, countryZone).format('hh:mm A');

        let Consignee_Name;
        if (!isAll) {
          Consignee_Name = consigneeName;
        } else {
          Consignee_Name = 'All';
        }

        const data = {
          UserEmail: localStorage.getItem('logisticsUseremail'),
          Consignee: Consignee_Name,
          ShowAllConsignee: isAll,
          IncludeDelivered: includeDelivered,
          MinRangeValue: minRangeValue,
          MaxRangeValue: maxRangeValue,
          userToday,
          userAsOf,
          printTime,
        };
        const endpoint = getMighalTagsPDF(data);
        const response = await apiFetch(endpoint);

        if (response && response.data.type !== 'application/json') {
          resolve(response);
        } else {
          reject('Failed');
        }
      } catch (error) {
        console.log(error);
        reject('Failed');
      }
    });
  };

  const handleChangeRange = (props) => (ev) => {
    try {
      if (isNaN(ev.target.value)) {
        return alert('Input only Number Format');
      }

      if (props === 'minRangeValue') {
        setMinRangeValue(ev.target.value);
      } else {
        setMaxRangeValue(ev.target.value);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleChecked = () => {
    setIsAll(!isAll);
  };

  const handleCheckedIsDelivered = () => {
    setIncludeDelivered(!includeDelivered);
  };

  const onRowSelect = (row) => {
    //console.log(row);
    try {
      setConsignee(row.Consignee);
    } catch (error) {
      console.log(error);
    }
  };

  const selectRowProp = {
    mode: 'radio',
    clickToSelect: true,
    onSelect: onRowSelect,
  };

  const modifiedDate = (dateValue) => {
    return new Date(momentTZ.tz(dateValue, 'Pacific/Guam'));
  };

  const onMRTDateChange = (selected) => {
    let AdjusteddateValue = new Date(momentTZ.tz(selected, 'Pacific/Guam'));
    setMrtDate(AdjusteddateValue);
  };

  const formatDateSQL = (valueDate) => {
    let d = new Date(valueDate),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('/');
  };

  const handleSendMRTEmail = () => {
    try {
      setIsLoading(true);
      if (consigneeName !== '') {
        sendMRTEmail()
          .then(() => {
            // console.log(result);
            alert(`Successfully Emailed MRT Nofitication to ${consigneeName}`);
            setIsLoading(false);
          })
          .catch((err) => {
            // console.log({ err });
            // console.log(typeof err);
            if (err === 'Empty') {
              alert(`There was no MRT Receiving for ${consigneeName} on ${formatDateSQL(mrtDate)}`);
            } else {
              alert(`Failed to Email MRT Nofitication to ${consigneeName}. Contact System Admin.`);
            }
            setIsLoading(false);
          });
      } else {
        setIsLoading(false);
        alert('Please select a Consignee');
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  const sendMRTEmail = () => {
    try {
      return new Promise(async (resolve, reject) => {
        const data = {
          UserEmail: localStorage.getItem('logisticsUseremail'),
          Consignee: consigneeName,
          mrtDate: formatDateSQL(mrtDate),
        };
        const endpoint = sendTodayMRTReceiving(data);
        const response = await apiFetch(endpoint);

        if (response && response.data === 'Success') {
          resolve('Success');
        } else if (response.data === 'Empty') {
          reject('Empty');
        } else {
          reject('Failed');
        }
      });
    } catch (e) {
      console.log(e);
      reject('Failed');
    }
  };

  return (
    <div className='wrapper'>
      <div className='loading-spinner'>
        <ClipLoader size={100} color={'#0054a6'} loading={isLoading} css={SpinnerStyle} />
      </div>
      <div className='help-button'>
        <a
          href='https://triplejlogistics.org/help/ReportsMighalTagsMenu.html'
          target='_blank'
          rel='noopener noreferrer'
          className='btn btn-labeled btn-outline-dark btn-sm mr-1'>
          <span className='btn-label'>
            <i className='fa fa-question-circle pr-1'></i>
          </span>
          Help
        </a>
      </div>
      <div className='generic-content-container container-fluid'>
        <h2>Mighal Receiving Tags</h2>
      </div>
      <div className='container-fluid'>
        <div className='container-fluid'>
          <ReactBootstrap.Row className='pb-2'>
            <button
              type='button'
              className='btn btn-labeled btn-primary btn-sm mr-1'
              onClick={handleGeneratePDFReport}>
              <span className='btn-label'>
                <i className='fa fa-print pr-1'></i>
              </span>
              View PDF Report
            </button>
            <ReactBootstrap.Form.Check
              type='checkbox'
              checked={isAll}
              onChange={handleChecked}
              id='select_allconsignee'
              className='ml-2 mt-auto mb-auto'
              label='All Consignee'
            />
            <ReactBootstrap.Form.Check
              type='checkbox'
              checked={includeDelivered}
              onChange={handleCheckedIsDelivered}
              id='select_isdelivered'
              className='ml-2 mt-auto mb-auto'
              label='Delivered'
            />
            <label className='ml-4 mb-auto mt-auto'>Filter Control No. From:</label>
            <input
              type='text'
              className='m-1 pr-2 text-md-right'
              value={minRangeValue}
              onChange={handleChangeRange('minRangeValue')}
            />
            <label className='ml-2 mb-auto mt-auto'>To:</label>
            <input
              type='text'
              className='m-1 pr-2 text-md-right'
              value={maxRangeValue}
              onChange={handleChangeRange('maxRangeValue')}
            />
          </ReactBootstrap.Row>
          <ReactBootstrap.Row className='pb-2'>
            <button
              type='button'
              className='btn btn-labeled btn-primary btn-sm mr-1'
              onClick={handleSendMRTEmail}>
              <span className='btn-label'>
                <i className='fa fa-envelope-o pr-1'></i>
              </span>
              Send MRT Email Notification
            </button>
            <div className='ml-2 mt-auto mb-auto'>
              <DatePicker
                selected={modifiedDate(mrtDate)}
                locale='en'
                onChange={onMRTDateChange}
                className=''
              />
            </div>
          </ReactBootstrap.Row>
        </div>
      </div>
      <div className='container-fluid table--horiz-scroll'>
        <BootstrapTable
          keyField='Consignee'
          data={dataInfoGrid}
          columns={columns}
          selectRow={selectRowProp}
          footerClasses='dashboard-foot'
          striped
          hover
        />
      </div>
    </div>
  );
}

export default MighalTags;
