import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import * as ReactBootstrap from 'react-bootstrap';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ClipLoader from 'react-spinners/ClipLoader';
import FileSaver from 'file-saver';
import momentTZ from 'moment-timezone';
import { SpinnerStyle } from '../../styles/SpinnerStyle';
import { useStateValue } from '../../context/StateProvider';
import DoubleScrollbar from 'react-double-scrollbar';
import { getContInfoWithPO, getLoadPlanViewList } from '../../services/containerEndpoint';
import apiFetch from '../../services/apiFetch';
import { sendLoadPlan } from '../../services/emailEndpoint';
import {
  getPOListByContNoPDF,
  getSummaryLoadingContainerPDF,
  getVesselZeroPOAmountsPDF,
} from '../../services/reportsEndpoint';
import { generateReceivingSheet } from '../../services/excelEndpoint';

function ContainerList() {
  const [state] = useStateValue();
  const [isLoading, setIsLoading] = useState(false);
  const [getListRender, SetgetListRender] = useState(true);
  const [dataInfoGrid, SetDataInfoGrid] = useState([]);
  const [ContainerNo, SetContainerNo] = useState('');
  const [ContainerID, SetContainerID] = useState(0);
  const [ContainerStatus, SetContainerStatus] = useState('');
  const [DepartureDate, SetDepartureDate] = useState('');
  const [ArrivalDate, SetArrivalDate] = useState('');
  const [showDeliver, SetShowDeliver] = useState(false);
  const [viewContBy, SetViewContBy] = useState('mighal');
  const [Voyage, SetVoyage] = useState('');
  const [Vessel, SetVessel] = useState('');
  const [Consignee, SetConsignee] = useState('');
  // const [isAffiliate, setIsAffiliate] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const params = {
          showDeliver: false,
          filterContainer: viewContBy,
        };
        const endpoint = getLoadPlanViewList(params);
        const response = await apiFetch(endpoint);

        if (response && response.data) {
          let arr = response.data;

          if (Array.isArray(arr) && arr.length > 0) {
            SetDataInfoGrid(arr);
          } else {
            SetDataInfoGrid([]);
          }
        } else {
          SetDataInfoGrid([]);
        }
        SetgetListRender(false);
      } catch (error) {
        SetgetListRender(false);
        console.log(error);
      }
    };

    if (getListRender) {
      getData().then(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }

    return () => {
      SetgetListRender(false);
    };
  }, [getListRender, dataInfoGrid]);

  if (!state.isLogin) return <Redirect to='/login' />;
  if (state.isLogin && state.newUser) return <Redirect to='/change-password' />;

  const formatINTNumber = (number) => {
    const fixedNumber = Number.parseInt(number === null ? 0 : number);
    return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const formatFixNumber = (cell) => {
    return formatINTNumber(cell);
  };

  const formatNumber = (number) => {
    const fixedNumber = Number.parseFloat(number === null ? 0 : number).toFixed(2);
    return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const formatInvAmt = (cell, row) => {
    return formatNumber(row.TtlInvoiceAmt);
  };

  const formatIsMighal = (cell, row) => {
    return row.IsMighal ? 'Yes' : 'No';
  };

  const NoneAffiliateStyle = (cell, row) => {
    if (row.isAffiliate === 0) {
      return {
        backgroundColor: '#f0f0b6',
      };
    }
  };

  const columns = [
    {
      dataField: 'HasZeroInvAmt',
      text: 'hasZero',
      hidden: true,
    },
    {
      dataField: 'isAffiliate',
      text: 'IsAffiliate',
      hidden: true,
    },
    {
      dataField: 'Container_ID',
      text: 'Id',
      hidden: true,
      headerStyle: () => {
        return { width: '40px', textAlign: 'left' };
      },
    },
    {
      dataField: 'Status',
      text: 'Status',
      sort: true,
      style: NoneAffiliateStyle,
      headerStyle: () => {
        return { width: '80px', textAlign: 'center' };
      },
      filter: textFilter(),
    },
    {
      dataField: 'Destination',
      text: 'Consignee',
      sort: true,
      style: NoneAffiliateStyle,
      headerStyle: () => {
        return { width: '120px', textAlign: 'center' };
      },
      filter: textFilter(),
    },
    {
      dataField: 'Temperature_Type',
      text: 'Temp',
      sort: true,
      style: NoneAffiliateStyle,
      headerStyle: () => {
        return { width: '80px', textAlign: 'center' };
      },
      filter: textFilter(),
    },
    {
      dataField: 'Container_No',
      text: 'Container No.',
      sort: true,
      style: NoneAffiliateStyle,
      headerStyle: () => {
        return { width: '200px', textAlign: 'center' };
      },
      filter: textFilter(),
    },
    {
      dataField: 'Booking',
      text: 'Booking No.',
      style: NoneAffiliateStyle,
      headerStyle: () => {
        return { width: '150px', textAlign: 'center' };
      },
      filter: textFilter(),
    },
    {
      dataField: 'Seal_No',
      text: 'Seal No.',
      style: NoneAffiliateStyle,
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
    },
    {
      dataField: 'Container_Type',
      text: 'Container',
      style: NoneAffiliateStyle,
      headerStyle: () => {
        return { width: '150px', textAlign: 'center' };
      },
    },
    {
      dataField: 'Vessel',
      text: 'Vessel',
      sort: true,
      style: NoneAffiliateStyle,
      headerStyle: () => {
        return { width: '150px', textAlign: 'center' };
      },
      filter: textFilter(),
    },
    {
      dataField: 'Voyage',
      text: 'Voyage',
      align: 'left',
      style: NoneAffiliateStyle,
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
      filter: textFilter(),
    },
    {
      dataField: 'Departure_Date',
      text: 'Departure',
      sort: true,
      style: NoneAffiliateStyle,
      align: 'center',
      headerStyle: () => {
        return { width: '120px', textAlign: 'center' };
      },
      filter: textFilter(),
    },
    {
      dataField: 'Arrival_Date',
      text: 'Arrival',
      sort: true,
      align: 'center',
      style: NoneAffiliateStyle,
      headerStyle: () => {
        return { width: '120px', textAlign: 'center' };
      },
      filter: textFilter(),
    },
    {
      dataField: 'TtlPlt',
      text: 'TTL PLT',
      align: 'right',
      style: NoneAffiliateStyle,
      headerStyle: () => {
        return { width: '90px', textAlign: 'center' };
      },
      formatter: formatFixNumber,
    },
    {
      dataField: 'TtlPcs',
      text: 'TTL QTY',
      align: 'right',
      style: NoneAffiliateStyle,
      headerStyle: () => {
        return { width: '90px', textAlign: 'center' };
      },
      formatter: formatFixNumber,
    },
    {
      dataField: 'TtlLbs',
      text: 'TTL LBS',
      align: 'right',
      style: NoneAffiliateStyle,
      headerStyle: () => {
        return { width: '90px', textAlign: 'center' };
      },
      formatter: formatFixNumber,
    },
    {
      dataField: 'TtlCube',
      text: 'TTL CFT',
      align: 'right',
      style: NoneAffiliateStyle,
      headerStyle: () => {
        return { width: '90px', textAlign: 'center' };
      },
      formatter: formatFixNumber,
    },
    {
      dataField: 'Available_Cu',
      text: 'Available Size Cu.Ft.',
      align: 'right',
      hidden: true,
      style: NoneAffiliateStyle,
      headerStyle: () => {
        return { width: '150px', textAlign: 'center' };
      },
    },
    {
      dataField: 'TtlInvoiceAmt',
      text: 'Total Invoice Amount',
      align: 'right',
      style: (cell, row) => {
        if (row.HasZeroInvAmt === 1) {
          return {
            backgroundColor: '#ff9900',
          };
        }
        if (row.isAffiliate === 0) {
          return {
            backgroundColor: '#f0f0b6',
          };
        }
      },
      headerStyle: () => {
        return { width: '120px', textAlign: 'center' };
      },
      formatter: formatInvAmt,
    },
    {
      dataField: 'Invoice_No',
      text: 'Invoice No',
      align: 'center',
      style: NoneAffiliateStyle,
      headerStyle: () => {
        return { width: '90px', textAlign: 'center' };
      },
      editable: false,
    },
    {
      dataField: 'IsMighal',
      text: 'Mighal Container',
      align: 'center',
      style: NoneAffiliateStyle,
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
      formatter: formatIsMighal,
      editable: false,
    },
  ];

  const formatDate = (valueDate) => {
    let d = new Date(valueDate),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('/');
  };

  const handleChecked = () => {
    SetShowDeliver(!showDeliver);
    try {
      setIsLoading(true);
      loadContainerGridView(!showDeliver, viewContBy)
        .then((result) => {
          SetDataInfoGrid(result);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const handleViewContByChange = (ev) => {
    try {
      SetViewContBy(ev.target.value);
      loadContainerGridView(showDeliver, ev.target.value)
        .then((result) => {
          SetDataInfoGrid(result);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const loadContainerGridView = (isDelivered, optContBy) => {
    return new Promise(async (resolve, reject) => {
      try {
        const params = {
          showDeliver: isDelivered,
          filterContainer: optContBy,
        };
        const endpoint = getLoadPlanViewList(params);
        const response = await apiFetch(endpoint);

        if (response && response.data) {
          let arr = response.data;
          /* console.log(arr);
          console.log(arr.length);
          console.log(Array.isArray(arr)); */
          if (Array.isArray(arr) && arr.length > 0) {
            resolve(arr);
          } else {
            reject('Empty');
          }
        } else {
          reject('Empty');
        }
      } catch (e) {
        console.log(e);
        reject('Failed');
      }
    });
  };

  const sendEmailNotification = (ev) => {
    ev.preventDefault();
    try {
      setIsLoading(true);
      if (ContainerID > 0) {
        getContainerInfoWithPO()
          .then((result) => {
            let IsMighal = result[0].IsMighal;
            let PoListQuery = [];
            let arrData = result;
            let contData = result[0];

            for (const iterator of arrData) {
              if (iterator.PO_No !== null) {
                PoListQuery.push(iterator.PO_No);
              } else {
                setIsLoading(false);
                return alert('Selected Container does not have a single PO');
              }
            }

            const bodyData = {
              PrintDate: new Date(),
              token: localStorage.getItem('logisticsToken'),
              UserEmail: localStorage.getItem('logisticsUseremail'),
              ContId: ContainerID,
              Departure: formatDate(contData.Departure_Date),
              Attention: contData.Attention,
              Arrival: formatDate(contData.Arrival_Date),
              Consignee: contData.Destination,
              ContType: contData.Container_Type,
              Destination: contData.Destination,
              Booking: contData.Booking,
              Vessel: contData.Vessel,
              Seal: contData.Seal_No,
              Voyage: contData.Voyage,
              Container_No: contData.Container_No,
              To: contData.Email,
              PO_List: PoListQuery,
              Comments: contData.Comment,
              Temperature: contData.Temperature_Type,
            };

            executeSendAPIEmail(IsMighal, bodyData)
              .then(() => {
                //console.log(result);
                setIsLoading(false);
                alert('Successfully sent Email Notifications');
              })
              .catch(() => {
                //console.log(err);
                setIsLoading(false);
                alert('Failed to send Email Notifications');
              });
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
            alert('Unable to get Container info details');
          });
      } else {
        alert('Please select a Container');
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  const getContainerInfoWithPO = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const params = {
          containerId: ContainerID,
        };
        const endpoint = getContInfoWithPO(params);
        const response = await apiFetch(endpoint);
        //console.log(typeof response.data);
        if (response && response.data) {
          let data = response.data;
          //console.log(data.length);
          if (data.length) {
            resolve(data);
          } else {
            reject('Failed');
          }
        } else {
          reject('Failed');
        }
      } catch (e) {
        console.log(e);
        reject('Failed');
      }
    });
  };

  const executeSendAPIEmail = (IsMighal, DataBody) => {
    return new Promise(async (resolve, reject) => {
      try {
        setIsLoading(true);
        const endpoint = sendLoadPlan(ContainerID, DataBody);
        const response = await apiFetch(endpoint);
        if (response && response.data === 'Success') {
          resolve('Success');
        } else {
          reject('Failed');
        }
      } catch (e) {
        console.log(e);
        reject('Catch Failed');
      }
    });
  };

  const handleContainerListPdfReport = (ev) => {
    ev.preventDefault();
    try {
      if (ContainerNo !== '') {
        setIsLoading(true);
        downloadPdf()
          .then((response) => {
            setIsLoading(false);
            const url = window.URL.createObjectURL(
              new Blob([response.data], { type: 'application/pdf' }),
            );
            window.open(url);
          })
          .catch((err) => {
            console.log(err);
            alert('Error Generating PDF Report');
            setIsLoading(false);
          });
      } else {
        alert('Please select a Container');
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const downloadPdf = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let today = new Date();
        let countryZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
        let userToday = momentTZ.tz(today, countryZone).format('MM/DD/YYYY');
        let userAsOf = momentTZ.tz(today, countryZone).format('MMMM DD, YYYY');
        let printTime = momentTZ.tz(today, countryZone).format('hh:mm A');

        const data = {
          UserEmail: localStorage.getItem('logisticsUseremail'),
          Container_No: ContainerNo,
          ContainerStatus,
          DepartureDate,
          ArrivalDate,
          userToday,
          userAsOf,
          printTime,
        };
        const endpoint = getPOListByContNoPDF(data);

        let response = await apiFetch(endpoint);

        if (response && response.data.type !== 'application/json') {
          resolve(response);
        } else {
          reject('Failed');
        }
      } catch (error) {
        console.log(error);
        reject('Failed');
      }
    });
  };

  const handleSummaryReport = (ev) => {
    ev.preventDefault();
    try {
      setIsLoading(true);
      downloadSummaryLoading()
        .then((response) => {
          setIsLoading(false);
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: 'application/pdf' }),
          );
          window.open(url);
        })
        .catch((err) => {
          console.log(err);
          alert('Error Generating PDF Report');
          setIsLoading(false);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const downloadSummaryLoading = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let today = new Date();
        let countryZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
        let userToday = momentTZ.tz(today, countryZone).format('MM/DD/YYYY');
        let userAsOf = momentTZ.tz(today, countryZone).format('MMMM DD, YYYY');
        let printTime = momentTZ.tz(today, countryZone).format('hh:mm A');

        const data = {
          UserEmail: localStorage.getItem('logisticsUseremail'),
          FilterBy: viewContBy,
          userToday,
          userAsOf,
          printTime,
        };
        const endpoint = getSummaryLoadingContainerPDF(data);
        const response = await apiFetch(endpoint);

        if (response && response.data.type !== 'application/json') {
          resolve(response);
        } else {
          reject('Failed');
        }
      } catch (e) {
        console.log(e);
        reject('Failed');
      }
    });
  };

  const handleReceivingSheet = (ev) => {
    try {
      ev.preventDefault();
      if (ContainerNo !== '') {
        setIsLoading(true);
        downloadReceivingExcel()
          .then((res) => {
            setIsLoading(false);
            //console.log(res);
            let byteCharacters = atob(res.data);
            let byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            let byteArray = new Uint8Array(byteNumbers);
            let blob = new Blob([byteArray], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });

            /* let blob = new Blob([res.data], {
              type: 'contentType',
            }); */
            FileSaver.saveAs(blob, `ReceivingSheet_${ContainerNo}.xlsx`);
          })
          .catch(() => {
            setIsLoading(false);
            alert('Unable to generate Excel');
          });
      } else {
        alert('Please select a Container');
      }
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const downloadReceivingExcel = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const data = {
          UserEmail: localStorage.getItem('logisticsUseremail'),
          Container_ID: ContainerID,
        };
        const endpoint = generateReceivingSheet(data);
        const response = await apiFetch(endpoint);

        if (response) {
          resolve(response);
        } else {
          reject('Failed');
        }
      } catch (e) {
        console.log(e);
        reject('Failed');
      }
    });
  };

  const handleZeroAmountByVessel = () => {
    try {
      if (Consignee === '') {
        return alert('Please select a container.');
      }

      setIsLoading(true);
      downloadZeroAmountReport()
        .then((response) => {
          setIsLoading(false);
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: 'application/pdf' }),
          );
          window.open(url);
        })
        .catch((err) => {
          console.log(err);
          alert('Error Generating PDF Report');
          setIsLoading(false);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const downloadZeroAmountReport = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let today = new Date();
        let countryZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
        let userToday = momentTZ.tz(today, countryZone).format('MM/DD/YYYY');
        let userAsOf = momentTZ.tz(today, countryZone).format('MMMM DD, YYYY');
        let printTime = momentTZ.tz(today, countryZone).format('hh:mm A');

        const data = {
          UserEmail: localStorage.getItem('logisticsUseremail'),
          Consignee,
          Vessel,
          Voyage,
          Departure_Date: formatDate(DepartureDate),
          showDeliver,
          userToday,
          userAsOf,
          printTime,
        };
        const endpoint = getVesselZeroPOAmountsPDF(data);
        let response = await apiFetch(endpoint);

        if (response.data.type !== 'application/json') {
          resolve(response);
        } else {
          reject('Failed');
        }
      } catch (e) {
        console.log(e);
        reject('Failed');
      }
    });
  };

  const onRowSelect = (row) => {
    try {
      SetContainerNo(row.Container_No);
      SetContainerID(row.Container_ID);
      SetContainerStatus(row.Status);
      SetDepartureDate(row.Departure_Date);
      SetArrivalDate(row.Arrival_Date);
      // setIsAffiliate(row.isAffiliate === 1);
      SetVessel(row.Vessel);
      SetVoyage(row.Voyage);
      SetConsignee(row.Destination);
    } catch (error) {
      console.log(error);
    }
  };

  const selectRowProp = {
    mode: 'radio',
    clickToSelect: true,
    onSelect: onRowSelect,
  };

  const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className='btn-group' role='group'>
      {options.map((option) => {
        const isSelect = currSizePerPage === `${option.page}`;
        return (
          <button
            key={option.text}
            type='button'
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${isSelect ? 'btn-secondary' : 'btn-default'}`}>
            {option.text}
          </button>
        );
      })}
    </div>
  );

  const PaginationOptions = {
    hideSizePerPage: true,
    sizePerPageRenderer,
  };

  return (
    <div className='wrapper'>
      <div className='loading-spinner'>
        <ClipLoader size={100} color={'#0054a6'} loading={isLoading} css={SpinnerStyle} />
      </div>
      <div className='help-button'>
        <a
          href='https://triplejlogistics.org/help/ReportsContainerListMenu.html'
          target='_blank'
          rel='noopener noreferrer'
          className='btn btn-labeled btn-outline-dark btn-sm mr-1'>
          <span className='btn-label'>
            <i className='fa fa-question-circle pr-1'></i>
          </span>
          Help
        </a>
      </div>
      <div className='generic-content-container container-fluid'>
        <h2>Container List</h2>
      </div>
      <div className='container-fluid'>
        <div className='container-fluid'>
          <ReactBootstrap.Row className='mb-2'>
            <button
              type='button'
              className='btn btn-labeled btn-primary btn-sm mr-1'
              onClick={handleContainerListPdfReport}>
              <span className='btn-label'>
                <i className='fa fa-print pr-1'></i>
              </span>
              View PDF Report
            </button>
            <button
              type='button'
              className='btn btn-labeled btn-primary btn-sm mr-1'
              onClick={sendEmailNotification}
              disabled={true}
              hidden={true}>
              <span className='btn-label'>
                <i className='fa fa-envelope-o pr-1'></i>
              </span>
              Email Notification
            </button>
            <button
              type='button'
              className='btn btn-labeled btn-primary btn-sm mr-1'
              onClick={handleReceivingSheet}>
              <span className='btn-label'>
                <i className='fa fa-file-excel-o pr-1'></i>
              </span>
              Receiving Excel Sheet
            </button>
            <button
              type='button'
              className='btn btn-labeled btn-primary btn-sm mr-1'
              onClick={handleSummaryReport}>
              <span className='btn-label'>
                <i className='fa fa-file-pdf-o pr-1'></i>
              </span>
              Summary Loading Container
            </button>
            <button
              type='button'
              className='btn btn-labeled btn-primary btn-sm mr-1'
              onClick={handleZeroAmountByVessel}>
              <span className='btn-label'>
                <i className='fa fa-file-pdf-o pr-1'></i>
              </span>
              Zero Amount by Vessel
            </button>
            <div className='mt-auto mb-auto'>
              <ReactBootstrap.Form.Check
                type='checkbox'
                checked={showDeliver}
                onChange={handleChecked}
                id='select_isdelivered'
                className='ml-2'
                label='Delivered'
              />
            </div>
            <label className='radio-btn mt-auto mb-auto'>Container By: </label>
            <input
              className='radio-btn mt-auto mb-auto'
              type='radio'
              id='container'
              name='view'
              value='mighal'
              checked={viewContBy === 'mighal'}
              onChange={handleViewContByChange}
            />
            <label htmlFor='container' className='mt-auto mb-auto'>
              Mighal
            </label>
            <input
              className='radio-btn mt-auto mb-auto'
              type='radio'
              id='container'
              name='view'
              value='nonmighal'
              checked={viewContBy === 'nonmighal'}
              onChange={handleViewContByChange}
            />
            <label htmlFor='container' className='mt-auto mb-auto'>
              Non-Mighal
            </label>
            <input
              className='radio-btn mt-auto mb-auto'
              type='radio'
              id='container'
              name='view'
              value='all'
              checked={viewContBy === 'all'}
              onChange={handleViewContByChange}
            />
            <label htmlFor='container' className='mt-auto mb-auto'>
              All
            </label>
          </ReactBootstrap.Row>
        </div>
      </div>
      <div className='container-fluid table--horiz-scroll'>
        <DoubleScrollbar>
          <BootstrapTable
            keyField='Container_ID'
            data={dataInfoGrid}
            columns={columns}
            selectRow={selectRowProp}
            filter={filterFactory()}
            pagination={paginationFactory(PaginationOptions)}
            filterPosition='top'
            striped
            hover
          />
        </DoubleScrollbar>
      </div>
    </div>
  );
}

export default ContainerList;
