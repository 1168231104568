import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import * as ReactBootstrap from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ClipLoader from 'react-spinners/ClipLoader';
import { useStateValue } from '../../context/StateProvider';
import momentTZ from 'moment-timezone';
// import moment from 'moment';
import { SpinnerStyle } from '../../styles/SpinnerStyle';
import { getVesselListGrid, getVesselListPDF } from '../../services/containerEndpoint';
import apiFetch from '../../services/apiFetch';
//import DispatchContext from '../../context/DispatchContext';

function VesselList() {
  const [state] = useStateValue();
  const [isLoading, setIsLoading] = useState(false);
  const [getListRender, setGetListRender] = useState(true);
  const [dataInfoGrid, SetDataInfoGrid] = useState([]);
  const [vesselName, SetVesselName] = useState('');
  const [voyageNo, SetVoyageNo] = useState('');
  const [departDate, SetDepartDate] = useState('');
  const [showDeliver, setShowDeliver] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const params = {
          showDeliver,
        };
        const endpoint = getVesselListGrid(params);
        const response = await apiFetch(endpoint);

        if (response && response.data) {
          let arr = response.data;

          if (Array.isArray(arr) && arr.length > 0) {
            SetDataInfoGrid(arr);
          } else {
            SetDataInfoGrid([]);
          }
        } else {
          SetDataInfoGrid([]);
        }
        setGetListRender(false);
      } catch (error) {
        setGetListRender(false);
        console.log(error);
      }
    };

    if (getListRender) {
      getData().then(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }

    return () => setGetListRender(false);
  }, [getListRender]);

  if (!state.isLogin) return <Redirect to='/login' />;
  if (state.isLogin && state.newUser) return <Redirect to='/change-password' />;

  /*   const clearFilter = () => {
    vesselFilter('');
  }; */

  const columns = [
    {
      dataField: 'ID',
      text: 'Id',
      hidden: true,
      headerStyle: () => {
        return { width: '40px', textAlign: 'left' };
      },
    },
    {
      dataField: 'Vessel',
      text: 'Vessel',
      sort: true,
      headerStyle: () => {
        return { width: '150px', textAlign: 'center' };
      },
      filter: textFilter(),
    },
    {
      dataField: 'Voyage',
      text: 'Voyage Number',
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
      filter: textFilter(),
    },
    {
      dataField: 'Departure_Date',
      text: 'Departure',
      align: 'center',
      sort: true,
      headerStyle: () => {
        return { width: '120px', textAlign: 'center' };
      },
      filter: textFilter(),
    },
  ];

  const onRowSelect = (row) => {
    try {
      //console.log(row);
      SetVesselName(row.Vessel);
      SetVoyageNo(parseInt(row.Voyage));
      SetDepartDate(row.Departure_Date);
      //SetContId(row.Container_ID);
      //SetAvailCube(row.Available_Cu);
    } catch (error) {
      console.log(error);
    }
  };

  const selectRowProp = {
    mode: 'radio',
    clickToSelect: true,
    onSelect: onRowSelect,
  };

  const handleVesselPdfReport = (ev) => {
    ev.preventDefault();
    try {
      if (vesselName !== '') {
        setIsLoading(true);
        downloadPdf()
          .then((response) => {
            setIsLoading(false);
            const url = window.URL.createObjectURL(
              new Blob([response.data], { type: 'application/pdf' }),
            );
            window.open(url);
          })
          .catch((err) => {
            console.log(err);
            alert('Error Generating PDF Report');
            setIsLoading(false);
          });
      } else {
        alert('Please select a Vessel');
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const formatDateSQL = (valueDate) => {
    let d = new Date(valueDate),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('/');
  };

  const downloadPdf = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let today = new Date();
        let countryZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
        let userToday = momentTZ.tz(today, countryZone).format('MM/DD/YYYY');
        let userAsOf = momentTZ.tz(today, countryZone).format('MMMM DD, YYYY');
        let printTime = momentTZ.tz(today, countryZone).format('hh:mm A');

        const data = {
          UserEmail: localStorage.getItem('logisticsUseremail'),
          Vessel: vesselName,
          Voyage: voyageNo,
          Departure: formatDateSQL(departDate.replace(/-/g, '/')),
          userToday,
          userAsOf,
          printTime,
        };
        const endpoint = getVesselListPDF(data);
        const response = await apiFetch(endpoint);

        if (response && response.data.type !== 'application/json') {
          resolve(response);
        } else {
          reject('Failed');
        }
      } catch (error) {
        console.log(error);
        reject('Failed');
      }
    });
  };

  const handleCheckedIsDelivered = () => {
    setShowDeliver(!showDeliver);
    setIsLoading(true);
    SetDataInfoGrid([]);
    getGridData(!showDeliver)
      .then((result) => {
        SetDataInfoGrid(result);
        setIsLoading(false);
      })
      .catch(() => {
        SetDataInfoGrid([]);
        setIsLoading(false);
      });
  };

  const getGridData = (isDeliver) => {
    return new Promise(async (resolve, reject) => {
      try {
        const params = {
          showDeliver: isDeliver,
        };
        const endpoint = getVesselListGrid(params);
        const response = await apiFetch(endpoint);
        if (response && response.data) {
          let arr = response.data;

          if (Array.isArray(arr) && arr.length > 0) {
            resolve(arr);
          } else {
            reject('Failed');
          }
        } else {
          reject('Failed');
        }
      } catch (error) {
        reject('Failed');
        console.log(error);
      }
    });
  };

  return (
    <div className='wrapper'>
      <div className='loading-spinner'>
        <ClipLoader size={100} color={'#0054a6'} loading={isLoading} css={SpinnerStyle} />
      </div>
      <div className='help-button'>
        <a
          href='https://triplejlogistics.org/help/ReportsVesselListMenu.html'
          target='_blank'
          rel='noopener noreferrer'
          className='btn btn-labeled btn-outline-dark btn-sm mr-1'>
          <span className='btn-label'>
            <i className='fa fa-question-circle pr-1'></i>
          </span>
          Help
        </a>
      </div>
      <div className='generic-content-container container-fluid'>
        <h2>Vessel Lists</h2>
      </div>
      <div className='container-fluid'>
        <div className='container-fluid'>
          <ReactBootstrap.Row className='pb-2'>
            <button
              type='button'
              className='btn btn-labeled btn-primary mr-1'
              onClick={handleVesselPdfReport}>
              <span className='btn-label'>
                <i className='fa fa-print pr-1'></i>
              </span>
              View PDF Report
            </button>
            <ReactBootstrap.Form.Check
              type='checkbox'
              checked={showDeliver}
              onChange={handleCheckedIsDelivered}
              id='select_isdelivered'
              className='ml-2 mt-auto mb-auto'
              label='Delivered'
            />
          </ReactBootstrap.Row>
        </div>
      </div>
      <div className='container-fluid scroll-container'>
        <BootstrapTable
          keyField='ID'
          data={dataInfoGrid}
          columns={columns}
          selectRow={selectRowProp}
          filter={filterFactory()}
          filterPosition='top'
          striped
          hover
        />
      </div>
    </div>
  );
}

export default VesselList;
