// noinspection JSUnresolvedVariable

import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import * as ReactBootstrap from 'react-bootstrap';
import { useStateValue } from '../context/StateProvider';
import _ from 'lodash';
import ClipLoader from 'react-spinners/ClipLoader';
import LoadingDotsIcon from '../components/LoadingDotsIcon';
import { SpinnerStyle } from '../styles/SpinnerStyle';
import { getSettings, updateUserSettingsInfo } from '../services/settingsEndpoint';
import apiFetch from '../services/apiFetch';

let initForm = {
  dryIns: 0,
  chillIns: 0,
  freezeIns: 0,
  docCharge: 0,
  drayageFee: 0,
  recorderFee: 0,
  consCharge20Ft: 0,
  consCharge40Ft: 0,
  consCharge40FtH: 0,
  lastMighalInvNo: 0,
  lastFileRefNo: 0,
  GuamLimitFrzLbs: 0,
  GuamLimitDryLbs: 0,
  GuamLimitFrzCu: 0,
  GuamLimitDryCu: 0,
  OtherLimitFrzLbs: 0,
  OtherLimitDryLbs: 0,
  OtherLimitFrzCu: 0,
  OtherLimitDryCu: 0,
};

function Settings() {
  const [state] = useStateValue();
  // const [isRender, SetIsRender] = useState(true);
  const [isFormDisable, SetisFormDisable] = useState(true);
  const [FormValue, SetFormValue] = useState(initForm);
  const [isDisabledBtnEdit, SetisDisabledBtnEdit] = useState(false);
  const [isDisabledBtnSave, SetisDisabledBtnSave] = useState(true);
  const [isDisabledBtnCancel, SetisDisabledBtnCancel] = useState(true);
  const [isLoading, SetIsLoading] = useState(false);
  const [isFetching, SetIsFetching] = useState(true);

  useEffect(() => {
    const getSettingsInfo = async () => {
      const endpoint = getSettings();
      const response = await apiFetch(endpoint);

      if (response.data) {
        let resData = response.data[0];
        if (!_.isEmpty(resData)) {
          let DataValue = {
            dryIns: resData.ContDryInsurance,
            chillIns: resData.ContChillInsurance,
            freezeIns: resData.ContFreezeInsurance,
            docCharge: resData.DocumentationCharge,
            drayageFee: resData.DrayageFee,
            recorderFee: resData.RecorderFee,
            consCharge20Ft: resData.ConsolidationCharge20Ft,
            consCharge40Ft: resData.ConsolidationCharge40Ft,
            consCharge40FtH: resData.ConsolidationCharge40FtH,
            lastMighalInvNo: resData.LastInvoiceMighal,
            lastFileRefNo: resData.LastFileRefNo,
            GuamLimitFrzLbs: resData.GuamLimitFrzLbs,
            GuamLimitDryLbs: resData.GuamLimitDryLbs,
            GuamLimitFrzCu: resData.GuamLimitFrzCu,
            GuamLimitDryCu: resData.GuamLimitDryCu,
            OtherLimitFrzLbs: resData.OtherLimitFrzLbs,
            OtherLimitDryLbs: resData.OtherLimitDryLbs,
            OtherLimitFrzCu: resData.OtherLimitFrzCu,
            OtherLimitDryCu: resData.OtherLimitDryCu,
          };
          SetFormValue(DataValue);
        }
      } else {
        SetFormValue(initForm);
      }
      SetIsFetching(false);
    };

    if (!isLoading) {
      getSettingsInfo().catch((e) => console.log(e));
    }
  }, []);

  if (!state.isLogin) return <Redirect to='/login' />;
  if (state.isLogin && state.newUser) return <Redirect to='/change-password' />;

  let errorsMsg = [];

  const validate = () => {
    return new Promise((resolve) => {
      errorsMsg = [];
      try {
        if (FormValue.dryIns === '') {
          errorsMsg.push('You must enter a Dry Insurance');
        }
        if (FormValue.chillIns === '') {
          errorsMsg.push('You must enter a Chill Insurance');
        }
        if (FormValue.freezeIns === '') {
          errorsMsg.push('You must enter a Freeze Insurance');
        }
        if (FormValue.docCharge === '') {
          errorsMsg.push('You must enter a Document Charge');
        }
        if (FormValue.consCharge20Ft === '') {
          errorsMsg.push('You must enter a Consolidation Charge for 20Ft Container');
        }
        if (FormValue.consCharge40Ft === '') {
          errorsMsg.push('You must enter a Consolidation Charge for 40Ft Container');
        }
        if (FormValue.consCharge40FtH === '') {
          errorsMsg.push('You must enter a Consolidation Charge for 40FtH Container');
        }
        if (FormValue.lastMighalInvNo === '') {
          errorsMsg.push('You must enter an Invoice Number');
        }

        if (FormValue.dryIns !== '' && isNaN(FormValue.dryIns)) {
          errorsMsg.push('You must provide Numeric Value only');
        }

        if (FormValue.chillIns !== '' && isNaN(FormValue.chillIns)) {
          errorsMsg.push('You must provide Numeric Value only');
        }
        if (FormValue.freezeIns !== '' && isNaN(FormValue.freezeIns)) {
          errorsMsg.push('You must provide Numeric Value only');
        }
        if (FormValue.docCharge !== '' && isNaN(FormValue.docCharge)) {
          errorsMsg.push('You must provide Numeric Value only');
        }
        if (FormValue.consCharge20Ft !== '' && isNaN(FormValue.consCharge20Ft)) {
          errorsMsg.push('You must provide Numeric Value only');
        }
        if (FormValue.consCharge40Ft !== '' && isNaN(FormValue.consCharge40Ft)) {
          errorsMsg.push('You must provide Numeric Value only');
        }
        if (FormValue.consCharge40FtH !== '' && isNaN(FormValue.consCharge40FtH)) {
          errorsMsg.push('You must provide Numeric Value only');
        }
        if (FormValue.lastMighalInvNo !== '' && isNaN(FormValue.lastMighalInvNo)) {
          errorsMsg.push('You must provide Numeric Value only');
        }

        if (parseFloat(FormValue.dryIns) === 0) {
          errorsMsg.push('Zero value not allowed in Dry Insurance');
        }
        if (parseFloat(FormValue.chillIns) === 0) {
          errorsMsg.push('Zero value not allowed in Chill Insurance');
        }
        if (parseFloat(FormValue.freezeIns) === 0) {
          errorsMsg.push('Zero value not allowed in Freeze Insurance');
        }
        if (parseFloat(FormValue.docCharge) === 0) {
          errorsMsg.push('Zero value not allowed in Document Charge');
        }
        if (parseFloat(FormValue.consCharge20Ft) === 0) {
          errorsMsg.push('Zero value not allowed in Consolidation Charge for 20Ft Container');
        }
        if (parseFloat(FormValue.consCharge40Ft) === 0) {
          errorsMsg.push('Zero value not allowed in Consolidation Charge for 40Ft Container');
        }
        if (parseFloat(FormValue.consCharge40FtH) === 0) {
          errorsMsg.push('Zero value not allowed in Consolidation Charge for 40FtH Container');
        }
        if (parseFloat(FormValue.lastMighalInvNo) === 0) {
          errorsMsg.push('Zero value not allowed in Invoice Number');
        }

        resolve();
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleSaveButton = async (ev) => {
    ev.preventDefault();
    try {
      SetIsLoading(true);
      await validate();
      if (!errorsMsg.length) {
        let CompData = {
          dryIns: parseFloat(FormValue.dryIns),
          chillIns: parseFloat(FormValue.chillIns),
          freezeIns: parseFloat(FormValue.freezeIns),
          docCharge: parseFloat(FormValue.docCharge),
          drayageFee: parseFloat(FormValue.drayageFee),
          recorderFee: parseFloat(FormValue.recorderFee),
          consCharge20Ft: parseFloat(FormValue.consCharge20Ft),
          consCharge40Ft: parseFloat(FormValue.consCharge40Ft),
          consCharge40FtH: parseFloat(FormValue.consCharge40FtH),
          lastMighalInvNo: parseInt(FormValue.lastMighalInvNo),
          lastFileRefNo: parseInt(FormValue.lastFileRefNo),
          GuamLimitFrzLbs: parseInt(FormValue.GuamLimitFrzLbs),
          GuamLimitDryLbs: parseInt(FormValue.GuamLimitDryLbs),
          GuamLimitFrzCu: parseInt(FormValue.GuamLimitFrzCu),
          GuamLimitDryCu: parseInt(FormValue.GuamLimitDryCu),
          OtherLimitFrzLbs: parseInt(FormValue.OtherLimitFrzLbs),
          OtherLimitDryLbs: parseInt(FormValue.OtherLimitDryLbs),
          OtherLimitFrzCu: parseInt(FormValue.OtherLimitFrzCu),
          OtherLimitDryCu: parseInt(FormValue.OtherLimitDryCu),
        };

        if (_.isEqual(initForm, CompData)) {
          SetIsLoading(false);
          return alert('No new data to update');
        }

        let bodyData = {
          DryIns: parseFloat(FormValue.dryIns),
          ChillIns: parseFloat(FormValue.chillIns),
          FrzIns: parseFloat(FormValue.freezeIns),
          DocCharge: parseFloat(FormValue.docCharge),
          DrayageFee: parseFloat(FormValue.drayageFee),
          RecorderFee: parseFloat(FormValue.recorderFee),
          Cons20: parseFloat(FormValue.consCharge20Ft),
          Cons40: parseFloat(FormValue.consCharge40Ft),
          Cons40H: parseFloat(FormValue.consCharge40FtH),
          LastInv: parseInt(FormValue.lastMighalInvNo),
          LastFileRef: parseInt(FormValue.lastFileRefNo),
          GuamLimitFrzLbs: parseInt(FormValue.GuamLimitFrzLbs),
          GuamLimitDryLbs: parseInt(FormValue.GuamLimitDryLbs),
          GuamLimitFrzCu: parseInt(FormValue.GuamLimitFrzCu),
          GuamLimitDryCu: parseInt(FormValue.GuamLimitDryCu),
          OtherLimitFrzLbs: parseInt(FormValue.OtherLimitFrzLbs),
          OtherLimitDryLbs: parseInt(FormValue.OtherLimitDryLbs),
          OtherLimitFrzCu: parseInt(FormValue.OtherLimitFrzCu),
          OtherLimitDryCu: parseInt(FormValue.OtherLimitDryCu),
          UserEmail: localStorage.getItem('logisticsUseremail'),
        };

        updateRecord(bodyData)
          .then(() => {
            SetIsLoading(false);
            alert('Successfully update System Parameter');
            applyDefaultBtnStatusState();
          })
          .catch(() => {
            SetIsLoading(false);
            alert('Unable to update!');
            applyDefaultBtnStatusState();
          });
      } else {
        SetIsLoading(false);
        errorsMsg.map((msg) => {
          return alert(msg);
        });
      }
    } catch (e) {
      console.log(e);
      SetIsLoading(false);
    }
  };

  const updateRecord = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoint = updateUserSettingsInfo(data);
        const response = await apiFetch(endpoint);
        if (response && response.data === 'Success') {
          resolve('Success');
        } else {
          reject('Failed');
        }
      } catch (e) {
        console.log(e);
        reject('Failed');
      }
    });
  };
  const applyDefaultBtnStatusState = () => {
    //SetFormValue(initForm);
    SetisFormDisable(true);
    SetisDisabledBtnEdit(false);
    SetisDisabledBtnSave(true);
    SetisDisabledBtnCancel(true);
  };

  const handleCancelButton = () => {
    if (!isFormDisable) {
      SetFormValue(initForm);
      SetisFormDisable(true);
      applyDefaultBtnStatusState();
    }
  };

  const handleEditButton = () => {
    if (localStorage.getItem('logisticsUserType') === 'Admin') {
      initForm = FormValue;
      SetisFormDisable(false);
      SetisDisabledBtnSave(false);
      SetisDisabledBtnCancel(false);
    } else {
      alert('Your account is not allowed to make changes');
    }
  };

  const handleChange = (prop) => (ev) => {
    try {
      //console.log(isNaN(ev.target.value));
      if (!isNaN(ev.target.value)) {
        SetFormValue({ ...FormValue, [prop]: ev.target.value });
      } else {
        alert('Input only Number Format');
      }
    } catch (e) {
      alert('Input only Number Format');
    }
  };

  if (isFetching) {
    return (
      <div className='wrapper'>
        <LoadingDotsIcon />
      </div>
    );
  }

  return (
    <div className='wrapper'>
      <div className='loading-spinner'>
        <ClipLoader size={100} color={'#0054a6'} loading={isLoading} css={SpinnerStyle} />
      </div>
      <div className='help-button'>
        <a
          href='https://triplejlogistics.org/help/SettingsMenu.html'
          target='_blank'
          rel='noopener noreferrer'
          className='btn btn-labeled btn-outline-dark btn-sm mr-1'>
          <span className='btn-label'>
            <i className='fa fa-question-circle pr-1'></i>
          </span>
          Help
        </a>
      </div>
      <div className='generic-content-container container'>
        <h2>Settings</h2>
      </div>
      <div className='container'>
        <div className='row mb-2'>
          <div className='col-md-12'>
            <button
              type='button'
              className='btn btn-sm btn-labeled btn-primary mr-1'
              disabled={isDisabledBtnEdit}
              onClick={handleEditButton}>
              <span className='btn-label'>
                <i className='fa fa-edit pr-1'></i>
              </span>
              Edit
            </button>
            <button
              type='button'
              className='btn btn-sm btn-labeled btn-primary mr-1'
              disabled={isDisabledBtnSave}
              onClick={handleSaveButton}>
              <span className='btn-label'>
                <i className='fa fa-check-square-o pr-1'></i>
              </span>
              Save
            </button>
            <button
              type='button'
              className='btn btn-sm btn-labeled btn-primary mr-1'
              disabled={isDisabledBtnCancel}
              onClick={handleCancelButton}>
              <span className='btn-label'>
                <i className='fa fa-ban pr-1'></i>
              </span>
              Cancel
            </button>
          </div>
        </div>
        <ReactBootstrap.Form>
          <ReactBootstrap.Row className='mb-2'>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Label>Mighal Last Invoice No.</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={7}>
              <ReactBootstrap.Form.Control
                placeholder='Mighal Last Invoice No...'
                disabled={isFormDisable}
                value={FormValue.lastMighalInvNo}
                onChange={handleChange('lastMighalInvNo')}
                tabIndex={1}
                required
              />
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>

          <ReactBootstrap.Row className='mb-2'>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Label>Last File Reference No.</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={7}>
              <ReactBootstrap.Form.Control
                placeholder='Last File Reference No...'
                disabled={isFormDisable}
                value={FormValue.lastFileRefNo}
                onChange={handleChange('lastFileRefNo')}
                tabIndex={2}
                required
              />
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>

          <ReactBootstrap.Row className='mb-2'>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Label>Dry Insurance Percentage</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={7}>
              <ReactBootstrap.Form.Control
                placeholder='Dry Insurance Percentage...'
                disabled={isFormDisable}
                value={FormValue.dryIns}
                onChange={handleChange('dryIns')}
                tabIndex={3}
                required
              />
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>

          <ReactBootstrap.Row className='mb-2'>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Label>Chill Insurance Percentage</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={7}>
              <ReactBootstrap.Form.Control
                placeholder='Chill Insurance Percentage...'
                disabled={isFormDisable}
                value={FormValue.chillIns}
                onChange={handleChange('chillIns')}
                tabIndex={4}
                required
              />
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>

          <ReactBootstrap.Row className='mb-2'>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Label>Freeze Insurance Percentage</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={7}>
              <ReactBootstrap.Form.Control
                placeholder='Freeze Insurance Percentage...'
                disabled={isFormDisable}
                value={FormValue.freezeIns}
                onChange={handleChange('freezeIns')}
                tabIndex={5}
                required
              />
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>

          <ReactBootstrap.Row className='mb-2'>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Label>Documentation Charge</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={7}>
              <ReactBootstrap.Form.Control
                placeholder='Documentation Charge...'
                disabled={isFormDisable}
                value={FormValue.docCharge}
                onChange={handleChange('docCharge')}
                tabIndex={6}
                required
              />
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>

          <ReactBootstrap.Row className='mb-2'>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Label>Drayage Charge</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={7}>
              <ReactBootstrap.Form.Control
                placeholder='Drayage Charge...'
                disabled={isFormDisable}
                value={FormValue.drayageFee}
                onChange={handleChange('drayageFee')}
                tabIndex={7}
                required
              />
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>

          <ReactBootstrap.Row className='mb-2'>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Label>Recorder Charge</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={7}>
              <ReactBootstrap.Form.Control
                placeholder='Recorder Charge...'
                disabled={isFormDisable}
                value={FormValue.recorderFee}
                onChange={handleChange('recorderFee')}
                tabIndex={8}
                required
              />
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>

          <ReactBootstrap.Row className='mb-2'>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Label>Consolidation Charge-20Ft</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={7}>
              <ReactBootstrap.Form.Control
                placeholder='Consolidation Charge-20Ft...'
                disabled={isFormDisable}
                value={FormValue.consCharge20Ft}
                onChange={handleChange('consCharge20Ft')}
                tabIndex={9}
                required
              />
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>

          <ReactBootstrap.Row className='mb-2'>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Label>Consolidation Charge-40Ft</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={7}>
              <ReactBootstrap.Form.Control
                placeholder='Consolidation Charge-40Ft...'
                disabled={isFormDisable}
                value={FormValue.consCharge40Ft}
                onChange={handleChange('consCharge40Ft')}
                tabIndex={10}
                required
              />
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>

          <ReactBootstrap.Row className='mb-2'>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Label>Consolidation Charge-40Ft High</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={7}>
              <ReactBootstrap.Form.Control
                placeholder='Consolidation Charge-40Ft High...'
                disabled={isFormDisable}
                value={FormValue.consCharge40FtH}
                onChange={handleChange('consCharge40FtH')}
                tabIndex={11}
                required
              />
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>

          <ReactBootstrap.Row className='mb-2'>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Label>Guam Limit Freeze/Chill Lbs.</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={7}>
              <ReactBootstrap.Form.Control
                placeholder='Guam Limit Frozen/Chill Lbs...'
                disabled={isFormDisable}
                value={FormValue.GuamLimitFrzLbs}
                onChange={handleChange('GuamLimitFrzLbs')}
                tabIndex={12}
                required
              />
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>

          <ReactBootstrap.Row className='mb-2'>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Label>Guam Limit Dry Lbs.</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={7}>
              <ReactBootstrap.Form.Control
                placeholder='Guam Limit Dry Lbs...'
                disabled={isFormDisable}
                value={FormValue.GuamLimitDryLbs}
                onChange={handleChange('GuamLimitDryLbs')}
                tabIndex={13}
                required
              />
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>

          <ReactBootstrap.Row className='mb-2'>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Label>Guam Limit Freeze/Chill CuFt.</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={7}>
              <ReactBootstrap.Form.Control
                placeholder='Guam Limit Freeze/Chill CuFt...'
                disabled={isFormDisable}
                value={FormValue.GuamLimitFrzCu}
                onChange={handleChange('GuamLimitFrzCu')}
                tabIndex={14}
                required
              />
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>

          <ReactBootstrap.Row className='mb-2'>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Label>Guam Limit Dry CuFt.</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={7}>
              <ReactBootstrap.Form.Control
                placeholder='Guam Limit Dry CuFt...'
                disabled={isFormDisable}
                value={FormValue.GuamLimitDryCu}
                onChange={handleChange('GuamLimitDryCu')}
                tabIndex={15}
                required
              />
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>

          <ReactBootstrap.Row className='mb-2'>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Label>Other Limit Freeze/Chill Lbs.</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={7}>
              <ReactBootstrap.Form.Control
                placeholder='Other Limit Freeze/Chill Lbs...'
                disabled={isFormDisable}
                value={FormValue.OtherLimitFrzLbs}
                onChange={handleChange('OtherLimitFrzLbs')}
                tabIndex={16}
                required
              />
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>

          <ReactBootstrap.Row className='mb-2'>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Label>Other Limit Dry Lbs.</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={7}>
              <ReactBootstrap.Form.Control
                placeholder='Other Limit Dry Lbs...'
                disabled={isFormDisable}
                value={FormValue.OtherLimitDryLbs}
                onChange={handleChange('OtherLimitDryLbs')}
                tabIndex={17}
                required
              />
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>

          <ReactBootstrap.Row className='mb-2'>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Label>Other Limit Freeze/Chill CuFt.</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={7}>
              <ReactBootstrap.Form.Control
                placeholder='Other Limit Freeze/Chill CuFt...'
                disabled={isFormDisable}
                value={FormValue.OtherLimitFrzCu}
                onChange={handleChange('OtherLimitFrzCu')}
                tabIndex={18}
                required
              />
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>

          <ReactBootstrap.Row className='mb-2'>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Label>Other Limit Dry CuFt.</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={7}>
              <ReactBootstrap.Form.Control
                placeholder='Other Limit Dry CuFt...'
                disabled={isFormDisable}
                value={FormValue.OtherLimitDryCu}
                onChange={handleChange('OtherLimitDryCu')}
                tabIndex={19}
                required
              />
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </ReactBootstrap.Form>
      </div>
    </div>
  );
}

export default Settings;
