import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import * as ReactBootstrap from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { useStateValue } from '../context/StateProvider';

const dataInfoGrid = [
  {
    id: 1,
    location: 'TJ(Ebeye)',
    firstName: 'Bruce',
    lastName: 'Blevins',
    email: 'bblevins@email.com',
  },
  {
    id: 2,
    location: 'TJ(Ebeye)',
    firstName: 'Wilson',
    lastName: 'Baroma',
    email: 'wbaroma@email.com',
  },
  {
    id: 3,
    location: 'TJ(Majuro)',
    firstName: 'Ryan',
    lastName: 'Arriesgado',
    email: 'rarriesgado@email.com',
  },
  {
    id: 4,
    location: 'TJ(Mighal)',
    firstName: 'John',
    lastName: 'Lazaro',
    email: 'jlazaro@email.com',
  },
];

const initForm = {
  location: 'TJ(GUAM)',
  firstName: '',
  lastName: '',
  email: '',
};

function Merchants() {
  const [state] = useStateValue();
  const [isFormDisable, SetisFormDisable] = useState(true);
  const [FormValue, SetFormValue] = useState(initForm);

  if (!state.isLogin) return <Redirect to='/login' />;
  if (state.isLogin && state.newUser) return <Redirect to='/change-password' />;

  let locationFilter = '';
  let fnameFilter = '';
  let lnameFilter = '';
  let emailFilter = '';

  const clearFilter = () => {
    locationFilter('');
    fnameFilter('');
    lnameFilter('');
    emailFilter('');
  };

  const columns = [
    {
      dataField: 'id',
      text: '#',
      headerStyle: () => {
        return { width: '40px', textAlign: 'left' };
      },
      sort: true,
    },
    {
      dataField: 'location',
      text: 'Consignee',
      headerStyle: () => {
        return { width: '140px', textAlign: 'center' };
      },
      filter: textFilter({
        getFilter: (filter) => (locationFilter = filter),
      }),
    },
    {
      dataField: 'firstName',
      text: 'First Name',
      headerStyle: () => {
        return { width: '140px', textAlign: 'center' };
      },
      filter: textFilter({
        getFilter: (filter) => (fnameFilter = filter),
      }),
    },
    {
      dataField: 'lastName',
      text: 'Last Name',
      headerStyle: () => {
        return { width: '140px', textAlign: 'center' };
      },
      filter: textFilter({
        getFilter: (filter) => (lnameFilter = filter),
      }),
    },
    {
      dataField: 'email',
      text: 'Email',
      headerStyle: () => {
        return { width: '140px', textAlign: 'center' };
      },
      filter: textFilter({
        getFilter: (filter) => (emailFilter = filter),
      }),
    },
  ];

  const handleNewButton = () => {
    SetFormValue(initForm);
    SetisFormDisable(false);
  };

  const handleCancelButton = () => {
    if (!isFormDisable) {
      SetFormValue(initForm);
      SetisFormDisable(true);
    }
  };

  const handleEditButton = () => {
    if (FormValue.email !== '') {
      SetisFormDisable(false);
    }
  };

  const onRowSelect = (row) => {
    SetFormValue(row);
  };

  const selectRowProp = {
    mode: 'radio',
    clickToSelect: true,
    onSelect: onRowSelect,
  };

  const handleChange = (prop) => (ev) => {
    SetFormValue({ ...FormValue, [prop]: ev.target.value });
  };

  return (
    <div className='wrapper'>
      <div className='generic-content-container container-fluid'>
        <h2>Contacts</h2>
      </div>
      <div className='help-button'>
        <a
          href='https://triplejlogistics.org/help/MerchantsMenu.html'
          target='_blank'
          rel='noopener noreferrer'
          className='btn btn-labeled btn-outline-dark btn-sm mr-1'>
          <span className='btn-label'>
            <i className='fa fa-question-circle pr-1'></i>
          </span>
          Help
        </a>
      </div>
      <div className='container-fluid'>
        <div className='row mb-4'>
          <div className='col-md-12'>
            <button
              type='button'
              className='btn btn-labeled btn-primary mr-1'
              onClick={handleNewButton}>
              <span className='btn-label'>
                <i className='fa fa-plus pr-1'></i>
              </span>
              New
            </button>
            <button
              type='button'
              className='btn btn-labeled btn-primary mr-1'
              onClick={handleEditButton}>
              <span className='btn-label'>
                <i className='fa fa-edit pr-1'></i>
              </span>
              Edit
            </button>
            <button type='button' className='btn btn-labeled btn-primary mr-1'>
              <span className='btn-label'>
                <i className='fa fa-check-square-o pr-1'></i>
              </span>
              Save
            </button>
            <button
              type='button'
              className='btn btn-labeled btn-primary mr-1'
              onClick={handleCancelButton}>
              <span className='btn-label'>
                <i className='fa fa-ban pr-1'></i>
              </span>
              Cancel
            </button>
            <button type='button' className='btn btn-labeled btn-primary mr-1'>
              <span className='btn-label'>
                <i className='fa fa-trash-o pr-1'></i>
              </span>
              Delete
            </button>
          </div>
        </div>
        <ReactBootstrap.Form>
          <ReactBootstrap.Form.Row>
            <ReactBootstrap.Form.Group controlId='formGridLocation' className='d-inline-flex'>
              <label className='pr-2'>Location</label>
              <ReactBootstrap.Form.Control
                as='select'
                disabled={isFormDisable}
                value={FormValue.location}
                onChange={handleChange('location')}
                required>
                <option>{`TJ(Saipan)`}</option>
                <option>{`TJ(Guam)`}</option>
                <option>{`TJ(Palau)`}</option>
                <option>{`TJ(Ebeye)`}</option>
                <option>{`TJ(Majuro)`}</option>
                <option>{`TJ(Mighal)`}</option>
              </ReactBootstrap.Form.Control>
            </ReactBootstrap.Form.Group>
          </ReactBootstrap.Form.Row>
          <ReactBootstrap.Form.Row>
            <ReactBootstrap.Form.Group controlId='formGridFName' className='pr-2 form-group--w-50'>
              <ReactBootstrap.Form.Label>First Name</ReactBootstrap.Form.Label>
              <ReactBootstrap.Form.Control
                placeholder='Enter First Name'
                disabled={isFormDisable}
                value={FormValue.firstName}
                onChange={handleChange('firstName')}
              />
            </ReactBootstrap.Form.Group>

            <ReactBootstrap.Form.Group controlId='formGridLName' className='form-group--w-50'>
              <ReactBootstrap.Form.Label>Last Name</ReactBootstrap.Form.Label>
              <ReactBootstrap.Form.Control
                placeholder='Enter Last Name'
                disabled={isFormDisable}
                value={FormValue.lastName}
                onChange={handleChange('lastName')}
                required
              />
            </ReactBootstrap.Form.Group>
          </ReactBootstrap.Form.Row>

          <ReactBootstrap.Form.Row>
            <ReactBootstrap.Form.Group controlId='formGridEmail' className='form-group--w-100'>
              <ReactBootstrap.Form.Label>Email address</ReactBootstrap.Form.Label>
              <ReactBootstrap.Form.Control
                type='email'
                placeholder='name@example.com'
                disabled={isFormDisable}
                value={FormValue.email}
                onChange={handleChange('email')}
                required
              />
            </ReactBootstrap.Form.Group>
          </ReactBootstrap.Form.Row>
        </ReactBootstrap.Form>
      </div>
      <div className='container-fluid'>
        <button className='btn btn-primary btn-small mb-2' onClick={clearFilter}>
          Clear Filter
        </button>
      </div>
      <div className='container-fluid table--horiz-scroll'>
        <BootstrapTable
          keyField='id'
          data={dataInfoGrid}
          columns={columns}
          selectRow={selectRowProp}
          filter={filterFactory()}
          filterPosition='top'
          striped
          hover
        />
      </div>
    </div>
  );
}

export default Merchants;
