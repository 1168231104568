import AuthProtect from '../common/enums/AuthProtect';

export const generateReceivingSheet = (data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    responseType: 'text',
    url: '/generate_excel/receiving_sheet',
    data,
  };
};

export const createPOStatusList = (data) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    responseType: 'text',
    url: '/generate_excel/create_po_status_list',
    data,
  };
};
export const getMighalBillingExcel = (params) => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    responseType: 'text',
    url: '/generate_excel/get_mighal_billing_excel',
    params,
  };
};
