// noinspection SillyAssignmentJS

export const initialState = {
  isLogin: Boolean(localStorage.getItem('logisticsToken')),
  userLocation: localStorage.getItem('logisticsLocation'),
  newUser: false,
  containerLoadPlanForm: {
    containerId: 0,
    container: '',
    c_type: '1X20 REEFER',
    l_type: 'CHILL',
    vessel: '',
    voyage: '',
    booking: '',
    attention: '',
    emailTo: '',
    destination: 'SELECT CONSIGNEE',
    location: '',
    depart: new Date(),
    arrival: new Date(),
    seal: '',
    status: 'Loading',
    available_cu: 0,
    available_lbs: 0,
    createDte: new Date(),
    comment: '',
    oceanfreight: 0,
    isSIT: false,
    file_ref: '',
    isAffiliate: true,
    HasZeroInvAmt: false,
    InvoiceNo: '',
  },
  containerNonMighal: {
    containerId: 0,
    container: '',
    c_type: '1X20 REEFER',
    l_type: 'CHILL',
    vessel: '',
    voyage: '',
    booking: '',
    attention: '',
    emailTo: '',
    destination: 'SELECT CONSIGNEE',
    location: '',
    depart: new Date(),
    arrival: new Date(),
    seal: '',
    status: 'Loading',
    available_cu: 0,
    available_lbs: 0,
    createDte: new Date(),
    comment: '',
    oceanfreight: 0,
    isSIT: false,
    isAffiliate: true,
  },
  maxContID: 0,
  consigneeLookUp: JSON.parse(localStorage.getItem('logisticsConsignee')),
  mighalbilling: {
    billing_ID: 0,
    isAffiliate: 0,
    cont_ID: 0,
    billTo: '',
    addressTo: '',
    company: '',
    address: '',
    city: '',
    state_zip: '',
    vessel: '',
    from: '',
    to: '',
    inv_no: '',
    inv_date: new Date(),
    cont_no: '',
    file_ref: '',
    consignee: '',
    shipper: '',
    booking: '',
    cont_temp: '',
    arrival: new Date(),
    inv_amt: 0,
    ofrt_amt: 0,
    contSize: '',
    ContainerStatus: '',
    DepartureDate: '',
    ArrivalDate: '',
    Desc1: 'Documentation',
    Desc1_Amt: 0,
    Desc2: 'Consolidation',
    Desc2_Amt: 0,
    Desc3: 'Insurance',
    Desc3_Amt: 0,
    Desc4: '',
    Desc4_Amt: 0,
    Desc5: '',
    Desc5_Amt: 0,
    Desc6: '',
    Desc6_Amt: 0,
    Desc7: '',
    Desc7_Amt: 0,
    Desc8: '',
    Desc8_Amt: 0,
    Desc9: '',
    Desc9_Amt: 0,
    Desc10: '',
    Desc10_Amt: 0,
    ReturnTo: '',
    isSIT: 0,
    comment: '',
  },
  mighalbillingNoneAffiliates: {
    billing_ID: 0,
    isAffiliate: 0,
    cont_ID: 0,
    billTo: '',
    company: '',
    address: '',
    city: '',
    state_zip: '',
    vessel: '',
    voyage: '',
    from: '',
    to: '',
    inv_no: '',
    inv_date: new Date(),
    cont_no: '',
    file_ref: '',
    consignee: '',
    consigned_to: '',
    shipper: '',
    c_type: '1X40 REEFER',
    temperature: 'CHILL',
    booking: '',
    depart: new Date(),
    arrival: new Date(),
    inv_amt: 0,
    ofrt_amt: 0,
    ofrt_inv_amt: 0,
    contSize: '',
    ContainerStatus: '',
    DepartureDate: '',
    ArrivalDate: '',
    Desc1: 'Drayage',
    Desc1_Amt: 0,
    Desc2: 'Documentation',
    Desc2_Amt: 0,
    Desc3: 'Consolidation',
    Desc3_Amt: 0,
    Desc4: 'Insurance',
    Desc4_Amt: 0,
    Desc5: '',
    Desc5_Amt: 0,
    Desc6: '',
    Desc6_Amt: 0,
    Desc7: '',
    Desc7_Amt: 0,
    Desc8: '',
    Desc8_Amt: 0,
    Desc9: '',
    Desc9_Amt: 0,
    Desc10: '',
    Desc10_Amt: 0,
    ReturnTo: '',
    isSIT: 0,
    comment: '',
  },
  trackMatsonContainer: '',
  helpModule: '',
  containerFilterView: '',
  consigneePOFilterView: '',
  isSidebarActive: true,
  purchaseOrderState: {
    loading: false,
    success: false,
    error: null,
    poData: [],
    page: 1,
    limit: 10,
    pageTotal: 0,
    searchPO: '',
    isDelivered: false,
    maxMRT: 0,
    triggerRefresh: false,
    filterBy: 'PO',
  },
};

const ourReducer = (draft, action) => {
  switch (action.type) {
    case 'LOG_IN':
      draft.isLogin = true;
      draft.userLocation = action.payload;
      return;
    case 'NEW_USER':
      draft.newUser = true;
      return;
    case 'PASS_CHANGE':
      draft.newUser = false;
      return;
    case 'LOG_OUT':
      draft.isLogin = false;
      return;
    case 'POPULATE_LOAD_PLAN':
      draft.containerLoadPlanForm = action.payload;
      return;
    case 'SET_NON_MIGHAL':
      draft.containerNonMighal = action.payload;
      return;
    case 'GET_MAX_CONT_ID':
      draft.maxContID = action.payload;
      return;
    case 'UPDATE_MIGHAL_BILLING_DATA':
      draft.mighalbilling = action.payload;
      return;
    case 'TRACK_MATSON':
      draft.trackMatsonContainer = action.payload;
      return;
    case 'OPEN_HELP_MODULE':
      draft.helpModule = action.payload;
      return;
    case 'SET_CONTAINER_FILTER':
      draft.containerFilterView = action.payload;
      return;
    case 'SET_PO_FILTER':
      draft.consigneePOFilterView = action.payload;
      return;
    case 'SET_NON_AFFILIATE_BILLING':
      draft.mighalbillingNoneAffiliates = action.payload;
      return;
    case 'TOGGLE_SIDEBAR':
      draft.isSidebarActive = action.payload;
      return;
    case 'SET_PO_PAGE_NUM':
      draft.purchaseOrderState.page = action.payload;
      return;
    case 'SET_PO_FETCHING':
      draft.purchaseOrderState = action.payload;
      return;
    case 'SET_PO_FULL_FILLED':
      draft.purchaseOrderState = action.payload;
      return;
    case 'SET_PO_REJECT':
      draft.purchaseOrderState = action.payload;
      return;
    case 'SET_PO_IS_DELIVERED':
      draft.purchaseOrderState.isDelivered = action.payload;
      return;
    case 'SET_PO_MRT_MAX':
      draft.purchaseOrderState.maxMRT = action.payload.maxMRT;
      return;
    case 'SET_PO_HANDLE_SEARCH':
      draft.purchaseOrderState = action.payload;
      return;
    default:
      draft = draft;
  }
};

export default ourReducer;
