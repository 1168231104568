// noinspection JSUnresolvedVariable

import React, { useEffect, useState } from 'react';
import * as ReactBootstrap from 'react-bootstrap';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { useStateValue } from '../context/StateProvider';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ClipLoader from 'react-spinners/ClipLoader';
import '../tablefooter.css';
import momentTZ from 'moment-timezone';
// import moment from 'moment';
import { SpinnerStyle } from '../styles/SpinnerStyle';
import {
  changeBilling,
  createBilling,
  getBillingCharges,
  validateInvoiceNumber,
} from '../services/billingEndpoint';
import apiFetch from '../services/apiFetch';
import { getMighalBillingDetailBreakDown, getMighalBillingPDF } from '../services/reportsEndpoint';

function MighalBillingAffiliates() {
  const [state] = useStateValue();
  const [FormValue, SetFormValue] = useState(state.mighalbilling);
  const [getListRender, SetgetListRender] = useState(true);
  const [dataInfoGrid, SetDataInfoGrid] = useState([]);
  const [isNew, SetIsNew] = useState(true);
  const [totalValue, SetTotalValue] = useState(0);
  const [isLoading, SetIsLoading] = useState(false);
  const [initialInvNo, SetInitialInvNo] = useState(0);
  const history = useHistory();
  const ReturnTo = state.mighalbilling.ReturnTo;
  let prevOFRT = state.mighalbilling.ofrt_amt;

  useEffect(() => {
    //console.log(FormValue);
    const setDataGrid = () => {
      //console.log(FormValue);
      SetInitialInvNo(parseInt(FormValue.inv_no));
      let arrayData = [
        {
          cont_ID: FormValue.cont_ID,
          id: 1,
          description: FormValue.Desc1,
          amount: FormValue.Desc1_Amt,
        },
        {
          cont_ID: FormValue.cont_ID,
          id: 2,
          description: FormValue.Desc2,
          amount: FormValue.Desc2_Amt,
        },
        {
          cont_ID: FormValue.cont_ID,
          id: 3,
          description: FormValue.Desc3,
          amount: FormValue.Desc3_Amt,
        },
        {
          cont_ID: FormValue.cont_ID,
          id: 4,
          description: FormValue.Desc4,
          amount: FormValue.Desc4_Amt,
        },
        {
          cont_ID: FormValue.cont_ID,
          id: 5,
          description: FormValue.Desc5,
          amount: FormValue.Desc5_Amt,
        },
        {
          cont_ID: FormValue.cont_ID,
          id: 6,
          description: FormValue.Desc6,
          amount: FormValue.Desc6_Amt,
        },
        {
          cont_ID: FormValue.cont_ID,
          id: 7,
          description: FormValue.Desc7,
          amount: FormValue.Desc7_Amt,
        },
        {
          cont_ID: FormValue.cont_ID,
          id: 8,
          description: FormValue.Desc8,
          amount: FormValue.Desc8_Amt,
        },
        {
          cont_ID: FormValue.cont_ID,
          id: 9,
          description: FormValue.Desc9,
          amount: FormValue.Desc9_Amt,
        },
        {
          cont_ID: FormValue.cont_ID,
          id: 10,
          description: FormValue.Desc10,
          amount: FormValue.Desc10_Amt,
        },
      ];
      /* let newObjData = {}; */
      /* for (let i = 1; i <= 10; i++) {
        newObjData = {
          cont_ID: FormValue.cont_ID,
          id: i,
          description: '',
          amount: 0,
        };
        arrayData.push(newObjData);
      } */
      let sumValue = 0;
      for (const iterator of arrayData) {
        //console.log(parseFloat(iterator.amount));
        sumValue = sumValue + parseFloat(iterator.amount);
      }
      SetTotalValue(sumValue);

      if (FormValue.billing_ID > 0) {
        SetIsNew(false);
      } else {
        SetIsNew(true);
      }
      SetDataInfoGrid(arrayData);

      SetIsLoading(false);
      SetgetListRender(false);
    };

    if (getListRender) {
      SetIsLoading(true);
      setDataGrid();
    }
    //SetFormValue()
    return () => {
      SetgetListRender(false);
    };
  }, [FormValue, getListRender]);

  if (!state.isLogin) return <Redirect to='/login' />;
  if (state.isLogin && state.newUser) return <Redirect to='/change-password' />;

  const columns = [
    {
      dataField: 'cont_ID',
      text: 'ContID',
      hidden: true,
    },
    {
      dataField: 'id',
      text: 'Line No.',
      hidden: true,
    },
    {
      dataField: 'description',
      text: 'Description',
      headerStyle: () => {
        return { width: '300px', textAlign: 'center' };
      },
    },
    {
      dataField: 'amount',
      text: 'Amount',
      align: 'right',
      type: 'number',
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
      formatter: (cell) => {
        //let valueObj = cell
        const fixedNumber = Number.parseFloat(cell).toFixed(2);
        return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      },
      validator: (newValue, row, column, done) => {
        setTimeout(() => {
          if (isNaN(newValue)) {
            return done({
              valid: false,
              message: 'Price should be numeric',
            });
          }
          return done();
        }, 200);
        return {
          async: true,
        };
      },
    },
  ];

  const afterSaveCell = () => {
    /* let currentData = dataInfoGrid;
    SetDataInfoGrid(currentData); */
    //console.log('After Saving Cell!!');
    let sumValue = 0;
    for (const iterator of dataInfoGrid) {
      //console.log(iterator);
      sumValue = sumValue + parseFloat(iterator.amount);
    }
    SetTotalValue(sumValue);
  };

  const beforeSaveCell = () => {
    /* console.log({ row });
    console.log({ column });
    console.log({ oldValue });
    console.log({ newValue }); */
    /*  let currentData = dataInfoGrid;
    SetPrevDataGrid(currentData); */
    //console.log('Before Saving Cell!!');
  };

  /* const onRowSelect = (row) => {
    SetRowData(row);
  }; */

  /* const selectRowProp = {
    mode: 'radio',
    clickToSelect: true,
    clickToEdit: true,
  }; */

  const formatDate = (valueDate) => {
    let d = new Date(valueDate),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('/');
  };

  const formatInvToNumber = (number) => {
    let convertoNum = number.replace(/,/g, '');
    convertoNum = parseFloat(convertoNum);
    return convertoNum;
  };

  const validateInvoice = () => {
    return new Promise(async (resolve, reject) => {
      const params = {
        invoiceNo: FormValue.inv_no,
      };
      const endpoint = validateInvoiceNumber(params);
      const response = await apiFetch(endpoint);
      if (response) {
        // console.log(response.data[0]);
        let invoiceCount = response.data[0];
        resolve(parseInt(invoiceCount.duplicateCount));
      } else {
        reject(0);
      }
    });
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    try {
      SetIsLoading(true);
      let verifyInvoice = 0;

      let isOFRTChange =
        formatInvToNumber(prevOFRT) !== formatInvToNumber(FormValue.ofrt_amt) ? 1 : 0;

      let newRecord = {
        billing_ID: FormValue.billing_ID,
        Container_ID: FormValue.cont_ID,
        Consignee_Name: FormValue.consignee,
        Consigned_To: FormValue.addressTo,
        Shipper: FormValue.shipper,
        address: FormValue.address,
        city: FormValue.city,
        state_zip: FormValue.state_zip,
        from: FormValue.from,
        to: FormValue.to,
        inv_no: FormValue.inv_no,
        inv_date: formatDate(FormValue.inv_date),
        file_ref: FormValue.file_ref.toUpperCase(),
        oceanfreight: formatInvToNumber(FormValue.ofrt_amt),
        Desc1: '',
        Desc1_Amt: 0,
        Desc2: '',
        Desc2_Amt: 0,
        Desc3: '',
        Desc3_Amt: 0,
        Desc4: '',
        Desc4_Amt: 0,
        Desc5: '',
        Desc5_Amt: 0,
        Desc6: '',
        Desc6_Amt: 0,
        Desc7: '',
        Desc7_Amt: 0,
        Desc8: '',
        Desc8_Amt: 0,
        Desc9: '',
        Desc9_Amt: 0,
        Desc10: '',
        Desc10_Amt: 0,
        isOFRTChange,
        UserEmail: localStorage.getItem('logisticsUseremail'),
        isAffiliate: FormValue.isAffiliate,
      };

      dataInfoGrid.forEach((value) => {
        switch (value.id) {
          case 1:
            newRecord = {
              ...newRecord,
              Desc1: value.description,
              Desc1_Amt: parseFloat(value.amount),
            };
            break;
          case 2:
            newRecord = {
              ...newRecord,
              Desc2: value.description,
              Desc2_Amt: parseFloat(value.amount),
            };
            break;
          case 3:
            newRecord = {
              ...newRecord,
              Desc3: value.description,
              Desc3_Amt: parseFloat(value.amount),
            };
            break;
          case 4:
            newRecord = {
              ...newRecord,
              Desc4: value.description,
              Desc4_Amt: parseFloat(value.amount),
            };
            break;
          case 5:
            newRecord = {
              ...newRecord,
              Desc5: value.description,
              Desc5_Amt: parseFloat(value.amount),
            };
            break;
          case 6:
            newRecord = {
              ...newRecord,
              Desc6: value.description,
              Desc6_Amt: parseFloat(value.amount),
            };
            break;
          case 7:
            newRecord = {
              ...newRecord,
              Desc7: value.description,
              Desc7_Amt: parseFloat(value.amount),
            };
            break;
          case 8:
            newRecord = {
              ...newRecord,
              Desc8: value.description,
              Desc8_Amt: parseFloat(value.amount),
            };
            break;
          case 9:
            newRecord = {
              ...newRecord,
              Desc9: value.description,
              Desc9_Amt: parseFloat(value.amount),
            };
            break;
          default:
            newRecord = {
              ...newRecord,
              Desc10: value.description,
              Desc10_Amt: parseFloat(value.amount),
            };
            break;
        }
      });

      if (isNew) {
        /* Handle Insert New Mighal Billing */
        // console.log('Handle Insert');
        //console.log({ newRecord });
        verifyInvoice = await validateInvoice();
        if (verifyInvoice > 0) {
          SetIsLoading(false);
          return alert(`Error found a duplicate Invoice No. ${FormValue.inv_no}`);
        }

        createNewBilling(newRecord)
          .then(() => {
            // console.log(result);
            SetIsLoading(false);
            alert('Successfully Saved Billing');
            history.push(`/${FormValue.ReturnTo}`);
          })
          .catch((err) => {
            console.log(err);
            SetIsLoading(false);
            alert('Unable to save billing');
          });
      } else {
        /* Handle Update Billing */
        //console.log('Handle Update');
        //console.log(newRecord);
        if (FormValue.inv_no !== initialInvNo) {
          verifyInvoice = await validateInvoice();
          if (verifyInvoice > 1) {
            SetIsLoading(false);
            return alert(`Error found a duplicate Invoice No. ${FormValue.inv_no}`);
          }

          updateBilling(newRecord)
            .then(() => {
              //console.log(result);
              SetIsLoading(false);
              alert('Successfully Update Billing');
              history.push(`/${FormValue.ReturnTo}`);
            })
            .catch((err) => {
              console.log(err);
              SetIsLoading(false);
              alert('Unable to update billing');
            });
        } else {
          updateBilling(newRecord)
            .then(() => {
              //console.log(result);
              SetIsLoading(false);
              alert('Successfully Update Billing');
              history.push(`/${FormValue.ReturnTo}`);
            })
            .catch((err) => {
              console.log(err);
              SetIsLoading(false);
              alert('Unable to update billing');
            });
        }
      }
    } catch (e) {
      console.log(e);
      SetIsLoading(false);
    }
  };

  const createNewBilling = (bodyData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoint = createBilling(bodyData);
        const response = await apiFetch(endpoint).then((res) => res.data);

        if (response && response === 'Success') {
          resolve(response);
        } else {
          reject('Failed');
        }
      } catch (e) {
        console.log(e);
        reject('Failed');
      }
    });
  };

  const updateBilling = (bodyData) => {
    return new Promise(async (resolve, reject) => {
      try {
        //console.log(bodyData);
        const endpoint = changeBilling(bodyData);
        const response = await apiFetch(endpoint).then((res) => res.data);
        if (response && response === 'Success') {
          resolve(response.data);
        } else {
          reject('Failed');
        }
      } catch (e) {
        console.log(e);
        reject('Failed');
      }
    });
  };

  const handleOnChange = (prop) => (ev) => {
    if (prop === 'file_ref') {
      return false;
    }

    if (prop !== 'inv_date' && prop !== 'arrival') {
      SetFormValue({ ...FormValue, [prop]: ev.target.value });
    } else {
      let AdjusteddateValue = new Date(momentTZ.tz(ev, 'Pacific/Guam'));
      SetFormValue({ ...FormValue, [prop]: AdjusteddateValue });
    }
  };

  const formatNumber = (number) => {
    const fixedNumber = Number.parseFloat(number).toFixed(2);
    return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const handlePreviewPDF = () => {
    try {
      if (FormValue.billing_ID > 0) {
        SetIsLoading(true);
        downloadBillingPDF()
          .then((response) => {
            SetIsLoading(false);
            const url = window.URL.createObjectURL(
              new Blob([response.data], { type: 'application/pdf' }),
            );
            window.open(url);
          })
          .catch((err) => {
            console.log(err);
            SetIsLoading(false);
            alert('No PDF Available');
          });
      } else {
        alert('Please save the billing form');
        SetIsLoading(false);
      }
    } catch (e) {
      console.log(e);
      SetIsLoading(false);
    }
  };

  const downloadBillingPDF = () => {
    return new Promise(async (resolve, reject) => {
      const params = {
        billingId: FormValue.billing_ID,
      };
      const endpoint = getMighalBillingPDF(params);
      const response = await apiFetch(endpoint);
      if (response.data.type !== 'application/json') {
        resolve(response);
      } else {
        reject('Failed');
      }
    });
  };

  const handleGetBillDetailsPDF = () => {
    try {
      SetIsLoading(true);
      downloadBillDetailsPDF()
        .then((response) => {
          SetIsLoading(false);
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: 'application/pdf' }),
          );
          window.open(url);
        })
        .catch((err) => {
          console.log(err);
          SetIsLoading(false);
          alert('Error Generating PDF Report');
        });
    } catch (e) {
      console.log(e);
      SetIsLoading(false);
    }
  };

  const downloadBillDetailsPDF = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let isNew = FormValue.billing_ID <= 0;
        let today = new Date();
        let countryZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
        let userToday = momentTZ.tz(today, countryZone).format('MM/DD/YYYY');
        let userAsOf = momentTZ.tz(today, countryZone).format('MMMM DD, YYYY');
        let printTime = momentTZ.tz(today, countryZone).format('hh:mm A');

        const bodyData = {
          UserEmail: localStorage.getItem('logisticsUseremail'),
          Container_No: FormValue.cont_no,
          Container_ID: FormValue.cont_ID,
          TempType: FormValue.cont_temp,
          ContSize: FormValue.contSize,
          ContainerStatus: FormValue.ContainerStatus,
          DepartureDate: FormValue.DepartureDate,
          ArrivalDate: FormValue.ArrivalDate,
          userToday,
          userAsOf,
          printTime,
          isNew,
          Billing_ID: FormValue.billing_ID,
        };
        const endpoint = getMighalBillingDetailBreakDown(bodyData);
        const response = await apiFetch(endpoint);

        if (response.data.type !== 'application/json') {
          resolve(response);
        } else {
          reject('Failed');
        }
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  };

  const handleCalculateBilling = () => {
    SetIsLoading(true);
    geTotalPOInvoiceAmt()
      .then((result) => {
        let DrayageCharge = FormValue.isSIT ? result.DrayageFee * 0.5 : result.DrayageFee;
        let DocChange = FormValue.isSIT
          ? result.DocumentationCharge * 0.5
          : result.DocumentationCharge;
        let contSize = FormValue.contSize;
        let InsValue = result.InsurancePercent;
        let InsFixDecimalValue = InsValue * 100;
        let TtlAddOnCharge = formatNumber(result.TtlPOinvOFrtAddOnCharge);
        let RecorderFee = FormValue.shipper === 'DRY' ? 0 : formatNumber(result.RecorderFee);

        let arrayData = [
          {
            cont_ID: FormValue.cont_ID,
            id: 1,
            description: 'Drayage BAY AREA PORT',
            amount: DrayageCharge,
          },
          {
            cont_ID: FormValue.cont_ID,
            id: 2,
            description: 'Documentation',
            amount: DocChange,
          },
          {
            cont_ID: FormValue.cont_ID,
            id: 3,
            description: `Consolidation ${contSize}`,
            amount:
              contSize === '20' ? result.ConsolidationCharge20ft : result.ConsolidationCharge40ft,
          },
          {
            cont_ID: FormValue.cont_ID,
            id: 4,
            description: `Insurance ${FormValue.shipper} ${InsFixDecimalValue.toFixed(
              2,
            )}% x $${TtlAddOnCharge}`,
            amount: result.TtlInsuranceAmt,
          },
          {
            cont_ID: FormValue.cont_ID,
            id: 5,
            description: FormValue.shipper === 'DRY' ? '' : `Recorder Charge ${FormValue.comment}`,
            amount: FormValue.shipper === 'DRY' ? 0 : RecorderFee,
          },
          {
            cont_ID: FormValue.cont_ID,
            id: 6,
            description:
              result.BuyingTtlAmt > 0
                ? `Buying Charge $${formatNumber(result.BuyingTtlAmt)} x 5%`
                : '',
            amount: result.BuyingTtlAmt > 0 ? result.BuyingCharge : 0,
          },
          {
            cont_ID: FormValue.cont_ID,
            id: 7,
            description: '',
            amount: 0,
          },
          {
            cont_ID: FormValue.cont_ID,
            id: 8,
            description: '',
            amount: 0,
          },
          {
            cont_ID: FormValue.cont_ID,
            id: 9,
            description: '',
            amount: 0,
          },
          {
            cont_ID: FormValue.cont_ID,
            id: 10,
            description: '',
            amount: 0,
          },
        ];

        let sumValue = 0;
        for (const iterator of arrayData) {
          sumValue = sumValue + parseFloat(iterator.amount);
        }
        SetTotalValue(sumValue);
        SetDataInfoGrid(arrayData);
        SetIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        SetIsLoading(false);
      });
  };

  const geTotalPOInvoiceAmt = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const params = {
          containerId: FormValue.cont_ID,
          tempType: FormValue.shipper,
        };
        const endpoint = getBillingCharges(params);
        const response = await apiFetch(endpoint);
        if (response && response.data) {
          resolve(response.data[0]);
        } else {
          reject('Failed');
        }
      } catch (e) {
        console.log(e);
        reject('Failed');
      }
    });
  };

  const modifiedDate = (dateValue) => {
    return new Date(momentTZ.tz(dateValue, 'Pacific/Guam'));
  };

  return (
    <div className='wrapper'>
      <div className='loading-spinner'>
        <ClipLoader size={100} color={'#0054a6'} loading={isLoading} css={SpinnerStyle} />
      </div>
      <div className='help-button'>
        <a
          href='https://triplejlogistics.org/help/MighalBillingMenu.html'
          target='_blank'
          rel='noopener noreferrer'
          className='btn btn-labeled btn-outline-dark btn-sm mr-1'>
          <span className='btn-label'>
            <i className='fa fa-question-circle pr-1'></i>
          </span>
          Help
        </a>
      </div>
      <div className='generic-content-container container-fluid'>
        <h2>Mighal Billing Entry</h2>
      </div>
      <div className='container-fluid'>
        <div className='row mb-4'>
          <div className='col-md-12'>
            <button
              type='submit'
              className='btn btn-labeled btn-primary btn-sm mr-1'
              onClick={handleSubmit}>
              <span className='btn-label'>
                <i className='fa fa-floppy-o pr-1'></i>
              </span>
              Save
            </button>
            <Link to={`/${ReturnTo}`} className='btn btn-labeled btn-primary btn-sm mr-1'>
              <span className='btn-label'>
                <i className='fa fa-arrow-left pr-1'></i>
              </span>
              Return Back
            </Link>
            <button
              type='button'
              className='btn btn-labeled btn-primary btn-sm mr-1'
              onClick={handlePreviewPDF}>
              <span className='btn-label'>
                <i className='fa fa-print pr-1'></i>
              </span>
              Print Preview
            </button>
            <button
              type='button'
              className='btn btn-labeled btn-primary btn-sm mr-1'
              onClick={handleCalculateBilling}>
              <span className='btn-label'>
                <i className='fa fa-calculator pr-1'></i>
              </span>
              Reset Billing Calculation
            </button>
          </div>
        </div>
      </div>
      <div className='container-fluid'>
        <ReactBootstrap.Form>
          <ReactBootstrap.Row className='mb-2'>
            <ReactBootstrap.Col xs={2}>
              <ReactBootstrap.Form.Label>Bill To</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Control
                placeholder='Bill To...'
                value={FormValue.billTo}
                onChange={handleOnChange('billTo')}
                disabled={true}
              />
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={2}>
              <ReactBootstrap.Form.Label>Invoice No.</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Control
                placeholder='Invoice No...'
                value={FormValue.inv_no}
                onChange={handleOnChange('inv_no')}
              />
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>

          <ReactBootstrap.Row className='mb-2'>
            <ReactBootstrap.Col xs={2}>
              <ReactBootstrap.Form.Label>Address</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Control
                placeholder='Billing Address...'
                value={FormValue.address}
                onChange={handleOnChange('address')}
              />
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={2}>
              <ReactBootstrap.Form.Label>Invoice Date</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={4}>
              <div className=''>
                <DatePicker
                  selected={modifiedDate(FormValue.inv_date)}
                  onChange={handleOnChange('inv_date')}
                  className=''
                  disabled={true}
                />
              </div>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>

          <ReactBootstrap.Row className='mb-2'>
            <ReactBootstrap.Col xs={2}>
              <ReactBootstrap.Form.Label>City</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Control
                placeholder='City...'
                value={FormValue.city}
                onChange={handleOnChange('city')}
              />
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={2}>
              <ReactBootstrap.Form.Label>Container No</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Control
                placeholder='Container No...'
                value={FormValue.cont_no}
                onChange={handleOnChange('cont_no')}
                disabled={true}
              />
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>

          <ReactBootstrap.Row className='mb-2'>
            <ReactBootstrap.Col xs={2}>
              <ReactBootstrap.Form.Label>State & Zip Code</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Control
                placeholder='State & Zip Code...'
                value={FormValue.state_zip}
                onChange={handleOnChange('state_zip')}
              />
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={2}>
              <ReactBootstrap.Form.Label>File Reference No.</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Control
                placeholder='File Reference No...'
                value={FormValue.file_ref}
                onChange={handleOnChange('file_ref')}
                disabled={true}
              />
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>

          <ReactBootstrap.Row className='mb-2'>
            <ReactBootstrap.Col xs={2}>
              <ReactBootstrap.Form.Label>Vessel</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Control
                placeholder='Vessel...'
                value={FormValue.vessel}
                onChange={handleOnChange('vessel')}
                disabled={true}
              />
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={2}>
              <ReactBootstrap.Form.Label>Consignee</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Control
                placeholder='Consignee...'
                value={FormValue.consignee}
                onChange={handleOnChange('consignee')}
              />
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>

          <ReactBootstrap.Row className='mb-2'>
            <ReactBootstrap.Col xs={2}>
              <ReactBootstrap.Form.Label>From</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Control
                placeholder='Bill From...'
                value={FormValue.from}
                onChange={handleOnChange('from')}
              />
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={2}>
              <ReactBootstrap.Form.Label>Shipper</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Control
                placeholder='Shipper...'
                value={FormValue.shipper}
                onChange={handleOnChange('shipper')}
              />
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>

          <ReactBootstrap.Row className='mb-2'>
            <ReactBootstrap.Col xs={2}>
              <ReactBootstrap.Form.Label>To</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Control
                placeholder='Destination...'
                value={FormValue.addressTo}
                onChange={handleOnChange('addressTo')}
              />
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={2}>
              <ReactBootstrap.Form.Label>Booking No</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Control
                placeholder='Booking No...'
                value={FormValue.booking}
                onChange={handleOnChange('booking')}
                disabled={true}
              />
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>

          <ReactBootstrap.Row className='mb-2'>
            <ReactBootstrap.Col xs={2}>
              <ReactBootstrap.Form.Label>Total Invoice Amount</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Control
                placeholder='Total Invoice Amount...'
                value={FormValue.inv_amt}
                disabled={true}
              />
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={2}>
              <ReactBootstrap.Form.Label>Arrival Date</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={4}>
              <div className=''>
                <DatePicker
                  selected={modifiedDate(FormValue.arrival)}
                  className=''
                  onChange={handleOnChange('arrival')}
                  disabled={true}
                />
              </div>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>

          <ReactBootstrap.Row className='mb-2'>
            <ReactBootstrap.Col xs={2}>
              <ReactBootstrap.Form.Label>Ocean Freight Amount</ReactBootstrap.Form.Label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={4}>
              <ReactBootstrap.Form.Control
                placeholder='Ocean Freight Amount...'
                value={FormValue.ofrt_amt}
                onChange={handleOnChange('ofrt_amt')}
              />
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={2}>
              <button
                type='button'
                className='btn btn-labeled btn-primary btn-sm mr-1'
                onClick={handleGetBillDetailsPDF}>
                <span className='btn-label'>
                  <i className='fa fa-print pr-1'></i>
                </span>
                View Billing Details
              </button>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </ReactBootstrap.Form>
      </div>
      <div className='container-fluid table--horiz-scroll'>
        <BootstrapTable
          keyField='id'
          data={dataInfoGrid}
          columns={columns}
          cellEdit={cellEditFactory({
            mode: 'click',
            blurToSave: true,
            beforeSaveCell,
            afterSaveCell,
          })}
          striped
          hover
        />
      </div>
      <div className='container-fluid'>
        <table className='tg tg--header'>
          <thead>
            <tr>
              <th className='tg-v4j2'>TOTAL</th>
              <th className='tg-v4j2'>{`$${formatNumber(totalValue)}`}</th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  );
}

export default MighalBillingAffiliates;
