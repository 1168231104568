import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import * as ReactBootstrap from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ClipLoader from 'react-spinners/ClipLoader';
import Axios from 'axios';
import { useStateValue } from '../context/StateProvider';
import moment_tz from 'moment-timezone';
import cityTZ from 'city-timezones';
import { SpinnerStyle } from '../styles/SpinnerStyle';

const initShipInfo = {
  containerNumber: '',
  checkDigit: '',
  bookingNumber: '',
  bookedDate: '',
  blDest: '',
  blOrigin: '',
  destPort: '',
  dischargePort: '',
  loadPort: '',
  originPort: '',
  sealNumber: '',
  stopInTransit: '',
};

const initContInfo = {
  equipmentType: '',
  temperature: '',
  netWeight: 0,
};

function MatsonTracking() {
  const [state] = useStateValue();
  const [searchValue, setSearchValue] = useState(state.trackMatsonContainer || '');
  const [isLoading, SetIsLoading] = useState(false);
  const [shipInfo, SetShipInfo] = useState(initShipInfo);
  const [contInfo, SetContInfo] = useState(initContInfo);
  const [trackEvents, SetTrackEvents] = useState([]);
  const [searchBy, SetSearchBy] = useState('container');
  const [isRender, SetIsRender] = useState(true);

  useEffect(() => {
    if (searchValue !== '' && isRender) {
      console.log('UseEffect Running');
      console.log(isRender);
      SetIsRender(false);
      SetIsLoading(true);
      getTrackingContainerNo()
        .then((result) => {
          SetIsLoading(false);
          //console.log(result);
          // Set Shipment Info Table
          let gotShipInfo = {
            containerNumber: result.containerNumber,
            checkDigit: result.checkDigit,
            bookingNumber: result.bookingNumber,
            bookedDate: result.bookedDate,
            blDest: result.blDest,
            blOrigin: result.blOrigin,
            destPort: result.destPort,
            dischargePort: result.dischargePort,
            loadPort: result.loadPort,
            originPort: result.originPort,
            sealNumber: result.sealNumber,
            stopInTransit: result.stopInTransit,
          };
          SetShipInfo(gotShipInfo);

          // Set Container Info Table
          let contInfo = {
            equipmentType: result.equipmentType,
            temperature: result.temperature,
            netWeight: result.netWeight,
          };
          SetContInfo(contInfo);

          // Set Tracking Events
          let holdArray = [];
          let newObjArr = result.events;
          //console.log(newObjArr);
          let i = 1;
          for (const iterator of newObjArr) {
            //console.log(iterator);
            let city = iterator.location;
            city = city.substring(0, city.length - 5);
            //console.log(city);
            let holdObj = {
              id: i,
              datetime: setDateFormat(iterator.date, city),
              location: iterator.location,
              status: iterator.status,
            };
            holdArray.push(holdObj);
            i += 1;
          }

          //console.log(holdArray);
          // console.log(newObjArr);
          SetTrackEvents(holdArray);
          setSearchValue('');
          // console.log(trackEvents);
        })
        .catch((err) => {
          console.log(err);
          SetIsLoading(false);
          alert('No record found');
        });
    } else {
      SetIsRender(false);
    }

    return () => {
      SetIsRender(false);
    };
  }, [searchValue]);

  if (!state.isLogin) return <Redirect to='/login' />;
  if (state.isLogin && state.newUser) return <Redirect to='/change-password' />;

  const getTrackingContainerNo = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let response = await Axios.get(
          `https://www.matson.com/vcsc/tracking/container/${searchValue.trim()}`,
        );
        if (typeof response.data === 'object') {
          resolve(response.data);
        } else {
          reject('Failed');
        }
      } catch (e) {
        console.log(e);
        console.log('Failed Catch');
        reject('Failed');
      }
    });
  };

  const getTrackingBookingNo = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let response = await Axios.get(
          `https://www.matson.com/vcsc/tracking/booking/${searchValue.trim()}`,
        );
        //console.log(typeof response.data);
        if (typeof response.data === 'object') {
          resolve(response.data[0]);
        } else {
          reject('Failed');
        }
      } catch (e) {
        console.log(e);
        console.log('Failed Catch');
        reject('Failed');
      }
    });
  };

  const formatNumber = (number) => {
    const fixedNumber = Number.parseFloat(number).toFixed(2);
    return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const titleCase = (str) => {
    str = str.toLowerCase().split(' ');
    for (let i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(' ');
  };

  const setDateFormat = (dateTime, cityName) => {
    try {
      const timeZArray = ['Pacific/Guam', 'Pacific/Honolulu', 'Pacific/Majuro'];
      let CName = titleCase(cityName);
      let ArrCount = 0;
      switch (CName) {
        case 'Guam':
          ArrCount = 0;
          break;
        case 'Ebeye':
          ArrCount = 2;
          break;
        case 'Majuro':
          ArrCount = 2;
          break;
        case 'Hawaii':
          ArrCount = 1;
          break;
        default:
          break;
      }

      let t = cityTZ.lookupViaCity(CName);
      let countryZone;
      if (t.length === 0) {
        countryZone = timeZArray[ArrCount];
      } else {
        countryZone = t[0].timezone;
      }

      return moment_tz.tz(dateTime, countryZone).format('MM/DD/YYYY hh:mm A'); //moment(dateTime).format('MM/DD/YYYY hh:mm');
    } catch (e) {
      console.log(e);
    }
  };

  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      hidden: true,
    },
    {
      dataField: 'datetime',
      text: 'DATE & TIME',
      headerStyle: () => {
        return { textAlign: 'center' };
      },
    },
    {
      dataField: 'location',
      text: 'LOCATION',
      headerStyle: () => {
        return { textAlign: 'center' };
      },
    },
    {
      dataField: 'status',
      text: 'STATUS',
      headerStyle: () => {
        return { textAlign: 'center' };
      },
    },
  ];

  const handleSubmit = (ev) => {
    ev.preventDefault();
    SetShipInfo(initShipInfo);
    SetContInfo(initContInfo);
    SetTrackEvents([]);
    try {
      if (searchValue !== '') {
        SetIsLoading(true);
        //console.log(searchBy);
        if (searchBy === 'container') {
          getTrackingContainerNo()
            .then((result) => {
              SetIsLoading(false);
              //console.log(result);

              // Set Shipment Info Table
              let gotShipInfo = {
                containerNumber: result.containerNumber,
                checkDigit: result.checkDigit,
                bookingNumber: result.bookingNumber,
                bookedDate: result.bookedDate,
                blDest: result.blDest,
                blOrigin: result.blOrigin,
                destPort: result.destPort,
                dischargePort: result.dischargePort,
                loadPort: result.loadPort,
                originPort: result.originPort,
                sealNumber: result.sealNumber,
                stopInTransit: result.stopInTransit,
              };
              SetShipInfo(gotShipInfo);

              // Set Container Info Table
              let contInfo = {
                equipmentType: result.equipmentType,
                temperature: result.temperature,
                netWeight: result.netWeight,
              };
              SetContInfo(contInfo);

              // Set Tracking Events
              let holdArray = [];
              let newObjArr = result.events;
              // console.log({ newObjArr });
              // console.log(typeof newObjArr);
              // console.log(newObjArr.length);
              if (newObjArr.length > 0) {
                let i = 1;
                for (const iterator of newObjArr) {
                  //console.log(iterator);
                  let city = iterator.location;
                  city = city.substring(0, city.length - 5);
                  let holdObj = {
                    id: i,
                    datetime: setDateFormat(iterator.date, city),
                    location: iterator.location,
                    status: iterator.status,
                  };
                  holdArray.push(holdObj);
                  i += 1;
                }
                SetTrackEvents(holdArray);
              } else {
                alert('No record found');
              }
              setSearchValue('');
            })
            .catch((err) => {
              console.log(err);
              SetIsLoading(false);
              setSearchValue('');
              alert('No record found');
            });
        } else {
          getTrackingBookingNo()
            .then((result) => {
              SetIsLoading(false);
              //console.log(result);
              let gotShipInfo = {
                containerNumber: result.containerNumber,
                checkDigit: result.checkDigit,
                bookingNumber: result.bookingNumber,
                bookedDate: result.bookedDate,
                blDest: result.blDest,
                blOrigin: result.blOrigin,
                destPort: result.destPort,
                dischargePort: result.dischargePort,
                loadPort: result.loadPort,
                originPort: result.originPort,
                sealNumber: result.sealNumber,
                stopInTransit: result.stopInTransit,
              };
              SetShipInfo(gotShipInfo);

              // Set Container Info Table
              let contInfo = {
                equipmentType: result.equipmentType,
                temperature: result.temperature,
                netWeight: result.netWeight,
              };
              SetContInfo(contInfo);

              // Set Tracking Events
              let holdArray = [];
              let newObjArr = result.events;
              if (newObjArr.length > 0) {
                let i = 1;
                for (const iterator of newObjArr) {
                  //console.log(iterator);
                  let city = iterator.location;
                  city = city.substring(0, city.length - 5);
                  let holdObj = {
                    id: i,
                    datetime: setDateFormat(iterator.date, city),
                    location: iterator.location,
                    status: iterator.status,
                  };
                  holdArray.push(holdObj);
                  i += 1;
                }
                SetTrackEvents(holdArray);
              }
              setSearchValue('');
            })
            .catch((err) => {
              console.log(err);
              SetIsLoading(false);
              alert('No record found');
              setSearchValue('');
            });
        }
      }
    } catch (e) {
      console.log(e);
      SetIsLoading(false);
      alert('Something went wrong');
      setSearchValue('');
    }
  };

  const handleChange = (ev) => {
    ev.preventDefault();
    let val = ev.target.value;
    //console.log(ev.target);
    setSearchValue(val.toUpperCase());
  };

  /* const handleEnter = (ev) => {
    ev.preventDefault();
  }; */

  const handleKeyDown = (ev) => {
    //console.log(ev.key);
    if (ev.key === 'Enter') {
      if (searchValue !== '') {
        handleSubmit(ev);
      } else {
        alert('Please input a Container Number');
      }
    }
  };

  const handleSearchByChange = (ev) => {
    //console.log(ev.target.value);
    SetSearchBy(ev.target.value);
  };

  // noinspection JSValidateTypes
  return (
    <div className='wrapper'>
      <div className='loading-spinner'>
        <ClipLoader size={100} color={'#0054a6'} loading={isLoading} css={SpinnerStyle} />
      </div>
      <div className='help-button'>
        <a
          href='https://triplejlogistics.org/help/MatsonTrackingMenu.html'
          target='_blank'
          rel='noopener noreferrer'
          className='btn btn-labeled btn-outline-dark btn-sm mr-1'>
          <span className='btn-label'>
            <i className='fa fa-question-circle pr-1'></i>
          </span>
          Help
        </a>
      </div>
      <div className='generic-content-container container-fluid'>
        <h2>Matson Tracking</h2>
      </div>
      <div className='container-fluid'>
        <ReactBootstrap.Form>
          <ReactBootstrap.Row className='mb-2'>
            <ReactBootstrap.Col xs={5}>
              <label className='radio-btn'>Search By: </label>
              <input
                className='radio-btn'
                type='radio'
                id='container'
                name='search'
                value='container'
                checked={searchBy === 'container'}
                onChange={handleSearchByChange}
              />
              <label htmlFor='status'>Container No.</label>
              <input
                className='radio-btn'
                type='radio'
                id='booking'
                name='search'
                value='booking'
                checked={searchBy === 'booking'}
                onChange={handleSearchByChange}
              />
              <label htmlFor='vendor'>Booking No.</label>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={5}>
              <ReactBootstrap.Form.Control
                type='text'
                placeholder='Track Matson Container Number...'
                value={searchValue || ''}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              />
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={2}>
              <ReactBootstrap.Button
                className='btn-block'
                variant='primary'
                type='button'
                onClick={handleSubmit}>
                Search
              </ReactBootstrap.Button>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </ReactBootstrap.Form>
      </div>
      <div className='container-fluid'>
        <table className='mtracking--tg mtracking--tg-table'>
          <thead>
            <tr>
              <th className='mtracking--tg-4xtg' colSpan='4'>
                SHIPMENT INFORMATION: {`${shipInfo.containerNumber}-${shipInfo.checkDigit}`}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='mtracking--tg-xoqx'>PORT OF ORIGIN:</td>
              <td className='mtracking--tg-ve35'>{shipInfo.originPort}</td>
              <td className='mtracking--tg-xoqx'>BOOKING NO:</td>
              <td className='mtracking--tg-ve35'>{shipInfo.bookingNumber}</td>
            </tr>
            <tr>
              <td className='mtracking--tg-lacr'>PORT OF DESTINATION:</td>
              <td className='mtracking--tg-4ydh'>{shipInfo.destPort}</td>
              <td className='mtracking--tg-lacr'>SEAL NO:</td>
              <td className='mtracking--tg-4ydh'>{shipInfo.sealNumber}</td>
            </tr>
            <tr>
              <td className='mtracking--tg-xoqx'>PORT OF LOADING:</td>
              <td className='mtracking--tg-ve35'>{shipInfo.loadPort}</td>
              <td className='mtracking--tg-xoqx'>BL DESTINATION:</td>
              <td className='mtracking--tg-ve35'>{shipInfo.blDest}</td>
            </tr>
            <tr>
              <td className='mtracking--tg-lacr'>PORT OF DISCHARGE:</td>
              <td className='mtracking--tg-4ydh'>{shipInfo.destPort}</td>
              <td className='mtracking--tg-lacr'>BL ORIGIN:</td>
              <td className='mtracking--tg-4ydh'>{shipInfo.blOrigin}</td>
            </tr>
          </tbody>
        </table>
        <table className='mtracking--tg mtracking--tg-table'>
          <thead>
            <tr>
              <th className='mtracking--tg-4xtg' colSpan='2'>
                CONTAINER INFORMATION
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='mtracking--tg-xoqx'>
                EQUIPMENT: <span className='mtracking--span-desc'>{contInfo.equipmentType}</span>
              </td>
            </tr>
            <tr>
              <td className='mtracking--tg-lacr'>
                TEMPERATURE: <span className='mtracking--span-desc'>{contInfo.temperature}</span>
              </td>
              <td className='mtracking--tg-4ydh'></td>
            </tr>
            <tr>
              <td className='mtracking--tg-xoqx'>
                NET CONTAINER WEIGHT(LBS):{' '}
                <span className='mtracking--span-desc'>{formatNumber(contInfo.netWeight)}</span>
              </td>
              <td className='mtracking--tg-xoqx'></td>
            </tr>
          </tbody>
        </table>
        <table className='mtracking--tg mtracking--tg-table'>
          <thead>
            <tr>
              <th className='mtracking--tg-4xtg' colSpan='2'>
                CONTAINER TRACKING MOVEMENTS
              </th>
            </tr>
          </thead>
        </table>

        <BootstrapTable keyField='id' data={trackEvents} columns={columns} striped hover />
      </div>
    </div>
  );
}

export default MatsonTracking;
