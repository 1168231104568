import axios from 'axios';
import merge from 'deepmerge';
import apiConfig from './apiConfig';
import AuthProtect from '../common/enums/AuthProtect';

const genericErrorMessage =
  'We just experienced a technical issue, please wait a few seconds and try again.';

function apiFetch(api) {
  const headers = {};
  const params = api.params || {}; // eslint-disable-line
  const baseURL = apiConfig.baseURL;
  const access_token = localStorage.getItem('logisticsToken');

  if (api.authorization === AuthProtect.REQUIRED) {
    const token = access_token;
    if (token) {
      if (api?.headers) Object.assign(headers, api?.headers);
      params.access_token = token;
    }
  }

  const apiConfigMerge = merge.all([
    apiConfig,
    api,
    {
      baseURL,
      headers,
      params,
    },
  ]);

  const axiosCreate = axios.create(apiConfigMerge);

  axiosCreate.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      return Promise.reject(error);
    },
  );

  return axiosCreate({
    ...apiConfigMerge,
    validateStatus: (status) => {
      return status < 500;
    },
  })
    .catch((e) => {
      console.log('apiFetch catch:', e);
      throw {
        message: genericErrorMessage,
      };
    })
    .then((response) => {
      if (response.status === 401 || response.status === 403) {
        localStorage.removeItem('logisticsToken');
        window.location.href = '/';
      }

      return {
        status: response.status,
        data: response.data,
      };
    });
}

export default apiFetch;
