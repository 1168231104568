import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { StateProvider } from './context/StateProvider';
import ourReducer, { initialState } from './context/reducer';

ReactDOM.render(
  <StateProvider initialState={initialState} reducer={ourReducer}>
    <App />
  </StateProvider>,
  document.getElementById('root'),
);
