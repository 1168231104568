import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import ClipLoader from 'react-spinners/ClipLoader';
import { useStateValue } from '../../context/StateProvider';
import { SpinnerStyle } from '../../styles/SpinnerStyle';
import { getContainerOrder } from '../../services/containerEndpoint';
import apiFetch from '../../services/apiFetch';

function ContainerOrder() {
  const [state] = useStateValue();
  const [isLoading, setIsLoading] = useState(false);
  const [getListRender, setGetListRender] = useState(true);
  const [dataInfoGrid, SetDataInfoGrid] = useState([]);

  useEffect(() => {
    const getContainerProjection = async () => {
      try {
        const endpoint = getContainerOrder();
        const response = await apiFetch(endpoint);

        if (response && response.data) {
          let arr = response.data;
          if (Array.isArray(arr) && arr.length > 0) {
            SetDataInfoGrid(arr);
          } else {
            SetDataInfoGrid([]);
          }
        } else {
          SetDataInfoGrid([]);
        }
        setIsLoading(false);
      } catch (e) {
        console.log(e);
        setIsLoading(false);
      }
    };

    if (getListRender) {
      getContainerProjection().then(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }

    return () => setGetListRender(false);
  }, [getListRender]);

  if (!state.isLogin) return <Redirect to='/login' />;
  if (state.isLogin && state.newUser) return <Redirect to='/change-password' />;

  const formatNumber = (number) => {
    const fixedNumber = Number.parseInt(number === null ? 0 : number);
    return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const formatFixNumber = (cell) => {
    return formatNumber(cell);
  };
  const columns = [
    {
      dataField: 'Destination',
      text: 'CONSIGNEE',
      headerStyle: () => {
        return { width: '130px', textAlign: 'center' };
      },
    },
    {
      dataField: 'TotalWeightChill',
      text: 'TOTAL WEIGHT CHILL',
      align: 'right',
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
      formatter: formatFixNumber,
    },
    {
      dataField: 'TotalWeightFreeze',
      text: 'TOTAL WEIGHT FREEZE',
      align: 'right',
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
      formatter: formatFixNumber,
    },
    {
      dataField: 'TotalWeightDry',
      text: 'TOTAL WEIGHT DRY',
      align: 'right',
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
      formatter: formatFixNumber,
    },
    {
      dataField: 'TotalCubeChill',
      text: 'TOTAL CUBE CHILL',
      align: 'right',
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
      formatter: formatFixNumber,
    },
    {
      dataField: 'TotalCubeFreeze',
      text: 'TOTAL CUBE FREEZE',
      align: 'right',
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
      formatter: formatFixNumber,
    },
    {
      dataField: 'TotalCubeDry',
      text: 'TOTAL CUBE DRY',
      align: 'right',
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
      formatter: formatFixNumber,
    },
    {
      dataField: '20FTChill',
      text: '20FT CHILL',
      align: 'right',
      style: () => {
        return { backgroundColor: '#a3c992' };
      },
      headerStyle: () => {
        return {
          width: '100px',
          textAlign: 'center',
          backgroundColor: '#a3c992',
        };
      },
    },
    {
      dataField: '20FTFreeze',
      text: '20FT FREEZE',
      align: 'right',
      style: () => {
        return { backgroundColor: '#a3c992' };
      },
      headerStyle: () => {
        return {
          width: '100px',
          textAlign: 'center',
          backgroundColor: '#a3c992',
        };
      },
    },
    {
      dataField: '20FTDry',
      text: '20FT DRY',
      align: 'right',
      style: () => {
        return { backgroundColor: '#a3c992' };
      },
      headerStyle: () => {
        return {
          width: '80px',
          textAlign: 'center',
          backgroundColor: '#a3c992',
        };
      },
    },
    {
      dataField: '40FTChill',
      text: '40FT CHILL',
      align: 'right',
      style: () => {
        return { backgroundColor: '#a3c992' };
      },
      headerStyle: () => {
        return {
          width: '100px',
          textAlign: 'center',
          backgroundColor: '#a3c992',
        };
      },
    },
    {
      dataField: '40FTFreeze',
      text: '40FT FREEZE',
      align: 'right',
      style: () => {
        return { backgroundColor: '#a3c992' };
      },
      headerStyle: () => {
        return {
          width: '100px',
          textAlign: 'center',
          backgroundColor: '#a3c992',
        };
      },
    },
    {
      dataField: '40FTDry',
      text: '40FT DRY',
      align: 'right',
      style: () => {
        return { backgroundColor: '#a3c992' };
      },
      headerStyle: () => {
        return {
          width: '80px',
          textAlign: 'center',
          backgroundColor: '#a3c992',
        };
      },
    },
  ];

  return (
    <div className='wrapper'>
      <div className='loading-spinner'>
        <ClipLoader size={100} color={'#0054a6'} loading={isLoading} css={SpinnerStyle} />
      </div>
      <div className='help-button'>
        <a
          href='https://triplejlogistics.org/help/ReportsContainerOrderMenu.html'
          target='_blank'
          rel='noopener noreferrer'
          className='btn btn-labeled btn-outline-dark btn-sm mr-1'>
          <span className='btn-label'>
            <i className='fa fa-question-circle pr-1'></i>
          </span>
          Help
        </a>
      </div>
      <div className='generic-content-container container-fluid'>
        <h2>Reference Containers Order</h2>
      </div>
      <div className='container-fluid'>
        <div className='row mb-4'>
          <div className='col-md-12'>
            <button type='button' className='btn btn-labeled btn-primary mr-1' hidden={true}>
              <span className='btn-label'>
                <i className='fa fa-print pr-1'></i>
              </span>
              View PDF Report
            </button>
          </div>
        </div>
      </div>
      <div className='container-fluid table--horiz-scroll'>
        <BootstrapTable
          keyField='Destination'
          data={dataInfoGrid}
          columns={columns}
          filter={filterFactory()}
          filterPosition='top'
          striped
          hover
        />
      </div>
    </div>
  );
}

export default ContainerOrder;
