// noinspection JSCheckFunctionSignatures,JSUnresolvedVariable,JSValidateTypes

import React, { useEffect, useMemo, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import * as ReactBootstrap from 'react-bootstrap';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import ClipLoader from 'react-spinners/ClipLoader';
import { useStateValue } from '../context/StateProvider';
import { SpinnerStyle } from '../styles/SpinnerStyle';
import momentTZ from 'moment-timezone';
import {
  fetchCompleteContainerForEmailList,
  getContainerInfoDetails,
  getContInfoWithPO,
  getLoadPlanViewList,
  changeContainerStatus,
} from '../services/containerEndpoint';
import apiFetch from '../services/apiFetch';
import { getMighalBillingPDF } from '../services/reportsEndpoint';
import { sendLoadPlan } from '../services/emailEndpoint';
import DoubleScrollbar from 'react-double-scrollbar';

const ViewContainer = () => {
  const [state, dispatch] = useStateValue();
  const [isLoading, SetIsLoading] = useState(false);
  const [getListRender, SetGetListRender] = useState(true);
  const [dataInfoGrid, SetDataInfoGrid] = useState([]);
  const [ContId, SetContId] = useState('');
  const [AvailCube, SetAvailCube] = useState(0);
  const [AvailLbs, SetAvailLbs] = useState(0);
  const [btnDisabled, SetBtnDisabled] = useState(true);
  const [ContNo, SetContNo] = useState('');
  const [isMighal, SetIsMighal] = useState(true);
  const [showDeliver, SetShowDeliver] = useState(false);
  const [isAffiliate, setIsAffiliate] = useState(false);
  const [viewContBy, SetViewContBy] = useState('mighal');
  const [departDate, SetDepartDate] = useState('');
  const [arrivalDate, SetArrivalDate] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [dataModalGrid, setDataModalGrid] = useState([]);
  const [inProcess, setInProcess] = useState(false);
  const [selectedContID, SetSelectedContID] = useState([]);
  const [EmailData, SetEmailData] = useState([]);
  const [HasZeroInvAmt, setHasZeroInvAmt] = useState(false);
  const [InvoiceNo, setInvoiceNo] = useState('');

  const userLoc = state.userLocation;
  const locationFilter = state.containerFilterView;
  const userAccess = localStorage.getItem('logisticsUserType');
  const permission = useMemo(() => ({ edit: userAccess !== 'User' }), [userAccess]);
  const isUserMighalDEV = useMemo(
    () => userLoc === 'TJ MIGHAL' || userLoc === 'DEVELOPMENT TEAM',
    [userLoc],
  );

  useEffect(() => {
    const initContainerGridView = () => {
      return new Promise(async (resolve, reject) => {
        try {
          const params = {
            showDeliver: false,
            filterContainer: viewContBy,
          };
          const endpoint = getLoadPlanViewList(params);
          const response = await apiFetch(endpoint);
          if (response) {
            let arr = response.data;
            /* console.log(arr);
            console.log(arr.length);
            console.log(Array.isArray(arr)); */
            if (Array.isArray(arr) && arr.length > 0) {
              resolve(arr);
            } else {
              reject('Empty');
            }
          } else {
            reject('Empty');
          }
        } catch (e) {
          console.log(e);
          reject('Failed');
        }
      });
    };

    const Get_CompleteContainerForEmailList = () => {
      return new Promise(async (resolve, reject) => {
        try {
          const endpoint = fetchCompleteContainerForEmailList();
          const response = await apiFetch(endpoint);
          if (response) {
            let arr = response.data;
            if (Array.isArray(arr) && arr.length > 0) {
              resolve(arr);
            } else {
              reject('Empty');
            }
          } else {
            reject('Empty');
          }
        } catch (e) {
          console.log(e);
          reject('Failed');
        }
      });
    };

    if (getListRender) {
      // locationFilter(state.containerFilterView);
      SetIsLoading(true);
      initContainerGridView()
        .then((result) => {
          let lastRecord = result[result.length - 1];
          dispatch({
            type: 'GET_MAX_CONT_ID',
            payload: lastRecord.Container_ID,
          });
          SetDataInfoGrid(result);
          if (isUserMighalDEV && permission.edit) {
            Get_CompleteContainerForEmailList()
              .then((result) => {
                setDataModalGrid(result);
                setShowModal(true);
              })
              .catch((err) => {
                console.log(err);
                setDataModalGrid([]);
                setShowModal(false);
              });
          }
          SetIsLoading(false);
        })
        .catch(() => {
          alert('No Record Found');
          SetIsLoading(false);
          SetDataInfoGrid([]);
        });
    } else {
      SetIsLoading(false);
    }

    return () => {
      SetGetListRender(false);
    };
  }, []);

  const history = useHistory();

  if (!state.isLogin) return <Redirect to='/login' />;
  if (state.isLogin && state.newUser) return <Redirect to='/change-password' />;

  const loadContainerGridView = (isDelivered, optContainer) => {
    return new Promise(async (resolve, reject) => {
      try {
        const params = {
          showDeliver: isDelivered,
          filterContainer: optContainer,
        };
        const endpoint = getLoadPlanViewList(params);
        const response = await apiFetch(endpoint);

        if (response) {
          let arr = response.data;
          /* console.log(arr);
          console.log(arr.length);
          console.log(Array.isArray(arr)); */
          if (Array.isArray(arr) && arr.length > 0) {
            resolve(arr);
          } else {
            reject('Empty');
          }
        } else {
          reject('Empty');
        }
      } catch (e) {
        console.log(e);
        reject('Failed');
      }
    });
  };

  const formatDate = (valueDate) => {
    let d = new Date(valueDate),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const formatINTNumber = (number) => {
    const fixedNumber = Number.parseInt(number === null ? 0 : number);
    return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const formatFixNumber = (cell) => {
    return formatINTNumber(cell);
  };

  const formatNumber = (number) => {
    const fixedNumber = Number.parseFloat(number === null ? 0 : number).toFixed(2);
    return String(fixedNumber).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const formatInvAmt = (cell, row) => {
    return formatNumber(row.TtlInvoiceAmt);
  };

  const formatIsMighal = (cell, row) => {
    return row.IsMighal ? 'Yes' : 'No';
  };

  const headerSortingStyle = {
    backgroundColor: '#b3e3fc',
  };

  const noneAffiliateStyle = (cell, row) => {
    if (row.isAffiliate === 0) {
      return {
        backgroundColor: '#f0f0b6',
        overflowWrap: 'anywhere',
      };
    } else {
      return { overflowWrap: 'anywhere' };
    }
  };

  const columns = [
    {
      dataField: 'Container_ID',
      text: 'Id',
      hidden: true,
      headerStyle: () => {
        return { width: '40px', textAlign: 'left' };
      },
    },
    {
      dataField: 'IsMighal',
      text: 'IsMighal',
      hidden: true,
    },
    {
      dataField: 'isAffiliate',
      text: 'IsAffiliate',
      hidden: true,
    },
    {
      dataField: 'isSIT',
      text: 'isSIT',
      hidden: true,
    },
    {
      dataField: 'HasZeroInvAmt',
      text: 'hasZero',
      hidden: true,
    },
    {
      dataField: 'Status',
      text: 'Status',
      sort: true,
      headerSortingStyle,
      style: noneAffiliateStyle,
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
      filter: textFilter(),
      editor: {
        type: Type.SELECT,
        getOptions: () => {
          return [
            {
              value: 'Loading',
              label: 'Loading',
            },
            {
              value: 'Shipped',
              label: 'Shipped',
            },
            {
              value: 'On-Hold',
              label: 'On-Hold',
            },
            {
              value: 'Delivered',
              label: 'Delivered',
            },
            {
              value: 'Cancel',
              label: 'Cancel',
            },
          ];
        },
      },
    },
    {
      dataField: 'Destination',
      text: 'Consignee',
      sort: true,
      headerSortingStyle,
      style: noneAffiliateStyle,
      headerStyle: () => {
        return { width: '120px', textAlign: 'center' };
      },
      filter: textFilter({
        defaultValue: locationFilter,
      }),
      editable: false,
    },
    {
      dataField: 'Temperature_Type',
      text: 'Temp',
      sort: true,
      style: noneAffiliateStyle,
      headerStyle: () => {
        return { width: '90px', textAlign: 'center' };
      },
      filter: textFilter(),
    },
    {
      dataField: 'File_Ref',
      text: 'File Ref',
      sort: true,
      headerSortingStyle,
      style: noneAffiliateStyle,
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
      filter: textFilter(),
      editable: false,
    },
    {
      dataField: 'Container_No',
      text: 'Container No.',
      sort: true,
      headerSortingStyle,
      style: noneAffiliateStyle,
      headerStyle: () => {
        return { width: '200px', textAlign: 'center' };
      },
      filter: textFilter(),
      editable: false,
    },
    {
      dataField: 'Booking',
      text: 'Booking No.',
      sort: true,
      headerSortingStyle,
      style: noneAffiliateStyle,
      headerStyle: () => {
        return { width: '150px', textAlign: 'center' };
      },
      filter: textFilter(),
      editable: false,
    },
    {
      dataField: 'Seal_No',
      text: 'Seal No.',
      style: noneAffiliateStyle,
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
      editable: false,
    },
    {
      dataField: 'Container_Type',
      text: 'Container Type',
      sort: true,
      headerSortingStyle,
      style: noneAffiliateStyle,
      headerStyle: () => {
        return { width: '150px', textAlign: 'center' };
      },
      editable: false,
    },
    {
      dataField: 'Vessel',
      text: 'Vessel',
      sort: true,
      headerSortingStyle,
      style: noneAffiliateStyle,
      headerStyle: () => {
        return { width: '150px', textAlign: 'center' };
      },
      filter: textFilter(),
      editable: false,
    },
    {
      dataField: 'Voyage',
      text: 'Voyage',
      align: 'right',
      style: noneAffiliateStyle,
      headerStyle: () => {
        return { width: '80px', textAlign: 'center' };
      },
      filter: textFilter(),
      editable: false,
    },
    {
      dataField: 'Departure_Date',
      text: 'Departure',
      sort: true,
      headerSortingStyle,
      style: noneAffiliateStyle,
      headerStyle: () => {
        return { width: '120px', textAlign: 'center' };
      },
      filter: textFilter(),
      editable: false,
    },
    {
      dataField: 'Arrival_Date',
      text: 'Arrival',
      sort: true,
      headerSortingStyle,
      style: noneAffiliateStyle,
      headerStyle: () => {
        return { width: '120px', textAlign: 'center' };
      },
      filter: textFilter(),
      editable: false,
    },
    {
      dataField: 'Available_Cu',
      text: 'Avail. CuFt.',
      align: 'right',
      style: noneAffiliateStyle,
      headerStyle: () => {
        return { width: '90px', textAlign: 'center' };
      },
      formatter: formatFixNumber,
      editable: false,
    },
    {
      dataField: 'Available_Lbs',
      text: 'Avail. Lbs.',
      align: 'right',
      style: noneAffiliateStyle,
      headerStyle: () => {
        return { width: '90px', textAlign: 'center' };
      },
      formatter: formatFixNumber,
      editable: false,
    },
    {
      dataField: 'TtlInvoiceAmt',
      text: 'Invoice Amount',
      align: 'right',
      style: (cell, row) => {
        if (row.HasZeroInvAmt === 1) {
          return {
            backgroundColor: '#ff9900',
          };
        }
        if (row.isAffiliate === 0) {
          return {
            backgroundColor: '#f0f0b6',
          };
        }
      },
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
      formatter: formatInvAmt,
      editable: false,
    },
    {
      dataField: 'Invoice_No',
      text: 'Invoice No.',
      sort: true,
      headerSortingStyle,
      style: noneAffiliateStyle,
      headerStyle: () => {
        return { width: '90px', textAlign: 'center' };
      },
      filter: textFilter(),
      editable: false,
    },
    {
      dataField: 'IsMighal',
      text: 'Mighal Container',
      align: 'center',
      style: noneAffiliateStyle,
      headerStyle: () => {
        return { width: '90px', textAlign: 'center' };
      },
      formatter: formatIsMighal,
      editable: false,
    },
  ];

  const printButtonPDF = (cell, row) => {
    return (
      <div>
        <button className='btn btn-sm btn-danger' onClick={viewBillingPDF(row.Billing_ID)}>
          View
        </button>
      </div>
    );
  };

  const columnsModal = [
    {
      dataField: 'Container_ID',
      text: 'CONT ID',
      hidden: true,
    },
    {
      dataField: 'Attention',
      text: 'Attention',
      hidden: true,
    },
    {
      dataField: 'Email',
      text: 'Email',
      hidden: true,
    },
    {
      dataField: 'Container_Type',
      text: 'Container Type',
      hidden: true,
    },
    {
      dataField: 'Seal_No',
      text: 'Seal',
      hidden: true,
    },
    {
      dataField: 'Comment',
      text: 'Comment',
      hidden: true,
    },
    {
      dataField: 'Temperature_Type',
      text: 'Temperature_Type',
      hidden: true,
    },
    {
      dataField: 'Billing_ID',
      text: 'Print Billing',
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
      formatter: printButtonPDF,
      editable: false,
    },
    {
      dataField: 'Consignee',
      text: 'Consignee',
      headerStyle: () => {
        return { width: '150px', textAlign: 'center' };
      },
    },
    {
      dataField: 'File_Ref',
      text: 'File Ref',
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
    },
    {
      dataField: 'Invoice_No',
      text: 'Invoice No.',
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
    },
    {
      dataField: 'Container_No',
      text: 'Container No',
      headerStyle: () => {
        return { width: '200px', textAlign: 'center' };
      },
    },
    {
      dataField: 'Booking',
      text: 'Booking',
      headerStyle: () => {
        return { width: '120px', textAlign: 'center' };
      },
    },
    {
      dataField: 'Vessel',
      text: 'Vessel',
      headerStyle: () => {
        return { width: '120px', textAlign: 'center' };
      },
    },
    {
      dataField: 'Voyage',
      text: 'Voyage',
      headerStyle: () => {
        return { width: '100px', textAlign: 'center' };
      },
    },
    {
      dataField: 'Departure_Date',
      text: 'Departure',
      align: 'center',
      headerStyle: () => {
        return { width: '120px', textAlign: 'center' };
      },
    },
    {
      dataField: 'Arrival_Date',
      text: 'Arrival',
      align: 'center',
      headerStyle: () => {
        return { width: '120px', textAlign: 'center' };
      },
    },
  ];

  const viewBillingPDF = (prop) => (ev) => {
    ev.preventDefault();
    let BillId = prop;

    if (BillId > 0) {
      setInProcess(true);
      downloadBillingPDF(BillId)
        .then((response) => {
          setInProcess(false);
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: 'application/pdf' }),
          );
          window.open(url);
        })
        .catch((err) => {
          console.log(err);
          setInProcess(false);
          alert('No PDF Available. Contact your System Admin.');
        });
    } else {
      console.log('Billing_ID:', BillId);
    }
  };

  const downloadBillingPDF = (BillId) => {
    return new Promise(async (resolve, reject) => {
      const params = {
        billingId: BillId,
      };
      const endpoint = getMighalBillingPDF(params);
      const response = await apiFetch(endpoint);
      if (response.data.type !== 'application/json') {
        resolve(response);
      } else {
        reject('Failed');
      }
    });
  };

  const getContInfoById = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoint = getContainerInfoDetails(ContId);
        const response = await apiFetch(endpoint);
        if (response) {
          resolve(response.data);
        } else {
          reject('Failed');
        }
      } catch (error) {
        console.log(error);
        reject('Error');
      }
    });
  };

  const modifiedDate = (dateValue) => {
    return new Date(momentTZ.tz(dateValue, 'Pacific/Guam'));
  };

  // const formatDateString = (dateValue) => {
  //   let myDateString =
  //     dateValue.getFullYear() +
  //     ',' +
  //     ('0' + (dateValue.getMonth() + 1)).slice(-2) +
  //     ',' +
  //     ('0' + dateValue.getDate()).slice(-2);
  //   return myDateString;
  // };

  const handleViewContDetails = () => {
    try {
      if (ContId !== '') {
        SetIsLoading(true);
        getContInfoById()
          .then((result) => {
            //console.log(result.Comment);
            // console.log('arrivalDate:', arrivalDate);
            // console.log(
            //   'new DateValue',
            //   new Date(arrivalDate.replace('-', '/'))
            // );
            //console.log(new Date(modifiedDate(result.Departure_Date)));
            let DeptDate = new Date(departDate.replace('-', '/'));
            let ArrvDate = new Date(arrivalDate.replace('-', '/'));
            let CrtDate = modifiedDate(result.Create_Date);
            let ContInfoData = {
              containerId: result.Container_ID,
              container: result.Container_No,
              c_type: result.Container_Type,
              l_type: result.Temperature_Type,
              vessel: result.Vessel,
              voyage: result.Voyage,
              booking: result.Booking,
              attention: result.Attention,
              emailTo: result.Email,
              destination: result.Destination,
              depart: DeptDate,
              arrival: ArrvDate,
              createDte: CrtDate,
              seal: result.Seal_No,
              status: result.Status,
              available_cu: AvailCube,
              available_lbs: AvailLbs,
              comment: result.Comment,
              oceanfreight: result.OceanFreight,
              isSIT: result.isSIT === 1,
              file_ref: result.File_Ref === null ? '' : result.File_Ref,
              isAffiliate,
              HasZeroInvAmt,
              InvoiceNo,
            };
            SetIsLoading(false);
            SetGetListRender(false);
            if (isMighal) {
              dispatch({ type: 'POPULATE_LOAD_PLAN', payload: ContInfoData });
              history.push('/container-mighal');
            } else {
              dispatch({ type: 'SET_NON_MIGHAL', payload: ContInfoData });
              history.push('/container-nonmighal');
            }
          })
          .catch((err) => {
            SetGetListRender(false);
            console.log(err);
          });
      } else {
        alert('Please select a Container');
      }
    } catch (error) {
      console.log(error);
      SetIsLoading(false);
    }
  };

  const handleTrackMatsonCont = (ev) => {
    ev.preventDefault();
    if (ContNo !== '') {
      dispatch({ type: 'TRACK_MATSON', payload: ContNo });
      history.push('/matsontracking');
    }
  };

  const handleCreateNewCont = (ev) => {
    ev.preventDefault();
    const defaultLoadPlanForm = {
      containerId: 0,
      container: '',
      c_type: '1X20 REEFER',
      l_type: 'CHILL',
      vessel: '',
      voyage: '',
      booking: '',
      attention: '',
      emailTo: '',
      destination: 'SELECT CONSIGNEE',
      location: '',
      depart: new Date(),
      arrival: new Date(),
      seal: '',
      status: 'Loading',
      available_cu: 0,
      available_lbs: 0,
      createDte: new Date(),
      comment: '',
      oceanfreight: 0,
      isSIT: false,
      file_ref: '',
      isAffiliate: true,
      HasZeroInvAmt: false,
      InvoiceNo: '',
    };

    dispatch({ type: 'POPULATE_LOAD_PLAN', payload: defaultLoadPlanForm });
    history.push('/container-mighal');
  };

  const onRowSelect = (row) => {
    try {
      SetIsMighal(row.IsMighal);
      SetContId(row.Container_ID);
      SetAvailCube(row.Available_Cu);
      SetAvailLbs(row.Available_Lbs);
      SetContNo(row.Container_No);
      SetDepartDate(row.Departure_Date);
      SetArrivalDate(row.Arrival_Date);
      setIsAffiliate(row.isAffiliate === 1);
      setHasZeroInvAmt(row.HasZeroInvAmt);
      setInvoiceNo(row.Invoice_No);
      SetBtnDisabled(false);
    } catch (error) {
      console.log(error);
    }
  };

  const selectRowProp = {
    mode: 'radio',
    clickToSelect: true,
    onSelect: onRowSelect,
    clickToEdit: true,
  };

  /*   const rowEventsProp = {
    onDoubleClick: (e, row, rowIndex) => {
      handleViewContDetails();
    },
  }; */

  const handleViewContByChange = (ev) => {
    try {
      SetViewContBy(ev.target.value);
      loadContainerGridView(showDeliver, ev.target.value)
        .then((result) => {
          SetDataInfoGrid(result);
          SetIsLoading(false);
        })
        .catch((err) => {
          SetDataInfoGrid([]);
          SetIsLoading(false);
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleChecked = () => {
    SetShowDeliver(!showDeliver);
    try {
      SetIsLoading(true);
      loadContainerGridView(!showDeliver, viewContBy)
        .then((result) => {
          SetDataInfoGrid(result);
          SetIsLoading(false);
        })
        .catch((err) => {
          SetDataInfoGrid([]);
          SetIsLoading(false);
          console.log(err);
        });
    } catch (e) {
      console.log(e);
      SetIsLoading(false);
    }
  };

  const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className='btn-group' role='group'>
      {options.map((option) => {
        const isSelect = currSizePerPage === `${option.page}`;
        return (
          <button
            key={option.text}
            type='button'
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${isSelect ? 'btn-secondary' : 'btn-default'}`}>
            {option.text}
          </button>
        );
      })}
    </div>
  );

  const PaginationOptions = {
    hideSizePerPage: true,
    sizePerPage: 5,
    sizePerPageRenderer,
  };

  const beforeSaveCell = (oldValue, newValue, row, column, done) => {
    setTimeout(() => {
      let Destination = row.Destination;
      if (!isUserMighalDEV) {
        if (Destination !== userLoc) {
          alert('User Location does not match with PO Consignee');
          done(false);
        } else {
          if (newValue !== 'Delivered') {
            alert('Current user can only select Delivered Status');
            done(false);
          } else {
            done();
          }
        }
      } else if (userLoc === 'TJ MIGHAL') {
        if (newValue === 'Delivered') {
          alert('Current user can not update to Delivered Status');
          done(false);
        } else {
          done();
        }
      } else {
        done();
      }
    }, 0);
    return { async: true };
  };

  const afterSaveCell = (oldValue, newValue, column) => {
    try {
      //let colField = row.dataField;
      let Cont_ID = column.Container_ID;
      let isDelivered = showDeliver;
      if (oldValue !== newValue) {
        SetIsLoading(true);
        updateContainerStatus(newValue, Cont_ID)
          .then((result) => {
            console.log(result);
            loadContainerGridView(isDelivered, viewContBy)
              .then((result) => {
                SetDataInfoGrid(result);
              })
              .catch((err) => {
                console.log(err);
              });
            SetIsLoading(false);
          })
          .catch(() => {
            SetIsLoading(false);
            alert('Unable to update Server! Please contact your Admin');
          });
      }
    } catch (e) {
      console.log(e);
      SetIsLoading(false);
    }
  };

  const updateContainerStatus = (newStatus, ContID) => {
    return new Promise(async (resolve, reject) => {
      try {
        const data = {
          UserEmail: localStorage.getItem('logisticsUseremail'),
          ContainerStatus: newStatus,
          Container_ID: ContID,
        };
        const endpoint = changeContainerStatus(data);
        const response = await apiFetch(endpoint);
        if (response.data === 'Success') {
          resolve('Success');
        } else {
          reject('Failed');
        }
      } catch (e) {
        console.log(e);
        reject('Failed');
      }
    });
  };

  const handleCloseModal = () => setShowModal(false);

  /* const handleOpenModal = () => {
    setInProcess(false);
  }; */

  const removeContIDSelected = (arr, value) => {
    let index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  };

  let DefaultEmailData = {
    PrintDate: '',
    token: '',
    UserEmail: '',
    ContId: 0,
    Departure: '',
    Attention: '',
    Arrival: '',
    Consignee: '',
    ContType: '',
    Destination: '',
    Booking: '',
    Vessel: '',
    Seal: '',
    Voyage: '',
    Container_No: '',
    To: '',
    PO_List: '',
    Comments: '',
    Temperature: '',
    FileRef: '',
    InvoiceNo: '',
  };

  const handleOnSelect = (row, isSelect) => {
    let today = new Date();
    let countryZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
    let userToday = momentTZ.tz(today, countryZone).format('YYYY-MM-DD');

    if (isSelect) {
      DefaultEmailData = {
        PrintDate: userToday,
        token: localStorage.getItem('logisticsToken'),
        UserEmail: localStorage.getItem('logisticsUseremail'),
        ContId: row.Container_ID,
        Departure: formatDate(row.Departure_Date),
        Attention: row.Attention,
        Arrival: formatDate(row.Arrival_Date),
        Consignee: row.Consignee,
        ContType: row.Container_Type,
        Destination: row.Consignee,
        Booking: row.Booking,
        Vessel: row.Vessel,
        Seal: row.Seal_No,
        Voyage: row.Voyage,
        Container_No: row.Container_No,
        To: row.Email,
        PO_List: [],
        Comments: row.Comment,
        Temperature: row.Temperature_Type,
        FileRef: row.File_Ref,
        InvoiceNo: row.Invoice_No,
      };

      let currentSelect = selectedContID;
      let currentEmailData = EmailData;
      SetSelectedContID([...currentSelect, row.Container_ID]);
      SetEmailData([...currentEmailData, DefaultEmailData]);
    } else {
      let newArray = removeContIDSelected(selectedContID, row.Container_ID);
      SetSelectedContID(newArray);

      let newEmailDataArray = EmailData.filter((item) => item.ContId !== row.Container_ID);
      // console.log({ newEmailDataArray });
      SetEmailData(newEmailDataArray);
    }
  };

  const handleOnSelectAll = (isSelect, rows) => {
    if (isSelect) {
      const ContID = rows.map((r) => r.Container_ID);
      SetSelectedContID(ContID);

      let today = new Date();
      let countryZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
      let userToday = momentTZ.tz(today, countryZone).format('YYYY-MM-DD');

      const EmailDataSelection = rows.map((row) => {
        DefaultEmailData = {
          PrintDate: userToday,
          token: localStorage.getItem('logisticsToken'),
          UserEmail: localStorage.getItem('logisticsUseremail'),
          ContId: row.Container_ID,
          Departure: formatDate(row.Departure_Date),
          Attention: row.Attention,
          Arrival: formatDate(row.Arrival_Date),
          Consignee: row.Consignee,
          ContType: row.Container_Type,
          Destination: row.Consignee,
          Booking: row.Booking,
          Vessel: row.Vessel,
          Seal: row.Seal_No,
          Voyage: row.Voyage,
          Container_No: row.Container_No,
          To: row.Email,
          PO_List: [],
          Comments: row.Comment,
          Temperature: row.Temperature_Type,
          FileRef: row.File_Ref,
          InvoiceNo: row.Invoice_No,
        };
        return DefaultEmailData;
      });
      // console.log(EmailDataSelection);
      SetEmailData(EmailDataSelection);
    } else {
      SetSelectedContID([]);
      SetEmailData([]);
    }
  };

  const selectRowPropModal = {
    mode: 'checkbox',
    clickToSelect: false,
    selected: selectedContID,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
  };

  const sendEmailNotification = (Data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const endpoint = sendLoadPlan(Data.ContId, Data);
        const response = await apiFetch(endpoint);
        if (response.data === 'Success') {
          resolve('Sent');
        } else {
          reject('Failed');
        }
      } catch (e) {
        console.log(e);
        reject('Failed');
      }
    });
  };

  const GetPOListQuery = (ContId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const params = {
          containerId: ContId,
        };
        const endpoint = getContInfoWithPO(params);
        const response = await apiFetch(endpoint);
        if (response) {
          let gotData = response.data;
          let holdArray = [];
          if (Array.isArray(gotData) && gotData.length > 0) {
            gotData.map((row) => {
              return holdArray.push(row.PO_No);
            });
            resolve(holdArray);
          } else {
            reject('Empty');
          }
        } else {
          reject('Empty');
        }
      } catch (e) {
        console.log(e);
        reject('Failed');
      }
    });
  };

  const handleSendEmail = async () => {
    if (Array.isArray(EmailData) && EmailData.length > 0) {
      setInProcess(true);
      let len = 0;
      let arrayLen = EmailData.length;

      for (const value of EmailData) {
        len = len + 1;
        await GetPOListQuery(value.ContId)
          .then(async (POListQuery) => {
            let HoldEmailData;
            HoldEmailData = { ...value, PO_List: POListQuery };

            await sendEmailNotification(HoldEmailData)
              .then((result) => {
                console.log('sendEmailNotification', result);
              })
              .catch((err) => {
                console.log('sendEmailNotification', err);
              });
          })
          .catch(() => {
            console.log('GetPOListQuery Failed');
          });
      }

      if (len === arrayLen) {
        setInProcess(false);
        handleCloseModal();
      } else {
        setInProcess(false);
        alert('Unable to send the email. Contanct your Admin Support');
        handleCloseModal();
      }
    } else {
      return alert('Please mark a CHECK to select the Container');
    }
  };

  return (
    <div className='wrapper'>
      <div className='loading-spinner'>
        <ClipLoader size={100} color={'#0054a6'} loading={isLoading} css={SpinnerStyle} />
      </div>
      <div className='help-button'>
        <a
          href='https://triplejlogistics.org/help/ViewContainerMenu.html'
          target='_blank'
          rel='noopener noreferrer'
          className='btn btn-labeled btn-outline-dark btn-sm mr-1'>
          <span className='btn-label'>
            <i className='fa fa-question-circle pr-1'></i>
          </span>
          Help
        </a>
      </div>
      <ReactBootstrap.Modal
        dialogClassName='modal-size'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={showModal}
        onHide={handleCloseModal}>
        <ReactBootstrap.Modal.Header closeButton>
          <ReactBootstrap.Modal.Title id='contained-modal-title-vcenter'>
            Container With Completed Documents Pending For Email
          </ReactBootstrap.Modal.Title>
        </ReactBootstrap.Modal.Header>
        <ReactBootstrap.Modal.Body>
          <div className='container-fluid wrapper'>
            <ReactBootstrap.Row>
              <div className='container-fluid scroll-container'>
                <BootstrapTable
                  keyField='Container_ID'
                  data={dataModalGrid}
                  columns={columnsModal}
                  selectRow={selectRowPropModal}
                  striped
                  hover
                />
              </div>
            </ReactBootstrap.Row>
          </div>
        </ReactBootstrap.Modal.Body>
        <ReactBootstrap.Modal.Footer>
          <ReactBootstrap.Button variant='secondary' onClick={handleCloseModal}>
            Close
          </ReactBootstrap.Button>
          <ReactBootstrap.Button variant='primary' disabled={inProcess} onClick={handleSendEmail}>
            {inProcess ? 'Processing...' : 'Send Email'}
          </ReactBootstrap.Button>
        </ReactBootstrap.Modal.Footer>
      </ReactBootstrap.Modal>
      <div className='generic-content-container container-fluid'>
        <h2>View Containers</h2>
      </div>
      <div className='container-fluid'>
        <div className='container-fluid'>
          <ReactBootstrap.Row className='mb-2'>
            {permission.edit ? (
              <button
                type='button'
                className='btn btn-labeled btn-primary btn-sm mr-1'
                onClick={handleCreateNewCont}>
                <span className='btn-label'>
                  <i className='fa fa-plus pr-1'></i>
                </span>
                Create New Mighal Container
              </button>
            ) : null}
            <button
              type='button'
              className='btn btn-labeled btn-primary btn-sm mr-1'
              onClick={handleViewContDetails}
              disabled={btnDisabled}>
              <span className='btn-label'>
                <i className='fa fa-edit pr-1'></i>
              </span>
              View Container Details
            </button>
            <button
              type='button'
              className='btn btn-labeled btn-primary btn-sm mr-1'
              disabled={btnDisabled}
              onClick={handleTrackMatsonCont}>
              <span className='btn-label'>
                <i className='fa fa-map-marker pr-1'></i>
              </span>
              Track Matson Container
            </button>
            <div className='mt-auto mb-auto'>
              <ReactBootstrap.Form.Check
                type='checkbox'
                checked={showDeliver}
                onChange={handleChecked}
                id='select_isdelivered'
                className='ml-2 mr-4'
                label='Delivered'
              />
            </div>
            <label className='radio-btn mt-auto mb-auto'>Container By: </label>
            <input
              className='radio-btn mt-auto mb-auto'
              type='radio'
              id='container'
              name='view'
              value='mighal'
              checked={viewContBy === 'mighal'}
              onChange={handleViewContByChange}
            />
            <label htmlFor='container' className='mt-auto mb-auto'>
              Mighal
            </label>
            <input
              className='radio-btn mt-auto mb-auto'
              type='radio'
              id='container'
              name='view'
              value='nonmighal'
              checked={viewContBy === 'nonmighal'}
              onChange={handleViewContByChange}
            />
            <label htmlFor='container' className='mt-auto mb-auto'>
              Non-Mighal
            </label>
            <input
              className='radio-btn mt-auto mb-auto'
              type='radio'
              id='container'
              name='view'
              value='all'
              checked={viewContBy === 'all'}
              onChange={handleViewContByChange}
            />
            <label htmlFor='container' className='mt-auto mb-auto'>
              All
            </label>
          </ReactBootstrap.Row>
        </div>
      </div>
      <div className='container-fluid'>
        <button className='btn btn-secondary btn-sm mb-2' disabled={true} hidden={true}>
          Clear Filter
        </button>
      </div>

      <div className='container-fluid scroll-container'>
        <DoubleScrollbar>
          <BootstrapTable
            keyField='Container_ID'
            data={dataInfoGrid}
            columns={columns}
            selectRow={selectRowProp}
            filter={filterFactory()}
            pagination={paginationFactory(PaginationOptions)}
            cellEdit={cellEditFactory({
              mode: 'dbclick',
              blurToSave: true,
              beforeSaveCell,
              afterSaveCell,
            })}
            filterPosition='top'
            striped
            hover
          />
        </DoubleScrollbar>
      </div>
    </div>
  );
};

export default ViewContainer;
